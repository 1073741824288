import React from 'react';
import animationData from '../Assets/Loaders/Loader.json';
import Lottie from 'react-lottie';

const LottieAnimation = () => {
  const defaultOptions = {
    autoplay: true,
    animationData: animationData,
    isStopped: false, // Ensure animation doesn't stop
    isClickToPauseDisabled: true, // Disable pausing on click
  };

  return (
    <div className='container'>
      <Lottie options={defaultOptions} width={360}  />
    </div>
  );
};

export default LottieAnimation;
