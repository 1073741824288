import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Helper from '../../../utils/helper';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeForgetPassword = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isResend, setTsResend] = useState<boolean>(false);
  const helper = Helper.getInstance();

  const [email, setEmail] = useState<string>('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    if (isEmailValid) {
      await handleForgot();
    } else {
      setLoading(false);
      toast.error('Enter Valid Email')
    }

  };

  const isCompanyEmail = (email: string): boolean => {
    // // List of company domains to compare against
    // const commonEmailProviderDomains: string[] = [
    //   'gmail.com',
    //   'yahoo.com',
    //   'outlook.com',
    //   'aol.com',
    //   'icloud.com',
    //   'protonmail.com',
    //   'zoho.com',
    //   'mail.com',
    //   'yandex.com',
    //   'gmx.com',
    //   'tutanota.com',
    //   'fastmail.com',
    //   'hushmail.com',
    //   'rackspace.com',
    //   'cox.net',
    //   'earthlink.net',
    //   'att.net',
    //   'comcast.net',
    //   'verizon.net',
    //   'spectrum.net',
    //   '',
    //   '.com',
    //   '.org',
    //   '.net'
    //   // Add more domains as needed
    // ];

    // // Extract the domain from the email address
    // const emailParts = email.split('@');
    // if (emailParts.length !== 2) return false;

    // const domain = emailParts[1];

    // return !commonEmailProviderDomains.includes(domain);
    return true;
  };

  const verifyEmail = (email: string) => {
    return new Promise((resolve, reject) => {
      const validEmail = isCompanyEmail(email);
      console.log(validEmail);
      setIsEmailValid(validEmail);
      resolve(validEmail);
    })
  };

  const handleEmailChange = async (email: string) => {
    await verifyEmail(email);
    setEmail(email);
  };

  const handleForgot = async () => {
    try {
      const res_data = await helper.postReq(helper.POST.EMP_FORGOT_PASSWORD, { email });
      const { data } = res_data.data;
      setLoading(false);
      if (data) {
        toast.success('Reset Password Link Sent Successfully to your Email');
        setTsResend(true);
      }
      else throw new Error("Failed");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Invalid Email ID');
    }
  };

  return (
    <>
      <section>
        <div className='row'>
          <div className='col-sm-6 lets-get-started-container d-none d-sm-block d-print-block'>
            <div className='container'>
              <h1 className='owow d-sm-none mb-4'>owow</h1>

              <div className='cst-mobile-bg '>
                <div className='right-side-container'>
                  <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started mb-4' />
                  <h2 className="welcome-lete-started" >Reset Password</h2>
                  <h4 className='text-white'>Access high-paying tech jobs at the best tech companies in the USA and India.</h4>
                  <ul className='text-white regsiter-page-lists p-0 mt-4'>
                    <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                    <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                    <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 '>
            {/* <div className='container cst-container-bg'> */}
            <div className='cst-container-bg'>
              {/* <h1 className='owow d-sm-none'>owow</h1> */}
              <div className='text-center'>
                <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow d-sm-none pt-4' />
              </div>

              <div className='cst-mobile-bg'>
                <form onSubmit={handleSubmit}>
                  <div className='right-side-container'>
                    {/* <h1 className='owow d-none d-sm-block d-print-block'>owow</h1> */}
                    <div className='d-none d-sm-block d-print-block'>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                    <h1 className='welcome-heading pt-5'>Forget Password!</h1>
                    <h4 className='heado-para margin-fix-bottam'>Enter your email address and we'll send you a link to reset your password.</h4>

                    <div className="col-12 input-group-lg margin-fix-bottam">
                      <label>Email ID</label>
                      <input
                        type="text"
                        id='email'
                        value={email}
                        onChange={(val) => handleEmailChange(val.target.value)}
                        className="form-control custom-input"
                        placeholder="Enter Your Email ID"
                        required
                      />
                      {!isEmailValid &&
                        <p style={{ color: 'red', fontSize: 'medium' }}>Please enter a valid company email address</p>
                      }
                    </div>

                    <div className="col-12 text-center margin-fix-bottam">
                      {!isResend ? (
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn create-account-btn p-3 col-12 '>
                          {!loading ? 'Send Password Reset Link' : 'Sending...'}
                        </button>
                      ) : (
                        <button
                          type='submit'
                          //   disabled={loading}
                          className='btn create-account-btn p-3 col-12 '>
                          {!loading ? 'ReSend Password Reset Link' : 'Resending...'}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default EmployeeForgetPassword