import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { CompanyDetails, CompanyFirstPageProps, User } from "../../types";

import Select from 'react-select';
import { BsFillCheckCircleFill } from "react-icons/bs"
import { companyProofOptions, companyTypes, countries, currencies, customStyles } from "../../utils/constant";
import Helper from "../../utils/helper";
import { toast } from "react-toastify";
// import useAuth from "../hooks/useAuth";
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../Assets/Icons/Logo_Horizontal_White.png'
const CompanyDetailsComponent = (props: CompanyFirstPageProps) => {

    const helper = Helper.getInstance();



    const [customer, setCustomer] = useState<User | null>(null);
    // const { login } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    const initialValue: CompanyDetails = {
        customerId: 1,
        country: "",
        currency: "",
        name: "",
        type: "",
        registrationNumber: "",
        VAT: "",
        EIN: "",
        taxId: "",
    };

    const [company, setCompany] = useState<CompanyDetails>(initialValue);
    const [radioselect, setRadioselect] = useState<boolean>(false);
    const [proof, setProof] = useState<string>('EIN');
    const [prErr, setPrErr] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setEditing] = useState<boolean>(false);

    useEffect(() => {
        if (!location.state || !location.state.customer) navigate('/employer-login', { replace: true });
        else {
            setCustomer(location.state.customer);
            // login('', '', location.state.customer);
        }

        // if (props && props.company) setCompany(location.state.company);
        if (location.state && location.state.company) {
            setCompany(location.state.company)
            setEditing(true);
        }
    }, []);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        if (prErr !== '') return toast.error(prErr);
        console.log(company);
        setLoading(true);
        if (isEditing) updateCompany();
        else addCompany();

    };

    const addCompany = async () => {
        try {
            const comData = { ...company, customerId: customer?.id };
            const res_data = await helper.postReq(helper.POST.ADD_COMPANY_DETAILS, comData);
            const { data } = res_data.data;

            if (data) props.handleNextButton(data);
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Add Company Details Failed');
        }
        setLoading(false);
    }

    const updateCompany = async () => {
        try {
            const comData = { ...company, companyId: company.id };
            delete comData.id;
            const res_data = await helper.putReq(helper.PUT.UPDATE_COMPANY, comData);
            const { data } = res_data.data;

            if (data) props.handleNextButton(data);
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Add Company Details Failed');
        }
        setLoading(false);
    }

    const regexTest = (val: string) => {
        if (proof == "EIN") {
            // const EINRegex: RegExp = /^\d{3}-\d{2}-\d{4}$/;
            const EINRegex: RegExp = /^[0-9]{2}-?[0-9]{7}$/;
            const valid = EINRegex.test(val);
            const msg = valid ? '' : 'EIN is incorrect';
            setPrErr(msg);
            handleProofChange(val);
        } else if (proof == "VAT") {
            // const customRegex: RegExp = /^[A-Z]{2}[0-9A-Z]+$/;
            const customRegex: RegExp = /^[0-9]{2}-?[0-9]{7}$/;
            const valid = customRegex.test(val);
            const msg = valid ? '' : 'VAT is incorrect';
            setPrErr(msg);
            handleProofChange(val);
        } else if (proof == "taxId") {
            // const customRegex: RegExp = /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d[Z]{1}[A-Z\d]{1})$/;
            const customRegex: RegExp  = /^[0-9]{2}-?[0-9]{7}$/;

            const valid = customRegex.test(val);
            const msg = valid ? '' : 'Tax ID is incorrect';
            setPrErr(msg);
            handleProofChange(val);
        } else {
            const valid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(val);
            const msg = valid ? '' : 'EIN is incorrect';
            setPrErr(msg);
            handleProofChange(val);
        }
    }

    const handleProofChange = (val: string) => {
        const newCompany: any = { ...company };
        newCompany.EIN = '';
        newCompany.VAT = '';
        newCompany.taxId = '';

        newCompany[proof] = val;
        setCompany(newCompany);
    }

    const handleNo = () => {
        setRadioselect(true);
        setPrErr('');
    };

    const [isOtherSelected, setIsOtherSelected] = useState<boolean>(false);

    const handleCTypeChange = (type: string) => {
        if (type === 'Others') {
            setCompany({ ...company, type: '' });
            setIsOtherSelected(true);
        } else {
            setCompany({ ...company, type });
            setIsOtherSelected(false);
        }
    };

    return (
        <>
            <section className='let-started'>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                            <div className='container'>
                                <div className='cst-mobile-bg '>
                                    <div className='right-side-container'>
                                        {/* <h1 className='  owow-lets-started'>owow</h1> */}
                                        <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />

                                        <h1 className='welcome-lete-started' style={{ fontSize: "30px" }}>Welcome {customer?.name || ''} 👋</h1>
                                        <p className="mt-4 mb-4"><BsFillCheckCircleFill style={{ fontSize: "30px" }} /> Personal Details</p>
                                        <h1 className='personal-details-heading' >Let’s get inside you company details</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='d-sm-block d-print-block cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                            <div className="text-center">
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none pt-4' />
                            </div>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    <h1 className='welcome-heading personal-details-heading-right-side'>Company details</h1>
                                    <h4 className='heado-para margin-fix-bottam'>We need your company details to shape your profile like a pro.</h4>
                                    <form name="CompanyForm" onSubmit={handleSubmit}>

                                        <label>Country*</label>
                                        <Select
                                            options={countries}
                                            value={countries.filter((ct) => ct.value == company.country)[0]}
                                            onChange={(ev) => setCompany({ ...company, country: ev?.value || '' })}
                                            styles={customStyles}
                                            className="mb-4"
                                            required
                                            placeholder="Select"
                                        />
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Legal Company Name*</label>
                                            <input
                                                type="text"
                                                value={company.name}
                                                onChange={(ev) => setCompany({ ...company, name: ev.target.value || '' })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                required
                                            />
                                        </div>

                                        <label>Company type*</label>
                                        <Select
                                            options={companyTypes}
                                            value={companyTypes.filter((ct) => ct.value == company.type)[0]}
                                            onChange={(ev) => handleCTypeChange(ev?.value || '')}
                                            styles={customStyles}
                                            className="mb-4"
                                            required
                                            placeholder="Select"
                                        />
                                        {isOtherSelected && <div className="col-12 input-group-lg margin-fix-bottam"><input
                                            type="text"
                                            value={company.type}
                                            onChange={(ev) => setCompany({ ...company, type: ev.target.value || '' })}
                                            className="form-control custom-input"
                                            placeholder="Enter Company Type"
                                            required
                                        /></div>}

                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Does your company have a VAT, EIN or Tax ID number?</label><br />
                                            <div style={{ display: "flex", gap: "30px" }}>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        checked={!radioselect}
                                                        onChange={() => setRadioselect(false)}
                                                        id="yes"
                                                        name="drone"
                                                        value="yes"
                                                        className='form-check-input'
                                                    />
                                                    <label>&nbsp; Yes</label>
                                                </div>
                                                <div>
                                                    <input
                                                        type="radio"
                                                        checked={radioselect}
                                                        onChange={() => handleNo()}
                                                        id="no"
                                                        name="drone"
                                                        value="no"
                                                        className='form-check-input'
                                                    />
                                                    <label>&nbsp;No</label>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            radioselect ? (
                                                <div className="col-12 input-group-lg margin-fix-bottam">
                                                    <label>Company Registration Number*</label>
                                                    <input
                                                        type="text"
                                                        value={company.registrationNumber}
                                                        onChange={(ev) => setCompany({ ...company, registrationNumber: ev.target.value })}
                                                        className="form-control custom-input"
                                                        placeholder="Enter Here"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <Select
                                                            defaultValue={companyProofOptions[0]}
                                                            options={companyProofOptions}
                                                            onChange={(ev) => setProof(ev?.value || '')}
                                                            styles={customStyles}
                                                            className="mb-4"
                                                            placeholder="Select"
                                                        />
                                                    </div>
                                                    <div className="col-md-8 col-12 input-group-lg margin-fix-bottam">
                                                        <input
                                                            type="text"
                                                            value={company[proof as keyof CompanyDetails]}
                                                            onChange={(ev) => regexTest(ev.target.value)}
                                                            className="form-control custom-input"
                                                            placeholder="XX-XXXXXXX"
                                                        />
                                                        {prErr !== "" && <p style={{ color: 'red', fontSize: 'medium' }}>{prErr}</p>}

                                                    </div>
                                                </div>
                                            )
                                        }

                                        <label>Desired Currency*</label>
                                        <Select
                                            options={currencies}
                                            value={currencies.filter((ct) => ct.value == company.currency)[0]}
                                            onChange={(ev) => setCompany({ ...company, currency: ev?.value || '' })}
                                            styles={customStyles}
                                            className="mb-4"
                                            required
                                            placeholder="Select"
                                        />
                                        <div className="container col-12 text-center margin-fix-bottam pb-5" style={{ display: "flex", gap: "20px", justifyContent: 'space-evenly' }}>
                                            <button
                                                className='btn btn-outline create-account-btn-outline p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                                onClick={() => navigate('/employer-personal-details', { replace: true, state: { customer } })}
                                            >Back
                                            </button>
                                            <button
                                                type="submit"
                                                disabled={loading}
                                                className='btn create-account-btn p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                            >{loading ? 'Adding Details...' : 'Next'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompanyDetailsComponent