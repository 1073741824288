import { useState } from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { PasswordData, cusIdProps } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';

const ChangePasswordModal = ({ customerId }: cusIdProps) => {
    const [showPassword, setShowPassword] = useState<Number>(0);
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    const { logout } = useAuth();

    const initVal: PasswordData = {
        customerId: Number(customerId),
        oldPassword: '',
        newPassword: '',
        retypeNewPassword: '',
    };

    const [passwordData, setPasswordData] = useState<PasswordData>(initVal);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);

        if (passwordData.oldPassword === passwordData.newPassword) {
            setLoading(false);
            return toast.error('Old and New Password cannot be same');
        } else if (passwordData.newPassword !== passwordData.retypeNewPassword) {
            setLoading(false);
            return toast.error('New Password and Confirm Password Does not Match');
        } else if (!checkPassword(passwordData.newPassword)) {
            setLoading(false);
            return toast.error('Password Length 8-64 characters, At least 1 Lowercase, 1 Uppercase, 1 Number, 1 Special Character');
        } else {
            await changePasswordAPI();
        }
    }

    const changePasswordAPI = async () => {
        try {
            const reqData = {
                id: Number(customerId),
                password: passwordData.oldPassword,
                newPassword: passwordData.newPassword
            }
            const res_data = await helper.putReq(helper.PUT.EMP_UPDATE_PASSWORD, reqData);
            const { data } = res_data.data;
            setLoading(false);
            if (data) {
                toast.success('Changed Successfully, Please login with new Password');
                document.getElementById('closeChangePassword')?.click();
                logout();
            }
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Old Password is Incorrect');
        }
    }


    const checkPassword = (val: string) => {
        const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(val);
        return isPasswordValid;
    };

    const handleForgot = async () => {
        try {
            document.getElementById('closeChangePassword')?.click();
            const res_data = await helper.getReq(helper.GET.EMP_RESET_PASSWORD);
            const { data } = res_data.data;
            setLoading(false);
            if (data) {
                toast.success('Reset Password Link Sent Successfully to your Email');
            }
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Something Went Wrong');
        }
    };

    return (
        <>
            <div className="modal fade" id="chnagePassordModalToggle" aria-hidden="true" aria-labelledby="chnagePassordModalToggleLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered change-password-modal-cst" >
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3 id="chnagePassordModalToggleLabel">Change password</h3>
                                <button id='closeChangePassword' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12 input-group-lg ">
                                        <label>Current password</label>
                                        <input
                                            type={showPassword === 1 ? 'text' : 'password'}
                                            id='oldpass'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={passwordData.oldPassword}
                                            onChange={(ev) => setPasswordData({ ...passwordData, oldPassword: ev.target.value })}
                                            required
                                        />
                                        {showPassword === 1 ? <BsFillEyeFill onClick={() => setShowPassword(0)} /> : <BsFillEyeSlashFill onClick={() => setShowPassword(1)} />}
                                    </div>
                                    <div className="col-12 input-group-lg ">
                                        <label>New password</label>
                                        <input
                                            type={showPassword === 2 ? 'text' : 'password'}
                                            id='newPassword'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={passwordData.newPassword}
                                            onChange={(ev) => setPasswordData({ ...passwordData, newPassword: ev.target.value })}
                                            required
                                        />
                                        {showPassword === 2 ? <BsFillEyeFill onClick={() => setShowPassword(0)} /> : <BsFillEyeSlashFill onClick={() => setShowPassword(2)} />}
                                    </div>
                                    <div className="col-12 input-group-lg ">
                                        <label>Re-enter new password</label>
                                        <input
                                            type={showPassword === 3 ? 'text' : 'password'}
                                            id='rePassword'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={passwordData.retypeNewPassword}
                                            onChange={(ev) => setPasswordData({ ...passwordData, retypeNewPassword: ev.target.value })}
                                            required
                                        />
                                        {showPassword === 3 ? <BsFillEyeFill onClick={() => setShowPassword(0)} /> : <BsFillEyeSlashFill onClick={() => setShowPassword(3)} />}
                                    </div>
                                    <div className='d-flex my-2 justify-content-center chnagepasss-cst-col-btn' style={{ gap: "16px" }}>
                                        <button
                                            className='btn social-media-btn change-password-cancle-btn'
                                            type='button'
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='btn create-account-btn change-password-submit-btn'
                                            type='submit'
                                             disabled={loading}
                                        >
                                            {loading ? 'Updating...' : 'Update'}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className='text-center m-2'>
                                <a>
                                    <span style={{ fontSize: "18px", fontWeight: 600, color: "#BCBCBC" }}>Forget password? </span>
                                    <span onClick={() => handleForgot()} style={{ fontSize: "18px", fontWeight: 600, color: "#492A99", cursor: 'pointer' }}>Click here to reset password</span>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default ChangePasswordModal