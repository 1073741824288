import { useEffect, useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent'
import Select from 'react-select';
import RegtangleImg from '../../Assets/Images/CcRegtangle.svg'
import OwowTableLogo from '../../Assets/Icons/owowTableLogo.svg'
import DollerIcon from '../../Assets/Icons/dollerIcon.svg'
import InfoIcon from '../../Assets/Icons/infoIcon.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';
import { CostCalcOptions, CostCalcReturnData, CostWidthAndData, OptionType, SalaryDetails } from '../../types';
import useAuth from '../../hooks/useAuth';
import Helper from '../../utils/helper';
import { COST_COUNTRIES, EXPERIENCE_LEVEL, customStyles } from '../../utils/constant';

 

const initData: CostCalcOptions = {
    country: 'US',
    perks: []
};

const CostCalcultorComponent = () => {
    const { user } = useAuth();
    const [selectedOptions, setSelectedOptions] = useState<CostCalcOptions>(initData);
    const [salaries, setSalaries] = useState<SalaryDetails[]>([]);
    const [roles, setRoles] = useState<OptionType[]>([]);
    const [costWidthAndData, setCostWidthAndData] = useState<CostWidthAndData>();

    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (user) getSalaryDetails();
    }, [user]);

    const getSalaryDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.SALARYS);
            const { data } = res_data.data;

            if (data) {
                const resData: SalaryDetails[] = data;
                setSalaries(resData);

                const rolesData: OptionType[] = [];

                resData.forEach((dt) => rolesData.push({
                    label: dt.position,
                    value: dt.id
                }));

                setRoles(rolesData);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getData = (type: number, value: any) => {
        return new Promise((res, rej) => {
            switch (type) {
                case 1:
                    selectedOptions.position = Number(value);
                    setSelectedOptions(selectedOptions);
                    break;
                case 2:
                    selectedOptions.seniority = Number(value);
                    setSelectedOptions(selectedOptions);
                    break;
                case 3:
                    let arr = value.map((d: any) => d.value);
                    selectedOptions.perks = arr;
                    setSelectedOptions(selectedOptions);
                    break;
                case 4:
                    selectedOptions.country = value;
                    setSelectedOptions(selectedOptions);
                    break;
                default:
                    break;
            };

            res(true);
        })

    };

    const handleSelectChange = async (type: number, value: any) => {
        const data = await getData(type, value);
        calcAmount();
    };

    const getLevelData = (data: SalaryDetails) => {
        let returnData: CostCalcReturnData = {
            upper: 0,
            lower: 0,
            range: 0,
            multiplier: 0
        };
        console.log("selectedOptions.seniority ", selectedOptions.seniority);
        switch (selectedOptions.seniority) {
            case 1:
                returnData.upper = data.salary.juniorUpper;
                returnData.lower = data.salary.juniorLower;
                returnData.range = data.salary.juniorRange;
                returnData.multiplier = 0.8;
                break;
            case 2:
                returnData.upper = data.salary.midUpper;
                returnData.lower = data.salary.midLower;
                returnData.range = data.salary.midRange;
                returnData.multiplier = 0.7;
                break;
            case 3:
                returnData.upper = data.salary.seniorUpper;
                returnData.lower = data.salary.seniorLower;
                returnData.range = data.salary.seniorRange;
                returnData.multiplier = 0.5;
                break;
            case 4:
                returnData.upper = data.salary.staffUpper;
                returnData.lower = data.salary.staffLower;
                returnData.range = data.salary.staffRange;
                returnData.multiplier = 0.3;
                break;
            default:
                break;
        };

        return returnData;
    };

    const getPercentage = (percentageRef: number, value: number) => {
        const divided = value / percentageRef;
        const pr = Math.round(divided * 100);
        return pr >= 100 ? 99 : pr;
    };

    const getCalcCost = (data: CostCalcReturnData) => {
        return new Promise((res, rej) => {

            console.log(data);

            const cost: CostWidthAndData = {
                refCountrySalary: 0,
                refCountryBenifit: 0,
                refCountryTotal: 0,
                altCountrySalary: 0,
                altCountryBenifit: 0,
                altCountryTotal: 0,
                refCountrySalaryPerc: 0,
                refCountryBenifitPerc: 0,
                altCountrySalaryPerc: 0,
                altCountryBenifitPerc: 0,
                savings: 0,
                savedPerc: 0,
                altUlWidth: 0

            };

            cost.refCountrySalary = data.lower;
            cost.refCountryBenifit = data.range;
            cost.refCountryTotal = data.upper;

            cost.altCountrySalary = Math.round(data.lower * data.multiplier);
            cost.altCountryBenifit = Math.round(data.range * data.multiplier);
            cost.altCountryTotal = Math.round(data.upper * data.multiplier);

            cost.refCountrySalaryPerc = getPercentage(data.upper, cost.refCountrySalary) - 1;
            cost.refCountryBenifitPerc = getPercentage(data.upper, cost.refCountryBenifit) - 1;
            cost.altCountrySalaryPerc = getPercentage(cost.altCountryTotal, cost.altCountrySalary) - 1;
            cost.altCountryBenifitPerc = getPercentage(cost.altCountryTotal, cost.altCountryBenifit) - 1;

            cost.savedPerc = Math.round((1 - data.multiplier) * 100);
            cost.savings = Math.round(cost.refCountryTotal - cost.altCountryTotal);

            const w = getPercentage(cost.refCountryTotal, cost.altCountryTotal);
            cost.altUlWidth = w < 45 ? 45 : w;

            console.log("refCountryBenifitPerc", cost.refCountryBenifitPerc);
            // console.log(cost.altCountrySalaryPerc);

            res(cost);
        });
    };

    const calcAmount = async () => {

        // setCostWidthAndData(undefined);

        if (
            !selectedOptions.position ||
            !selectedOptions.seniority ||
            !selectedOptions.country
        ) return;

        const selected = salaries.filter((sal) => sal.id == selectedOptions.position);

        if (!selected || !selected.length) return;

        const currentData = selected[0];

        const levelData = getLevelData(currentData);
        // console.log(levelData);

        const calculated = await getCalcCost(levelData);
        setCostWidthAndData(calculated as CostWidthAndData);
    };

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                         <NotificationComponent/>
                        <h2>Cost Calculator</h2>
                        <p>You can review the cost and time savings when hiring through Owow.</p>
                    </div>
                    <form name='cost'>
                        <div className='row mt-5'>
                            <div className='col-md-4 col-12'>
                                <label>Role</label>
                                <Select
                                    styles={customStyles}
                                    options={roles}
                                    value={roles.filter((p) => p.value == selectedOptions.position)[0]}
                                    onChange={(ev) => handleSelectChange(1, ev?.value || 1)}
                                    placeholder="Role"
                                    className="mb-4"
                                    name='pos'
                                 />
                            </div>
                            <div className='col-md-4 col-12'>
                                <label>Seniority</label>
                                <Select
                                    styles={customStyles}
                                    options={EXPERIENCE_LEVEL}
                                    value={EXPERIENCE_LEVEL.filter((p) => Number(p.value) == selectedOptions.seniority)[0]}
                                    onChange={(ev) => handleSelectChange(2, ev?.value || 1)}
                                    placeholder="Seniority"
                                    className="mb-4"
                                    name='sen'
                                 />
                            </div>
                            {/* <div className='col-md-3 col-12 mb-2'>
                                <label>Benefits</label>
                                <Select
                                    styles={customStyles}
                                    className="mb-4"
                                    required
                                    placeholder="Select"
                                /> */}
                            {/* </div> */}
                            <div className='col-md-4 col-12'>
                                <label>Country</label>
                                <Select
                                    styles={customStyles}
                                    options={COST_COUNTRIES}
                                    value={COST_COUNTRIES.filter((p) => p.value == selectedOptions.country)[0]}
                                    onChange={(ev) => handleSelectChange(4, ev?.value || 'US')}
                                    className="mb-4"
                                    placeholder="Country"
                                    name='con'
                                />
                            </div>
                        </div>
                    </form>


                    {(costWidthAndData && costWidthAndData.altCountryTotal !== 0) ? (
                        (
                            <>
                                <div className='card cst-card mt-4'>
                                    <div className="table-responsive cst-border-table">
                                        <table className="table table-cost-cal">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='cost-c-th'></th>
                                                    <th scope="col" className='cost-c-th'>
                                                        <div className='table-header-button'>
                                                            <button className='btn btn-salary'>Salary</button>
                                                            <button className='btn btn-indirect-cost'>Benefits+Indirect cost</button>
                                                        </div>
                                                    </th>
                                                    <th scope="col" className='cost-c-th text-capitalize text-black'><h4>Employer Cost</h4></th>
                                                    <th scope="col" className='cost-c-th text-capitalize text-black' style={{borderRight: "none"}}><h4>Time to hire</h4></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='pt-3 cost-c-td'><h2 className='span-savings' data-tooltip-id="my-tooltip-1" >USA&nbsp;<img src={InfoIcon} className='img-fluid cst-ptr' /></h2></td>
                                                    <td className='pt-3 cost-c-td'>
                                                        {/* <button className='btn btn-doller-first-td'>$100K</button>
                                    <button className='btn btn-btn-doller-second-td'>$100K</button> */}
                                                        <div className="navtab_wrap">
                                                            <ul className="nav nav-tabs" id="myTab">
                                                                <li
                                                                    className="nav-item"
                                                                    style={{ width: `${costWidthAndData.refCountrySalaryPerc || 0}%` }}
                                                                >
                                                                    <button
                                                                        className="nav-link active"
                                                                        id="home-tab"
                                                                        type="button"
                                                                        style={{ width: '100%' }}
                                                                    >${costWidthAndData.refCountrySalary}k
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    className="nav-item"
                                                                    style={{ width: `${costWidthAndData.refCountryBenifitPerc || 0}%` }}
                                                                >
                                                                    <button
                                                                        className="nav-link"
                                                                        id="profile-tab"
                                                                        type="button"
                                                                        style={{ width: '100%' }}
                                                                    >${costWidthAndData.refCountryBenifit}k
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td className='cost-c-td'><h2><b>$ {costWidthAndData.refCountryTotal}k</b></h2></td>
                                                    <td className='cost-c-td' style={{ borderRight:"none" }}><h2><b>45-60 days</b></h2></td>
                                                </tr>

                                                <tr>
                                                    <td className='cost-c-td' ><h2><img src={OwowTableLogo} className='img-fluid' /> <img src={InfoIcon} className='img-fluid cst-ptr' data-tooltip-id="my-tooltip-2" /></h2></td>
                                                    <td className='cost-c-td'>
                                                        <div className="navtab_wrap">
                                                            <ul
                                                                className="nav nav-tabs"
                                                                id="myTab"
                                                                style={{ width: `${costWidthAndData.altUlWidth}%` }}
                                                            >
                                                                <li
                                                                    className="nav-item"
                                                                    style={{ width: `${costWidthAndData.altCountrySalaryPerc || 0}%` }}
                                                                >
                                                                    <button
                                                                        className="nav-link active"
                                                                        id="home-tab"
                                                                        type="button"
                                                                        style={{ width: '100%' }}
                                                                    >${costWidthAndData.altCountrySalary}k
                                                                    </button>
                                                                </li>
                                                                <li
                                                                    className="nav-item"
                                                                    style={{ width: `${costWidthAndData.altCountryBenifitPerc || 0}%` }}
                                                                >
                                                                    <button
                                                                        className="nav-link "
                                                                        id="profile-tab"
                                                                        type="button"
                                                                        style={{ width: '100%' }}
                                                                    >${costWidthAndData.altCountryBenifit}k
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                    <td className='cost-c-td'><h2><b>$ {costWidthAndData.altCountryTotal}k</b></h2></td>
                                                    <td className='cost-c-td' style={{ borderRight:"none" }}><h2><b>30-45 days</b></h2></td>
                                                </tr>
                                                <tr>
                                                    <td className='cost-c-td cost-c-th no-bottom-border' colSpan={2} style={{ backgroundColor: "#ab8bfc" }}>
                                                        <h2 className='text-start'>
                                                            <img src={DollerIcon} className='img-fluid' />&nbsp;&nbsp;
                                                            <img src={OwowTableLogo} className='img-fluid' />
                                                            &nbsp; <span className='span-savings'>Savings</span>
                                                        </h2>
                                                    </td>
                                                    <td className='cost-c-td cost-c-th no-bottom-border' style={{ backgroundColor: "#ab8bfc" }}>
                                                        <h2><b>$ {costWidthAndData.savings}k</b></h2>
                                                        <button className='btn btn-savings no-bottom-border'>${costWidthAndData.savedPerc}% Savings</button>
                                                    </td>
                                                    <td className='cost-c-td cost-c-th no-bottom-border' style={{ backgroundColor: "#ab8bfc",borderRight:"none" }}>
                                                        <h2><b>15-30 days</b></h2>
                                                        <button className='btn btn-savings'>35% Savings</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* table End */}
                                <h4 className='mt-4'>*Estimations are based on information from the survey and research, the data is be displayed live Owow.</h4>
                            </>
                        )
                    ) : (
                        <div className='mt-5 text-center'>
                            <img src={RegtangleImg} alt='Regtangle' />
                            <div className='d-flex justify-content-center mt-5'>
                                <h4 className='owow-savings'>Please select above things to take a glimpse of your savings you can do with owow.</h4>
                            </div>
                        </div>
                    )
                    }
                    {/* table Start */}
                </div>
            </div >
            <ReactTooltip
                className='cstkanda'
                id="my-tooltip-1"
                place="bottom"
                render={(props) => (
                    <div>
                        <b>Total Employer Cost = Salary + Benefits + Indirect Costs</b> <br /><br />
                        1. Salary (Wages and Salaries) <br /><br />
                        2. Benefits: Legally Required Benefits, Insurance, Paid Leave, Retirement and Savings, Annual Bonus/401K Supplemental Pay, Employment Training <br /><br />
                        3. Indirect cost: Overhead, General & Administrative
                    </div>
                )}
            />

            <ReactTooltip
                className='cstkanda'
                id="my-tooltip-2"
                place="bottom"
                render={(props) => (
                    <div>
                        <b>Total Employer Cost = Salary + Benefits + Indirect Costs</b> <br /><br />
                        1. Salary (Wages and Salaries) <br /><br />
                        2. Benefits: Legally Required Benefits, Insurance, Paid Leave, Retirement and Savings, Annual Bonus/401K Supplemental Pay, Employment Training<br /><br />
                        3. Indirect cost: Overhead, General & Administrative<br /><br />
                        4. OWOW Fees
                    </div>
                )}
            />






        </>
    )
}

export default CostCalcultorComponent
