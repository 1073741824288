import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';
import UndrawMailbox from '../../../Assets/Icons/undraw_mailbox.svg'
import { toast } from 'react-toastify';
import Helper from '../../../utils/helper';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeVerificationEmailPage = () => {
    const navigate = useNavigate();
    const helper = Helper.getInstance();
    const { logout } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);

    const location = useLocation();
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        if (location.state && location.state.email) setEmail(location.state.email);

        else navigate('/candidate-signup', { replace: true });
    }, []);

    const resendEmail = async () => {
        setLoading(true);
        try {
            const res_data = await helper.putReq(helper.PUT.EMP_RESEND_EMAIL, { email });
            const { data } = res_data.data;
            // console.log(data);
            toast.success('Email Resent Successfully');
        } catch (error) {
            console.log(error);
            toast.error("Resent Email Failed");
        }
        setLoading(false);
    };

    return (
        <>
            <section className='d-none d-sm-block d-print-block'>
                <div className='row'>
                    <div className='col-sm-6 d-none d-sm-block d-print-block'>
                        <div className='welcome-lets-started-container d-none d-sm-block d-print-block px-5'>
                            {/* <h1 className="owow-lets-started">owow</h1> */}
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />

                            <h2 className="welcome-lete-started" >Create your profile👋</h2>
                            <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                            <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6 '>
                        <div className='container cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                            <div className='cst-mobile-bg container'>
                                <div className='right-side-container '>
                                    <h1 className='owow owow-verification-email d-none d-sm-block d-print-block'></h1>
                                    <img src={UndrawMailbox} className='px-2 mail-box' />
                                    <h1>Verification email on its way!</h1>
                                    <h4 className='heado-para margin-fix-bottam'>We've sent a link to <span style={{ color: "#492A99" }}>{email}</span> If it's not in your inbox, please check your spam folder.  </h4>

                                    <div className="d-flex  justify-content-center mobile-two-buttons">
                                        <button
                                            className="btn btn-outline create-account-btn-outline p-3 col-md-6 col-12"
                                            onClick={() => resendEmail()}
                                        >{loading ? 'Resending...' : 'Resend Link'}</button>
                                        <button onClick={() => navigate('/candidate-signup', { replace: true })} className="btn create-account-btn p-3 col-md-6 col-12">
                                            Change Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='d-sm-none'>
                <div className='row'>
                    <div className='col-sm-6 '>
                        <div className='container cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-5'>owow</h1> */}
                            <div className='text-center pt-4'>
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluidowow d-sm-none' />
                            </div>

                            <div className='container text-center'>
                                <img src={UndrawMailbox} className='px-2 mail-box mt-5' />
                                <h1 className='text-white email-sent-text'>Email Sent Successfully!</h1>
                                <h4 className='mobile-para-msg margin-fix-bottam text-white mb-4'>We've sent a link to <span className='email-color-span'>{email}</span> If it's not in your inbox, please check your spam folder.  </h4>

                                <div className="text-center">
                                    {/* <button onClick={() => navigate('/employer-login', { replace: true })}
                                        className="btn btn-outline create-account-btn-outline p-3 col-md-6 col-12"
                                        style={{ background: "white" }}>
                                        Go to inbox
                                    </button> */}
                                    <button className="btn btn-outline create-account-btn-outline p-3 col-md-6 col-12 mb-2" onClick={() => resendEmail()} >{loading ? 'Resending...' : 'Resend Link'} </button>
                                    <button onClick={() => navigate('/candidate-login', { replace: true })} className="btn create-account-btn p-3 col-md-6 col-12" >
                                        Change Email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default EmployeeVerificationEmailPage