import { BsPlusCircle } from "react-icons/bs"


const AddCollaboratorButton = () => {

    const buttonStyle = {
        minWidth: "285px",
        border: "none",
        fontSize: "22px"
    }

    const CircleIcon = {
        color: "white",
        fontSize: "22px"
    }

    const handleClick = () => {
        localStorage.removeItem('serviceId');
        document.getElementById('updateId')?.click();
    };

    return (
        <>
            <div style={{
                borderRadius: "50%",
                bottom: "30px",
                position: "fixed",
                right: "30px",
                transition: ".4s linear",
            }}>
                <button id="add-collaborator" onClick={() => handleClick()} className="create-account-btn p-3" data-bs-target="#exampleModalToggle" data-bs-toggle="modal" style={buttonStyle}>
                    <BsPlusCircle style={CircleIcon} /> Add a collaborator</button>
            </div>
        </>
    )
}

export default AddCollaboratorButton