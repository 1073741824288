import MapPinIcon from '../../../Assets/Icons/map-pin.svg'
import LaunguageIcon from '../../../Assets/Icons/tabler_language.svg'
import CloseIcon from '../../../Assets/Icons/close-bold-icon.png'
import DotImg from '../../../Assets/Icons/EllipseDot.svg'
import { EmpProfileDetails, ViewCandidProfileProps } from '../../../types'
import { useState, useEffect } from 'react'
import useAuth from '../../../hooks/useAuth'
import { EXPERIENCE_LEVEL, SKILL_TYPES, countries, languagesOptions } from '../../../utils/constant'
import Helper from '../../../utils/helper'
import DefaultImage from '../../../Assets/Icons/default.png';


const ViewCandidProfile = ({ employeeId, positions }: ViewCandidProfileProps) => {

    const [loading, setLoading] = useState<boolean>(true);
    const helper = Helper.getInstance();
    const [empId, setEmpId] = useState<number>();
    const [profile, setProfile] = useState<EmpProfileDetails>();

    const { user } = useAuth();

    useEffect(() => {
        if (employeeId) {
            setEmpId(employeeId);
        };
    }, [employeeId]);

    useEffect(() => {
        if (user && empId) getProfile();
    }, [empId]);

    const getProfile = async () => {

        if (!empId) return;

        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.EMP_PROFILE_DETAILS + empId);
            const { data } = res_data.data;

            if (data) setProfile(data);

            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getPOSLabel = (val: string | number) => {
        const pos = positions.find((x) => x.id == val);

        if (pos) return pos.position;

        return '';
    };

    return (
        <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="viewProfileCanvas" aria-labelledby="viewProfileCanvasLabel"  >
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="viewProfileCanvasLabel">View Profile</h5>
                {/* <button type="button" className="btn btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button> */}
            </div>
            <div className="offcanvas-body">
                <div className="container-fluid">
                    {
                        loading ? (<h2>Loading...</h2>) : (
                            <>
                                <div className='float-end'>
                                    <button className='btn btn-outline btn-close-cst'>
                                        <img src={CloseIcon} className='img-fluid' id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                                    </button>

                                </div>
                                <div className='d-flex mobile-two-buttons request-for-interview-card-sidbar' style={{ gap: "20px" }}>
                                    <div>
                                        <img src={profile?.imageUrl || DefaultImage} className='img-fluid cst-image-interview-side-model' />
                                    </div>
                                    <div>
                                        <div className='d-flex justify-content-between mobile-two-buttons mb-2' style={{ gap: "16px" }}>
                                            <div>
                                                <h5>{profile?.name}</h5>
                                            </div>
                                            <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                                                <button className='btn btn-tag-senior'>{getOptionLabel(profile?.skillAndEmpStatus.seniority || 1, EXPERIENCE_LEVEL)}</button>
                                            </div>
                                        </div>
                                        <h6>
                                            <span style={{ fontWeight: "400" }}>{getPOSLabel(profile?.skillAndEmpStatus.positionIds[0] || 1)}
                                                &nbsp;&nbsp;
                                                <img className="img-fluid" src={MapPinIcon} /> {getOptionLabel(profile?.country || 'US', countries)}
                                                &nbsp;&nbsp;
                                                <img className="img-fluid" src={LaunguageIcon} />{getOptionLabel(profile?.skillAndEmpStatus.languages[0].language || 'English', languagesOptions)}<br />
                                            </span>
                                            {
                                                profile?.skillAndEmpStatus.relativeExp && (
                                                    <span style={{ fontWeight: "500" }}>{profile.skillAndEmpStatus.relativeExp} {profile.skillAndEmpStatus.relativeExp > 1 ? 'years' : 'year'}</span>
                                                )
                                            }
                                        </h6>
                                    </div>

                                </div>
                                <hr />
                                <div>
                                    <label className='lable-heading'>Core Technical Skills</label>
                                    <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                        {
                                            profile?.empSkills.map((sk, index) => {
                                                if (sk.skillType == SKILL_TYPES.MAIN) {
                                                    return (
                                                        // <button className='btn cst-btn-tags' key={index}>
                                                        //     {sk.skill}
                                                        // </button>
                                                        <button className='btn cst-btn-tags' key={index}>
                                                            {sk.skill}
                                                            <span className="cst-primary-color">
                                                                &nbsp;
                                                                <img src={DotImg} className='img-fluid' />
                                                                &nbsp;
                                                                {sk.experience} years
                                                            </span> </button>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                                <hr />

                                <div>
                                    <label className='lable-heading'>Additional Skills</label>
                                    <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                        {
                                            profile?.empSkills.map((sk, index) => {
                                                if (sk.skillType == SKILL_TYPES.ADDITIONAL) {
                                                    return (
                                                        // <button className='btn cst-btn-tags' key={index}>
                                                        //     {sk.skill}
                                                        // </button>
                                                        <button className='btn cst-btn-tags' key={index}>
                                                            {sk.skill}
                                                            <span className="cst-primary-color">
                                                                &nbsp;
                                                                <img src={DotImg} className='img-fluid' />
                                                                &nbsp;
                                                                {sk.experience} years
                                                            </span> </button>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                </div>

                                <hr />
                                <div className='row cst-h6-heading-css-main mt-3'>
                                    <div className='col-sm-9 col-12' >
                                        <label className='lable-heading' >Experience</label>
                                        {profile !== undefined && profile.experience.length > 0 && profile.experience.map((exp, index) => (
                                            <div className='row mt-2' key={index} style={{ paddingRight: "12px" }}>
                                                <div className='col-md-2 col-12 mb-2'>
                                                    <img className='img-fluid expirence-image-card-cst' src={DefaultImage} />
                                                </div>
                                                <div className='col-md-6 col-12 mb-2 px-3'>
                                                    <h6>{exp.role}</h6>
                                                    <label>{exp.company}</label>
                                                </div>
                                                <div className='col-md-4 col-12 mb-2'>
                                                    <h6 className='fst-italic cst-secondary-color'>{`01/${exp.startMonth}/${exp.startYear}`}&nbsp;-&nbsp;{exp.currentlyWorking ? 'Present' : `01/${exp.endMonth}/${exp.endYear}`}</h6>
                                                    <label className='cst-secondary-color'>{exp.totalExp}</label>
                                                </div>
                                                {profile.experience.length - 1 !== index && <hr className='mt-2' />}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr />
                                <label className='lable-heading' htmlFor="awards">Awards and Acheivements</label>
                                <div className='d-flex mt-2' style={{ gap: "20px", flexWrap: 'wrap' }}>
                                    {profile !== undefined && profile.certification.length > 0 && profile.certification.map((cert, index) => (
                                        <div key={cert.id}>
                                            <label className='lable-heading'>{cert.courseName}</label>
                                            <h6>{cert.orgName} <span className="cst-primary-color"><img src={DotImg} className='img-fluid' /> {cert.issueDate}</span> </h6>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )
                    }

                </div>
            </div>
        </div >
    )
}

export default ViewCandidProfile