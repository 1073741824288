import { useState } from 'react';
import VerifiedIcon from '../../../Assets/Icons/material-symbols_verified.svg'
import { toast } from 'react-toastify';
import Helper from '../../../utils/helper';
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const ForgotPassword = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [isResend, setTsResend] = useState<boolean>(false);
  const helper = Helper.getInstance();

  const [email, setEmail] = useState<string>('');

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setLoading(true);
    if (isEmailValid) {
      await handleForgot();
    } else {
      setLoading(false);
      toast.error('Enter Valid Company Email')
    }

  };

  const isCompanyEmail = (email: string): boolean => {
    // List of company domains to compare against
    const commonEmailProviderDomains: string[] = [
      'gmail.com',
      'yahoo.com',
      'outlook.com',
      'aol.com',
      'icloud.com',
      'protonmail.com',
      'zoho.com',
      'mail.com',
      'yandex.com',
      'gmx.com',
      'tutanota.com',
      'fastmail.com',
      'hushmail.com',
      'rackspace.com',
      'cox.net',
      'earthlink.net',
      'att.net',
      'comcast.net',
      'verizon.net',
      'spectrum.net',
      '',
      '.com',
      '.org',
      '.net'
      // Add more domains as needed
    ];

    // Extract the domain from the email address
    const emailParts = email.split('@');
    if (emailParts.length !== 2) return false;

    const domain = emailParts[1];

    return !commonEmailProviderDomains.includes(domain);
  };

  const verifyEmail = (email: string) => {
    return new Promise((resolve, reject) => {
      const validEmail = isCompanyEmail(email);
      console.log(validEmail);
      setIsEmailValid(validEmail);
      resolve(validEmail);
    })
  };

  const handleEmailChange = async (email: string) => {
    await verifyEmail(email);
    setEmail(email);
  };

  const handleForgot = async () => {
    try {
      const res_data = await helper.postReq(helper.POST.FORGOT_PASSWORD, { email });
      const { data } = res_data.data;
      setLoading(false);
      if (data) {
        toast.success('Reset Password Link Sent Successfully to your Email');
        setTsResend(true);
      }
      else throw new Error("Failed");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Invalid Email ID');
    }
  };

  return (
    <>
      <section>
        <div className='row'>
          <div className='col-sm-6 d-none d-sm-block d-print-block'>
            <div className='hero-left-col px-5'>
              <h1 className='left-clumn-first-heading text-white'>“</h1>
              <h1 className='left-clumn-heading text-white'>From talent acquisition to HR, legal compliance, and  logistics, we've got you covered.</h1>
              <button className='btn btn-outline verified-button mt-2'><img src={VerifiedIcon} className='px-2' /> 100% Verfied Talents</button>
            </div>
          </div>
          <div className='col-sm-6 '>
            {/* <div className='container cst-container-bg'> */}
            <div className='cst-container-bg'>
              {/* <h1 className='owow d-sm-none '>owow</h1> */}
              <div className='text-center'>
                <img src={Logo_Horizontal_WhiteImage} className='owow d-sm-none pt-4' />
              </div>
              <div className='cst-mobile-bg'>
                <form onSubmit={handleSubmit}>
                  <div className='right-side-container'>
                    {/* <h1 className='owow d-none d-sm-block d-print-block'>owow</h1> */}
                    <img src={Logo_Horizontal_purpleImage} className='img-fluid owow d-none d-sm-block d-print-block' />

                    <div className='owow d-none d-sm-block d-print-block'><br /><br /></div>
                    <h1 className='welcome-heading pt-5'>Forget Password!</h1>
                    <h4 className='heado-para margin-fix-bottam'>Enter your email address and we'll send you a link to reset your password.</h4>


                    <div className="col-12 input-group-lg margin-fix-bottam">
                      <label>Email ID</label>
                      <input
                        type="text"
                        id='email'
                        value={email}
                        onChange={(val) => handleEmailChange(val.target.value)}
                        className="form-control custom-input"
                        placeholder="Enter Your Email ID"
                        required
                      />
                      {!isEmailValid && <p style={{ color: 'red', fontSize: 'medium' }}>Please enter a valid company email address</p>}
                    </div>

                    <div className="col-12 text-center margin-fix-bottam">
                      {!isResend ? (
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn create-account-btn p-3 col-12 '>
                          {!loading ? 'Send Password Reset Link' : 'Sending...'}
                        </button>
                      ) : (
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn create-account-btn p-3 col-12 '>
                          {!loading ? 'ReSend Password Reset Link' : 'Resending...'}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ForgotPassword