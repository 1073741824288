import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../../../hooks/useAuth';

import MarshMello from '../../../Assets/Icons/undraw_mello.svg'
import { User } from '../../../types';
import OwowLogo from '../../../Assets/Icons/Logo_Horizontal_White.png'

const LetsGetStartedPage = () => {
  const navigate = useNavigate()
  const { tokenLogin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<User | null>();

  useEffect(() => {
    const init = async () => {
      const token = searchParams.get("token");
      // console.log(token);
      if (!token) navigate('/employer-login', { replace: true });
      else {
        const userData = await tokenLogin(token);
        if (!userData) navigate('/employer-signup', { replace: true });
        else setUser(userData)
      }
    }

    init();
  }, [searchParams])
  return (
    <>
      <section className='let-started'>
        <div className='row'>
          <div className='col-sm-6 lets-get-started-container d-none d-sm-block d-print-block'>
            <div className='container'>
              {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
              <div className='cst-mobile-bg '>
                <div className='right-side-container'>
                  {/* <h1 className='owow owow-lets-started'>owow</h1> */}
                  <img src={OwowLogo} className='img-fluid owow owow-lets-started' />
                  <h1 className='welcome-lete-started' style={{ fontSize: "40px" }}>Welcome👋</h1>
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 new-right-side-col'>
            <div className='text-center'>
              <img src={OwowLogo} className='img-fluid owow d-sm-none mt-4 mb-4' />
            </div>
            {/* <h2 className='owow d-sm-none mb-4'>owow</h2> */}
            <div className='container'>
              <div className='right-side-container'>
                <p className='d-sm-none text-white text-center'>Welcome👋</p>
                <img src={MarshMello} className='px-2 marshmello-image-box img-fluid' />
                <h1 className='lets-started-heading mt-4' style={{ fontSize: "40px", letterSpacing: "-0.64px" }}>Let’s get started!</h1>
                <p className='heado-para margin-fix-bottam'>
                  Great job! 🎉<br />
                  Email verified! Start hiring vetted global software engineers now. Just a few clicks left to complete your account.
                </p>
                <div className="col-12 text-center margin-fix-bottam">
                  <button className='btn create-account-btn p-3 col-12' style={{ fontSize: "20px" }} onClick={() => navigate("/employer-personal-details", { state: { customer: user } })}>Get Started Now!</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LetsGetStartedPage