
import Select from 'react-select';
import { FilterJobsProps, OptionType, jobSearch } from '../../../types';
import { EXPERIENCE_LEVEL, OFFICE_POLICY, countries, customStyles } from '../../../utils/constant';
import { useEffect, useRef, useState } from 'react';

interface jobSearch2 {
    positionIds: OptionType[];
    skills: OptionType[];
    optionalSkills: OptionType[];
    officePolicy: OptionType[];
    location: OptionType[];
};

const FilterSideebar = ({ positions, applyFilter, searchM, skillsDB }: FilterJobsProps) => {

    const searchInit2 = {
        positionIds: [],
        skills: [],
        optionalSkills: [],
        officePolicy: [],
        location: [],
    }

    const [searchMain, setSearchMain] = useState<jobSearch>(searchM);
    const [search, setSearch] = useState<jobSearch2>(searchInit2);

    const ref = useRef<any>();

    const getMultiSelectedDef = (dataArr: OptionType[], dataVal: number[] | string[]) => {
        if (!dataVal.length) return;

        const jdataArr: OptionType[] = JSON.parse(JSON.stringify(dataArr));
        const newArr = [];

        for (let d of dataVal) {
            const val = jdataArr.filter((dt) => dt.value == d);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handleSelectChange = (keyType: number, value: any) => {
        // console.log(value);
        let arr = value.map((d: any) => d.value);

        switch (keyType) {
            case 1:
                setSearch({ ...search, positionIds: value });
                searchMain.positionIds = arr;
                break;
            case 2:
                // search.skills = value;
                setSearch({ ...search, skills: value });
                searchMain.skills = arr;
                break;
            case 3:
                setSearch({ ...search, officePolicy: value });
                searchMain.officePolicy = arr;
                break;
            case 4:
                setSearch({ ...search, location: value });
                searchMain.location = arr;
                break;
            case 5:
                setSearch({ ...search, optionalSkills: value });
                searchMain.optionalSkills = arr;
                break;
            default:
                break;
        }

        // setSearch(search);
        setSearchMain(searchMain);
    };

    const handleInput = (type: number, val: number) => {
        if (type == 1) {
            setSearchMain({ ...searchMain, seniority: val });
            // setSearchMain(searchMain);
        };
        if (type == 2) {
            searchMain.sortBy = val;
            setSearchMain({ ...searchMain, sortBy: val });
            // setSearchMain(searchMain);
            // getPositionReqs();
        };

    };

    const clearFilter = () => {
        setSearchMain({
            ...searchM,
            positionIds: [],
            skills: [],
            optionalSkills: [],
            officePolicy: [],
            location: [],
        });
        setSearch(searchInit2);
        applyFilter({
            ...searchM,
            positionIds: [],
            skills: [],
            optionalSkills: [],
            officePolicy: [],
            location: [],
        })
    }

    return (
        <>
            <div className=" offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">Filters</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <div className='row'>
                        <div className='col-12'>
                            <label>Roles</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Select Roles"
                                name='JobRole'
                                options={positions}
                                value={search.positionIds}
                                onChange={(ev) => handleSelectChange(1, ev)}
                                isMulti
                            />
                        </div>
                        {/* <div className='col-12'>
                            <label>Must have skills</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Select Skills"
                                name='mustSkills'
                                options={skillsDB}
                                value={search.skills}
                                onChange={(ev) => handleSelectChange(2, ev)}
                                isMulti
                            />
                        </div>
                        <div className='col-12'>
                            <label>Good to have skills</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Select Skills"
                                name='goodSkills'
                                options={skillsDB}
                                value={search.optionalSkills}
                                onChange={(ev) => handleSelectChange(5, ev)}
                                isMulti
                            />
                        </div>
                        <div className='col-12'>
                            <label>Experience</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Select experience"
                                name='preferredWork'
                                options={EXPERIENCE_LEVEL}
                                value={EXPERIENCE_LEVEL.filter((ct) => ct.value == searchMain.seniority)[0]}
                                onChange={(ev) => handleInput(1, ev?.value || 1)}
                            />
                        </div> */}
                        <div className='col-12'>
                            <label>Job Type</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Search"
                                name='loc'
                                options={OFFICE_POLICY}
                                value={search.officePolicy}
                                onChange={(ev) => handleSelectChange(3, ev)}
                                isMulti
                            />
                        </div>
                        <div className='col-12'>
                            <label>Job location</label>
                            <Select
                                styles={customStyles}
                                className="mb-4"
                                placeholder="Search"
                                name='loc'
                                options={countries}
                                value={search.location}
                                onChange={(ev) => handleSelectChange(4, ev)}
                                isMulti
                            />
                        </div>
                    </div>
                    <div className='d-flex cst-clear-apply-btn'>
                        <button className='clear-filter' onClick={() => clearFilter()}>Clear Filter</button>
                        <button className='apply-filter' onClick={() => applyFilter(searchMain)}>Apply Filter</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FilterSideebar