import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { User } from '../../../types';
import useAuth from '../../../hooks/useAuth';
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeCongratulationsPage = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const [customer, setCustomer] = useState<User | null>(null);
  const { getDetails } = useAuth();

  useEffect(() => {
    if (!location.state || !location.state.customer) navigate('/candidate-login', { replace: true });
    else setCustomer(location.state.customer);
  }, []);

  return (
    <>
      <section className='congratulations' style={{ height: "100vh", background: 'linear-gradient(180deg, #492A99 0%, #000 100%)' }}>
        <div className='container'>
           <div className='text-center'>
            <img src={Logo_Horizontal_WhiteImage} className='congratulations-owow text-center text-white pt-5' />
          </div>
          <h2 className='congratulations-heading text-center text-white pb-5'>Congratulations {customer?.name} 🎉</h2>
          <h3 className='congratulations-paragraph text-center text-white pb-5'>You've joined a network of the world's best tech talents. Here, you can access full-time, long-term tech jobs at leading companies from the USA and India.</h3>
          <div className='text-center' >
            <button className="btn dashboard-btn p-3 col-12 col-md-6" onClick={() => window.location.reload()}>Go to Dashboard</button>
          </div>
        </div>
      </section>
    </>
  )
}

export default EmployeeCongratulationsPage