import React from 'react';

interface Props {
  date: Date;
}

const TimeAgo: React.FC<Props> = ({ date }) => {
  const getTimeDifference = (currentDate: Date, previousDate: Date): string => {
    const differenceInSeconds = Math.floor((currentDate.getTime() - previousDate.getTime()) / 1000);
    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let count: number = 0; // Initialize count before loop
    let unit: string = '';

    for (const [key, value] of Object.entries(intervals)) {
      count = Math.floor(differenceInSeconds / value);
      if (count > 0) {
        unit = key as string;
        break;
      }
    }

    if (count === 0) return 'Just now';
    return `${count} ${unit}${count === 1 ? '' : 's'} ago`;
  };

  return <>{getTimeDifference(new Date(), date)}</>;
};

export default TimeAgo;
