import TableUserImages from '../../Assets/Admin/Icons/Ellipse507.svg'
import MenuIcon from '../../Assets/Icons/menuIcon.svg'
import BigUserImage from '../../Assets/Icons/Ellipse496.svg'
import AddCollaboratorButton from '../../Pages/Employer/AddCollaboratorButton'
import { useEffect, useState } from 'react'
import Helper from '../../utils/helper'
import { CollaboratorDetails } from '../../types'
import DefaultImage from '../../Assets/Icons/default.png'
import { toast } from 'react-toastify'
import useAuth from '../../hooks/useAuth'

const ManageCollaboratorsTable = () => {

    const [collaborators, setCollaborators] = useState<CollaboratorDetails[]>([]);
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useAuth();

    const getDetails = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.COLLABORATOR_DETAILS);
            const { data } = res_data.data;

            if (data) setCollaborators(data);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (user) {
            setLoading(true)
            getDetails();
        }
        setLoading(false)
    }, [user]);

    const handleEdit = (id: number = 1) => {
        localStorage.setItem('serviceId', JSON.stringify(id));
        document.getElementById('updateId')?.click();

        setTimeout(() => {
            document.getElementById('add-collaborator')?.click();
        }, 100);

    };

    const handleActiveInactive = async (id: number = 1, isActive: boolean) => {
        try {
            const uData = { collaboratorId: id, isActive: !isActive };
            const res_data = await helper.putReq(helper.PUT.UPDATE_COLLABORATOR_STATUS, uData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Updated Successfully');
                await getDetails();
            }
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Update Failed');
        }
    };

    return (
        <>
            <div className='card p-3' style={{
                borderRadius: "17px",
                background: "#FFF",
                border: "none",
            }}>
                <div className="table-responsive cst-manage-collaborator-tbl" style={{ height: "100vh" }}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Role</th>
                                <th scope="col">Status</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {collaborators.map((collaborator) => (
                                <tr key={collaborator.id}>
                                    <td ><img src={DefaultImage} style={{ width: '52px', height: '52px' }} /><b> {collaborator.name}</b><br /></td>
                                    <td className='pt-3' >{collaborator.email}</td>
                                    <td className='pt-3' >{collaborator.role}</td>
                                    <td className='pt-3' ><button className={'button-active ' + (collaborator.isActive ? 'active-green' : 'inactive-red')}>{collaborator.isActive ? 'Active' : 'Inactive'}</button></td>

                                    <td className='pt-3 details-card-for-input cst-ptr'>
                                        <div className="dropdown">
                                            <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                <li><a className="p-3" onClick={() => handleEdit(collaborator.id)}>Edit</a><hr /></li>
                                                <li><a className="p-3 text-danger" style={{ fontWeight: "500" }} onClick={() => handleActiveInactive(collaborator.id, collaborator.isActive)} > Mark {collaborator.isActive ? 'Inactive' : 'Active'} </a></li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                            )
                            )}
                            {/* {loading && <tr><td className='pt-3' ><h2>Loading...</h2></td></tr>} */}
                        </tbody>
                    </table>
                    {collaborators.length === 0 && <h5 className='text-center mt-5'>No Data Founds!</h5>}

                </div>
                s
            </div>
            <button id="updateView" className='d-none' onClick={() => getDetails()}></button>
            <AddCollaboratorButton />
        </>
    )
}

export default ManageCollaboratorsTable