import React, { useEffect, useState } from 'react'
import { TerminationReasons, customStyles } from '../../../utils/constant'
import Select from 'react-select'
import InfoIcon from '../../../Assets/Icons/infoIcon.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";
import ContractEndedMessageModel from './ContractEndedMessageModel';
import { DatePicker } from 'antd';
import { BsCalendarFill } from 'react-icons/bs';
import dayjs from 'dayjs';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';

interface EndContractData {
    endDate: string;
    reason: string;
    description: string;
};

const EndContractModel = ({ appliId }: { appliId: number }) => {

    const [applicaId, setApplicaId] = useState<number>();
    const [name, setName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();

    const initEndContractData: EndContractData = {
        endDate: '',
        reason: '',
        description: ''
    };

    const [endContractData, setEndContractData] = useState<EndContractData>(initEndContractData);

    useEffect(() => {
        if (appliId) setApplicaId(appliId);
    }, [appliId]);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        endContract();
    };

    const endContract = async () => {
        try {
            setLoading(true);
            const reqDate = {
                id: applicaId,
                ...endContractData
            };

            const res_data = await helper.postReq(helper.POST.END_CONTRACT, reqDate);
            const { data } = res_data.data;

            if (data) {
                setName(data);
                clearCache();
                toast.success('Ended Successfully');
                document.getElementById('endContractClose')?.click();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Failed to End Contract");
        }
    };

    const clearCache = () => {
        setApplicaId(undefined);
        setEndContractData(initEndContractData);
    };

    return (
        <>
            <div className="modal fade" id="endcontract" aria-hidden="true" aria-labelledby="endcontractLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="endcontractLabel">End Contract</h3>
                            <button 
                                id='endcontractModel' 
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close"
                                onClick={() => clearCache()}
                            ></button>
                        </div>
                        <div className='container'>
                            <div className="modal-body">
                                <form id='contractForm' name='contractForm' onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-12 input-group-lg mb-4">
                                            <label data-tooltip-id="my-tooltip-info-for-endcontract">End Date <img src={InfoIcon} className='img-fluid cst-ptr' /></label>
                                            <input
                                                type="date"
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={endContractData.endDate}
                                                min={new Date().toISOString().split('T')[0]}
                                                onChange={(val) => setEndContractData({ ...endContractData, endDate: val?.target.value })}

                                                required
                                        />
                                            {/* <div className='date-picker-input'>
                                                <DatePicker
                                                    className='form-control custom-datepicker'
                                                    disabledDate={d => !d || d.isBefore(new Date())}
                                                    suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                    allowClear={false}
                                                    defaultValue={dayjs(endContractData.endDate || new Date())}
                                                    onChange={(val) => setEndContractData({ ...endContractData, endDate: val?.toISOString() })}
                                                    required
                                                />
                                            </div> */}
                                        </div>
                                        <div className="col-12 mb-4">
                                            <label>Reason of Ending</label>
                                            <Select
                                                styles={customStyles}
                                                options={TerminationReasons}
                                                placeholder="Select"
                                                value={TerminationReasons.filter((p) => p.value == endContractData?.reason)[0]}
                                                onChange={(ev) => setEndContractData({ ...endContractData, reason: ev?.value.toString() || '' })}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 input-group-lg mb-4">
                                            <label>Description(optional)</label>
                                            <textarea
                                                className="form-control"
                                                rows={4}
                                                value={endContractData.description}
                                                onChange={(ev) => setEndContractData({ ...endContractData, description: ev.target.value })}
                                            />
                                        </div>

                                        <div className="d-flex justify-content-end flex-wrap" style={{ gap: '12px' }}>
                                            <button
                                                className='btn end-contract-btn p-2 col-md-4 col-12'
                                                type='submit'
                                                disabled={loading}
                                            >{loading ? 'Ending...' : 'End Contract'}
                                            </button>
                                            <button
                                                className='d-none'
                                                id='endContractClose'
                                                type='button'
                                                data-bs-target="#endcontractMessage"
                                                data-bs-toggle="modal">
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContractEndedMessageModel name={name} />
            <ReactTooltip
                className='cstkanda'
                id="my-tooltip-info-for-endcontract"
                place="bottom"
                render={(props) => (
                    <div>
                        The end date for the contract will be the number of notice period days from today. You will be billed for that specific time period.
                    </div>
                )}
            />
        </>
    )
}

export default EndContractModel