import { useEffect, useState } from 'react'
import DotImg from '../../Assets/Icons/EllipseDot.svg'
import NotificationComponent from '../../Components/NotificationComponent'
import { ApplicantData, PosDetails, PositionDetails, RequestForInterviewModelProps, ViewJobDetailsProps } from '../../types'
import Helper from '../../utils/helper'
import useAuth from '../../hooks/useAuth'
import { APPLICATION_STATUS_DATA, APPLICATION_STATUS, SKILL_TYPES } from '../../utils/constant'
import DefaultImage from '../../Assets/Icons/default.png';
import RequestForInterviewModel from '../../Components/Employer/SideCanvas/RequestForInterviewModel'
import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ViewJobDetailsModel from '../../Components/Employer/SideCanvas/ViewJobDetailsModel'

const ViewJobDetails = () => {

    const [activeNavTab, setActiveNavTab] = useState<number>(1);
    const [applicants, setApplicants] = useState<ApplicantData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const helper = Helper.getInstance();
    const [positionData, setPositions] = useState<PositionDetails[]>([]);
    const [posData, setPosData] = useState<PosDetails>();
    const [intReqLength, setIntReqLength] = useState<number>(0);
    const [rejectedLength, setRejectedLength] = useState<number>(0);
    const { id } = useParams();

    const navigate = useNavigate();

    const [modalData, setModalData] = useState({
        employeeId: 0,
        applicationId: 0,
        interviewDates: {},
    });

    const { user } = useAuth();

    useEffect(() => {

        if (user && id) {
            getPositionDetails();
            getApplicants();
        } else navigate(`/employer-hire-talents`, { replace: true });
    }, [user, id]);

    const getApplicants = async () => {

        if (!id) return navigate(`/employer-hire-talents`, { replace: true });

        try {
            setLoading(true)
            const res_data = await helper.postReq(helper.POST.APPLICANTS, { posReqId: Number(id) });
            const { data } = res_data.data;

            const { applications, posData: psd } = data;

            if (applications) {
                let i = 0;
                let r = 0;
                setApplicants(applications);
                setPosData(psd);

                for (const dt of applications) {
                    if (dt.status == APPLICATION_STATUS.INTERVIEW_REQUESTED || dt.status == APPLICATION_STATUS.INTERVIEW_OUTSOURCED) i += 1;
                    if (dt.status == APPLICATION_STATUS.REJECTED) r += 1;
                };

                setIntReqLength(i);
                setRejectedLength(r);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getPositionDetails = async () => {
        try {
            // setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            if (data) setPositions(data);
            // setLoading(false)
        } catch (error) {
            console.log(error);
            // setLoading(false)
        }
    };

    const getPosLabel = (val: string | number, opArr: any[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.id == val);

        if (op) return op.position;

        return '';
    };

    const getSuffix = (value: number = 0) => {
        if (value && Number(value) > 2) return 's';
        return '';
    };

    const getSkillsMatched = (empArr: any[]) => {
        let returnData = 0;

        for (const sk of empArr) {
            const matched = posData?.skills?.find((pSk) => pSk == sk.skill);
            if (matched) returnData += 1;
        }
        return returnData;
    };

    const handleViewProfile = (application: ApplicantData) => {
        setModalData({
            employeeId: application.employee.id,
            applicationId: application.id,
            interviewDates: {
                interviewScheduledAt1: application.interviewScheduledAt1,
                interviewScheduledAt2: application.interviewScheduledAt2,
                interviewScheduledAt3: application.interviewScheduledAt3,
            }
        });
    };

    const handleHireNow = (application: ApplicantData) => {
        const url = `/employer-myteammates?appId=${application.id}`;
        navigate(url, { replace: true });
    };

    // if (loading) return
     return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <NotificationComponent/>
                        <h2>Hire Talents</h2>
                        <p>This dashboard will help you in analysing your activities in various aspects.</p>
                    </div>

                    <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between flex-wrap mt-5">
                        <div>
                            <button className='btn view-details-btn px-4 mb-3' onClick={() => navigate('/employer-hire-talents', { replace: true })}>Back</button>
                        </div>
                        <h3>{posData?.position?.position}</h3>
                        <div>
                            <h4 className='mt-2 cst-primary-color text-decoration-underline cst-ptr' data-bs-toggle="offcanvas" data-bs-target="#offcanvasViewJobDetails" aria-controls="offcanvasBottom" style={{ fontWeight: "700" }}>View job details</h4>
                        </div>
                    </div>

                    <div className='mt-5 mb-5'>
                        <ul className="my-account-tabs-list">
                            <li><a href="#" className={`${activeNavTab == 1 && 'active'}`} onClick={() => setActiveNavTab(1)}>All Candidates ({applicants.length})</a></li>
                            <li><a href="#" className={`${activeNavTab == 2 && 'active'}`} onClick={() => setActiveNavTab(2)}>Interview Scheduled ({intReqLength})</a></li>
                            <li><a href="#" className={`${activeNavTab == 3 && 'active'}`} onClick={() => setActiveNavTab(3)}>Rejected ({rejectedLength})</a></li>
                        </ul>
                    </div>

                    {loading ? <h5>Loading...</h5> : (

                        <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                            {
                                applicants.map(applicant => {
                                    if (
                                        activeNavTab === 1 ||
                                        (activeNavTab == 2 && (applicant.status == APPLICATION_STATUS.INTERVIEW_REQUESTED) || applicant.status == APPLICATION_STATUS.INTERVIEW_OUTSOURCED) ||
                                        (activeNavTab == 3 && applicant.status == APPLICATION_STATUS.REJECTED)
                                    ) {
                                        return (
                                            <div className='mb-4' key={applicant.id}>
                                                <div className='card developer-information-card text-capitalize p-3'>
                                                    <div className='d-flex align-items-center' style={{ gap: "20px" }}>
                                                        <div>
                                                            <img src={applicant.employee.imageUrl || DefaultImage} className='img-fluid cst-card-img' />
                                                        </div>
                                                        <div>
                                                            <h5 
                                                                // className="blurred" 
                                                                contentEditable={false} 
                                                                onMouseDown={(e) => e.preventDefault()} 
                                                                onContextMenu={(e) => e.preventDefault()} 
                                                                onSelect={(e) => e.preventDefault()}
                                                                >{applicant.employee.name}
                                                            </h5>
                                                            <label>{getPosLabel(applicant.employee.skillAndEmpStatus.positionIds[0], positionData)}
                                                                <br />
                                                                {applicant.employee.skillAndEmpStatus.relativeExp} yr{getSuffix(applicant.employee.skillAndEmpStatus.relativeExp)}
                                                            </label>
                                                            <label>{applicant.employee.workingCountry == 'IN' ? '₹':'$'}{(applicant.employee.employemetStatus.expectedPASalary || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}/Yr</label>
                                                        </div>
                                                    </div>
                                                    <hr className='mt-3 mb-3' />
                                                    <div className='d-flex justify-content-between'>
                                                        <label>Stacks</label>
                                                        <label>{getSkillsMatched(applicant.employee.empSkills)}/{posData?.skills?.length} matched</label>
                                                    </div>
                                                    <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                                        {
                                                            applicant.employee.empSkills.map((sk, index) => {
                                                                if (index < 5) {
                                                                    return (
                                                                        <button className='btn cst-btn-tags' key={`${applicant.id}${index}`}>
                                                                            {sk.skill}
                                                                            <span className="cst-primary-color">
                                                                                &nbsp;
                                                                                <img src={DotImg} className='img-fluid' />
                                                                                &nbsp;
                                                                                {sk.experience} year{getSuffix(sk.experience)}
                                                                            </span>
                                                                        </button>
                                                                    )
                                                                }
                                                                if (index == 5) return <button key={`${applicant.id}${index}`} className='btn cst-btn-tags'>+{applicant.employee.empSkills.length - (index + 1)} more</button>
                                                            })
                                                        }
                                                    </div>
                                                    <div className='mt-2 mb-5'>
                                                        <label>Languages</label>
                                                        <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                                            {
                                                                applicant.employee.skillAndEmpStatus.languages.map((ln, index) => (
                                                                    <button key={index} className='btn cst-btn-tags'>{ln.language}</button>
                                                                ))
                                                            }

                                                        </div>
                                                    </div>
                                                    <hr className='mt-1 mb-2' />
                                                    <button className={`btn ${applicant.status === APPLICATION_STATUS.REJECTED ? 'rejected-button' : 'interview-schdule-button'}`} >{APPLICATION_STATUS_DATA[applicant.status]}</button>
                                                    {
                                                        applicant.status == APPLICATION_STATUS.NEW_APPLICATION &&
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <button
                                                                className='btn view-profile-btn'
                                                                type="button"
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRight"
                                                                aria-controls="offcanvasRight"
                                                                disabled={loading}
                                                                onClick={() => handleViewProfile(applicant)}
                                                            >View profile
                                                            </button>
                                                            <button
                                                                className='btn hire-now-btn'
                                                                type="button"
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRight"
                                                                aria-controls="offcanvasRight"
                                                                disabled={loading}
                                                                onClick={() => handleViewProfile(applicant)}
                                                            >Request For Interview
                                                            </button>
                                                        </div>
                                                    }

                                                    {
                                                        applicant.status == APPLICATION_STATUS.INTERVIEW_REQUESTED &&
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <button
                                                                className='btn view-profile-btn'
                                                                type="button"
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRight"
                                                                aria-controls="offcanvasRight"
                                                                disabled={loading}
                                                                onClick={() => handleViewProfile(applicant)}
                                                            >View profile
                                                            </button>
                                                            {/* <button
                                                                className='btn hire-now-btn'
                                                                type="button"
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRight"
                                                                aria-controls="offcanvasRight"
                                                                disabled={loading}
                                                                onClick={() => handleViewProfile(applicant)}
                                                            >Update Interview
                                                            </button> */}
                                                            <button
                                                                className='btn hire-now-btn'
                                                                type="button"
                                                                disabled={loading}
                                                                onClick={() => handleHireNow(applicant)}
                                                            >Hire now
                                                            </button>
                                                        </div>
                                                    }

                                                    {
                                                        applicant.status == APPLICATION_STATUS.INTERVIEW_SCHEDULED &&
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <button
                                                                className='btn view-profile-btn'
                                                                type="button"
                                                                data-bs-toggle="offcanvas"
                                                                data-bs-target="#offcanvasRight"
                                                                aria-controls="offcanvasRight"
                                                                disabled={loading}
                                                                onClick={() => handleViewProfile(applicant)}
                                                            >View profile
                                                            </button>
                                                            <button
                                                                className='btn hire-now-btn'
                                                                type="button"
                                                                disabled={loading}
                                                                onClick={() => handleHireNow(applicant)}
                                                            >Hire now
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    } else return <React.Fragment key={applicant.id}></React.Fragment>;
                                })
                            }
                        </div>
                    )}
                    {applicants.length === 0 && <h5 className='text-center mt-4'>No Candidates!</h5>}

                </div>
            </div>
            <button id="updateView" className='d-none' onClick={() => getApplicants()}></button>
            {
                positionData.length !== 0 && <RequestForInterviewModel
                    employeeId={modalData.employeeId}
                    applicationId={modalData.applicationId}
                    interviewDates={modalData.interviewDates}
                    positions={positionData}
                    posData={posData}
                />
            }

            {posData && <ViewJobDetailsModel posData={posData} />}
        </>
    )
}

export default ViewJobDetails