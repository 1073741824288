import InterviewerImage from '../../../Assets/Images/InterviwerImage.png'
import MapPinIcon from '../../../Assets/Icons/map-pin.svg'
import LaunguageIcon from '../../../Assets/Icons/tabler_language.svg'
import { ApplyJobProps, SkillsAndExpDetails } from '../../../types';
import { useEffect, useState } from 'react';
import Helper from '../../../utils/helper';
import DefaultImage from '../../../Assets/Icons/default.png';
import { EXPERIENCE_LEVEL, genders, languagesOptions } from '../../../utils/constant';
import { toast } from 'react-toastify';


export const ApplyForJobModal = ({ posId, positions, user, setApplied }: ApplyJobProps) => {

    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const [sk, setSk] = useState<SkillsAndExpDetails>();

    useEffect(() => { getSkAndExp() }, [user]);

    const getSkAndExp = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.GET_SK_AND_EXP);
            const { data } = res_data.data;

            if (data) setSk(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const applyForJob = async () => {
        setLoading(true);
        try {
            const res_data = await helper.postReq(helper.POST.APPLY_FOR_JOB, { positionRequestId: posId, status: 1 });
            const { data } = res_data.data;

            if (data) {
                setApplied(true);
                toast.success('Applied Successfully');
                setLoading(false);
                document.getElementById("ApplyForJobModalToggleClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
            toast.error('Failed');
        }
    };

    if (!user) return <p>Loading...</p>

    return (
        <>
            <div className="modal fade" id="ApplyForJobModalToggle" aria-hidden="true" aria-labelledby="ApplyForJobModalToggleLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered ApplyForJobModal-cst" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="container-fluid">
                            {/* <h3>Application</h3> */}
                            <div className='float-end'>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='ApplyForJobModalToggleClose' ></button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='d-flex mobile-two-buttons mb-4' style={{ gap: "20px" }}>
                                <div>
                                    <img src={user.imageUrl && user.imageUrl !== "" ? user.imageUrl : DefaultImage} className='img-fluid employee-image-card-cst' />
                                </div>
                                <div>
                                    <div className='d-flex justify-content-between mobile-two-buttons mb-2'>
                                        <h3>{user.name}</h3>
                                        <div className='d-flex flex-wrap' style={{ gap: "18px" }}>
                                            <button className='btn btn-tag-senior'>{getOptionLabel(sk?.seniority || 1, EXPERIENCE_LEVEL)}{ }</button>
                                            <button className='btn btn-tag-final-interview'>{getOptionLabel(user?.gender || 'Male', genders)}</button>
                                        </div>
                                    </div>
                                    <p className='text-black' style={{ fontWeight: 400 }}>
                                        {getOptionLabel(sk?.positionIds[0] || 1, positions)}&nbsp;&nbsp;
                                        <img className="img-fluid" src={MapPinIcon} />&nbsp;{user?.country}&nbsp;&nbsp;
                                        <img className="img-fluid" src={LaunguageIcon} />&nbsp;
                                        {sk?.languages[0].language || 'English'}<br />
                                    </p>
                                    <p className='text-black' style={{ fontWeight: 500 }}>{sk?.relativeExp} years</p>
                                </div>
                            </div>
                            <div>
                                <label className='cst-secondary-color'>Mobile number</label>
                                <p className='text-black'>{user.phone}</p>
                            </div>
                            <div className='mt-4'>
                                <label className='cst-secondary-color' >Email ID</label>
                                <p className='text-black'>{user.email}</p>
                            </div>
                            <div className='text-center mt-4'>
                                <button
                                    className='btn view-details-btn px-4'
                                    disabled={loading}
                                    onClick={() => applyForJob()}
                                >{loading ? 'Applying...' : 'Apply for this job'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
