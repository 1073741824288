import React, { useState } from 'react'
import { PerkModelProps, PosReqOtherDetails } from '../../../types'

const EquipmentModel = ({ setPosReqOtherData }: PerkModelProps) => {

    const [selected, setSelected] = useState<number[]>([]);

    const handelChange = (evt: any) => {
        const val = Number(evt.target.value);

        if (evt.target.checked) {
            const sel = [...selected];
            sel.push(val);
            setSelected(sel);
        } else {
            const filtered = selected.filter((elm) => elm !== val);
            setSelected(filtered);
        }
    };

    const handleSubmit = (evt: any) => {

        evt.preventDefault();

        if (!selected.length) return;

        setPosReqOtherData((posReq: PosReqOtherDetails) => {
            const perksData = posReq.perks;
            perksData.push(...selected);

            console.log(perksData);

            return { ...posReq, perks: perksData };
        });

        document.getElementById("closeEquipmentModel")?.click();
    };

    return (
        <>
            <div className="modal fade" id="EquipmentModelToggle" aria-hidden="true" aria-labelledby="EquipmentModelToggleLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="EquipmentModelToggleLabel" className='cst-primary-color'>Equipments</h3>
                            <button id='closeEquipmentModel' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='container'>
                            <h5 className='mt-3'>Select a equipments that best meets your team’s needs.</h5>
                            <form name='EquipmentModel' id='HealthCareModelForm' onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                className="form-check-input eqiupment-checkbox"
                                                type="checkbox"
                                                id="flexCheckDefault"
                                                value="5"
                                                onChange={handelChange}
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>Laptop</h5>
                                            <h6>16' MacBook Pro M1 MAX</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6> $100/Month Per unit</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                className="form-check-input eqiupment-checkbox"
                                                type="checkbox"
                                                id="flexCheckDefault"
                                                value="6"
                                                onChange={handelChange}
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>Monitor</h5>
                                            <h6>4K Monitor 27</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$20/Month Per unit</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                className="form-check-input eqiupment-checkbox"
                                                type="checkbox"
                                                id="flexCheckDefault"
                                                value="7"
                                                onChange={handelChange}
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>Keyboard and Mouse</h5>
                                            <h6>Additional unit of keyboard and mouse</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$10/Month Per unit</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                className="form-check-input eqiupment-checkbox"
                                                type="checkbox"
                                                id="flexCheckDefault"
                                                value="8"
                                                onChange={handelChange}
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>Hi-Speed Internet</h5>
                                            <h6>Wifi Connection for your teammates</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$10/Month Per unit</h6>
                                        </div>
                                    </div>
                                    <button
                                        className='btn create-account-btn p-2 col-12'
                                        style={{ minWidth: "158px", minHeight: "58px" }}
                                        type='submit'
                                    >
                                        {/* <img src={AddIcon}/> */}
                                        Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EquipmentModel