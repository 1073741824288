import React, { useState, useRef, useEffect } from "react";
import ClockIcon from '../../Assets/Icons/mingcute_time-line.png'
import { Tooltip as ReactTooltip } from "react-tooltip";

interface TimerProps {
    setCountdownEnd: Function;
}

const Timer = ({ setCountdownEnd }: TimerProps) => {
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [timer, setTimer] = useState<string>("10:00");
    const [countdownEnded, setCountdownEnded] = useState<boolean>(false);
    const [deadline, setDeadline] = useState<Date | null>(null);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                deductTwoMinutes();
                alert('Please refrain from changing tabs or windows, as it may result in termination of the assessment. A penalty of 2 minutes will be deducted for any such actions.');
            } else {
                // Tab is visible
                console.log('Tab is visible');
            }
        };

        const handleWindowBlur = () => {
            // Browser window is minimized
            console.log('Browser window is minimized');
            alert('Please refrain from changing tabs or windows, as it may result in termination of the assessment. A penalty of 2 minutes will be deducted for any such actions.');
            deductTwoMinutes();
        };

        // document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            // document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, []);

    const getTimeRemaining = (deadline: Date): { total: number; minutes: number; seconds: number } => {
        const total = Date.parse(deadline.toString()) - Date.parse(new Date().toString());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total,
            minutes,
            seconds,
        };
    };

    const startTimer = (deadline: Date) => {
        const { total, minutes, seconds } = getTimeRemaining(deadline);
        if (total >= 0) {
            setTimer(
                `${minutes > 9 ? minutes : "0" + minutes}:${seconds > 9 ? seconds : "0" + seconds}`
            );
        } else {
            clearInterval(timerRef.current as NodeJS.Timeout);
            setCountdownEnded(true);
            setCountdownEnd(true);
        }
    };

    const clearTimer = (deadline: Date) => {
        setTimer("10:00");
        if (timerRef.current) clearInterval(timerRef.current);
        const id = setInterval(() => {
            startTimer(deadline);
        }, 1000);
        timerRef.current = id;
    };

    const getDeadline = (): Date => {
        const newDeadline = new Date();
        newDeadline.setMinutes(newDeadline.getMinutes() + 10); // Set deadline to 10 minutes from now
        setDeadline(newDeadline);
        return newDeadline;
    };

    useEffect(() => {
        const newDeadline = getDeadline();
        clearTimer(newDeadline);
        return () => {
            if (timerRef.current) clearInterval(timerRef.current);
        };
    }, []);

    const onClickReset = () => {
        const newDeadline = getDeadline();
        clearTimer(newDeadline);
        setCountdownEnded(false);
        setCountdownEnd(false);
    };

    const handleCountdownEnd = () => {
        // Handle countdown end event here
        console.log("Countdown ended!");
        setCountdownEnded(true);
        setCountdownEnd(true);
    };

    const deductTwoMinutes = () => {
        if (deadline) {
            const newDeadline = new Date(deadline);
            newDeadline.setMinutes(newDeadline.getMinutes() - 2); // Deduct 2 minutes
            clearTimer(newDeadline);
            setDeadline(newDeadline);
        }
    };

    return (
        <>
            <div style={{ gap: "12px" }} className="cst-border-btn-clock d-flex justify-content-end align-items-" data-tooltip-id="my-tooltip-12">
                <div><img src={ClockIcon} className="img-fluid" /></div>
                <div><h4>{timer}</h4></div>
                {/* {countdownEnded && <p>Countdown Ended!</p>} */}
            </div>
            <ReactTooltip
                className='cst-bg-blue-tooltip'
                id="my-tooltip-12"
                place="bottom"
                render={(props) => (
                    <>
                        <div>
                            <b>Save 90% of engineering &</b><br /> recruitment bandwidth, outsource<br /> candidate interviews to us
                        </div>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <b>Step 1 of 4</b>
                            <b>Skip</b>
                            <button className="btn tooltip-next-button">Next</button>
                        </div>
                    </>
                )}
            />
        </>
    );
};

export default Timer;
