import React, { useEffect, useState } from 'react'
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import RightSideArrowIcon from '../../Assets/Icons/maki_arrow.png'
import CodeImage from '../../Assets/Images/image 20.png'
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import Helper from '../../utils/helper'
import { TestData } from '../../types'
import { toast } from 'react-toastify'
import Timer from '../../Components/Candidate/Timer'
import LottieAnimation from '../../Components/LottieAnimation'
import ClockIcon from '../../Assets/Icons/mingcute_time-line.png'

const GPTVettingEmployeeMcqPage = () => {

    const location = useLocation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [isCountdownEnd, setCountdownEnd] = useState<boolean>(false);
    const [isAnswered, setIsAnswered] = useState<boolean>(false);
    const [currentQue, setCurrentQue] = useState<number>(1);
    const [tabChanges, setTabChanges] = useState<number>(0);
    const helper = Helper.getInstance();
    const [test, setTest] = useState<TestData[]>([]);

    useEffect(() => {
        if (!location.state || !location.state.skill || !location.state.exp) {
            navigate('/candidate-gpt-vetting', { replace: true });
        } else if (user) getMcqs(location.state.skill, location.state.exp);
    }, [user, location.state]);

    const getMcqs = async (skill: string, exp: string) => {
        try {
            const res_data = await helper.postReq(helper.POST.GEN_MCQS, { skill, experience: Number(exp) });
            const { data } = res_data.data;

            if (data) setTest(data.test);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
            navigate('/candidate-gpt-vetting', { replace: true });
        }
    };

    useEffect(() => {
        if (isCountdownEnd) saveTest();
    }, [isCountdownEnd])

    // useEffect(() => {
    //     const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    //         event.preventDefault();
    //         event.returnValue = ''; // This line is required for some browsers
    //         console.log('Aeeeee');
    //         if (tabChanges === 3) navigate('/candidate-gpt-vetting', { replace: true })
    //         else alert('Please don"t Change Tab or Window, It will lead to termination of Assesment');
    //     };

    //     const addBeforeUnloadListener = () => {
    //         window.addEventListener('beforeunload', handleBeforeUnload);
    //     };

    //     const removeBeforeUnloadListener = () => {
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //     };

    //     addBeforeUnloadListener();

    //     return () => {
    //         removeBeforeUnloadListener();
    //     };
    // }, []);

    const getAnswerString = (optIn: number) => {
        switch (optIn) {
            case 0:
                return 'a';
            case 1:
                return 'b';
            case 2:
                return 'c';
            case 3:
                return 'd';
            default:
                return 'a';
        }
    }

    const giveAnswer = (index: number, answer: number) => {
        // console.log(index);
        // console.log(answer);
        const answerString = getAnswerString(answer);
        // console.log(answerString);
        const newData = JSON.parse(JSON.stringify(test));
        newData[index].givenAns = answerString;
        setTest(newData);
        setIsAnswered(true);
        // console.log(test);
    };

    const handleNext = () => {
        if (currentQue <= 9 && isAnswered) {
            setCurrentQue(currentQue + 1);
            setIsAnswered(false);
        } else if (currentQue === 10 && isAnswered) saveTest();
    };

    const saveTest = async () => {
        setLoading2(true);
        const bData = {
            skill: location.state.skill,
            experience: Number(location.state.exp),
            queAnswers: test,
            type: Number(location.state.type)
        };

        try {
            const res_data = await helper.postReq(helper.POST.CREATE_RESULT, bData);
            const { data } = res_data.data;

            if (data) navigate('/candidate-gpttest-end', { replace: true, state: { result: true } });
            setLoading2(false);
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };

    const handleCopy = (event: any) => {
        event.preventDefault();
        toast.error('Copying text is not allowed.');
    };

    return (
        <>
            <section className='vh-100' style={{ background: "#F1EBFF", height: '100%' }}>
                <div className='container'>
                    <div className='row'>
                        {loading ?
                            <>
                                <div className='col-sm-8 col-12 mx-auto mb-4'>
                                    <h3 className='pt-5 text-center'>Preparing your Test...</h3>
                                </div>
                                <LottieAnimation />
                            </> : (
                                <div className='col-sm-8 col-12 mx-auto mb-4'>
                                    <div className='d-flex justify-content-between mt-4 mb-3'>
                                        <div>
                                            <img src={Logo_Horizontal_purpleImage} />
                                            {/* <Timer setCountdownEnd={setCountdownEnd} /> */}
                                        </div>
                                        {/* <div style={{ color: "#BCBCBC" }}>
                                            <h5>Gen AI Vetting</h5>
                                            <h6>powered by OWOW</h6>
                                        </div> */}
                                            <Timer setCountdownEnd={setCountdownEnd} />

                                        {/* <button><img src={ClockIcon} /><Timer setCountdownEnd={setCountdownEnd} /></button> */}
                                    </div>
                                    <div className='card cst-Lets-get-started p-4'>
                                        <div className='d-flex justify-content-end'>
                                            <h5>{currentQue}/10</h5>
                                        </div>
                                        {test.map((testData: TestData, index: number) => {
                                            if (currentQue - 1 === index) return (<React.Fragment key={`qt${index}`}>
                                                {/* <div className='list-of-numbers-text'>
                                                <ul onCopy={handleCopy}>
                                                    <li onCopy={handleCopy}> {testData.que}</li>
                                                </ul>
                                            </div> */}
                                                <label style={{ 'fontSize': '18px', 'fontWeight': '500' }} onCopy={handleCopy}>{index + 1}. {testData.que}</label>

                                                {/* <div className='text-center'>
                                                <img src={CodeImage} className='img-fluid' />
                                            </div> */}
                                                <ul style={{ listStyle: "none" }}>
                                                    {testData.opt.map((opt: string, indexQ: number) => (
                                                        <li className='pt-4' key={`opt${indexQ}`} onCopy={handleCopy}>
                                                            <div className='d-flex align-items-center'>
                                                                <input
                                                                    type="radio"
                                                                    id={`options${currentQue}`}
                                                                    className='form-check-input'
                                                                    name={`options${currentQue}`}
                                                                    value={indexQ}
                                                                    onClick={() => giveAnswer(index, indexQ)}
                                                                />
                                                                <label
                                                                    onCopy={handleCopy}
                                                                    htmlFor="option-c"
                                                                    className="option option-c"
                                                                >&nbsp;&nbsp;{opt}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    ))}
                                                    {/* <li className='pt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <input type="radio" id="option-b" className='form-check-input' name="options" value="b" />
                                                        <label htmlFor="option-b" className="option option-b">
                                                            &nbsp;b) [2, 2, 3]
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className='pt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <input type="radio" id="option-c" className='form-check-input' name="options" value="c" />
                                                        <label htmlFor="option-c" className="option option-c">
                                                            &nbsp;c) [4, 2, 3]
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className='pt-4'>
                                                    <div className='d-flex align-items-center'>
                                                        <input type="radio" id="option-d" className='form-check-input' name="options" value="d" />
                                                        <label htmlFor="option-d" className="option option-d">
                                                            &nbsp;d) [1, 6, 3]
                                                        </label>
                                                    </div>

                                                </li> */}
                                                </ul>
                                            </React.Fragment>)
                                        })}
                                        {/* <div className="code-snippet">
                                                <pre>
                                                    <code>
                                                        {`function add(a, b) {
    return a + b;
    }`}
                                                    </code>
                                                </pre>
                                            </div> */}
                                        {/* <ul>
                                                <li>a) Adds two numbers</li>
                                                <li>b) Subtracts two numbers</li>
                                                <li>c) Multiplies two numbers</li>
                                                <li>d) Divides two numbers</li>
                                            </ul> */}

                                        <div className='d-flex justify-content-end mt-4'>
                                            {currentQue === 10 ? (
                                                <button
                                                    className='btn start-test-button'
                                                    disabled={!isAnswered || loading2}
                                                    onClick={() => handleNext()}
                                                >{loading2 ? 'Generating Result...' : 'Submit'} <img src={RightSideArrowIcon} className='img-fluid' />
                                                </button>
                                            ) : (
                                                <button
                                                    className='btn start-test-button'
                                                    disabled={!isAnswered || loading2}
                                                    onClick={() => handleNext()}
                                                >{loading2 ? 'Generating Result...' : 'Next'} <img src={RightSideArrowIcon} className='img-fluid' />
                                                </button>

                                            )}
                                        </div>
                                    </div>

                                </div>
                            )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default GPTVettingEmployeeMcqPage