import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import moment from 'moment'
import UploadImage from '../../Assets/Images/uploadImage.png'

// import useAuth from '../hooks/useAuth';

import axios from 'axios';
import { toast } from 'react-toastify';
import Helper from '../../utils/helper';

import { SideBarProps, User, imageAllowedFileTypes } from '../../types';
import { customStyles, genders } from '../../utils/constant';

import DefaultImage from '../../Assets/Icons/default.png';
import IditIcon from '../../Assets/Icons/EditIcon.svg'
import LinkdinIcon from '../../Assets/Icons/LinkdinIcon.svg'
import Spinner from '../../Assets/Images/spinner.png';

import Cropper, { ReactCropperElement } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { compress } from 'image-conversion';
import useAuth from '../../hooks/useAuth';

import dayjs from 'dayjs';


import CountryIconOne from '../../Assets/CountryIcons/in.png'
import CountryIconFoure from '../../Assets/CountryIcons/us.png'
import { DatePicker } from 'antd';
import { BsCalendarFill } from "react-icons/bs";

const AccountForm = () => {

    const helper = Helper.getInstance();
    const [isEditing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [linkErr, setLinkErr] = useState<string | null>(null);
    const [dob, setDOB] = useState<string>('');
    const { user, getDetails } = useAuth();

    const initData = {
        id: 1,
        email: '',
    };

    const [customerData, setCustomerData] = useState<User>(user || initData);

    const cropperRef = useRef<ReactCropperElement>(null);

    useEffect(() => {
        try {
            // login('', '', customer);
            setDOB((new Date((customerData.dob) as string)).toISOString().split('T')[0]);
        } catch (error) {
            console.log(error);
        }
    }, [customerData.dob]);

    useEffect(() => {
        if (customerData && customerData?.countryCode == '+1') {
          setSelectedCountryCode('+1');
          setSelectedCountryFlag(CountryIconFoure);
        } 
      }, [customerData?.countryCode]);

    useEffect(() => {
        setCustomerData(user || initData);
    }, [user]);


    const validLinkedIn = () => {
        const linkedinRegex: RegExp = /^https:\/\/www\.linkedin\.com\/(in|pub)\/[a-zA-Z0-9_-]+\/?$/;
        const isValid = linkedinRegex.test(customerData.linkedinUrl || '');
        return isValid;
    }

    const handleSave = async (ev: any) => {
        ev.preventDefault();

        if (linkErr) return toast.error('Invalid LinkedIn URL');

        setLoading(true);
        try {
            const userDat = {
                name: customerData.name,
                phone: customerData.phone,
                countryCode: customerData.countryCode,
                dob: customerData.dob,
                jobTitle: customerData.jobTitle,
                gender: customerData.gender,
                linkedinUrl: customerData.linkedinUrl,
                fromProfile: true
            };

            const res_data = await helper.postReq(helper.POST.ADD_PERSONAL_DETAILS, userDat);
            const { data } = res_data.data;

            if (data.customer) {
                toast.success('Updated Successfully');
                setEditing(false);
                // getDetails();
                window.location.reload();
            } else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Update Details Failed');
        }
        setLoading(false);
    };
    // console.log(customerData)

    const handleUpload = async (blobData: Blob) => {
        if (!blobData) return;

        try {
            setLoading2(true);

            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);
            const fileTypeArr = blobData.type.split("/");
            const name = `${fileID}.${fileTypeArr[1]}`;

            reqData.append('file', blobData, name);

            reqData.append('id', JSON.stringify(user?.id));

            console.log(reqData);
            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            // const res_data = await axios.post(`http://localhost:3030${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData);
            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setCustomerData({ ...customerData, imageUrl: data });
                setTimeout(() => {
                    setLoading2(false);
                }, 100);
            } else {
                setLoading2(false);
            }
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Update Details Failed');
        }
    };

    // Function to handle image selection
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result as string);
                setTimeout(() => {
                    document.getElementById('crop-button')?.click();
                }, 100);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to handle cropping
    const handleCrop = async () => {
        if (cropperRef.current) {
            // if (typeof cropperRef.current?.cropper !== "undefined") {
            // setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            handleCompress(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setTimeout(() => {
                document.getElementById('crop-button')?.click();
            }, 100);
            // }
        }
    };

    const dataURLtoBlob = (dataURL: string): Blob => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const handleCompress = async (data: any) => {
        if (data) {
            // console.log('Cropped Image URL:', croppedImage);

            const croppedBlob = dataURLtoBlob(data);
            const compressedImageData = await compress(croppedBlob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
            });

            handleUpload(compressedImageData);
        }
    };
    const maxDate = () => {
        const today = new Date('01/01/2010');
        return today;
    };

    const minDate = () => {
        const today = new Date('01/01/1910');
        return today;
    };

    const handleLinkedIn = (val: string) => {
        const linkedinUrlRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/(in|pub)\/[a-zA-Z0-9-]+(\/[a-zA-Z0-9-]+)?\/?$/;

        const isValidLinkedInUrl = linkedinUrlRegex.test(val);

        if (!isValidLinkedInUrl) setLinkErr('Invalid URL');
        else setLinkErr(null);

        setCustomerData({ ...customerData, linkedinUrl: val });

    }

    const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    const [selectedCountryFlag, setSelectedCountryFlag] = useState(CountryIconOne);

    const handleCountryCodeChange = (code: any, flag: any) => {
        setSelectedCountryCode(code);
        setSelectedCountryFlag(flag);
    };

    return (
        <>
            {/* Edit Collaborators*/}
            <div className="modal fade" id="cropperModal" aria-hidden="true" aria-labelledby="cropperModalLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="cropperModalLabel">Crop Image</h3>
                            <button id='closeCropperModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* {imageSrc && ( */}
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-12">
                                    <Cropper
                                        aspectRatio={1}
                                        ref={cropperRef}
                                        src={imageSrc || ''}
                                        style={{ height: 400, width: '100%' }}
                                    />
                                </div>
                            </div>

                        </div>
                        {/* )} */}
                        {/* <div className='d-flex my-2 justify-content-center' style={{ gap: "16px" }}> */}
                        <div className='d-flex my-2 justify-content-center flex-md-row flex-column' style={{ gap: "16px" }}>

                            <button
                                className='btn social-media-btn'
                                type='button'
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ minWidth: "326px" }}>
                                Cancel
                            </button>
                            <button
                                className='btn create-account-btn'
                                type='submit'
                                style={{ minWidth: "326px" }}
                                onClick={handleCrop}
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {!isEditing &&
                <div className='container-fluid'>
                    <div className='d-flex flex-wrap align-items-center justify-content-between'>
                        <div className='d-flex flex-wrap align-items-center' style={{ gap: "16px" }}>
                            <div>
                                {loading2 ? (
                                    <img
                                        alt="not found"
                                        className='img-fluid'
                                        style={{ width: '80px', borderRadius: "50%" }}
                                        src={Spinner}
                                    />
                                ) : (
                                    <img
                                        alt="not found"
                                        className='img-fluid'
                                        style={{ width: '80px', cursor: 'pointer', borderRadius: "50%" }}
                                        src={customerData.imageUrl && customerData.imageUrl !== "" ? customerData.imageUrl : UploadImage}
                                        onClick={() => document.getElementById("imageInput")?.click()}
                                    />
                                )}

                                <input
                                    type="file"
                                    name="myImage"
                                    id='imageInput'
                                    hidden
                                    accept={imageAllowedFileTypes}
                                    onChange={handleImageChange}
                                />
                            </div>
                            <div>
                                <h1>{customerData.name}</h1>
                                <p className='text-black'>{customerData.isCollaborator ? 'Collaborator' : 'Admin'}</p>
                                <button type='button' hidden id="crop-button" className="btn" data-bs-target="#cropperModal" data-bs-toggle="modal">Add DDDDDDDDD</button>

                            </div>
                        </div>
                        <div>
                            <div className='text-end my-5 cst-ptr' onClick={() => setEditing(true)}>
                                <img src={IditIcon} className='img-fluid' alt='idit-icon' />&nbsp;<span style={{ color: "#492A99", fontSize: "22px", fontWeight: "500" }}>Edit</span>
                            </div>
                        </div>
                    </div>
                    <div className='card p-3 font-size-small-div' style={{
                        borderRadius: "36px",
                        background: "rgb(255, 255, 255)",
                        border: "none",
                    }}>
                        <div className='m-3 '>
                            <h2>Overview</h2>
                            <div className='d-flex justify-content-between flex-wrap' style={{ gap: "16px" }}>
                                <div className='mt-4'>
                                    <label className='overview-sec-label'>Date of birth (DOB)</label>
                                    <h4>{dob ? moment(dob).format("MM-DD-YYYY") : "-"}</h4>


                                </div>
                                <div className='mt-4'>
                                    <label className='overview-sec-label'>Gender</label>
                                    <h4>{customerData?.gender !== null ? customerData?.gender : "-"}</h4>
                                </div>
                                <div className='mt-4'>
                                    <label className='overview-sec-label'>Mobile number</label>
                                    <h4>{customerData?.phone !== null ? customerData?.phone : "-"}</h4>
                                </div>

                                <div className='mt-4'>
                                    <label className='overview-sec-label'>Email ID</label>
                                    <h4>{customerData?.email !== null ? customerData?.email : "-"}</h4>
                                </div>
                                <div className='mt-4'>
                                    <label className='overview-sec-label'>Linkedin</label>
                                    <h4><a target='_blank' href={customerData.linkedinUrl || 'https://linkedin.com'}><img src={LinkdinIcon} alt='Linkdin-icon' /></a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >}

            {/* Add Collaborators*/}
            {isEditing &&
                <div className='container-fluid'>
                    <form onSubmit={handleSave}>

                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex flex-wrap align-items-center' style={{ gap: "16px" }}>
                                <div>
                                    {loading2 ? (
                                        <img
                                            alt="not found"
                                            className='img-fluid'
                                            style={{ width: '80px', borderRadius: "50%" }}
                                            src={Spinner}
                                        />
                                    ) : (
                                        <img
                                            alt="not found"
                                            className='img-fluid'
                                            style={{ width: '80px', cursor: 'pointer', borderRadius: "50%" }}
                                            src={customerData.imageUrl && customerData.imageUrl !== "" ? customerData.imageUrl : UploadImage}
                                            onClick={() => document.getElementById("imageInput")?.click()}
                                        />
                                    )}

                                    <input
                                        type="file"
                                        name="myImage"
                                        id='imageInput'
                                        hidden
                                        accept={imageAllowedFileTypes}
                                        onChange={handleImageChange}
                                    />
                                </div>
                                <div>
                                    <h1>{customerData.name}</h1>
                                    <p className='text-black'>{customerData.isCollaborator ? 'Collaborator' : customerData.jobTitle}</p>
                                    <button type='button' hidden id="crop-button" className="btn" data-bs-target="#cropperModal" data-bs-toggle="modal">Add DDDDDDDDD</button>

                                </div>
                            </div>
                            <div>
                                <div className='text-end my-5 cst-ptr'  >
                                    <div className='d-flex my-4' style={{ gap: "16px" }}>
                                        <button
                                            className='btn create-account-btn cst-save-das'
                                            type='submit'
                                            disabled={loading} >
                                            {loading ? 'Saving...' : 'Save'}
                                        </button>
                                        <button
                                            className='btn social-media-btn cst-save-das'
                                            type='button'
                                            onClick={() => window.location.reload()}
                                            disabled={loading}
                                        >Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='card cst-account-card' style={{
                            borderRadius: "36px",
                            background: "#FFF",
                            border: "none"
                        }}>
                            <div className='row'>
                                <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                    <label>Full name</label>
                                    <input
                                        type="text"
                                        name='name'
                                        className="form-control custom-input"
                                        placeholder="Enter here"
                                        value={customerData.name}
                                        onChange={(ev) => setCustomerData({ ...customerData, name: ev.target.value })}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                    <label>Email ID</label>
                                    <input
                                        type='email'
                                        id='email'
                                        className="form-control custom-input"
                                        value={customerData.email}
                                        disabled
                                        readOnly
                                    />
                                </div>
                                <div className="col-12 col-md-4 margin-fix-bottam">
                                    <label>Mobile number</label>
                                    {/* <input
                                        type="text"
                                        id='phone'
                                        className="form-control custom-input"
                                        placeholder="Enter here"
                                        value={customerData.phone}
                                        onChange={(ev) => setCustomerData({ ...customerData, phone: ev.target.value })}
                                        required
                                    /> */}
                                    <div className='d-flex' style={{ gap: "12px" }}>
                                        <div className='m-cst-mobile-int'>
                                            <button
                                                type="button"
                                                className="phno"
                                                id="countryCodeDropdown"
                                                data-bs-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <span id="selectedCountryCode">
                                                    {selectedCountryCode} <img src={selectedCountryFlag} className="img-fluid" />{' '}
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                                        <path d="M7 8L0 0H14L7 8Z" fill="black" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <div className="dropdown-menu" aria-labelledby="countryCodeDropdown">
                                                <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+91', CountryIconOne)}>
                                                    <img src={CountryIconOne} className="img-fluid" alt="India" /> +91 (India) <hr />
                                                </a>
                                                <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+1', CountryIconFoure)}>
                                                    <img src={CountryIconFoure} className="img-fluid" alt="US" />  +1 (US)
                                                </a>
                                            </div>
                                        </div>
                                        <div style={{ width: "-webkit-fill-available" }}>
                                            <input
                                                type="tel"
                                                className="form-control custom-input"
                                                name="phone"
                                                value={customerData.phone}
                                                onChange={(ev) => setCustomerData({ ...customerData, phone: ev.target.value })}
                                                id="phone"
                                                placeholder="Enter here"
                                                minLength={9}
                                                maxLength={10}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                    <label>Job title</label>
                                    <input
                                        type="text"
                                        id='jobTitle'
                                        className="form-control custom-input"
                                        placeholder="Enter here"
                                        value={customerData.jobTitle}
                                        onChange={(ev) => setCustomerData({ ...customerData, jobTitle: ev.target.value })}
                                        required
                                    />
                                </div>
                                <div className="col-12 col-md-4 margin-fix-bottam">
                                    <label>Date of Birth</label>
                                    <div className='date-picker-input'>
                                        <DatePicker
                                            className='form-control custom-datepicker'
                                            disabledDate={d => !d || d.isAfter(maxDate()) || d.isBefore(minDate())}
                                            suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                            allowClear={false}
                                            defaultValue={dayjs(customerData.dob || new Date())}
                                            onChange={(val) => setCustomerData({ ...customerData, dob: val?.toISOString() })}
                                            style={{ width: '100%', fontSize: '16px' }}
                                            placeholder='YYYY-MM-DD'
                                        // popupStyle={{ fontSize: '12px' }} 
                                        />
                                    </div>

                                </div>
                                <div className="col-12 col-md-4 margin-fix-bottam m-0">
                                    <label>Gender</label>
                                    <Select
                                        options={genders}
                                        value={genders.filter((ct) => ct.value == customerData.gender)[0]}
                                        onChange={(ev) => setCustomerData({ ...customerData, gender: ev?.value || '' })}
                                        styles={customStyles}
                                        className="mb-4"
                                        required
                                        placeholder="Select"
                                    />
                                </div>
                                <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                    <label>LinkedIn</label>
                                    <input
                                        type="text"
                                        className="form-control custom-input"
                                        placeholder="https://www.linkedin.com/in/username"
                                        value={customerData.linkedinUrl || ''}
                                        onChange={(ev) => handleLinkedIn(ev.target.value)}
                                    />
                                    {linkErr !== "" && <p style={{ color: 'red', fontSize: 'medium' }}>{linkErr}</p>}
                                </div>
                            </div>
                        </div>
                    </form>
                    {/* <button type='button' id="crop-button" className="btn" data-bs-target="#cropperModal" data-bs-toggle="modal">Add DDDDDDDDD</button> */}
                </div>
            }
        </>
    )
}

export default AccountForm