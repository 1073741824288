import { useEffect, useState } from 'react';
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { KeyedObject, PasswordValidation, ResetPasswordType, UserEmailPass } from '../../../types';
import { toast } from 'react-toastify';
import VerifiedIcon from '../../../Assets/Icons/material-symbols_verified.svg'
import { useNavigate, useSearchParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Helper from '../../../utils/helper';
import useAuth from '../../../hooks/useAuth';

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const helper = Helper.getInstance();
  const { logout } = useAuth();

  const initialValue: ResetPasswordType = {
    customerId: 1,
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isPassValid, setIsPassValid] = useState<boolean>(true);

  const [user, setUser] = useState<ResetPasswordType>(initialValue);

  const initPassVal: PasswordValidation = {
    hasNumber: false,
    hasSpecialChar: false,
    hasUppercase: false,
    hasLowercase: false,
    isMinLength: false,
  };

  const [passValidation, setPassValidation] = useState<PasswordValidation>(initPassVal);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) navigate('/employer-login', { replace: true });
    else {
      const decoded: KeyedObject = jwtDecode(token);
      if (!decoded || !decoded.userId || !decoded.email || !decoded.resetPassword) navigate('/employer-login', { replace: true });
      else {
        const data = {
          customerId: decoded.userId,
          email: decoded.email,
          password: "",
          confirmPassword: "",
        }
        setUser(data);
      }
    }
  }, []);


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (user.password == '') return;

    if (user.password !== user.confirmPassword) return toast.error('Both Password do not match');
    else await resetPasswordAPI();
  };

  const resetPasswordAPI = async () => {
    setLoading(true);
    try {
      const reqData = {
        customerId: Number(user.customerId),
        password: user.password,
        token: searchParams.get("token")
      }
      const res_data = await helper.putReq(helper.PUT.RESET_PASSWORD, reqData);
      const { data } = res_data.data;
      setLoading(false);

      if (data) {
        toast.success('Set Successfully, Please login with new Password');
        document.getElementById('closeChangePassword')?.click();
        setLoading(false);
        logout();
      } else throw new Error("Failed");
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Reset Password Failed');
    }
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    // const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(
    const isPasswordValid = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z]).{10,}$/.test(
      newPassword
    );
    setIsPassValid(isPasswordValid);
    setPassValidation({
      hasNumber: /\d/.test(newPassword),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
      hasUppercase: /[A-Z]/.test(newPassword),
      hasLowercase: /[a-z]/.test(newPassword),
      isMinLength: newPassword.length >= 10,
    });
    setUser({ ...user, password: newPassword });
  };

  return (
    <section>
      <div className='row'>
        <div className='col-sm-6 d-none d-sm-block d-print-block'>
          <div className='hero-left-col px-5'>
            <h1 className='left-clumn-first-heading text-white'>“</h1>
            <h1 className='left-clumn-heading text-white'>From talent acquisition to HR, legal compliance, and  logistics, we've got you covered.</h1>
            <button className='btn btn-outline verified-button mt-2'><img src={VerifiedIcon} className='px-2' /> 100% Verfied Talents</button>
          </div>
        </div>
        <div className='col-sm-6 '>
          {/* <div className='container cst-container-bg'> */}
          <div className='cst-container-bg'>
            <h1 className='owow d-sm-none '>owow</h1>
            <div className='cst-mobile-bg'>
              <form onSubmit={handleSubmit}>
                <div className='right-side-container'>
                  <h1 className='owow d-none d-sm-block d-print-block'>owow</h1>
                  <h1 className='welcome-heading'>Welcome 👋</h1>
                  <h4 className='heado-para margin-fix-bottam'>Reset Password - {user.email}</h4>

                  <div className="col-12 input-group-lg">
                    <label>Enter New Password</label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      onChange={handlePasswordChange}
                      className="form-control custom-input"
                      placeholder="Enter Here"
                      id="password"
                      required
                    />
                    {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}
                    {/* {!isPassValid && <p style={{ color: 'red', fontSize: 'medium' }}>Password Length 8-64 characters<br />At least 1 Lowercase<br />1 Uppercase<br />1 Number<br /> 1 Special Character</p>} */}
                    {!isPassValid && (
                      <div className='password-validation-card p-3 mb-4'>
                        <h6 className="text-black checkbox-text">Your password must contain:</h6>
                        <div className='form-check'>
                          <ul>
                            <li>
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.isMinLength} />
                              <p className="text-black checkbox-text"> a minimum of 10 character</p>
                            </li>
                            <li>
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasUppercase} />
                              <p className="text-black checkbox-text"> an uppercase character</p></li>
                            <li>
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasLowercase} />
                              <p className="text-black checkbox-text"> a lowercase character</p></li>

                          </ul>
                          <ul>
                            <li>
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasNumber} />
                              <p className="text-black checkbox-text"> a number</p></li>
                            <li>
                              <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasSpecialChar} />
                              <p className="text-black checkbox-text"> a special character</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-12 input-group-lg">
                    <label>Confirm New Password</label>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      onChange={(ev) => setUser({ ...user, confirmPassword: ev.target.value })}
                      disabled={!isPassValid || !user.password}
                      className="form-control custom-input"
                      placeholder="Enter Here"
                      id="password"
                      required
                    />
                    {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}
                    {/* {!isPassValid && <p style={{ color: 'red', fontSize: 'medium' }}>Password Length 8-64 characters<br />At least 1 Lowercase<br />1 Uppercase<br />1 Number<br /> 1 Special Character</p>} */}
                  </div>

                  <div className="col-12 text-center margin-fix-bottam">
                    <button
                      type='submit'
                      disabled={loading}
                      className='btn create-account-btn p-3 col-12 '>
                      {!loading ? 'Reset Password' : 'Resetting...'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResetPassword