import { useEffect, useState } from 'react';
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { PasswordValidation, UserEmailPass } from '../../../types';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { LOGIN_TYPES } from '../../../utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeSignUp = () => {
    const initialValue: UserEmailPass = {
        email: "",
        password: ""
    };

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPassValid, setIsPassValid] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [tandcRead, setTandcRead] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserEmailPass>(initialValue);
    const { register, socialLogin } = useAuth();

    const initPassVal: PasswordValidation = {
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
        hasLowercase: false,
        isMinLength: false,
    };

    const [passValidation, setPassValidation] = useState<PasswordValidation>(initPassVal);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (!tandcRead) return toast.error('Please read and accept T&C and Privacy Policy');
        setLoading(true);
        if (isEmailValid && isPassValid) {
            setLoading(true);
            await register(user, true);
            setLoading(false);
        } else {
            setLoading(false);
            toast.error('Enter Valid Email and Password')
        }

    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        // const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(
        const isPasswordValid = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z]).{10,}$/.test(
            newPassword
        );
        setIsPassValid(isPasswordValid);
        setPassValidation({
            hasNumber: /\d/.test(newPassword),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
            hasUppercase: /[A-Z]/.test(newPassword),
            hasLowercase: /[a-z]/.test(newPassword),
            isMinLength: newPassword.length >= 10,
        });
        setUser({ ...user, password: newPassword });
    };

    const handleEmailChange = async (email: string) => {
        setUser({ ...user, email });
    };

    const handleGoogleSuccess = async (data: any) => {
        if (data && data.credential) {
            console.log(data);
            setLoading(true);
            socialLogin(data.credential, LOGIN_TYPES.GOOGLE, true);
            setLoading(false);
        } else {
            setLoading(false);
            toast.error('Google Login Failed');
        }
    };
    const handleEmpLogin = (e: any) => {
        e.preventDefault();
        navigate('/candidate-login')
    }
    return (
        <>
            <section>
                <div className='row'>
                    <div className='col-sm-6 d-none d-sm-block d-print-block'>
                        <div className='welcome-lets-started-container d-none d-sm-block d-print-block px-5'>
                            {/* <h1 className="owow-lets-started">owow</h1> */}
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />
                            <h2 className="welcome-lete-started" >Create your profile👋</h2>
                            <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India</h4>
                            <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-sm-6 '>
                        <div className='cst-container-bg'>
                            {/* <h1 className='owow d-sm-none '>owow</h1> */}
                            <div className="text-center pt-4">
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none ' />
                            </div>

                            <div className='cst-mobile-bg'>
                                <form onSubmit={handleSubmit}>
                                    <div className='right-side-container'>
                                        <h1 className='welcome-heading d-none d-sm-block d-print-block pt-5'>Welcome 👋</h1>
                                        <h1 className='welcome-heading d-sm-none'>Welcome</h1> {/* Display on Mobile Only */}
                                        <h4 className='heado-para margin-fix-bottam'>Signing up is simple, free, and fast. Sign up and get started.</h4>

                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Email*</label>
                                            <input
                                                type="text"
                                                id='email'
                                                value={user.email}
                                                onChange={(val) => handleEmailChange(val.target.value)}
                                                className="form-control custom-input"
                                                placeholder="Enter Your Email ID"
                                                required
                                            />
                                        </div>

                                        <div className="col-12 input-group-lg">
                                            <label>Password</label>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={handlePasswordChange}
                                                className="form-control custom-input"
                                                placeholder="Enter Your password"
                                                id="password"
                                                required
                                            />
                                            {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}

                                            {!isPassValid && (
                                                <div className='password-validation-card p-3 mb-4'>
                                                    <h6 className="text-black checkbox-text">Your password must contain:</h6>
                                                    <div className='form-check'>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.isMinLength} />
                                                                <p className="text-black checkbox-text"> a minimum of 10 character</p>
                                                            </li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasUppercase} />
                                                                <p className="text-black checkbox-text"> an uppercase character</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasLowercase} />
                                                                <p className="text-black checkbox-text"> a lowercase character</p></li>

                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasNumber} />
                                                                <p className="text-black checkbox-text"> a number</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasSpecialChar} />
                                                                <p className="text-black checkbox-text"> a special character</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        <div className="col-12 form-check  margin-fix-bottam">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={tandcRead}
                                                onChange={() => setTandcRead((val) => !val)}
                                                id="flexCheckDefault"
                                            />
                                            <p className="text-black checkbox-text"> I agree to OWOW’s <a href="https://owowtalents.com/terms-of-service-agreement" target='_blank'>Terms of Service</a> and <a href="https://owowtalents.com/privacy-policy" target='_blank'>Privacy Policy</a>. </p>
                                        </div>

                                        <div className="col-12 text-center margin-fix-bottam">
                                            <button
                                                type='submit'
                                                disabled={loading}
                                                className='btn create-account-btn col-12 '>
                                                {!loading ? 'Create an account' : 'Creating your account...'}
                                            </button>
                                        </div>
                                        <div className='col-12 d-flex justify-content-center fix-margin-for-or '>
                                            <span className='divide-span '> </span>
                                            <span className='px-2 py-0' style={{ marginTop: "-10px" }}> OR</span>
                                            <span className='divide-span'> </span>
                                        </div>
                                        <div className='cst-button-alignment'>
                                            <button
                                                type='button'
                                                className='btn btn-outline social-media-btn w-100'
                                            >
                                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                                                    <GoogleLogin

                                                        text={'signup_with'}
                                                        onSuccess={handleGoogleSuccess}
                                                        onError={() => toast.error('Google Signup Failed')}
                                                    />
                                                </GoogleOAuthProvider>
                                            </button>


                                        </div>
                                        <div className='col-12 text-center mt-4' style={{ gap: "10px" }}>
                                            <label>
                                                <p className='already-account'>Already have an account?</p>
                                            </label> <a href='#' onClick={handleEmpLogin} className='log-in'>Log in</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmployeeSignUp