import axios from './axios';

import { CLIENT_DEVICE_TYPE, MEMBER_TYPE, IMAGE_URL_TYPE } from './constant';
import { POST, GET, PUT, DELETE } from './http_methods';

import { toast } from 'react-toastify';

export default class Helper {
    static instance: Helper;

    static createInstance() {
        const object = new Helper();
        return object;
    }

    static getInstance() {
        if (!Helper.instance) {
            Helper.instance = Helper.createInstance();
        }
        return Helper.instance;
    }

    public POST: typeof POST = POST;

    public GET: typeof GET = GET;

    public PUT: typeof PUT = PUT;

    public DELETE: typeof DELETE = DELETE;

    public CLIENT_DEVICE_TYPE: typeof CLIENT_DEVICE_TYPE = CLIENT_DEVICE_TYPE;

    public IMAGE_URL_TYPE: typeof IMAGE_URL_TYPE = IMAGE_URL_TYPE;

    // constructor() {}

    public successMsg = (message: string, code: number = 0) => {
        if (code !== 100) toast.success(message, { theme: 'colored' });
    };

    public errorMsg = (message: string, code: number = 0) => {
        if (code !== 997) toast.error(message, { theme: 'colored' });
        if (code === 997) toast.error('Something Went Incorrect!', { theme: 'colored' });
    };

    public getReq = (url: string) => axios.get(url);

    public postReq = (url: string, body: any) => axios.post(url, body);

    public putReq = (url: string, body: any) => axios.put(url, body);

    public deleteReq = (url: string, body: any) => axios.delete(url, body);

    public getImageBaseURL = () => process.env.REACT_APP_IMAGE_BASE_URL || '';

    public formatMinutes = (minutes: number) => (minutes < 10 ? `0${minutes}` : `${minutes}`);

    public formatSeconds = (seconds: number) => (seconds < 10 ? `0${seconds}` : `${seconds}`);
}
