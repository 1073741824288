import NotificationComponent from '../../Components/NotificationComponent'
import ImageCard from '../../Assets/Images/SPOC.png'
import DotImg from '../../Assets/Icons/EllipseDot.svg'
import { FaRegCheckCircle } from "react-icons/fa";
import { AssesmentResultData, skData } from '../../types';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Helper from '../../utils/helper';
import { SKILL_TYPES } from '../../utils/constant';

const EmployeeGptVettingNewPage = () => {

    const [skillsData, setSkillsData] = useState<skData[]>([]);
    const [assesmentResult, setAssesmentResult] = useState<AssesmentResultData | null>();

    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const { user, getDetails } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        getDetails(false);
    }, []);

    useEffect(() => {
        if (user) {
            getSkData();
        }
    }, [user]);

    const getSkData = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.SK_DATA);
            const { data } = res_data.data;

            if (data) {
                setSkillsData(data.skills);
                if (data.assesmentResult) setAssesmentResult(data.assesmentResult);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const startTest = () => {
        navigate(`/candidate-gpttest-start`, { state: { skills: skillsData } });
    };

    return (<>
        <div id="content" className='pt-5'>
            <div className='top-navigation mb-5'>
                <NotificationComponent />
                <h2>GPT Vetting</h2>
                <p>This dashboard will help you in analysing your activities in various aspects.</p>
            </div>
            <div className='employee-white-card p-4'>
                <div className='row'>
                    <div className='col-sm-4 col-12'>
                        <img src={user?.imageUrl || ImageCard} className='img-fluid' />
                    </div>
                    <div className='col-sm-8 col-12'>
                        <h2 className='d-block d-md-none mt-3'>General Interview</h2>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h5 className='d-none d-md-block' style={{ fontWeight: "600" }}>General Interview</h5>
                            <button
                                className='btn start-test-button d-none d-md-block'
                                onClick={() => startTest()}
                            >{assesmentResult ? 'Reappear': 'Start'} Test</button>
                        </div>
                        <div>
                            <h5 style={{ fontWeight: "600" }}>Core Technical Skills</h5>
                            <div className='d-flex' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {skillsData?.map((sk) => {
                                    if (sk.skillType == SKILL_TYPES.MAIN) {
                                        return (
                                            <button className='btn cst-btn-tags' key={sk.id}>{sk.skill}
                                                <span className="cst-primary-color"> &nbsp;
                                                    <img src={DotImg} className='img-fluid' />
                                                    &nbsp;&nbsp;
                                                    {sk.experience}
                                                    &nbsp;
                                                    year{sk.experience > 2 && 's'}
                                                </span>
                                            </button>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div className='mt-2'>
                            <h5 style={{ fontWeight: "600" }}>Additional Skills</h5>
                            <div className='d-flex' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {skillsData?.map((sk) => {
                                    if (sk.skillType == SKILL_TYPES.ADDITIONAL) {
                                        return (
                                            <button className='btn cst-btn-tags' key={sk.id}>{sk.skill}
                                                <span className="cst-primary-color"> &nbsp;
                                                    <img src={DotImg} className='img-fluid' />
                                                    &nbsp;&nbsp;
                                                    {sk.experience}
                                                    &nbsp;
                                                    year{sk.experience > 2 && 's'}
                                                </span>
                                            </button>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        <div className='mt-2'>
                            <h5 style={{ fontWeight: "600" }}>Test Description</h5>
                            <h6>10 Q&A with Voice Recordings</h6>
                        </div>
                    </div>
                    <button
                        className='btn start-test-button d-block d-md-none'
                        onClick={() => startTest()}
                    >{assesmentResult ? 'Reappear': 'Start'} Test</button>
                </div>
            </div>
            <br />
            {assesmentResult &&
                <div className="row">
                    <div className='col-sm-6'>
                        <div className='employee-white-card p-4 scrollable-content'>
                            <h2>Performance Report</h2>
                            {assesmentResult?.overAllPerformance.map((pr, index) => (
                                <div key={index} className="card p-2 mb-2">
                                    <div className="d-flex" style={{ gap: "12px" }}>
                                        <div>
                                            <FaRegCheckCircle style={{ color: "green" }} />
                                        </div>
                                        <h6>{pr}</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='employee-white-card p-4 scrollable-content'>
                            <h2>Transcripts</h2>
                            {assesmentResult?.questions.map((que, index: number) => (
                                <div key={index} className="card mb-2">
                                    <div className="card-header card-header-cst">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <strong>Question {index + 1} </strong>
                                            {/* <button type="button" className="btn btn-outline-warning">{assesmentResult?.results[index]?.score}</button> */}
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <strong>AI Interviewer</strong><br />
                                        <h6>{que}</h6>
                                        <strong>You</strong><br />
                                        <h6>{assesmentResult?.answers[index]}</h6>
                                        <strong>Feedback</strong>
                                        <div className='feeback-div'>
                                            <h6>{assesmentResult?.results[index]}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    </>
    )
}

export default EmployeeGptVettingNewPage
