import React, { useEffect, useState } from 'react';
import { CollaboratorDetails, cusIdProps } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { customStyles, roleTypes } from '../../../utils/constant';
import CountryIconOne from '../../../Assets/CountryIcons/in.png'
import CountryIconTwo from '../../../Assets/CountryIcons/ad.png'
import CountryIconThree from '../../../Assets/CountryIcons/ae.png'
import CountryIconFoure from '../../../Assets/CountryIcons/us.png'

export const AddCollaborator = ({ customerId }: cusIdProps) => {

    const initVal = {
        addedById: 1,
        name: "",
        email: "",
        phone: "",
        role: "",
        isActive: true,
    };

    const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    const [selectedCountryFlag, setSelectedCountryFlag] = useState(CountryIconOne);
    const [collaborator, setCollaborator] = useState<CollaboratorDetails>(initVal);
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setEditing] = useState<boolean>(false);

    const handleCountryCodeChange = (code: any, flag: any) => {
        setSelectedCountryCode(code);
        setSelectedCountryFlag(flag);
    }


    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        if (isEditing) await updateCollab();
        else await addCollab();
    }

    const addCollab = async () => {
        try {
            const cData = { ...collaborator, addedById: customerId };
            const res_data = await helper.postReq(helper.POST.ADD_COLLABORATOR, cData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Added Successfully');
                document.getElementById('updateView')?.click();
                document.getElementById("cancel")?.click();
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Add Collaborator Failed');
        }
    }

    const updateCollab = async () => {
        try {
            const cData = { ...collaborator, collaboratorId: collaborator.id };
            delete cData.id;
            const res_data = await helper.putReq(helper.PUT.UPDATE_COLLABORATOR, cData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Updated Successfully');
                document.getElementById('updateView')?.click();
                document.getElementById("cancel")?.click();
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Update Collaborator Failed');
        }
    }

    const getDetails = async (id: string) => {
        try {
            const url = `${helper.GET.COLLABORATOR_DETAILS}/${id}`;
            const res_data = await helper.getReq(url);
            const { data } = res_data.data;
            console.log(data);
            if (data) {
                setCollaborator(data);
                setEditing(true);
            } else {
                setCollaborator(initVal);
                setEditing(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const checkId = () => {
        try {
            const id = localStorage.getItem('serviceId');
            console.log(`Id is ${id}`);
            if (id) getDetails(id);
            else setCollaborator(initVal);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <button id="updateId" className='d-none' onClick={() => checkId()}></button>
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered add-collaborator-modal-cst" >
                    <form onSubmit={handleSubmit}>
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3 id="exampleModalToggleLabel">Add a collaborator</h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12 mb-3">
                                        <label>Full name</label>
                                        <input
                                            type="text"
                                            name='name'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={collaborator.name}
                                            onChange={(ev) => setCollaborator({ ...collaborator, name: ev.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label>Email ID</label>
                                        <input
                                            type="email"
                                            id='email'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={collaborator.email}
                                            disabled={isEditing}
                                            readOnly={isEditing}
                                            onChange={(ev) => setCollaborator({ ...collaborator, email: ev.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <label>Mobile number</label>
                                        {/* <input
                                            type="tel"
                                            id='phone'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={collaborator.phone}
                                            onChange={(ev) => setCollaborator({ ...collaborator, phone: ev.target.value })}
                                            required
                                        /> */}
                                        <div>
                                            <div className='d-flex' style={{ gap: "12px" }}>
                                                <div className='m-cst-mobile-int'>
                                                    <button
                                                        type="button"
                                                        className="phno"
                                                        id="countryCodeDropdown"
                                                        data-bs-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <span id="selectedCountryCode">
                                                            {selectedCountryCode} <img src={selectedCountryFlag} className="img-fluid" />{' '}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                                                <path d="M7 8L0 0H14L7 8Z" fill="black" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="countryCodeDropdown">
                                                        <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+91', CountryIconOne)}>
                                                            <img src={CountryIconOne} className="img-fluid" alt="India" /> +91 (India) <hr />
                                                        </a>
                                                        <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+1', CountryIconFoure)}>
                                                            <img src={CountryIconFoure} className="img-fluid" alt="US" />  +1 (US)
                                                        </a>
                                                        
                                                    </div>
                                                </div>
                                                <div style={{ width: "-webkit-fill-available" }}>
                                                    <input
                                                        type="tel"
                                                        className="form-control custom-input"
                                                        name="phone"
                                                        id='phone'
                                                        value={collaborator.phone}
                                                        onChange={(ev) => setCollaborator({ ...collaborator, phone: ev.target.value })}
                                                        // value={user.phone}
                                                        // onChange={(val) => setUser({ ...user, phone: val.target.value })}
                                                         placeholder="Enter here"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <label>Role</label>
                                        <Select
                                            options={roleTypes}
                                            value={roleTypes.filter((ct) => ct.value == collaborator.role)[0]}
                                            onChange={(ev) => setCollaborator({ ...collaborator, role: ev?.value || '' })}
                                            styles={customStyles}
                                            className="mb-4"
                                            required
                                            placeholder="Select"
                                        />
                                    </div>
                                    <div className="col-12 ">
                                        <label>Mark as Active</label>
                                        <div className='mt-2' style={{ display: "flex", gap: "60px" }}>
                                            <div>
                                                <input type="radio" id="yes" name="drone" value="yes" className='form-check-input' checked={collaborator.isActive} onChange={() => setCollaborator({ ...collaborator, isActive: true })} />
                                                <label>&nbsp; Yes</label>
                                            </div>
                                            <div>
                                                <input
                                                    className='form-check-input'
                                                    type="radio"
                                                    id="no"
                                                    name="drone"
                                                    value="no"
                                                    checked={!collaborator.isActive}
                                                    onChange={() => setCollaborator({ ...collaborator, isActive: false })}
                                                />
                                                <label>&nbsp;No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex my-2' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                {
                                    isEditing ? (
                                        <button
                                            type='submit'
                                            className='btn create-account-btn'
                                            style={{ minWidth: "159px" }}
                                            disabled={loading}
                                        >{loading ? 'Updating...' : 'Update'}
                                        </button>
                                    ) : (
                                        <button
                                            type='submit'
                                            className='btn create-account-btn'
                                            style={{ minWidth: "159px" }}
                                            disabled={loading}
                                        >{loading ? 'Saving...' : 'Save'}
                                        </button>
                                    )
                                }
                                <button
                                    type='button'
                                    id='cancel'
                                    className='btn social-media-btn'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ minWidth: "159px" }}
                                >Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
