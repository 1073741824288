import React from 'react'
import PageNotFoundImage from '../../Assets/Images/Image20231107164053.gif'
import Header404PageLogo from '../../Assets/Images/pagenotfoundheaderlogo.png'
import { useNavigate } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <section>
        <div className='container mt-4 mb-2'>
          <img src={Header404PageLogo} alt="logo" className='img-fluid' />
        </div>
        <div className='text-center'>
          <img src={PageNotFoundImage} className='img-fluid' />
        </div>
        <div className='text-center mt-5'>
          <button className='btn btn-home-page-not-found px-3 py-2' onClick={() => navigate("/employer-login")}>
            <BsArrowLeft style={{ fontSize: "24px" }} /> Home</button>
        </div>
      </section>
    </>
  )
}

export default PageNotFound