import { useEffect, useState } from 'react';
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { PasswordValidation, UserEmailPass } from '../../../types';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';
// import { GoogleLogin, GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import { LOGIN_TYPES } from '../../../utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'
import GoogleCstIcon from '../../../Assets/Icons/bi_google.svg'


const EmployeeLogin = () => {
    const initialValue: UserEmailPass = {
        email: "",
        password: ""
    };

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserEmailPass>(initialValue);
    const { socialLogin, login } = useAuth();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);
        setLoading(true);
        await login(user.email.toLocaleLowerCase().trim(), user.password, true);
        setLoading(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, password: event.target.value });
    };

    const handleEmailChange = async (email: string) => {
        setUser({ ...user, email });
    };

    const handleGoogleSuccess = async (data: any) => {
        if (data && data.credential) {
            console.log(data);
            setLoading(true);
            socialLogin(data.credential, LOGIN_TYPES.GOOGLE, true);
            setLoading(false);
        } else {
            setLoading(false);
            toast.error('Google Login Failed');
        }
    };

    const handleEmpSignup = (e: any) => {
        e.preventDefault();
        navigate('/candidate-signup')
    }

    // const loginGoogle = useGoogleLogin({
    //     onSuccess: handleGoogleSuccess,
    //     onError: () => toast.error('Google Login Failed')
    // });

    return (
        <section>
            <div className='row'>
                <div className='col-sm-6 d-none d-sm-block d-print-block'>
                    <div className='welcome-lets-started-container d-none d-sm-block d-print-block px-5'>
                        {/* <h1 className="owow-lets-started">owow</h1> */}
                        <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />
                        <h2 className="welcome-lete-started" >Create your profile👋</h2>
                        <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                        <ul className='text-white regsiter-page-lists p-0 mt-4'>
                            <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                            <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                            <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                        </ul>
                    </div>
                </div>
                <div className='col-sm-6 '>
                    <div className='cst-container-bg'>
                        {/* <h1 className='owow d-sm-none '>owow</h1> */}
                        <div className="text-center pt-4">
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none ' />
                        </div>

                        <div className='cst-mobile-bg'>
                            <form onSubmit={handleSubmit}>
                                <div className='right-side-container'>
                                    <h1 className='welcome-heading d-none d-sm-block d-print-block pt-5'>Welcome 👋</h1>
                                    <h1 className='welcome-heading d-sm-none'>Welcome</h1> {/* Display on Mobile Only */}
                                    <h4 className='heado-para margin-fix-bottam'>Please Log in to continue further with the platform.</h4>

                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Email ID</label>
                                        <input
                                            type="text"
                                            id='email'
                                            value={user.email}
                                            onChange={(val) => handleEmailChange(val.target.value)}
                                            className="form-control custom-input"
                                            placeholder="Enter Your Email ID"
                                            required
                                        />
                                    </div>

                                    <div className="col-12 input-group-lg">
                                        <label>Password</label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={handlePasswordChange}
                                            className="form-control custom-input"
                                            placeholder="Enter Your password"
                                            id="password"
                                            required
                                        />
                                        {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}
                                    </div>


                                    <div className='col-12 text-end mb-4' style={{ cursor: 'pointer' }}>
                                        <a
                                            onClick={() => navigate('/candidate-forgot-password', { replace: true })}
                                            className='log-in'
                                        >Forgot Password?</a>
                                    </div>

                                    <div className="col-12 text-center margin-fix-bottam">
                                        <button
                                            type='submit'
                                            disabled={loading}
                                            className='btn create-account-btn col-12 '>
                                            {!loading ? 'Log In' : 'Logging In...'}
                                        </button>

                                    </div>
                                    <div className='col-12 d-flex justify-content-center fix-margin-for-or '>
                                        <span className='divide-span '> </span>
                                        <span className='px-2 py-0' style={{ marginTop: "-10px" }}> OR</span>
                                        <span className='divide-span'> </span>
                                    </div>
                                    <div className='cst-button-alignment'>
                                        <button
                                            type='button'
                                            id='googleButton'
                                            className='btn btn-outline social-media-btn w-100 d-none'
                                        >
                                            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                                                <GoogleLogin

                                                    text={'continue_with'}
                                                    onSuccess={handleGoogleSuccess}
                                                    onError={() => toast.error('Google Login Failed')}
                                                />
                                            </GoogleOAuthProvider>
                                        </button>
                                        {/* <button
                                                type='button'
                                                className='btn continue-with-google'
                                                onClick={() => loginGoogle()}
                                            >
                                                <img src={GoogleCstIcon} />
                                                &nbsp; Continue with Google
                                            </button> */}
                                    </div>
                                    <div className='col-12 text-center mt-4' style={{ gap: "10px" }}>
                                        <label>
                                            <p className='already-account'>Didn't have an account?</p>
                                        </label> <a href='#' onClick={handleEmpSignup} className='log-in'>Sign Up</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmployeeLogin