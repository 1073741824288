import React, { createContext, useEffect, useReducer, useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import axios from '../utils/axios';
import accountReducer from '../store/accountReducer';
import { LOGIN, LOGOUT } from '../store/actions';
import Helper from '../utils/helper';

import { JWTContextType, initialLoginContextProps, KeyedObject, UserEmailPass, User } from '../types';
import { toast } from 'react-toastify';

const initialState: initialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: true,
    user: null
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    // console.log(decoded);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const helper = Helper.getInstance();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigate = useNavigate();
    const location = useLocation();
    const [isEmp, setIsEmp] = useState<boolean>();

    useEffect(() => {
        if (!state.user || !state.isLoggedIn) getDetails();
    }, [isEmp]);

    const loginSuccess = (customer: User, isEmp4: boolean = false, setSessionData: Boolean = true) => {
        setIsEmp(isEmp4);
        localStorage.setItem('isEmp', isEmp4 as unknown as string);

        if (setSessionData) setSession(customer.accessToken || '');

        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: customer
            }
        });
    };

    const getDetails = useCallback(async (isRedirect: boolean = true) => {
        try {
            // console.log('i fire once');
            const serviceToken = localStorage.getItem('serviceToken');
            // console.log(serviceToken)
            // console.log(verifyToken(serviceToken || ''));
            if (!serviceToken || !verifyToken(serviceToken)) return;

            let isEmpD = false;

            try {
                isEmpD = JSON.parse(localStorage.getItem('isEmp') || '');
            } catch (error) {
                isEmpD = false
            }

            // console.log("serviceToken ", serviceToken);
            // console.log("verifyToken(serviceToken) ", verifyToken(serviceToken));
            // console.log("isEmpD ", isEmpD);

            axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

            const url = isEmpD ? helper.GET.EMP_DETAILS : helper.GET.DETAILS;
            // console.log(url);

            const res_data = await helper.getReq(url);
            const { data } = res_data.data;

            if (!data.customer) return;

            loginSuccess(data.customer, isEmpD, false);

            if (isRedirect) {
                const arr = [
                    '/employer-dashboard',
                    '/candidate-dashboard',
                ];
    
                if (!arr.includes(location.pathname) && arr.includes(data.route)) {
                    // navigate(location.pathname);
    
                    // console.log('Do Nothing');
                    if (
                        location.pathname == '/employer-login' || location.pathname == '/employer-signup' || 
                        location.pathname == '/candidate-login' || location.pathname == '/candidate-signup' ||
                        location.pathname == '/employer-congratulations' || location.pathname == '/candidate-congratulations'
                    ) {
                        navigate(data.route, { state: data.state });
                    }
                }
                else {
                    navigate(data.route, { state: data.state });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }, []);

    const register = async (userData: UserEmailPass, isEmp2: boolean = false) => {
        try {

            userData.email = userData.email.toLocaleLowerCase().trim();
            const url = isEmp2 ? helper.POST.EMP_USER_REGISTER : helper.POST.USER_REGISTER;

            console.log(url);
            const res_data = await helper.postReq(url, userData);
            const { data } = res_data.data;
            console.log(data);
            if (!data) helper.errorMsg('Email Already Registered');

            loginSuccess(data, isEmp2);
            const url2 = isEmp2 ? '/candidate-email-verification' : '/employer-email-verification';
            navigate(url2, { replace: true, state: { email: data.email } });

        } catch (error) {
            toast.error('Invalid Details');
            console.log(error);
        }
    };

    const login = async (email: string, password: string, isEmp2: boolean = false) => {
        try {
            const url = isEmp2 ? helper.POST.EMP_USER_LOGIN : helper.POST.USER_LOGIN;

            const res_data = await helper.postReq(url, { email, password });
            const { data } = res_data.data;
            if (!data.customer) logout(isEmp2);

            loginSuccess(data.customer, isEmp2);
            navigate(data.route, { state: data.state });
        } catch (err) {
            toast.error('Login Failed');
            console.error(err);
            logout(isEmp2);
        }
    };

    const logout = (isEmp4: boolean = false) => {
        console.log(isEmp);
        console.log(isEmp4);

        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
        dispatch({ type: LOGOUT });
        const url = (isEmp || isEmp4) ? '/candidate-login' : '/employer-login';

        navigate(url, { replace: true, state: { login: true } });
    };

    const socialLogin = async (tokenId: string, loginType: number, isEmp2: boolean = false) => {
        try {
            const url = isEmp2 ? helper.POST.EMP_SOCIAL_LOGIN : helper.POST.SOCIAL_LOGIN;

            const json = { token: tokenId, device_type: helper.CLIENT_DEVICE_TYPE.WEB, login_type: loginType };
            const res_data = await helper.postReq(url, json);
            console.log(res_data.data);
            const { data } = res_data.data;
            if (!data.customer) logout(isEmp2);

            loginSuccess(data.customer, isEmp2);
            navigate(data.route, { state: data.state })
        } catch (err) {
            console.log(err);
            logout(isEmp2);
        }
    };

    const tokenLogin = async (tokenId: string, isEmp2: boolean = false) => {
        try {
            const url = isEmp2 ? helper.POST.EMP_TOKEN_LOGIN : helper.POST.TOKEN_LOGIN;
            const json = { token: tokenId };

            const res_data = await helper.postReq(url, json);
            console.log(res_data.data);

            const { data } = res_data.data;
            if (data) {
                loginSuccess(data, isEmp2);
                return data;
            }
            else {
                logout(isEmp2);
                return null;
            }
        } catch (err) {
            console.log(err);
            logout(isEmp2);
            return null;
        }
    };

    const setSession = (serviceToken: string) => {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <p>Loading...</p>;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                register,
                login,
                logout,
                socialLogin,
                tokenLogin,
                getDetails
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
