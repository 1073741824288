import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { JWTProvider } from './contexts/JWTContext';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import CompanyDetailsPage from './Pages/Employer/Auth/CompanyDetailsPage';
import CongratulationsPage from './Pages/Employer/Auth/CongratulationsPage';
import VerificationEmailPage from './Pages/Employer/Auth/VerificationEmailPage';
import LetsGetStartedPage from './Pages/Employer/Auth/LetsGetStartedPage';
import PersonalDetailsPage from './Pages/Employer/Auth/PersonalDetailsPage';
import MyAccount from './Pages/Employer/MyAccount';
import ResetPassword from './Pages/Employer/Auth/ResetPassword';
import ForgotPassword from './Pages/Employer/Auth/ForgotPassword';
import CostCalculatorPage from './Pages/Employer/CostCalculatorPage';
import SpocPage from './Pages/Employer/SpocPage';
import HelpPage from './Pages/Employer/HelpPage';
import HireTalents from './Pages/Employer/HireTalents';
import WithNav from './layout/withNav';
import PageNotFound from './Pages/Employer/PageNotFound';
import AddTalentPage from './Pages/Employer/AddTalentPage';
import EmployeeVerificationEmailPage from './Pages/Candidate/Auth/EmployeeVerificationEmailPage';
import EmployeeLetsGetStartedPage from './Pages/Candidate/Auth/EmployeeLetsGetStartedPage';
import EmployeePersonalDeatilsPage from './Pages/Candidate/Auth/EmployeePersonalDeatilsPage';
import EmployeeYourSkillsExperience from './Pages/Candidate/Auth/EmployeeYourSkillsExperience';
import EmployeeForgetPassword from './Pages/Candidate/Auth/EmployeeForgetPassword';
import EmployeeProfilePage from './Pages/Candidate/EmployeeProfilePage';
import EmpWithNav from './layout/empWithNav';
import EmployeeEmploymentStatus from './Pages/Candidate/Auth/EmployeeEmploymentStatus';
import EmployeeCongratulationsPage from './Pages/Candidate/Auth/EmployeeCongratulationsPage';
import EmployeeAllJobsPage from './Pages/Candidate/EmployeeAllJobsPage';
import EmployeeMyApllicationsPage from './Pages/Candidate/EmployeeMyApllicationsPage';
import EmployeeSavedJobsPage from './Pages/Candidate/EmployeeSavedJobsPage';
import EmployeeViewJobPage from './Pages/Candidate/EmployeeViewJobPage';
import PaymentsAndRefundsPage from './Pages/Employer/PaymentsAndRefundsPage';
import BillingAndinvoicesPage from './Pages/Employer/BillingAndinvoicesPage';
import EmployeeSpocPage from './Pages/Candidate/EmployeeSpocPage';
import EmployeeHelpPage from './Pages/Candidate/EmployeeHelpPage';
import NewDashbord from './Pages/Employer/NewDashbord';
import { NewEmployeeDashbord } from './Pages/Candidate/NewEmployeeDashbord';
import ViewJobDetails from './Pages/Employer/ViewJobDetails';
import PositionReqList from './Pages/Employer/PositionReqList';
import EmployeeMyAccount from './Pages/Candidate/EmployeeMyAccount';
import EmployeeResetPassword from './Pages/Candidate/Auth/EmployeeResetPassword';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import TestPage from './Pages/Employer/TestPage';
import EmployeeLogin from './Pages/Candidate/Auth/EmployeeLogin';
import EmployeeSignUp from './Pages/Candidate/Auth/EmployeeSignUp';
import EmployeeGptVetting from './Pages/Candidate/EmployeeGptVetting';
import GPTVettingLetsStartedPage from './Pages/Candidate/GPTVettingLetsStartedPage';
import GPTVettingEmployeeMcqPage from './Pages/Candidate/GPTVettingEmployeeMcqPage';
import OwowVettingGreatJobsPage from './Pages/Candidate/OwowVettingGreatJobsPage';
import { AssesmentResultPDF } from './Pages/Candidate/AssesmentResultPDF';
import { GoogleOAuthProvider } from '@react-oauth/google';
import CompanyGerenralInformationPage from './Pages/Employer/CompanyGerenralInformationPage';
import EmployerLogin from './Pages/Employer/Auth/EmployerLogin';
import EmployerSignUp from './Pages/Employer/Auth/EmployerSignUp';
import "driver.js/dist/driver.css";
import EmployeeNewGPTVetting from './Pages/Candidate/EmployeeNewGPTVetting';
import EmployeeGptVettingNewPage from './Pages/Candidate/EmployeeGptVettingNewPage';

function App() {

  return (
    <>
      <BrowserRouter>
        <JWTProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
            <>
              <Routes>
                {/* <Route> */}
                <Route path="/employer-login" element={<EmployerLogin />} />
                <Route path="/employer-signup" element={<EmployerSignUp />} />
                <Route path="/employer-email-verification" element={<VerificationEmailPage />} />
                <Route path="/employer-lets-started" element={<LetsGetStartedPage />} />
                <Route path="/employer-personal-details" element={<PersonalDetailsPage />} />
                <Route path="/employer-company-details" element={<CompanyDetailsPage />} />
                <Route path="/employer-congratulations" element={<CongratulationsPage />} />
                <Route path="/employer-forgot-password" element={<ForgotPassword />} />
                <Route path="/employer-reset-password" element={<ResetPassword />} />

                <Route element={<WithNav />}>
                  <Route path="/employer-dashboard" element={<NewDashbord />} />
                  <Route path="/employer-cost-calculator" element={<CostCalculatorPage />} />
                  <Route path="/employer-spoc" element={<SpocPage />} />
                  <Route path="/employer-help" element={<HelpPage />} />
                  <Route path="/employer-hire-talents" element={<PositionReqList />} />
                  <Route path="/employer-hire-talents-main" element={<HireTalents />} />
                  {/* <Route path="/employer-add-talents" element={<AddTalentPage />} /> */}
                  <Route path="/employer-view-job/:id" element={<ViewJobDetails />} />
                  <Route path="/employer-myteammates" element={<AddTalentPage />} />
                  <Route path="/employer-payments-refunds" element={<PaymentsAndRefundsPage />} />
                  <Route path="/employer-billing-invoices" element={<BillingAndinvoicesPage />} />
                  <Route path="/employer-general-information" element={<CompanyGerenralInformationPage />} />
                  <Route path="/employer-new-dashboard" element={<NewDashbord />} />
                  <Route path="/employer-my-account" element={<MyAccount />} />
                  
                  <Route path="/testPage" element={<TestPage />} />
                </Route>

                {/* Emp Routes */}
                <Route path="/candidate-login" element={<EmployeeLogin />} />
                <Route path="/candidate-signup" element={<EmployeeSignUp />} />
                <Route path="/candidate-email-verification" element={<EmployeeVerificationEmailPage />} />
                <Route path="/candidate-lets-started" element={<EmployeeLetsGetStartedPage />} />
                <Route path="/candidate-personal-details" element={<EmployeePersonalDeatilsPage />} />
                <Route path="/candidate-your-expirence" element={<EmployeeYourSkillsExperience />} />
                <Route path="/candidate-your-employement" element={<EmployeeEmploymentStatus />} />
                <Route path="/candidate-congratulations" element={<EmployeeCongratulationsPage />} />
                <Route path="/candidate-forgot-password" element={<EmployeeForgetPassword />} />
                <Route path="/candidate-reset-password" element={<EmployeeResetPassword />} />
                <Route path="/candidate-gpttest-start" element={<GPTVettingLetsStartedPage />} />
                <Route path="/candidate-gpttest-mcqs" element={<GPTVettingEmployeeMcqPage />} />
                <Route path="/candidate-gpttest-end" element={<OwowVettingGreatJobsPage />} />
                <Route path="/candidate-gpttest-result/:id" element={<AssesmentResultPDF />} />
                <Route path="/candidate-new-gpt-vetting" element={<EmployeeNewGPTVetting />} />

                <Route element={<EmpWithNav />}>
                  <Route path="/candidate-dashboard" element={<NewEmployeeDashbord />} />
                  <Route path="/candidate-my-profile" element={<EmployeeProfilePage />} />
                  <Route path="/candidate-all-jobs" element={<EmployeeAllJobsPage />} />
                  <Route path="/candidate-my-applications" element={<EmployeeMyApllicationsPage />} />
                  <Route path="/candidate-saved-jobs" element={<EmployeeSavedJobsPage />} />
                  <Route path="/candidate-view-jobs/:id" element={<EmployeeViewJobPage />} />
                  <Route path="/candidate-spoc" element={<EmployeeSpocPage />} />
                  <Route path="/candidate-help" element={<EmployeeHelpPage />} />
                  <Route path="/candidate-my-account" element={<EmployeeMyAccount />} />
                  <Route path="/candidate-gpt-vetting" element={<EmployeeGptVetting />} />
                  <Route path="/candidate-gpt-vetting-page" element={<EmployeeGptVettingNewPage />} />
                </Route>

                <Route path="/" element={<Navigate to="/employer-login" />} />
                <Route path="/*" element={<PageNotFound />} />
                {/* </Route> */}
              </Routes>

              <ToastContainer
                closeButton={false}
                draggable={false}
                position="top-center"
                autoClose={3000}
                limit={1}
                hideProgressBar
                pauseOnFocusLoss={false}
                pauseOnHover={false}
              />

            </>
          </GoogleOAuthProvider>
        </JWTProvider>
      </BrowserRouter >

    </>
  );
}

export default App;
