import NotificationComponent from '../../Components/NotificationComponent'
// import InterviewerImage from '../../Assets/Images/Rectangle17405.png'
import DefaultImage from '../../Assets/Icons/default.png';

// import AddFlexibleAllowanceModel from '../../Components/Employer/Modals/AddFlexibleAllowanceModel'
// import AddSigningBonusModel from '../../Components/Employer/Modals/AddSigningBonusModel'
// import AddVariableComponentModel from '../../Components/Employer/Modals/AddVariableComponentModel'
import ProgressLineComponentForHireTalent from '../../Components/Employer/ProgressLineComponentForHireTalent'
import PlusIconForAddKitButton from '../../Assets/Icons/addKitPlusIcon.svg'
import RetentionBonusModel from '../../Components/Employer/Modals/RetentionBonusModel'
import GratuityAllowanceModel from '../../Components/Employer/Modals/GratuityAllowanceModel'
import SigningBonusModel from '../../Components/Employer/Modals/SigningBonusModel'
import EditIcon from '../../Assets/Icons/EditIcon.svg'
import InfoIcon from '../../Assets/Icons/infoIcon.svg'
import Select from 'react-select';
import { FaCalendarAlt } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PositionDetails, Talent, TalentDetails, TalentProfile, imageAllowedFileTypes } from '../../types';
import { BsCalendarFill, BsPlusCircle, BsUpload } from 'react-icons/bs';
import MenuIcon from '../../Assets/Icons/menuIcon.svg'
// import DefaultImage from '../../Assets/Icons/default.png';
import Helper from '../../utils/helper'
import useAuth from '../../hooks/useAuth'
import { PERKS, POSITION_TYPES, customStyles, TypeOfWorker, PayemntFrequeny, countries } from '../../utils/constant'
import { toast } from 'react-toastify'
import DeleteIcon from '../../Assets/Icons/DeleteIcon.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";

import WelcomeIcon from '../../Assets/Icons/welcomeIcon.svg'
import HealthCareIcon from '../../Assets/Icons/HealthCareIcon.svg'
import EqvipmentIconIcon from '../../Assets/Icons/EqvipmentIcon.svg'
import CoworkingSpace from '../../Assets/Icons/CoWorkingSpace.svg'
import GymIcon from '../../Assets/Icons/GymIcon.svg'

import HealthCareModel from '../../Components/Employer/Modals/HealthCareModel';
import EquipmentModel from '../../Components/Employer/Modals/EquipmentModel';
import CoWorkingSpaceModel from '../../Components/Employer/Modals/CoWorkingSpaceModel';
import GymAccessModel from '../../Components/Employer/Modals/GymAccessModel';

import Spinner from '../../Assets/Images/spinner.png';
import Cropper, { ReactCropperElement } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { compress } from 'image-conversion';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker } from 'antd'
import CurrencyInput from 'react-currency-input-field';
import ViewCandidProfile from '../../Components/Employer/SideCanvas/ViewCandidProfile'
import ContractsAgreementsModel from '../../Components/Employer/Modals/ContractsAgreementsModel';
 
 
const AddTalentPage = () => {

    const { user } = useAuth();
    const [view, setView] = useState<Number>(1);
    const [subView, setSubView] = useState<Number>(1);
    const [talentData, setTalentData] = useState<TalentDetails[]>([]);
    const [talentProfile, setTalentProfile] = useState<TalentProfile>();
    const [searchParams, setSearchParams] = useSearchParams();
    const [startDate, setStartDate] = useState(new Date());
    const [positions, setPositions] = useState<PositionDetails[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [employeeId, setEmployeeId] = useState<number>();
    const [posReqId, setPosReqId] = useState<number>();

    const helper = Helper.getInstance();
    const navigate = useNavigate();

    const initData: Talent = {
        employeeId: 1,
        positionRequestId: 1,
        perks: [],
        noticePeriod: 30
    };

    const [talent, setTalent] = useState<Talent>(initData);

    useEffect(() => {
        if (user) getPositionDetails();
    }, [user]);

    const getPositionDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            if (data) setPositions(data);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    useEffect(() => {
        if (user) {
            const appId = searchParams.get("appId");

            if (!appId) {
                getTalents();
                setView(3);
            } else {
                getProfile(Number(appId));
                setSubView(1);
                setView(2);
            }
        }
    }, [user]);

    const getTalents = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.ALL_TALENT);
            const { data } = res_data.data;

            if (data) setTalentData(data);

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getProfile = async (appId: number) => {
        try {
            setLoading(true);
            const res_data = await helper.postReq(helper.POST.TALENT_PROFILE, { appId });
            const { data } = res_data.data;

            if (data) {
                setTalentProfile(data);
                setTalent({
                    employeeId: data.employee.id,
                    positionRequestId: data.positionRequestId,
                    perks: data.position.perks,
                    imageUrl: data.position.imageUrl,
                    noticePeriod: 30
                });
                setLoading(false);
            } else {
                getTalents();
                setView(3);
                setLoading(false);

            }
        } catch (error) {
            console.log(error);
            getTalents();
            setView(3);
            setLoading(false);
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getPOSLabel = (val: string | number) => {
        const pos = positions.find((x) => x.id == val);

        if (pos) return pos.position;

        return '';
    };

    const getSuffix = (value: number = 0) => {
        if (value && Number(value) > 2) return 's';
        return '';
    };

    const handleFirstSubmit = async (ev: any) => {
        ev.preventDefault();
        console.log(talentData);
        setSubView(2);
        // setLoading(true);
    };

    const handleSecondSubmit = async (ev: any) => {
        ev.preventDefault();
        console.log(talentData);
        setSubView(3);
        // setLoading(true);
    };

    const handleThirdSubmit = async () => {
        console.log(talentData);
        setLoading(true);

        try {
            setLoading(true);
            const res_data = await helper.postReq(helper.POST.ADD_TALENT, talent);
            const { data } = res_data.data;

            if (data) {
                toast.success('Added Successfully');
                await getTalents();
                setView(3);
                setSubView(1);
                setLoading(false);
            } else {
                setLoading(false);
                throw new Error();
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Failed to Add Team Mate");
        }
    };

    const handleCancel = () => {
        setTalent(initData);
        setView(3);
        setSubView(1);
    }

    const handleAddPerk = (perk: number) => {
        const perks = talent.perks;
        perks.push(perk);
        setTalent({ ...talent, perks });
    };

    const handleRemovePerk = (type: number) => {
        const perks = talent.perks || [];
        let filtered: number[] = [];

        switch (type) {
            case 1:
                filtered = perks.filter((elm) => elm !== PERKS.WELCOME_KIT);
                break;
            case 2:
                const refArr1 = [
                    PERKS.HEALTHCARE_BASIC,
                    PERKS.HEALTHCARE_STANDARD,
                    PERKS.HEALTHCARE_PREMIUM
                ];
                filtered = perks.filter((elm: number) => !refArr1.includes(elm));
                break;
            case 3:
                const refArr2 = [
                    PERKS.EQUIPMENT_LAPTOP,
                    PERKS.EQUIPMENT_MONITOR,
                    PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE,
                    PERKS.EQUIPMENT_WIFI_CONNECTION
                ];
                filtered = perks.filter((elm: number) => !refArr2.includes(elm));
                break;
            case 4:
                const refArr3 = [
                    PERKS.CO_WORKING_SEAT,
                    PERKS.CO_WORKING_CABIN
                ];
                filtered = perks.filter((elm: number) => !refArr3.includes(elm));
                break;
            case 5:
                const refArr4 = [
                    PERKS.GYM_ACCESS_BASIC,
                    PERKS.GYM_ACCESS_STANDARD,
                    PERKS.GYM_ACCESS_PREMIUM
                ];
                filtered = perks.filter((elm: number) => !refArr4.includes(elm));
                break;
            case 6:
                filtered = perks.filter((elm) => elm !== PERKS.BACKGROUND_CHECK);
                break;
            default:
                break;
        }

        if (type == 1) {
            setTalent({ ...talent, perks: filtered, imageUrl: '' });
        } else {
            setTalent({ ...talent, perks: filtered });
        }
    };

    const [loading2, setLoading2] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const cropperRef = useRef<ReactCropperElement>(null);

    const handleUpload = async (blobData: Blob) => {
        if (!blobData) return;

        try {
            setLoading2(true);

            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);
            const fileTypeArr = blobData.type.split("/");
            const name = `${fileID}.${fileTypeArr[1]}`;

            reqData.append('file', blobData, name);

            console.log(reqData);
            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.COMPANY_LOGO_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            // const res_data = await axios.post(`http://localhost:3030${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData);
            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setTalent({ ...talent, imageUrl: data });
                toast.success('Logo Uploaded Successfully');

                setTimeout(() => {
                    setLoading2(false);
                }, 100);
            } else {
                toast.error('Logo Upload Failed');
                setLoading2(false);
            }
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Logo Upload Failed');
        }
    };

    // Function to handle image selection
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result as string);
                setTimeout(() => {
                    document.getElementById('crop-button')?.click();
                }, 100);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to handle cropping
    const handleCrop = async () => {
        if (cropperRef.current) {
            // if (typeof cropperRef.current?.cropper !== "undefined") {
            // setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            handleCompress(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setTimeout(() => {
                document.getElementById('crop-button')?.click();
            }, 100);
            // }
        }
    };

    const dataURLtoBlob = (dataURL: string): Blob => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const handleCompress = async (data: any) => {
        if (data) {
            // console.log('Cropped Image URL:', croppedImage);

            const croppedBlob = dataURLtoBlob(data);
            const compressedImageData = await compress(croppedBlob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
            });

            handleUpload(compressedImageData);
        }
    };

    const setParams = (tlt: TalentDetails) => {
        setEmployeeId(tlt.employee.id);
        setPosReqId(tlt.positionRequestId);
    };

    const prefix = '₹    ';

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    {view === 1 && <p>Loading...</p>}

                    {view === 2 && talentProfile !== undefined && (
                        <>
                            <div className='top-navigation mb-5'>
                                <NotificationComponent />
                                <h2>Hire Talents</h2>
                                <p>This dashboard will help you in analysing your activities in various aspects.</p>
                            </div>
                            <div className='add-talent-card p-4'>
                                <div className='d-flex justify-content-between mobile-two-buttons display-center-cst-hiretalent-profile'>
                                    <div className='d-flex flex-wrap' style={{ gap: "20px" }}>
                                        <div>
                                            <img src={talentProfile?.employee.imageUrl || DefaultImage} alt='Interviewer' className='add-talent-card-img img-fluid' />
                                        </div>
                                        <div>
                                            <div className='d-flex justify-content-between mobile-two-buttons m-0'>
                                                <h5 className='text-capitalize'>{talentProfile?.employee.name}</h5>
                                            </div>
                                            <label className='product-designer-subheading'>Employee Nationality: <span className="text-black">{getOptionLabel(talentProfile?.employee?.country || 'US', countries)}</span></label><br />
                                            <label className='product-designer-subheading'> Employment Country: <span className="text-black">{getOptionLabel(talentProfile?.employee?.workingCountry || 'US', countries)}</span></label>
                                            {/* <label className='product-designer-subheading'>{getPOSLabel(talentProfile?.employee.skillAndEmpStatus.positionIds[0] || 1)}&nbsp;&nbsp;<br />{talentProfile?.employee.skillAndEmpStatus.relativeExp} year{getSuffix(talentProfile?.employee.skillAndEmpStatus.relativeExp)}</label> */}
                                        </div>
                                    </div>
                                    <div>
                                        <label className='product-designer-subheading'>Hiring for</label>
                                        <h5 className='cst-primary-color'>{getPOSLabel(talentProfile?.position?.positionId || 1)}</h5>
                                    </div>
                                    <div>
                                        <div className='d-flex mobile-two-buttons'>
                                            <button
                                                className='btn btn-view-profile'
                                                data-bs-target="#viewProfileCanvas"
                                                data-bs-toggle="offcanvas"
                                                onClick={() => setEmployeeId(talentProfile.employee.id)}
                                            >View profile</button>
                                            <button className='btn btn-add-talent'>Add talent</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                subView === 1 && (
                                    <form id='firstForm' name='firstForm' onSubmit={handleFirstSubmit}>
                                        <div className='d-flex justify-content-between mobile-two-buttons align-items-center flex-wrap mt-4 mb-4'>
                                            <div>
                                                <ProgressLineComponentForHireTalent />
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-between mt-3' style={{ gap: "12px" }}>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline create-account-btn-outline-ractangle'
                                                        onClick={() => handleCancel()}
                                                    >Cancel
                                                    </button>
                                                    <button
                                                        type='submit'
                                                        className='btn create-account-btn-ractangle p-3'
                                                        disabled={loading}
                                                    >Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>


                                        <h3 style={{ fontWeight: "600" }}>Provide Information to start onboarding</h3>
                                        <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row mt-4'>
                                                <div className='col-md-4 col-12 mb-4'>
                                                    <label>Type of worker*</label>
                                                    <Select
                                                        styles={customStyles}
                                                        options={TypeOfWorker}
                                                        value={TypeOfWorker.filter((p) => p.value == talent.typeOfWorker)[0]}
                                                        onChange={(ev) => setTalent({ ...talent, typeOfWorker: ev?.value || 'Employee on Record' })}
                                                        className="mb-4"
                                                        placeholder="Select Worker"
                                                        name='con'
                                                    />
                                                </div>
                                                <div className='col-md-4 col-12 mb-4'>
                                                    <label>Official Email ID*</label>
                                                    <input
                                                        type='email'
                                                        className='form-control custom-input'
                                                        placeholder="Enter Email ID"
                                                        value={talent.officialEmailId}
                                                        onChange={(ev) => setTalent({ ...talent, officialEmailId: ev.target.value })}
                                                        required
                                                    />
                                                </div>
                                                <div className='col-md-4 col-12'>
                                                    <label>Employee ID*</label>
                                                    <input
                                                        type='text'
                                                        className='form-control custom-input'
                                                        placeholder="Enter Employee ID"
                                                        value={talent.officialEmployeeId}
                                                        onChange={(ev) => setTalent({ ...talent, officialEmployeeId: ev.target.value })}
                                                        required
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        {talent.typeOfWorker === 'Contractor' && (<>
                                            <h3 style={{ fontWeight: "600" }}>Payment rate</h3>
                                            <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                                <div className='row mt-4 mb-4 align-items-center'>
                                                    <div className='col-sm-8 mb-2'>
                                                        <h4>Monthly Rate*</h4>
                                                        <div className='col-sm-8 col-12'>
                                                            <label>Define how much the contractor will be paid monthly.</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <CurrencyInput
                                                            id="input-example"
                                                            name="input-name"
                                                            className='form-control custom-input'
                                                            placeholder="₹    Add Annual Compensation"
                                                            defaultValue={talent.monthlyRate}
                                                            decimalsLimit={2}
                                                            // onValueChange={(value, name, values) => console.log(value, name, values)}
                                                            onChange={(ev) => setTalent({ ...talent, monthlyRate: Number(ev.target.value) })}
                                                            prefix={prefix}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                                <div className='row mt-4 mb-4 align-items-center'>
                                                    <div className='col-sm-8 mb-2'>
                                                        <h4>Invoice Cycle*</h4>
                                                        <div className='col-sm-8 col-12'>
                                                            <label>Determine the frequency of invoices.</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <Select
                                                            styles={customStyles}
                                                            options={PayemntFrequeny}
                                                            value={PayemntFrequeny.filter((p) => p.value == talent.invoiceCycle)[0]}
                                                            onChange={(ev) => setTalent({ ...talent, invoiceCycle: ev?.value || 'Monthly' })}
                                                            // value={COST_COUNTRIES.filter((p) => p.value == selectedOptions.country)[0]}
                                                            // onChange={(ev) => handleSelectChange(4, ev?.value || 'US')}
                                                            className="mb-4"
                                                            placeholder="Payment Frequency"
                                                            name='con'
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </>)}
                                        {talent.typeOfWorker === 'Employee on Record' && (<>
                                            <h3 style={{ fontWeight: "600" }}>Compensation</h3>
                                            <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                                <div className='row mt-4 mb-4 align-items-center'>
                                                    <div className='col-sm-8 mb-2'>
                                                        <h4>Annual Compensation*</h4>
                                                        <div className='col-sm-8 col-12'>
                                                            <label>All Compensation will be awarded in Indian Rupee (₹). Due to compliance, contract currencies are not customised in EOR</label>
                                                        </div>
                                                    </div>
                                                    <div className='col-sm-4'>
                                                        <CurrencyInput
                                                            id="input-example"
                                                            name="input-name"
                                                            className='form-control custom-input'
                                                            placeholder="₹    Add Annual Compensation"
                                                            defaultValue={talent.annualCompensation}
                                                            decimalsLimit={2}
                                                            onChange={(ev) => setTalent({ ...talent, annualCompensation: Number(ev.target.value) })}
                                                            prefix={prefix}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row align-items-baseline'>
                                                <div className='col-sm-8 mb-2'>
                                                    <h4>Retention bonus</h4>
                                                </div>
                                                <div className='col-sm-4 text-sm-end text-start'>
                                                    {
                                                        talent.retentionBonus !== undefined && talent.retentionBonus.amount !== 0 ? (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                onClick={() => setTalent({ ...talent, retentionBonus: undefined })}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                data-bs-target="#RetentionBonusToggle"
                                                                data-bs-toggle="modal"
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                            </button>
                                                        )
                                                    }
                                                    <button
                                                        className='btn add-kit-button col-md-4 col-12'
                                                        style={{  textAlign: "center" }}
                                                        data-bs-target="#RetentionBonusToggle"
                                                        data-bs-toggle="modal"
                                                    ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}

                                            {/* <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row align-items-baseline'>
                                                <div className='col-sm-8 mb-2'>
                                                    <h4>Gratuity allowance</h4>
                                                </div>
                                                <div className='col-sm-4 text-sm-end text-start'>
                                                    {
                                                        talent.gratuityAllowance !== undefined && talent.gratuityAllowance.amount !== 0 ? (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                onClick={() => setTalent({ ...talent, gratuityAllowance: undefined })}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                data-bs-target="#GratuityAllowanceToggle"
                                                                data-bs-toggle="modal"
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                            </button>
                                                        )
                                                    }
                                                    <button
                                                        className='btn add-kit-button col-md-4 col-12'
                                                        style={{  textAlign: "center" }}
                                                        data-bs-target="#GratuityAllowanceToggle"
                                                        data-bs-toggle="modal"
                                                    ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                    </button>
                                                </div>
                                            </div>
                                        </div> */}

                                            <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                                <div className='row align-items-center mt-4 mb-4'>
                                                    <div className='col-sm-8 mb-2'>
                                                        <h4>Signing bonus</h4>
                                                        <label htmlFor="">One time payment to the employee as part of their first payroll.</label>
                                                    </div>
                                                    <div className='col-sm-4 '>
                                                        {talent.signingBonus !== undefined ? (
                                                            <>
                                                                <CurrencyInput
                                                                    id="input-example"
                                                                    name="input-name"
                                                                    className='form-control custom-input'
                                                                    placeholder="₹    Add Signing bonus"
                                                                    defaultValue={talent.signingBonus?.amount}
                                                                    decimalsLimit={2}
                                                                    // onValueChange={(value, name, values) => console.log(value, name, values)}
                                                                    onChange={(ev) => setTalent({ ...talent, signingBonus: { amount: Number(ev.target.value), effectiveDate: (new Date).toISOString() } })}
                                                                    prefix={prefix}
                                                                    required
                                                                />
                                                                <button
                                                                    type='button'
                                                                    className='btn add-kit-button col-md-4 col-12 mt-3'
                                                                    style={{ textAlign: "center" }}
                                                                    onClick={() => setTalent({ ...talent, signingBonus: undefined })}
                                                                ><img src={DeleteIcon} className='img-fluid' /> Remove
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                onClick={(ev) => setTalent({ ...talent, signingBonus: { amount: 0, effectiveDate: (new Date).toISOString() } })}
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                            </button>
                                                        )}
                                                        {/* <CurrencyInput
                                                        id="input-example"
                                                        name="input-name"
                                                        className='form-control custom-input'
                                                        placeholder="₹    Add Signing bonus"
                                                        defaultValue={1000}
                                                        decimalsLimit={2}
                                                        // onValueChange={(value, name, values) => console.log(value, name, values)}
                                                        onChange={(ev) => setTalent({ ...talent, annualCompensation: Number(ev.target.value) })}
                                                        prefix={prefix}
                                                        required
                                                    />
                                                    {/* {
                                                        talent.signingBonus !== undefined && talent.signingBonus.amount !== 0 ? (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                onClick={() => setTalent({ ...talent, signingBonus: undefined })}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type='button'
                                                                className='btn add-kit-button col-md-4 col-12'
                                                                style={{ textAlign: "center" }}
                                                                data-bs-target="#SigningBonusToggle"
                                                                data-bs-toggle="modal"
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                            </button>
                                                        )
                                                    } */}
                                                        {/* <button
                                                        className='btn add-kit-button col-md-4 col-12'
                                                        style={{  textAlign: "center" }}
                                                        data-bs-target="#SigningBonusToggle"
                                                        data-bs-toggle="modal"
                                                    ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add
                                                    </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </>)}
                                    </form>
                                )
                            }

                            {
                                subView === 2 && (
                                    <form id='secondForm' name='secondForm' onSubmit={handleSecondSubmit}>
                                        <div className='d-flex justify-content-between mobile-two-buttons align-items-center flex-wrap mt-5 mb-4'>
                                            <div>
                                                <ProgressLineComponentForHireTalent />
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-between justify-content-end mt-2' style={{ gap: "12px" }}>
                                                    <button type='button' className='btn btn-outline create-account-btn-outline-ractangle' onClick={() => setSubView(1)} >Cancel</button>
                                                    <button type='submit' className='btn create-account-btn-ractangle p-3' disabled={loading} >Next</button>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='row'>
                                            <h3 className='mb-3'>Job’s terms and conditions</h3>
                                            <div className='col-sm-6 col-12 mb-4'>
                                                <div className='details-card-for-input px-4 pt-1 pb-42 pb-4'>
                                                    <h3 className='mt-3 mb-3'>Desired Start Date</h3>
                                                    <label>Select the worker’s start date.</label>
                                                    {/* <input
                                                        type='number'
                                                        className='form-control custom-input'
                                                        placeholder="Add Days"
                                                        value={talent.probationPeriod}
                                                        onChange={(ev) => setTalent({ ...talent, probationPeriod: Number(ev.target.value) })}
                                                        required
                                                    /> */}
                                                    {/* <div className='col-sm-7 col-12'> */}
                                                    <div className='date-picker-input'>
                                                        <DatePicker
                                                            className='form-control custom-datepicker'
                                                            disabledDate={d => !d || d.isBefore(new Date())}
                                                            suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                            allowClear={false}
                                                            defaultValue={dayjs(talent.joiningDate || new Date())}
                                                            onChange={(val) => setTalent({ ...talent, joiningDate: val?.toISOString() })}
                                                            required
                                                        />
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                            <div className='col-sm-6 col-12 mb-4'>
                                                <div className='details-card-for-input px-4 pt-1 pb-2 pb-4'>
                                                    <h3 className='mt-3 mb-3'>Probation Period</h3>
                                                    <label>Number of Probation days <img src={InfoIcon} className='img-fluid cst-ptr' data-tooltip-id="my-tooltip-5" /></label>
                                                    {/* <div className='col-sm-7 col-12'> */}
                                                    <input
                                                        type='number'
                                                        min={30}
                                                        max={180}
                                                        className='form-control custom-input'
                                                        placeholder="Add Days"
                                                        value={talent.probationPeriod}
                                                        onChange={(ev) => setTalent({ ...talent, probationPeriod: Number(ev.target.value) })}
                                                        required
                                                    />
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>


                                        {/* <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row mt-4'>
                                                <h3>Probation Period</h3>
                                                <div className='col-sm-4 col-12 mb-4 mt-4'>
                                                    <label>Joining Date</label>
                                                    <div className='date-picker-input'>
                                                        <DatePicker
                                                            className='form-control custom-datepicker'
                                                            disabledDate={d => !d || d.isBefore(new Date())}
                                                            suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                            allowClear={false}
                                                            defaultValue={dayjs(talent.joiningDate || new Date())}
                                                            onChange={(val) => setTalent({ ...talent, joiningDate: val?.toISOString() })}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}


                                        <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row mt-4'>
                                                <h3>Time Off</h3>
                                                <div className='row mt-4 mb-4'>
                                                    <div className='col-md-6 col-12 mb-4'>
                                                        <div className='card card-small-cst'>
                                                            <div className='d-flex mt-4 mx-4'>
                                                                <input
                                                                    type="radio"
                                                                    id="timeOff"
                                                                    name="timeOff"
                                                                    value={1}
                                                                    checked={talent.timeOffType == 1}
                                                                    onChange={(ev) => setTalent({ ...talent, timeOffType: 1, timeOffValue: 18 })}
                                                                    required
                                                                    className='form-check-input'
                                                                />
                                                                <label><h5>&nbsp;Minimum legal requirement</h5></label>
                                                            </div>
                                                            <div className='d-flex mb-4 mx-4' style={{ flexDirection: 'column' }}>
                                                                {
                                                                    talent.timeOffType == 1 && <>
                                                                        <label>The minimum legal requirement in India is 18 days.</label>
                                                                        {/* <input
                                                                            min={10}
                                                                            max={180}
                                                                            type="number"
                                                                            className='form-control custom-input'
                                                                            placeholder='In Days'
                                                                            value={talent.timeOffValue}
                                                                            onChange={(ev) => setTalent({ ...talent, timeOffValue: Number(ev.target.value) })}
                                                                            required
                                                                        /> */}
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12 mb-4'>
                                                        <div className='card card-small-cst'>
                                                            <div className='mt-4 mb-4 mx-4'>
                                                                <input
                                                                    type="radio"
                                                                    id="timeOff"
                                                                    name="timeOff"
                                                                    value={2}
                                                                    checked={talent.timeOffType == 2}
                                                                    onChange={(ev) => setTalent({ ...talent, timeOffType: 2, timeOffValue: 0 })}
                                                                    required
                                                                    className='form-check-input'
                                                                />
                                                                <label><h5>&nbsp;Specific</h5></label>
                                                                {talent.timeOffType == 2 &&
                                                                    <input
                                                                        type="number"
                                                                        min={1}
                                                                        max={180}
                                                                        className='form-control custom-input'
                                                                        placeholder='In Days'
                                                                        value={talent.timeOffValue}
                                                                        onChange={(ev) => setTalent({ ...talent, timeOffValue: Number(ev.target.value) })}
                                                                        required
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row mt-4'>
                                                <h3>Employment terms</h3>
                                                <div className='row mt-4 mb-4'>
                                                    <div className='col-md-6 col-12 mb-4'>
                                                        <div className='card card-small-cst'>
                                                            <div className='mt-4 mb-4 mx-4'>
                                                                <input
                                                                    type="radio"
                                                                    id="empTerms"
                                                                    name="empTerms"
                                                                    value={1}
                                                                    checked={talent.empTermType == 1}
                                                                    onChange={(ev) => setTalent({ ...talent, empTermType: 1 })}
                                                                    required
                                                                    className='form-check-input'
                                                                />
                                                                <label><h5>&nbsp;Indefinite</h5></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6 col-12 mb-4'>
                                                        <div className='card card-small-cst'>
                                                            <div className='mt-4 mb-4 mx-4'>
                                                                <input
                                                                    type="radio"
                                                                    id="empTerms"
                                                                    name="empTerms"
                                                                    value={2}
                                                                    checked={talent.empTermType == 2}
                                                                    onChange={(ev) => setTalent({ ...talent, empTermType: 2 })}
                                                                    required
                                                                    className='form-check-input'
                                                                />
                                                                <label><h5>&nbsp;Definite</h5></label><br />
                                                                {talent.empTermType == 2 && <>
                                                                    <label>Duration of definite contract: 90 days</label>
                                                                    <div className='date-picker-input'>
                                                                        <DatePicker
                                                                            className='form-control custom-datepicker'
                                                                            disabledDate={d => !d || d.isBefore(new Date())}
                                                                            suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                                            allowClear={false}
                                                                            defaultValue={dayjs(talent.contractEndingDate || new Date())}
                                                                            onChange={(val) => setTalent({ ...talent, contractEndingDate: val?.toISOString() })}
                                                                        />
                                                                    </div>
                                                                </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='details-card-for-input px-4 pt-1 pb-2 mb-4'>
                                            <div className='row mt-4'>
                                                <h3>Notice Period</h3>

                                                <div className='col-md-6 col-12 mb-4'>
                                                    <div className='card card-small-cst'>
                                                        <div className='d-flex mt-4 mx-4'>
                                                            <input
                                                                type="radio"
                                                                id="notice"
                                                                name="notice"
                                                                // value={30}
                                                                className='form-check-input'
                                                                checked={talent.noticePeriod == 30}
                                                                onChange={(ev) => setTalent({ ...talent, noticePeriod: 30 })}
                                                                required
                                                            />
                                                            <label><h5>&nbsp;Standard Notice period</h5></label>
                                                        </div>
                                                        <div className='d-flex mt-1 mb-4 mx-4'>
                                                            <label>Standard notice period in India is 30 days</label>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12 mb-4'>
                                                    <div className='card card-small-cst'>
                                                        <div className='mt-4 mb-4 mx-4'>
                                                            <input
                                                                type="radio"
                                                                id="notice"
                                                                name="notice"
                                                                // value={2}
                                                                checked={talent.noticePeriod !== 30}
                                                                onChange={(ev) => setTalent({ ...talent, noticePeriod: 1 })}
                                                                required
                                                                className='form-check-input'
                                                            />
                                                            <label><h5>&nbsp;Specific</h5></label>
                                                            {talent.noticePeriod !== 30 && (<>
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    max={180}
                                                                    id="notice"
                                                                    name="notice"
                                                                    value={talent.noticePeriod}
                                                                    onChange={(ev) => setTalent({ ...talent, noticePeriod: Number(ev.target.value) })}
                                                                    className='form-control custom-input'
                                                                    placeholder='Notice Period (days)'
                                                                    required
                                                                /></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                )
                            }

                            {
                                subView === 3 && (
                                    <>
                                        <div className='d-flex justify-content-between mobile-two-buttons align-items-center flex-wrap mt-5 mb-4'>
                                            <div>
                                                <ProgressLineComponentForHireTalent />
                                            </div>
                                            <div className='d-flex justify-content-between justify-content-end mt-2' style={{ gap: "12px" }}>
                                                <button
                                                    type='button'
                                                    className='btn btn-outline create-account-btn-outline-ractangle'
                                                    onClick={() => setSubView(2)}
                                                >Cancel
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn create-account-btn-ractangle p-3'
                                                    disabled={loading}
                                                    onClick={() => handleThirdSubmit()}
                                                >{loading ? 'Adding...' : 'Add Talent'}
                                                </button>
                                            </div>
                                        </div>

                                        <h2>Employee Perks</h2>
                                        <div className='card p-4' style={{ borderRadius: "27px", background: "#FFF", marginTop: '20px' }}>
                                            <div className='row perks-font-kit-cst'>
                                                <div className='col-md-8 col-12 mb-2'>
                                                    <h4><img src={WelcomeIcon} className='img-fluid' /> Welcome Kit</h4>
                                                </div>
                                                <div className='col-md-4 mb-2 text-end'>
                                                    {
                                                        talent.perks.includes(PERKS.WELCOME_KIT) ? (
                                                            <button
                                                                className='delete-button p-2 col-md-4 col-12' onClick={() => handleRemovePerk(1)}>
                                                                <img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                        ) : (
                                                            <button className='btn add-kit-button p-2 col-md-4  col-12' onClick={() => handleAddPerk(1)} >
                                                                <img src={PlusIconForAddKitButton} className='img-fluid' /> Add Kit</button>
                                                        )
                                                    }
                                                </div>
                                                <div className='row mt-2'>
                                                    <div className='col-md-8 col-12 mb-2 perks-font-kit-cst'>
                                                        <h5>Following thing we’ll be attaching with kit:</h5>
                                                        <ul>
                                                            <li className='mt-2'>T-shirt</li>
                                                            <li className='mt-2'>Notebook</li>
                                                            <li className='mt-2'>Mug</li>
                                                            <li className='mt-2'>Hoodie</li>
                                                            <li className='mt-2'>Backpack</li>
                                                        </ul>
                                                    </div>
                                                    <div className='col-md-4 mb-1'>
                                                        {
                                                            talent.perks.includes(PERKS.WELCOME_KIT) &&
                                                            <div className='text-center text-md-end'>
                                                                <h5 className="cst-primary-color">Upload your Company Logo</h5>
                                                                <button className='btn uplod-copmany-logo p-2 col-md-4 col-12' style={{ minWidth: "184px", minHeight: "58px" }}
                                                                    onClick={() => document.getElementById('imageInput')?.click()} >
                                                                    <BsUpload /> Upload Logo
                                                                </button>
                                                                <input type="file" name="myImage" id='imageInput' hidden accept={imageAllowedFileTypes} onChange={handleImageChange} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card perks-font-kit-cst mt-4 p-4' style={{ borderRadius: "27px", background: "#FFF" }}>
                                            <div className='row'>
                                                <div className='col-md-8 mb-2'>
                                                    <h4><img src={HealthCareIcon} className='img-fluid' /> Healthcare</h4>
                                                </div>

                                                <div className='col-md-4 mb-2 text-end'>
                                                    {
                                                        (
                                                            talent.perks.includes(PERKS.HEALTHCARE_BASIC) ||
                                                            talent.perks.includes(PERKS.HEALTHCARE_STANDARD) ||
                                                            talent.perks.includes(PERKS.HEALTHCARE_PREMIUM)
                                                        ) ? (
                                                            <button
                                                                className='delete-button p-2 col-md-3 col-12'

                                                                onClick={() => handleRemovePerk(2)}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                        ) : (
                                                            <button
                                                                className='btn add-kit-button p-2 col-md-3  col-12'
                                                                data-bs-target="#healthCareModalToggle"
                                                                data-bs-toggle="modal"
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' />Add Insurance</button>
                                                        )
                                                    }
                                                </div>
                                                <div className='col-md-8 mb-2 mt-2'>
                                                    <ul>
                                                        {talent.perks.includes(PERKS.HEALTHCARE_BASIC) &&
                                                            <li>
                                                                <h5 className='owow-savings'>Cover $6K</h5>
                                                                <span>$10/Month Per Person</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.HEALTHCARE_STANDARD) &&
                                                            <li>
                                                                <h5 className='owow-savings'>Cover $12K</h5>
                                                                <span>$15/Month Per Person</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.HEALTHCARE_PREMIUM) &&
                                                            <li>
                                                                <h5 className='owow-savings'>Cover $20K</h5>
                                                                <span>$24/Month Per Person</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card perks-font-kit-cst mt-4 p-4' style={{ borderRadius: "27px", background: "#FFF" }}>
                                            <div className='row'>
                                                <div className='col-md-8 col-12 mb-2'>
                                                    <h4><img src={EqvipmentIconIcon} /> Equipment Rental</h4>
                                                </div>
                                                <div className='col-md-4 mb-2 text-end'>
                                                    {
                                                        (
                                                            talent.perks.includes(PERKS.EQUIPMENT_LAPTOP) ||
                                                            talent.perks.includes(PERKS.EQUIPMENT_MONITOR) ||
                                                            talent.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) ||
                                                            talent.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION)
                                                        ) ? (
                                                            <button
                                                                className='delete-button p-2 col-md-3 col-12'

                                                                onClick={() => handleRemovePerk(3)}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                        ) : (
                                                            <button
                                                                className='btn add-kit-button p-2 col-md-3  col-12'
                                                                data-bs-target="#EquipmentModelToggle"
                                                                data-bs-toggle="modal"
                                                            // onClick={() => handleAddPerk(3)}
                                                            >
                                                                <img src={PlusIconForAddKitButton} className='img-fluid' /> Add Equipment</button>
                                                        )
                                                    }
                                                </div>
                                                <div className='col-md-8 mb-2 mt-2'>
                                                    <ul>
                                                        {talent.perks.includes(PERKS.EQUIPMENT_LAPTOP) &&
                                                            <li className='mt-2'>
                                                                <h5 className='owow-savings'>Laptop</h5>
                                                                <span>16' MacBook Pro M1 MAX</span>
                                                                <span> $100/Month Per unit</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.EQUIPMENT_MONITOR) &&
                                                            <li className='mt-2'>
                                                                <h5 className='owow-savings'>Monitor</h5>
                                                                <span>4K Monitor 27</span>
                                                                <span> $20/Month Per unit</span>
                                                            </li>

                                                        }
                                                        {talent.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) &&
                                                            <li className='mt-2'>
                                                                <h5 className='owow-savings'>Keyboard And Mouse</h5>
                                                                <span>Additional unit of keyboard and mouse</span>
                                                                <span> $10/Month Per unit</span>
                                                            </li>

                                                        }
                                                        {talent.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION) &&
                                                            <li className='mt-2'>
                                                                <h5 className='owow-savings'>Hi-Speed Internet</h5>
                                                                <span>Wifi Connection for your teammates</span>
                                                                <span> $10/Month Per unit</span>
                                                            </li>

                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <h5 className='owow-savings'>Lorem ipsum dolor sit amet consectetur. In porttitor egestas eros egestas pellentesque fermentum. Eu nec ut scelerisque pretium condimentum risus sagittis.</h5> */}

                                        </div>

                                        <div className='card perks-font-kit-cst mt-4 p-4' style={{ borderRadius: "27px", background: "#FFF" }}>
                                            <div className='row'>
                                                <div className='col-md-8 col-12 mb-2'>
                                                    <h4><img src={CoworkingSpace} /> Co working space</h4>
                                                </div>

                                                <div className='col-md-4 mb-2 text-end'>
                                                    {
                                                        (
                                                            talent.perks.includes(PERKS.CO_WORKING_SEAT) ||
                                                            talent.perks.includes(PERKS.CO_WORKING_CABIN)
                                                        ) ? (
                                                            <button
                                                                className='delete-button p-2 col-md-3 col-12'

                                                                onClick={() => handleRemovePerk(4)}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                        ) : (
                                                            <button
                                                                className='btn add-kit-button p-2 col-md-3  col-12'
                                                                data-bs-target="#CoWorkingSpaceModelToggle"
                                                                data-bs-toggle="modal"
                                                            // onClick={() => handleAddPerk(4)}
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add Space</button>
                                                        )
                                                    }
                                                </div>
                                                <div className='col-md-8 mb-2 mt-2'>
                                                    <ul>
                                                        {talent.perks.includes(PERKS.CO_WORKING_SEAT) &&
                                                            <li>
                                                                <h5 className='owow-savings'>Dedicated Work Station</h5>
                                                                <span>$99/Month Per desk</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.CO_WORKING_CABIN) &&
                                                            <li>
                                                                <h5 className='owow-savings'>Private Cabins</h5>
                                                                <span>$149/Month Per desk</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <h5 className='owow-savings'>Lorem ipsum dolor sit amet consectetur. In porttitor egestas eros egestas pellentesque fermentum. Eu nec ut scelerisque pretium condimentum risus sagittis.</h5> */}
                                        </div>

                                        <div className='card perks-font-kit-cst mt-4 p-4' style={{ borderRadius: "27px", background: "#FFF" }}>
                                            <div className='row'>
                                                <div className='col-md-8 col-12 mb-2'>
                                                    <h4><img src={GymIcon} className='img-fluid' /> Gym Access</h4>
                                                </div>
                                                <div className='col-md-4 mb-2 text-end'>
                                                    {
                                                        (
                                                            talent.perks.includes(PERKS.GYM_ACCESS_BASIC) ||
                                                            talent.perks.includes(PERKS.GYM_ACCESS_STANDARD) ||
                                                            talent.perks.includes(PERKS.GYM_ACCESS_PREMIUM)
                                                        ) ? (
                                                            <button
                                                                className='delete-button p-2 col-md-3 col-12'
                                                                onClick={() => handleRemovePerk(5)}
                                                            ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                        ) : (
                                                            <button
                                                                className='btn add-kit-button p-2 col-md-3 col-12'
                                                                data-bs-target="#GymAccessModelToggle"
                                                                data-bs-toggle="modal"
                                                            // onClick={() => handleAddPerk(5)}
                                                            ><img src={PlusIconForAddKitButton} className='img-fluid' />  Add Access</button>
                                                        )
                                                    }
                                                </div>
                                                <div className='col-md-8 mb-2 mt-2'>
                                                    <ul>
                                                        {talent.perks.includes(PERKS.GYM_ACCESS_BASIC) &&
                                                            <li>
                                                                <h5 className='owow-savings'>3 Months</h5>
                                                                <span>Your teammate will get access to cult.fit pass for 3 months</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.GYM_ACCESS_STANDARD) &&
                                                            <li>
                                                                <h5 className='owow-savings'>6 Months</h5>
                                                                <span>Your teammate will get access to cult.fit pass for 6 months</span>
                                                            </li>
                                                        }
                                                        {talent.perks.includes(PERKS.GYM_ACCESS_PREMIUM) &&
                                                            <li>
                                                                <h5 className='owow-savings'>12 Months</h5>
                                                                <span>Your teammate will get access to cult.fit pass for 12 months</span>
                                                            </li>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <h5 className='owow-savings'>Lorem ipsum dolor sit amet consectetur. In porttitor egestas eros egestas pellentesque fermentum. Eu nec ut scelerisque pretium condimentum risus sagittis.</h5> */}
                                        </div>
                                    </>
                                )
                            }

                        </>
                    )}

                    {view === 3 && (
                        <>
                            <div className='top-navigation mb-5'>
                                <NotificationComponent />
                                <h2>My Teammates</h2>
                                <p>Your hired talents will be showcased here, enabling you to build your global team.</p>
                            </div>
                            {/* <div className='text-end mb-4'>
                                <button id="add-collaborator" className="create-account-btn p-3" style={buttonStyle}>
                                    <BsPlusCircle style={CircleIcon} /> Add teammate</button>
                            </div> */}
                            <div className='card p-3' style={{
                                borderRadius: "17px",
                                background: "#FFF",
                                border: "none",
                            }}>
                                <div className="table-responsive" style={{ height: "100vh" }}>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">App Id</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">COUNTRY</th>
                                                <th scope="col">JOB TITLE</th>
                                                <th scope="col">TYPE OF JOB</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {talentData.map((tlt, index) => (<tr key={tlt.id}>
                                                <td className='pt-3'>{tlt.applicationId}</td>
                                                <td className="text-capitalize">
                                                    <div className='d-flex align-items-center flex-wrap' style={{ gap: '4px' }}>
                                                        <img src={tlt.employee.imageUrl || DefaultImage} className='img-fluid employye-image-cst-table' />
                                                        &nbsp;<b>{tlt.employee.name}</b>
                                                    </div>
                                                </td>
                                                <td className='pt-3'>{tlt.employee.country}</td>
                                                <td className='pt-3'>{getPOSLabel(tlt.position.positionId)}</td>
                                                <td className='pt-3'>{getOptionLabel(tlt.position.positionType, POSITION_TYPES)}</td>
                                                <td className='details-card-for-input px-4 pt-2 pb-2 mb-4 cst-ptr align-items-center'>
                                                    <div className="dropdown">
                                                        <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li
                                                                data-bs-target="#viewProfileCanvas"
                                                                data-bs-toggle="offcanvas"
                                                                onClick={() => setEmployeeId(tlt.employee.id)}
                                                            ><a className="dropdown-item" href="#">View Profie<hr /></a>
                                                            </li>
                                                            <li><a className="dropdown-item" href="#">Payments<hr /></a></li>
                                                            <li><a 
                                                                className="dropdown-item" 
                                                                href="#" 
                                                                data-bs-target="#contractsAgreement" 
                                                                data-bs-toggle="modal"
                                                                onClick={() => setParams(tlt)}
                                                                >Contracts / Agreements<hr />
                                                                </a></li>
                                                            <li><a className="dropdown-item" href="#">Time off</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>

                                            ))}

                                        </tbody>
                                    </table>
                                    {talentData.length == 0 && <h5 className='text-center mt-5'>No Data Found!!</h5>}
                                </div>

                            </div>
                        </>
                    )}
                </div>
            </div >
            {/* <AddFlexibleAllowanceModel />
            <AddVariableComponentModel />
            <AddSigningBonusModel /> */}

            {/* <RetentionBonusModel setTalent={setTalent} />
            <GratuityAllowanceModel setTalent={setTalent} />
            <SigningBonusModel setTalent={setTalent} /> */}

            <HealthCareModel setPosReqOtherData={setTalent} />
            <EquipmentModel setPosReqOtherData={setTalent} />
            <CoWorkingSpaceModel setPosReqOtherData={setTalent} />
            <GymAccessModel setPosReqOtherData={setTalent} />

            <button type='button' id="updateView" className="btn d-none" onClick={() => getTalents()}></button>
            <button type='button' id="crop-button" className="btn d-none" data-bs-target="#cropperModal" data-bs-toggle="modal"></button>
            <div className="modal fade" id="cropperModal" aria-hidden="true" aria-labelledby="cropperModalLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="cropperModalLabel">Crop Image</h3>
                            <button id='closeCropperModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* {imageSrc && ( */}
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-12">
                                    <Cropper
                                        aspectRatio={1}
                                        ref={cropperRef}
                                        src={imageSrc || ''}
                                        style={{ height: 400, width: '100%' }}
                                    />
                                </div>
                            </div>

                        </div>
                        {/* )} */}
                        {/* <div className='d-flex my-2 justify-content-center' style={{ gap: "16px" }}> */}
                        <div className='d-flex my-2 justify-content-center flex-md-row flex-column' style={{ gap: "16px" }}>

                            <button
                                className='btn social-media-btn'
                                type='button'
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ minWidth: "326px" }}>
                                Cancel
                            </button>
                            <button
                                className='btn create-account-btn'
                                type='submit'
                                style={{ minWidth: "326px" }}
                                onClick={handleCrop}
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltip
                className='prabobationdaysinfo'
                id="my-tooltip-5"
                place="top"
                render={(props) => (
                    <div>
                        Due to local labor laws, our minimum probation period is 30 days. You can increase it to the legally allowed maximum of 180.
                    </div>
                )}
            />

            <ViewCandidProfile employeeId={employeeId || 0} positions={positions || []} />
            <ContractsAgreementsModel posReqId={posReqId || 0} employeeId={employeeId || 0}/>
            {/* <EndContractModel/> */}
        </>
    )
}

export default AddTalentPage