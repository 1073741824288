import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { EXPERIENCE_LEVEL, addressCountries, customStyles, genders, languagesOptions, roleTypes } from '../../../utils/constant';
// import ProfileEditIcon from '../../../Assets/Images/Group47624.png';
import useAuth from '../../../hooks/useAuth';
import Helper from '../../../utils/helper';
import { Employee, EmployeeProfileModalProps, LanguageSelected, OptionType, User, imageAllowedFileTypes } from '../../../types';
import { toast } from 'react-toastify';

import Spinner from '../../../Assets/Images/spinner.png';

import Cropper, { ReactCropperElement } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { compress } from 'image-conversion';
import axios from 'axios';
import DefaultImage from '../../../Assets/Images/uploadImage.png';



export const ProfileModal = ({ seniority, languages }: EmployeeProfileModalProps) => {
    const { user: UserData } = useAuth();

    // if (!UserData) return <p>Loading...</p>;

    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const cropperRef = useRef<ReactCropperElement>(null);

    const emp: Employee = {
        name: UserData?.name || '',
        gender: UserData?.gender || '',
        country: UserData?.country || '',

    }
    const [user, setUser] = useState<Employee>(emp);
    const [otherData, setOtherData] = useState<EmployeeProfileModalProps>({ seniority, languages });

    const setFluency = (index: number, val: string) => {
        const lang: LanguageSelected = { ...otherData.languages[index] };
        lang.fluency = val;
        otherData.languages[index] = lang;
        setOtherData({ ...otherData, languages: otherData.languages });
        // console.log(posReqOtherData.languagesData)
    };

    const handleLanguageSelect = (val: any) => {
        // let arr = val.map((d: any) => ({ language: d.value, fluency: "Intermediate" }));
        let arr = val.map((d: any) => {
            const found = otherData.languages.find((x) => x.language == d.value);
            return { language: d.value, fluency: found?.fluency || null }
        });
        setOtherData({ ...otherData, languages: arr });
    };

    const getMultiSelectedDef2 = () => {
        if (!otherData.languages.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(languagesOptions));
        const newArr = [];

        for (let ln of otherData.languages) {
            const val = ldataArr.filter((dt) => dt.value == ln.language);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const updateProfile = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        try {
            const uData = { ...user, ...otherData };

            const res_data = await helper.putReq(helper.PUT.UPDATE_PROFILE, uData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Updated Successfully');
                document.getElementById('updateView2')?.click();
                document.getElementById("ProfileModalToggleClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Update Failed');
        }
    };

    const handleUpload = async (blobData: Blob) => {
        if (!blobData) return;

        try {
            setLoading2(true);

            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);
            const fileTypeArr = blobData.type.split("/");
            const name = `${fileID}.${fileTypeArr[1]}`;

            reqData.append('file', blobData, name);

            reqData.append('id', JSON.stringify(user.id));

            console.log(reqData);
            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.EMPLOYEE_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            // const res_data = await axios.post(`http://localhost:3030${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData);
            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setUser({ ...user, imageUrl: data });
                setTimeout(() => {
                    setLoading2(false);
                }, 100);
            } else {
                setLoading2(false);
            }
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Update Details Failed');
        }
    };



    // Function to handle image selection
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result as string);
                setTimeout(() => {
                    document.getElementById('crop-button')?.click();
                }, 100);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to handle cropping
    const handleCrop = async () => {
        if (cropperRef.current) {
            // if (typeof cropperRef.current?.cropper !== "undefined") {
            // setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            handleCompress(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setTimeout(() => {
                document.getElementById('crop-button')?.click();
            }, 100);
            // }
        }
    };

    const dataURLtoBlob = (dataURL: string): Blob => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const handleCompress = async (data: any) => {
        if (data) {
            // console.log('Cropped Image URL:', croppedImage);

            const croppedBlob = dataURLtoBlob(data);
            const compressedImageData = await compress(croppedBlob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
            });

            handleUpload(compressedImageData);
        }
    };

    const handleUpload2 = async (ev: any) => {
        const file = ev.target.files[0];
        if (!file) return;
        setLoading2(true);

        try {
            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);

            reqData.append('file', file, `${fileID}_${file.name}`);
            reqData.append('id', JSON.stringify(UserData?.id || ''));

            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.EMPLOYEE_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setUser({ ...user, imageUrl: data });
            }
            setLoading2(false);
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Failed to Upload Image');
        }
    };

    return (
        <>
            <form onSubmit={handleCrop}>
                <div className="modal fade" id="cropperModal" aria-hidden="true" aria-labelledby="cropperModalLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3 id="cropperModalLabel">Crop Image</h3>
                                <button id='closeCropperModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            {/* {imageSrc && ( */}
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12">
                                        <Cropper
                                            aspectRatio={1}
                                            ref={cropperRef}
                                            src={imageSrc || ''}
                                            style={{ height: 400, width: '100%' }}
                                        />
                                    </div>
                                </div>

                            </div>
                            {/* )} */}
                            {/* <div className='d-flex my-2 justify-content-center' style={{ gap: "16px" }}> */}
                            <div className='d-flex my-2 justify-content-center flex-md-row flex-column' style={{ gap: "16px" }}>

                                <button
                                    className='btn social-media-btn'
                                    type='button'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ minWidth: "326px" }}>
                                    Cancel
                                </button>
                                <button
                                    className='btn create-account-btn'
                                    type='submit'
                                    style={{ minWidth: "326px" }}
                                >
                                    Crop
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form onSubmit={updateProfile}>
                <div className="modal fade" id="ProfileModalToggle" aria-hidden="true" aria-labelledby="ProfileModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered profile-modal-toggle-cst" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3>Profile</h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='text-center'>
                                        {loading2 ? (
                                            <img
                                                alt="not found"
                                                className='img-fluid'
                                                style={{ width: '80px', height: '80px', borderRadius: "50%" }}
                                                src={Spinner}
                                            />
                                        ) : (
                                            <img
                                                alt="not found"
                                                className='img-fluid'
                                                style={{ width: '80px', height: '80px', cursor: 'pointer', borderRadius: "50%" }}
                                                src={user.imageUrl && user.imageUrl !== "" ? user.imageUrl : DefaultImage}
                                                onClick={() => document.getElementById("imageInput")?.click()}
                                            />
                                        )}

                                        <input
                                            type="file"
                                            name="myImage"
                                            id='imageInput'
                                            hidden
                                            accept={imageAllowedFileTypes}
                                            onChange={handleUpload2}
                                        />
                                    </div>
                                    <div className="col-12 margin-fix-bottam">
                                        <label>Full Name*</label>
                                        <input
                                            type="text"
                                            name='name'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={user.name}
                                            onChange={(val) => setUser({ ...user, name: val.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4  col-12 margin-fix-bottam">
                                        <label>Gender*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Gender'
                                            options={genders}
                                            value={genders.filter((ct) => ct.value == user.gender)[0]}
                                            onChange={(ev) => setUser({ ...user, gender: ev?.value || '' })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4  col-12 margin-fix-bottam">
                                        <label>Senioriy Level* </label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='seniority'
                                            options={EXPERIENCE_LEVEL}
                                            value={EXPERIENCE_LEVEL.filter((ct) => ct.value == otherData.seniority)[0]}
                                            onChange={(ev) => setOtherData({ ...otherData, seniority: ev?.value || 1 })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-4  col-12 margin-fix-bottam">
                                        <label>Location*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Nationality'
                                            options={addressCountries}
                                            value={addressCountries.filter((ct) => ct.value == user.country)[0]}
                                            onChange={(ev) => setUser({ ...user, country: ev?.value || '' })}
                                            required
                                        />
                                    </div>

                                    <div className="col-12 margin-fix-bottam">
                                        <label>Language*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='languages'
                                            options={languagesOptions}
                                            onChange={(ev) => handleLanguageSelect(ev)}
                                            value={getMultiSelectedDef2()}
                                            isMulti
                                            required
                                        />

                                        {
                                            otherData.languages.length !== 0 && (
                                                <>
                                                    <div className='row'>
                                                        <div className='col-md-3 col-12'>
                                                            <label>Language selected</label>
                                                        </div>
                                                        <div className='col-md-3 col-12'>
                                                            <label>Select Proficiency</label>
                                                        </div>

                                                    </div>
                                                    {
                                                        otherData.languages.map((lan, index) => (
                                                            <div className='row' key={`${lan.language}${index}`} >
                                                                <div className='col-md-3 col-12'>
                                                                    <h5 className='mt-3'>{lan.language}</h5>
                                                                </div>
                                                                <div className='col-md-8 col-12'>
                                                                    <div className='d-flex flex-wrap mt-3 launguage-proficiency-cst'>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Fluent' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Fluent")}
                                                                        >Fluent</button>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Intermediate' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Intermediate")}
                                                                        >Intermediate</button>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Native' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Native")}
                                                                        >Native</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}

                                                </>
                                            )
                                        }
                                    </div>

                                </div>
                            </div>

                            <div className='d-flex my-2' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                <button
                                    type='submit'
                                    className='btn create-account-btn'
                                    style={{ minWidth: "159px" }}
                                > {loading ? 'Saving...' : 'Save'}
                                </button>
                                <button
                                    type='button'
                                    id='ProfileModalToggleClose'
                                    className='btn social-media-btn'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ minWidth: "159px" }}
                                    onClick={() => document.getElementById('updateView')?.click()}
                                >Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type='button' hidden id="crop-button" className="btn" data-bs-target="#cropperModal" data-bs-toggle="modal">Add DDDDDDDDD</button>
            </form>
        </>
    )
}
