import React, { useState, useEffect } from 'react';
import useAuth from '../../../hooks/useAuth';
import Helper from '../../../utils/helper';

import CloseIcon from '../../../Assets/Icons/close-bold-icon.png';
import DefaultImage from '../../../Assets/Images/tesalImage.png';
import ArrowLeftIcon from '../../../Assets/Icons/arrow-left.png';
import { OutSouceData } from '../../../types';
import { APPLICATION_STATUS } from '../../../utils/constant';
import { toast } from 'react-toastify';

const ChooseTypeOfInterview = ({ applicationId }: { applicationId: number }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<OutSouceData[]>([]);
    const [selectedId, setSelectedId] = useState<number>();
    const { user } = useAuth();
    const helper = Helper.getInstance();

    useEffect(() => {
        if (user) getData();
    }, [user]);

    const getData = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.OUT_INT);
            const { data } = res_data.data;

            if (data) setData(data);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const updateStatus = async () => {
        if (!selectedId) return;

        try {
            setLoading(true);
            const uData = { id: applicationId, status: APPLICATION_STATUS.INTERVIEW_OUTSOURCED, outsourceId: selectedId };
            const res_data = await helper.putReq(helper.PUT.UPDATE_APPLICATION, uData);

            const { data } = res_data.data;

            setLoading(false);
            if (data) {
                // toast.success('Booked Successfully');
                document.getElementById('offcanvastoggler')?.click();
                // document.getElementById('updateView')?.click();
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Booking Failed');
        }
    }

    return (
        <>
            <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="offcanvasRightThree" aria-labelledby="offcanvasRightThreeLabel"  >
                <div className="container">
                    <div className="offcanvas-body">
                        <div className="container-fluid">
                            <div className='float-end'>
                                <img src={CloseIcon} style={{ cursor: 'pointer' }} id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                            </div>
                            <div className='mb-5'>
                                <h5><img src={ArrowLeftIcon} style={{ cursor: 'pointer' }} className='img-fluid'  data-bs-dismiss="offcanvas" aria-label="Close" /> Choose Type of Interview</h5>
                            </div>
                            <br />
                            {(data || []).map((outSource: OutSouceData) => {
                                return (
                                    <React.Fragment key={outSource.id}>
                                        <div
                                            className={`row custom-row p-4 ${outSource.id === selectedId ? 'custom-row-selected' : ''}`}
                                            onClick={() => setSelectedId(outSource.id || 1)}>
                                            <div className='col-sm-2 col-12'>
                                                <img src={outSource.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%", width: "80px", height: "80px" }} />
                                            </div>
                                            <div className='col-sm-8 col-12'>
                                                <h5>{outSource.companyName}</h5>
                                                <h6>{outSource.description}</h6>
                                            </div>
                                            <div className='col-sm-2 col-12'>
                                                <h5>${outSource.pricing}/hr</h5>
                                            </div>
                                        </div>
                                        <hr />
                                    </React.Fragment>
                                );
                            })}
                            <div className='text-center cst-static-buttons-sidemodel mt-auto'>
                                <button
                                    className='btn create-account-btn col-12 p-3'
                                    onClick={() => updateStatus()}
                                    disabled={!selectedId || loading}
                                >
                                    {loading ? 'Requesting...' : 'Request Now'}
                                </button>
                                <button
                                    id='offcanvastoggler'
                                    className='btn d-none'
                                    data-bs-toggle="offcanvas"
                                    data-bs-target="#offcanvasRightFoure"
                                    aria-controls="offcanvasRightFoure"
                                ></button>
                            </div>

                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default ChooseTypeOfInterview