import MyAccountComponent from '../../Components/Employer/MyAccountComponent'
import useAuth from '../../hooks/useAuth'
import { AddCollaborator } from '../../Components/Employer/Modals/AddCollaborator'
import ChangePassword from '../../Components/Employer/Modals/ChangePassword'
 
const MyAccount = () => {
  const { user } = useAuth();
 
  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <MyAccountComponent customer={user} />
      <AddCollaborator customerId={user?.id} />
      <ChangePassword customerId={user?.id} />
    </>
  )
}

export default MyAccount