import { useState, useEffect } from 'react';
import { EXPERIENCE_LEVEL, countries, languagesOptions } from '../../../utils/constant';

import MapPinIcon from '../../../Assets/Icons/map-pin.svg';
import LaunguageIcon from '../../../Assets/Icons/tabler_language.svg';
import CloseIcon from '../../../Assets/Icons/close-bold-icon.png';
import DotImg from '../../../Assets/Icons/EllipseDot.svg';
import { EmpProfileDetails, OutSourceProps } from '../../../types';
import DefaultImage from '../../../Assets/Icons/default.png';
import ArrowLeftIcon from '../../../Assets/Icons/arrow-left.png';
import ChooseTypeOfInterview from './ChooseTypeOfInterview';
import RequestRecivedModel from './RequestRecivedModel';


const RequestOutSourceInterview = ({ profile, positions, posData, applicationId }: OutSourceProps) => {

    const [profileData, setProfileData] = useState<EmpProfileDetails>();

    useEffect(() => {
        setProfileData(profile);
    }, [profile]);

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const getPOSLabel = (val: string | number) => {
        const pos = positions.find((x) => x.id == val);

        if (pos) return pos.position;

        return '';
    };

    return (
        <>
            <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="offcanvasRightTwo" aria-labelledby="offcanvasRightTwoLabel"  >

                <div className="offcanvas-body">
                    <div className="container-fluid">

                        <div className='float-end'>
                            <img src={CloseIcon} style={{ cursor: 'pointer' }} className='cst-ptr img-fluid' id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div>
                        <div>
                            <h5><img src={ArrowLeftIcon} style={{ cursor: 'pointer' }} className='img-fluid' data-bs-dismiss="offcanvas" aria-label="Close" /> Outsouce Interview for "{posData?.position?.position}"</h5>
                        </div>

                        <div className='d-flex mobile-two-buttons request-for-interview-card-sidbar' style={{ gap: "20px" }}>
                            <div>
                                <img src={profile?.imageUrl || DefaultImage} className='img-fluid cst-image-interview-side-model' />
                            </div>
                            <div>
                                <div className='d-flex justify-content-between mobile-two-buttons mb-2' style={{ gap: "16px" }}>
                                    <div>
                                        <h5
                                            className="blurred"
                                            contentEditable={false}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onSelect={(e) => e.preventDefault()}
                                        >{profile?.name}
                                        </h5>
                                    </div>
                                    <div className='d-flex flex-wrap' style={{ gap: "16px" }}>
                                        <button className='btn btn-tag-senior'>{getOptionLabel(profile?.skillAndEmpStatus.seniority || 1, EXPERIENCE_LEVEL)}</button>
                                        <button className='btn btn-tag-final-interview'>Final Interview</button>
                                    </div>
                                </div>
                                <h6>
                                    <span style={{ fontWeight: "400" }}>{getPOSLabel(profile?.skillAndEmpStatus.positionIds[0] || 1)}
                                        &nbsp;&nbsp;
                                        <img className="img-fluid" src={MapPinIcon} /> {getOptionLabel(profile?.country || 'US', countries)}
                                        &nbsp;&nbsp;
                                        <img className="img-fluid" src={LaunguageIcon} />{getOptionLabel(profile?.skillAndEmpStatus.languages[0].language || 'English', languagesOptions)}<br />
                                    </span>
                                    {
                                        profile?.skillAndEmpStatus.relativeExp && (
                                            <span style={{ fontWeight: "500" }}>{profile.skillAndEmpStatus.relativeExp} {profile.skillAndEmpStatus.relativeExp > 1 ? 'years' : 'year'}</span>
                                        )
                                    }
                                </h6>
                            </div>

                        </div>
                        <hr />

                        <div>
                            <label className='lable-heading'>Skills</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {
                                    profile?.empSkills.map((sk, index) => (
                                        <button className='btn cst-btn-tags' key={index}>
                                            {sk.skill}
                                            <span className="cst-primary-color">
                                                &nbsp;
                                                <img src={DotImg} className='img-fluid' />
                                                &nbsp;
                                                {sk.experience} {sk.experience > 1 ? 'years' : 'year'}
                                            </span>
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                        <br />
                        <label className='lable-heading mb-3'>Job Requirements</label>
                        <div className='card job-requirementcard-cst p-3'>
                            <label className='lable-heading'>Round Name:Technical Round</label>
                            <label className='lable-heading mt-3'>Must have skills</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {posData?.skills.map((sk, index) => (
                                    <button className='btn cst-btn-tags' key={sk + index + '12'}>{sk}</button>
                                ))}
                                {/* <button className='btn cst-btn-tags'>Figma</button>
                                <button className='btn cst-btn-tags'>Adobe XD</button>
                                <button className='btn cst-btn-tags'>User research</button>
                                <button className='btn cst-btn-tags'>Sketch</button>
                                <button className='btn cst-btn-tags'>Wireframe</button> */}
                            </div>

                            <label className='lable-heading mt-3'>Good to have skills</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {posData?.optionalSkills.map((sk, index) => (
                                    <button className='btn cst-btn-tags' key={sk + index + '34'}>{sk}</button>
                                ))}
                                {/* <button className='btn cst-btn-tags'>Figma</button>
                                <button className='btn cst-btn-tags'>Adobe XD</button> */}
                                {/* <button className='btn cst-btn-tags'>User research</button> */}
                                {/* <button className='btn cst-btn-tags'>Sketch</button> */}
                            </div>
                            <label className='lable-heading mt-3'>Job Description</label>
                            <div className='mt-3 black-para outsdesc' dangerouslySetInnerHTML={{ __html: posData?.description || '' }} />
                        </div>
                        {/* <div className='text-center cst-static-buttons-sidemodel mt-3  '> */}
                        <div className='text-center mt-3  '>
                            <button className='btn request-outsource-interview-cst' data-bs-toggle="offcanvas" data-bs-target="#offcanvasRightThree" aria-controls="offcanvasRightThree">
                                <div>
                                    <div>Request outsource interview</div>
                                    <div className='second-recruitment-btn'>Save 90% of engineering & recruitment bandwidth</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div >
            <ChooseTypeOfInterview applicationId={applicationId} />
            {profile !== undefined && <RequestRecivedModel name={profile.name || ''} />}
        </>
    )




}

export default RequestOutSourceInterview