import CloseIcon from '../../../Assets/Icons/close-bold-icon.png'
import WelcomeIcon from '../../../Assets/Icons/welcomeIcon.svg'
import HealthCareIcon from '../../../Assets/Icons/HealthCareIcon.svg'
import EqvipmentIconIcon from '../../../Assets/Icons/EqvipmentIcon.svg'
import CoworkingSpace from '../../../Assets/Icons/CoWorkingSpace.svg'
import GymIcon from '../../../Assets/Icons/GymIcon.svg'
import DeleteIcon from '../../../Assets/Icons/DeleteIcon.svg'
import ClockIcon from '../../../Assets/Icons/clockIcon.svg';
import LocationIcon from '../../../Assets/Icons/LocationIcon.svg';
import WorkSuitcaseIcon from '../../../Assets/Icons/material-symbols_work-outline.svg';
import LanguageIcon from '../../../Assets/Icons/tabler_language.svg';
import { PosDetails } from '../../../types'
import { EXPERIENCE_LEVEL, OFFICE_POLICY, PERKS, POSITION_TYPES } from '../../../utils/constant'
import Helper from '../../../utils/helper'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const ViewJobDetailsModel = ({ posData }: { posData: PosDetails }) => {

    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const deletePositionDetails = async (id: number) => {

        const confirmed = window.confirm('Are you sure you want to Delete Position Request');

        if (!confirmed) return;

        try {
            setLoading(true)
            const res_data = await helper.deleteReq(helper.DELETE.DELETE_POSITION_REQ + id, {});
            const { data } = res_data.data;

            setLoading(false);
            if (data) {
                document.getElementById('cancelId')?.click();
                toast.success('Deleted Sucessfully');
                navigate('/employer-hire-talents', { replace: true });
            };
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <>
            <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="offcanvasViewJobDetails" aria-labelledby="offcanvasViewJobDetailsLabel"  >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel"></h5>
                    <div className='float-end'>
                        <img src={CloseIcon} className='img-fluid cst-ptr' id='cancelId' data-bs-dismiss="offcanvas" aria-label="Close" />
                    </div>
                </div>
                <div className="offcanvas-body pt-0 mt-0">
                    <div className="container-fluid">
                        <div className="row employee-white-card cst-heading-changes align-items-center p-4 mb-4">
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Job Role</label>
                                <h3>{posData?.position?.position}</h3>
                            </div>
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Location</label>
                                <h3><img src={LocationIcon} /> {getOptionLabel(posData?.officePolicy || 1, OFFICE_POLICY)} • {posData?.companyAddres.country}</h3>
                            </div>
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Shift Time</label>
                                <h3> <img src={ClockIcon} /> {posData.timezone}</h3>
                            </div>
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Type of role</label>
                                <h3> {getOptionLabel(posData?.positionType, POSITION_TYPES)} </h3>
                            </div>
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Experience Required</label>
                                <h3><img src={WorkSuitcaseIcon} /> {getOptionLabel(posData?.seniority || 1, EXPERIENCE_LEVEL)}</h3>
                            </div>
                            <div className='col-sm-4 col-12'>
                                <label className="cst-secondary-color">Languages</label>
                                <h3><img src={LanguageIcon} /> {posData?.languages[0]?.language}</h3>
                            </div>
                        </div>
                        <hr />
                        <div className='card job-requirementcard-cst p-3'>
                            <label className='lable-heading mt-3'>Must have skills</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {posData?.skills.map((sk, index) => (
                                    <button className='btn cst-btn-tags' key={sk + index}>{sk}</button>
                                ))}
                                {/* <button className='btn cst-btn-tags'>Figma</button>
                                <button className='btn cst-btn-tags'>Adobe XD</button>
                                <button className='btn cst-btn-tags'>User research</button>
                                <button className='btn cst-btn-tags'>Sketch</button>
                                <button className='btn cst-btn-tags'>Wireframe</button> */}
                            </div>

                            <label className='lable-heading mt-3'>Good to have skills</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {/* <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}> */}
                                {posData?.optionalSkills.map((sk, index) => (
                                    <button className='btn cst-btn-tags' key={sk + index}>{sk}</button>
                                ))}
                                {/* <button className='btn cst-btn-tags'>Figma</button>
                                    <button className='btn cst-btn-tags'>Adobe XD</button>
                                    <button className='btn cst-btn-tags'>User research</button>
                                    <button className='btn cst-btn-tags'>Sketch</button>
                                    <button className='btn cst-btn-tags'>Wireframe</button> */}
                                {/* </div> */}
                            </div>

                            <label className='lable-heading mt-3'>Job Description</label>
                            {/* <div className='mt-3 black-para outsdesc' dangerouslySetInnerHTML={{ __html: posData?.description || '' }} /> */}
                            <div className='mt-3 black-para outsdesc' dangerouslySetInnerHTML={{ __html: posData?.description || '' }} />
                        </div>

                        <div>
                            <label className='lable-heading mt-3'>Additional benefit</label>
                            <div className='d-flex mt-2' style={{ gap: "12px", flexWrap: 'wrap' }}>
                                {posData.perks.includes(PERKS.WELCOME_KIT) && <div>
                                    <img src={WelcomeIcon} /><b> Welcome Kit</b>
                                </div>}

                                {(posData.perks.includes(PERKS.HEALTHCARE_BASIC) ||
                                    posData.perks.includes(PERKS.HEALTHCARE_STANDARD) ||
                                    posData.perks.includes(PERKS.HEALTHCARE_PREMIUM)) && <div>
                                        <img src={HealthCareIcon} /><b> Healthcare</b>
                                    </div>}

                                {(posData.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) ||
                                    posData.perks.includes(PERKS.EQUIPMENT_LAPTOP) ||
                                    posData.perks.includes(PERKS.EQUIPMENT_MONITOR) ||
                                    posData.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION)) && <div>
                                        <img src={EqvipmentIconIcon} /><b> Equipment Rental</b>

                                    </div>}
                                {(posData.perks.includes(PERKS.GYM_ACCESS_BASIC) ||
                                    posData.perks.includes(PERKS.GYM_ACCESS_STANDARD) ||
                                    posData.perks.includes(PERKS.GYM_ACCESS_PREMIUM)) && <div>
                                        <img src={GymIcon} /><b> Gym Access</b>
                                    </div>}

                                {(posData.perks.includes(PERKS.CO_WORKING_CABIN) ||
                                    posData.perks.includes(PERKS.CO_WORKING_SEAT)) && <div>
                                        <img src={CoworkingSpace} /><b> Co working space</b>
                                    </div>}
                                {/* <div>
                                    <img src={HealthCareIcon} /><b> Healthcare</b>
                                </div>
                                <div>
                                    <img src={EqvipmentIconIcon} /><b> Equipment Rental</b>
                                </div>
                                <div>
                                    <img src={GymIcon} /><b> Gym Access</b>
                                </div>
                                <div>
                                    <img src={CoworkingSpace} /><b> Co working space</b>
                                </div> */}
                            </div>
                        </div>
                        <div>
                            <div className='d-flex justify-content-end flex-wrap mt-4 pb-2  delete-job-post-sidemodel-view-profile'>
                                <button
                                    className='btn btn-for-reject w-100'
                                    type='button'
                                    disabled={loading}
                                    onClick={() => deletePositionDetails(posData.id)}
                                >{loading ? 'Deleting...' : 'Delete the Job post'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default ViewJobDetailsModel;
