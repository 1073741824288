import NotificationComponent from '../../Components/NotificationComponent'
import A1Icon from '../../Assets/Icons/A1.svg'
import A2Icon from '../../Assets/Icons/A2.svg'
import A3Icon from '../../Assets/Icons/A3.svg'
import A4Icon from '../../Assets/Icons/A4.svg'
import { useEffect, useState } from 'react'
import { EmployerDash, PositionsProject } from '../../types'
import Helper from '../../utils/helper'
import useAuth from '../../hooks/useAuth'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { driver } from "driver.js";

const NewDashbord = () => {
    let location = useLocation();
    const [data, setData] = useState<EmployerDash>();
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);
    const { user, getDetails } = useAuth();
    const navigate = useNavigate();

    const getDashDetails = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.EMPLOYER_DASH);
            const { data } = res_data.data;

            setLoading(false);
            if (data) setData(data);
            else throw new Error();

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) getDashDetails();
    }, [user]);

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.id == val);

        if (op) return op.position;

        return '';
    };

    const getSuffix = (value: number = 0) => {
        if (value && Number(value) > 2) return 's';
        return '';
    };

    const getPosLabel = (val: string | number, opArr: PositionsProject[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.id == val);

        if (op) return op.position;

        return '';
    };

    const handleCardClick = (id: number, applicants: number = 0) => {
        if (applicants > 0) navigate(`/employer-view-job/${id}`, { replace: true });
        else toast.error('No Candidates applied for this Job');
    };

    // if (location.pathname == "/employer-dashboard") {
    //     const driverObj = driver({
    //         showProgress: true,
    //         steps: [
    //             { element: '.all-talents-cst', popover: { title: 'All Talents', description: 'Current and Growing Pool of Vetted Talent.' } },
    //             { element: '.company-info-cst', popover: { title: 'Your Company', description: "View and edit your company's profile and billing information" } },
    //             { element: '.manage-talents-cst', popover: { title: 'Click to start hiring', description: "Recruit, hire, and manage payroll and benefits for talent worldwide in just a few clicks." } },
    //             { element: '.hire-talents-cst-guide', popover: { title: 'Click Here', description: 'Start hiring software engineers globally.' } },
    //         ]
    //     });

    //     driverObj.drive();
    // }

    const updateGuidePrefrence = async () => {
        await helper.putReq(helper.PUT.UPDATE_CUS_GUIDE_PREF, {});
    };
    
    useEffect(() => {
        if (user?.isGuideGiven == false) {
            const driverObj = driver({
                showProgress: true,
                steps: [
                    { element: '.all-jobs-cst', popover: { title: 'All Jobs', description: 'Current Job Openings.' } },
                    { element: '.my-profile-cst', popover: { title: 'Your Profile', description: ' Access and Update Details to View Matching Jobs.' } },
                    { element: '.gen-ai-vetting-cst', popover: { title: 'Technical Skill Test', description: 'Complete Gen AI Vetting to Get Matched with High-Paying Full-Time Remote Jobs in the US' } },
                    { element: '.my-application-view-all', popover: { title: 'My Application', description: 'Browse my application jobs.' } },
                ]
            });
    
            driverObj.drive();
            updateGuidePrefrence();
            getDetails(false);
        }
    }, []);

    if (loading) return <p>Loading...</p>;


    return (
        <>
            <div id="content" className='pt-5'>
                <div className='top-navigation mb-5'>
                    <NotificationComponent />
                    <h2>Dashboard</h2>
                    <p>This dashboard provides an overview of the talent pool, job postings, and hired candidates.</p>
                </div>
                <div className='custom-display-manage-mbile-desk flex-wrap'>
                    <div className='mb-4'>
                        <div className='card dahbord-cst-card all-talents-cst p-3'>
                            <div className='d-flex' style={{ gap: "16px" }}>
                                <div>
                                    <img src={A1Icon} className='img-fluid ' />
                                </div>
                                <div>
                                    <h2>{45000 + (data?.allTalents || 0)}</h2>
                                    <h4>All Talents</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-4'>
                        <div className='card dahbord-cst-card p-3'>
                            <div className='d-flex' style={{ gap: "16px" }}>
                                <div>
                                    <img src={A2Icon} className='img-fluid' />
                                </div>
                                <div>
                                    <h2>{data?.jobPosted || 0}</h2>
                                    <h4>Job Posted</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='card dahbord-cst-card p-3'>
                            <div className='d-flex' style={{ gap: "16px" }}>
                                <div>
                                    <img src={A3Icon} className='img-fluid' />
                                </div>
                                <div>
                                    <h2>{data?.vacancies || 0}</h2>
                                    <h4>Vacancies</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <div className='card dahbord-cst-card p-3'>
                            <div className='d-flex' style={{ gap: "16px" }}>
                                <div>
                                    <img src={A4Icon} className='img-fluid' />
                                </div>
                                <div>
                                    <h2>{data?.hired || 0}</h2>
                                    <h4>Hired</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>                    
                    <div className='d-flex justify-content-between align-items-center flex-wrap mb-5 mt-5'>
                        <h3>Jobs Posted</h3>
                        <h5><a
                            href='#'
                            className='cst-primary-color text-decoration-underline view-all-cst'
                            onClick={() => navigate(`/employer-hire-talents`, { replace: true })}
                        >View All</a></h5>
                    </div>
                    <div className='d-flex cst-scollbar-add'>
                        {
                            data?.jobs.map((posReq) => (
                                <div className='card job-posted-card-cst p-3 mb-4 col-md-3 col-sm-6' style={{ cursor: `${posReq.noOfApply && posReq.noOfApply > 0 ? 'pointer' : 'not-allowed'}` }} key={posReq.id} onClick={() => handleCardClick(posReq.id, posReq.noOfApply)}>
                                    <div className='d-flex justify-content-between'>
                                        <h4 style={{ fontWeight: "700" }}>{getPosLabel(posReq.positionId || 1, data.positions || [])}</h4>
                                        {posReq.noOfApply !== undefined && posReq.noOfApply > 0 && <button className='button-active'>{posReq.noOfUnseen} New Applicant{getSuffix(posReq.noOfUnseen)}</button>}
                                    </div>
                                    <div className='d-flex justify-content-between align-items-center mt-2'>
                                        <ul className='d-flex m-0 p-0 px-3' style={{ gap: "30px" }}>
                                            <li><p>{posReq.numberOfPosition} position{getSuffix(posReq.numberOfPosition)}</p></li>
                                            <li><p>{posReq.noOfApply} Applicant{getSuffix(posReq.numberOfPosition)}</p></li>
                                        </ul>
                                        <p className='hired-cst'>{posReq.noOfHired}/{posReq.numberOfPosition} Hired</p>
                                    </div>
                                    <div className='d-flex justify-content-between mt-4'>
                                        <div>
                                            <h5 className='cst-secondary-color'>Reject</h5>
                                            <h6>{posReq.noOfRejected} applicant{getSuffix(posReq.noOfRejected)}</h6>
                                        </div>
                                        <div>
                                            <h5 className='cst-secondary-color'>Interview Scheduled</h5>
                                            <h6>{posReq.noOfShortlisted} applicant{getSuffix(posReq.noOfShortlisted)}</h6>
                                        </div>
                                        <div>
                                            <h5 className='cst-secondary-color'>Not Seen</h5>
                                            <h6>{posReq.noOfUnseen} applicant{getSuffix(posReq.noOfUnseen)}</h6>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {/* <div className='card job-posted-card-cst p-3 mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h4 style={{ fontWeight: "700" }}>QA Engineer </h4>
                                <button className='button-active'>03 New Applicants</button>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <ul className='d-flex m-0 p-0 px-3' style={{ gap: "30px" }}>
                                    <li><p>3 positions</p></li>
                                    <li><p>32 Applicants</p></li>
                                </ul>
                                <p className='hired-cst'>0/3 Hired</p>
                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    <h5 className='cst-secondary-color'>Reject</h5>
                                    <h6>10 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Interview Scheduled</h5>
                                    <h6>04 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Not Seen</h5>
                                    <h6>20 applicants</h6>
                                </div>
                            </div>
                        </div>
                        <div className='card job-posted-card-cst p-3 mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h4 style={{ fontWeight: "700" }}>QA Engineer </h4>
                                <button className='button-active'>03 New Applicants</button>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <ul className='d-flex m-0 p-0 px-3' style={{ gap: "30px" }}>
                                    <li><p>3 positions</p></li>
                                    <li><p>32 Applicants</p></li>
                                </ul>
                                <p className='hired-cst'>0/3 Hired</p>
                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    <h5 className='cst-secondary-color'>Reject</h5>
                                    <h6>10 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Interview Scheduled</h5>
                                    <h6>04 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Not Seen</h5>
                                    <h6>20 applicants</h6>
                                </div>
                            </div>
                        </div>
                        <div className='card job-posted-card-cst p-3 mb-4'>
                            <div className='d-flex justify-content-between'>
                                <h4 style={{ fontWeight: "700" }}>QA Engineer </h4>
                                <button className='button-active'>03 New Applicants</button>
                            </div>
                            <div className='d-flex justify-content-between mt-2'>
                                <ul className='d-flex m-0 p-0 px-3' style={{ gap: "30px" }}>
                                    <li><p>3 positions</p></li>
                                    <li><p>32 Applicants</p></li>
                                </ul>
                                <p className='hired-cst'>0/3 Hired</p>
                            </div>
                            <div className='d-flex justify-content-between mt-4'>
                                <div>
                                    <h5 className='cst-secondary-color'>Reject</h5>
                                    <h6>10 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Interview Scheduled</h5>
                                    <h6>04 applicants</h6>
                                </div>
                                <div>
                                    <h5 className='cst-secondary-color'>Not Seen</h5>
                                    <h6>20 applicants</h6>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default NewDashbord;