import React, { useEffect, useRef, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import useAuth from '../../hooks/useAuth';
import Helper from '../../utils/helper';
import { EXPERIENCE_LEVEL, OFFICE_POLICY, PERKS, PER_MONTH_YEAR_CONST, POSITION_TYPES, SALARY_CONST, TIMEZONES, customStyles, languagesOptions } from '../../utils/constant'
import { LanguageSelected, OptionType, PosReqOtherDetails, PositionDetails, SkillData, imageAllowedFileTypes } from '../../types';

import ProgressLineComponentForHireTalent from '../../Components/Employer/ProgressLineComponentForHireTalent';
import HealthCareModel from '../../Components/Employer/Modals/HealthCareModel';
import EquipmentModel from '../../Components/Employer/Modals/EquipmentModel';
import CoWorkingSpaceModel from '../../Components/Employer/Modals/CoWorkingSpaceModel';
import GymAccessModel from '../../Components/Employer/Modals/GymAccessModel';

import PlusIconForAddKitButton from '../../Assets/Icons/addKitPlusIcon.svg'
import AdditionIcon from '../../Assets/Icons/additionVector.svg'
import NotificationComponent from '../../Components/NotificationComponent'
import SubstractionIcon from '../../Assets/Icons/SubstractionVector.svg'
import WelcomeIcon from '../../Assets/Icons/welcomeIcon.svg'
import HealthCareIcon from '../../Assets/Icons/HealthCareIcon.svg'
import EqvipmentIconIcon from '../../Assets/Icons/EqvipmentIcon.svg'
import CoworkingSpace from '../../Assets/Icons/CoWorkingSpace.svg'
import GymIcon from '../../Assets/Icons/GymIcon.svg'
import DeleteIcon from '../../Assets/Icons/DeleteIcon.svg'
import UploadIcon from '../../Assets/Icons/material-symbols_upload.svg'
import { BsCalendarFill, BsUpload } from 'react-icons/bs';

import Spinner from '../../Assets/Images/spinner.png';
import Cropper, { ReactCropperElement } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { compress } from 'image-conversion';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';

import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import CreatableSelect from 'react-select/creatable';
import CurrencyInput from 'react-currency-input-field';

const HireTalents = () => {
    const { user } = useAuth();
    // const [activeNavTab, setActiveNavTab] = useState("AllCandidate")
    const [textLimitExceeded, setTextLimitExceeded] = useState(false);
    const maxTextLength = 10000; // Maximum text length
    const [activeTab, setActiveTab] = useState<Number>(1);
    const [positions, setPositions] = useState<PositionDetails[]>([]);
    const [selectedPositions, setSelectedPositions] = useState<PositionDetails[]>([]);
    const navigate = useNavigate();

    const initData: PosReqOtherDetails = {
        languagesData: [],
        timezone: '',
        officePolicy: 1,
        startDate: '',
        perks: [],
        isPostConsent: false,
        imageUrl: ''
    }
    const [posReqOtherData, setPosReqOtherData] = useState<PosReqOtherDetails>(initData);

    const [customPosition, setCustomPosition] = useState<string>('');
    const [selectedArr, setSelectedArr] = useState<number[]>([]);

    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);
    const [currentReq, setCurrentReq] = useState<string>('');
    const [skillsDB, setSkillsDB] = useState<OptionType[]>([]);

    useEffect(() => {
        if (user) {
            getPositionDetails();
            getSkillsDataDB();
        }
    }, [user]);

    const getPositionDetails = async (id?: string) => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            if (data) {
                setPositions(data);
                if (id) setTimeout(() => { document.getElementById(`posInp${id}`)?.click() }, 2000);
            }
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getSkillsDataDB = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.SKILLS_DATA);
            const { data } = res_data.data;
            if (data) {
                const mapped: OptionType[] = data.map((sk: SkillData) => ({ label: sk.skill, value: sk.skill }));
                setSkillsDB(mapped);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const addCustomPosition = async () => {

        if (!customPosition || !customPosition.trim()) return;

        const alreadyAdded = positions.find((pos) => pos.position.toLowerCase().trim() === customPosition.toLowerCase().trim());

        if (alreadyAdded) return toast.error("This Postion already Exist");

        try {
            setLoading(true);
            const reqData = { position: customPosition };
            const res_data = await helper.postReq(helper.POST.ADD_POSITION, reqData);
            const { data } = res_data.data;

            setLoading(false);
            if (data) {
                getPositionDetails(data.id);
                setCustomPosition('');
                toast.success('Added Successfully');
            }
            else throw new Error();
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Failed to Add a Postion");
        }
    }

    const additionHandlebutton = (index: number) => {
        const selected = JSON.parse(JSON.stringify(selectedPositions[index]));

        const countData = selected.numberOfPosition;

        if (!countData || countData < 5) {
            const newArray = JSON.parse(JSON.stringify(selectedPositions));
            newArray[index].numberOfPosition = countData ? countData + 1 : 2;

            const newData = { ...selected, hideTitle: true, positionCount: countData ? countData + 1 : 2 };
            newArray.splice(index + (countData || 1), 0, newData);
            console.log(newArray);
            setSelectedPositions(newArray);

            console.log(selectedPositions);
        }
    };

    const substrationHandlebutton = (index: number) => {
        const selected = JSON.parse(JSON.stringify(selectedPositions[index]));
        const countData = selected.numberOfPosition;

        if (countData > 1) {
            const newArray = JSON.parse(JSON.stringify(selectedPositions));
            newArray[index].numberOfPosition = countData - 1;
            newArray.splice(index + (countData - 1), 1);
            setSelectedPositions(newArray);
            console.log(selectedPositions);
        }
    };

    const handleCheckboxChange = (ev: any) => {
        const posId = Number(ev.target.value);
        if (ev.target.checked) {
            const position = positions.filter((pos) => pos.id === posId);
            const newSelectedPositions = [...selectedPositions, ...position];
            setSelectedPositions(newSelectedPositions);
            const nsa = [...selectedArr, position[0].id];
            setSelectedArr(nsa);
        } else {
            const newSelectedPositions = selectedPositions.filter((pos) => pos.id !== posId);
            setSelectedPositions(newSelectedPositions);
            const nsa = selectedArr.filter((id) => id !== posId);
            setSelectedArr(nsa);
        }
    };

    const handleSelectChange = (keyType: number, index: number, value: any) => {
        const position: PositionDetails = { ...selectedPositions[index] };
        switch (keyType) {
            case 1:
                position.seniority = Number(value);
                break;
            case 2:
                let arr: string[] = value.map((d: any) => d.value);
                position.skills = arr;
                break;
            case 3:
                position.positionType = Number(value);
                break;
            case 4:
                // position.description = value;
                // position.descLength = value.length;
                // break;
                // const maxLength = 10000;
                // if (value.length <= maxLength) {
                //     position.description = value;
                //     position.descLength = value.length;
                //     console.log(position.descLength, "position.descLength")
                // }
                if (value.length <= maxTextLength) {
                    position.description = value;
                    position.descLength = value.length;
                    setTextLimitExceeded(false); // Reset the flag if limit not exceeded
                } else {
                    setTextLimitExceeded(true); // Set the flag if limit exceeded
                }
                break;
            case 5:
                let arr2 = value.map((d: any) => d.value);
                position.optionalSkills = arr2;
                break;
            case 6:
                position.salary = Number(value);
                break;
            case 7:
                position.salaryType = Number(value);
                break;
            default:
                break;
        }

        selectedPositions[index] = position;
        setSelectedPositions([...selectedPositions]);
        // console.log(selectedPositions);
    };

    const getMultiSelectedDef = (dataArr: OptionType[], dataVal: number[] | string[]) => {
        if (!dataVal.length) return;

        const jdataArr: OptionType[] = JSON.parse(JSON.stringify(dataArr));
        const newArr = [];

        for (let d of dataVal) {
            const val = jdataArr.filter((dt) => dt.value == d);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const getMultiSelectedDef2 = () => {
        if (!posReqOtherData.languagesData.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(languagesOptions));
        const newArr = [];

        for (let ln of posReqOtherData.languagesData) {
            const val = ldataArr.filter((dt) => dt.value == ln.language);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handelPosSubmit = (ev: any) => {
        ev.preventDefault();

        if (selectedPositions.length) {
            const emptyDesc = selectedPositions.find((pos) => pos.description === '');
            const limitDesc = selectedPositions.find((pos) => pos.descLength && pos.descLength >= 10000);

            if (emptyDesc) toast.error('Please fillup all Job Descriptions');
            if (limitDesc) toast.error('Job Description Characters Limit Exeeded');
            else setActiveTab(2);
        }
    };

    const handleLanguageSelect = (val: any) => {
        // let arr = val.map((d: any) => ({ language: d.value, fluency: "Intermediate" }));
        let arr = val.map((d: any) => {
            const found = posReqOtherData.languagesData.find((x) => x.language == d.value);
            return { language: d.value, fluency: found?.fluency || '' }
        });
        setPosReqOtherData({ ...posReqOtherData, languagesData: arr });
    };

    const setFluency = (index: number, val: string) => {
        const lang: LanguageSelected = { ...posReqOtherData.languagesData[index] };
        lang.fluency = val;
        posReqOtherData.languagesData[index] = lang;
        setPosReqOtherData({ ...posReqOtherData, languagesData: posReqOtherData.languagesData });
        console.log(posReqOtherData.languagesData)
    };

    const handelPosOtherSubmit = (ev: any) => {
        ev.preventDefault();

        if (
            !posReqOtherData.languagesData.length ||
            !posReqOtherData.timezone ||
            !posReqOtherData.officePolicy
        ) return;

        console.log(posReqOtherData);
        setActiveTab(3);
    };

    const handleAddPerk = (perk: number) => {
        const perks = posReqOtherData.perks;
        perks.push(perk);
        setPosReqOtherData({ ...posReqOtherData, perks });
    };

    const handleRemovePerk = (type: number) => {
        const perks = posReqOtherData.perks;
        let filtered: number[] = [];

        switch (type) {
            case 1:
                filtered = perks.filter((elm) => elm !== PERKS.WELCOME_KIT);
                break;
            case 2:
                const refArr1 = [
                    PERKS.HEALTHCARE_BASIC,
                    PERKS.HEALTHCARE_STANDARD,
                    PERKS.HEALTHCARE_PREMIUM
                ];
                filtered = perks.filter((elm: number) => !refArr1.includes(elm));
                break;
            case 3:
                const refArr2 = [
                    PERKS.EQUIPMENT_LAPTOP,
                    PERKS.EQUIPMENT_MONITOR,
                    PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE,
                    PERKS.EQUIPMENT_WIFI_CONNECTION
                ];
                filtered = perks.filter((elm: number) => !refArr2.includes(elm));
                break;
            case 4:
                const refArr3 = [
                    PERKS.CO_WORKING_SEAT,
                    PERKS.CO_WORKING_CABIN
                ];
                filtered = perks.filter((elm: number) => !refArr3.includes(elm));
                break;
            case 5:
                const refArr4 = [
                    PERKS.GYM_ACCESS_BASIC,
                    PERKS.GYM_ACCESS_STANDARD,
                    PERKS.GYM_ACCESS_PREMIUM
                ];
                filtered = perks.filter((elm: number) => !refArr4.includes(elm));
                break;
            case 6:
                filtered = perks.filter((elm) => elm !== PERKS.BACKGROUND_CHECK);
                break;
            default:
                break;
        }

        if (type == 1) {
            setPosReqOtherData({ ...posReqOtherData, perks: filtered, imageUrl: '' });
        } else {
            setPosReqOtherData({ ...posReqOtherData, perks: filtered });
        }
    };

    const handlePosReqFinalSubmit = async () => {
        // if (!posReqOtherData.isPostConsent) return toast.error('Please check nad give Consent for Post Request Term');

        setLoading(true);
        for (let i = 0; i < selectedPositions.length; i++) {
            const post = selectedPositions[i];

            try {
                setCurrentReq(post.position);
                const requestData = {
                    positionId: post.id,
                    numberOfPosition: post.numberOfPosition || 1,
                    seniority: post.seniority,
                    skills: post.skills,
                    optionalSkills: post.optionalSkills || [],
                    salary: post.salary || 0,
                    salaryType: post.salaryType || 2,
                    positionType: post.positionType,
                    description: post.description,
                    languages: posReqOtherData.languagesData,
                    timezone: posReqOtherData.timezone,
                    officePolicy: posReqOtherData.officePolicy,
                    startDate: posReqOtherData.startDate,
                    perks: posReqOtherData.perks || []
                };

                const res_data = await helper.postReq(helper.POST.ADD_POSITION_REQUEST, requestData);
                const { data } = res_data.data;

                if (!data) throw new Error("Failed");
                else if (i == selectedPositions.length - 1) {
                    toast.success('Position Request Created Successfully');
                    // setSelectedPositions([]);
                    // setPosReqOtherData(initData);
                    // setCurrentReq('');
                    setLoading(false);
                    navigate(`/employer-hire-talents`, { replace: true });
                } else console.log(data);
            } catch (error) {
                setLoading(false);
                console.log(error);
                toast.error(`Create Position Request Failed for ${post.position}`);
                return;
            }
        };
    };

    const [loading2, setLoading2] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const cropperRef = useRef<ReactCropperElement>(null);

    const handleUpload = async (blobData: Blob) => {
        if (!blobData) return;

        try {
            setLoading2(true);

            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);
            const fileTypeArr = blobData.type.split("/");
            const name = `${fileID}.${fileTypeArr[1]}`;

            reqData.append('file', blobData, name);

            console.log(reqData);
            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.COMPANY_LOGO_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            // const res_data = await axios.post(`http://localhost:3030${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData);
            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setPosReqOtherData({ ...posReqOtherData, imageUrl: data });
                toast.success('Logo Uploaded Successfully');

                setTimeout(() => {
                    setLoading2(false);
                }, 100);
            } else {
                setPosReqOtherData({ ...posReqOtherData, imageUrl: '' });
                toast.error('Logo Upload Failed');
                setLoading2(false);
            }
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Logo Upload Failed');
        }
    };

    // Function to handle image selection
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result as string);
                setTimeout(() => {
                    document.getElementById('crop-button')?.click();
                }, 100);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to handle cropping
    const handleCrop = async () => {
        if (cropperRef.current) {
            // if (typeof cropperRef.current?.cropper !== "undefined") {
            // setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            handleCompress(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setTimeout(() => {
                document.getElementById('crop-button')?.click();
            }, 100);
            // }
        }
    };

    const dataURLtoBlob = (dataURL: string): Blob => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const handleCompress = async (data: any) => {
        if (data) {
            // console.log('Cropped Image URL:', croppedImage);

            const croppedBlob = dataURLtoBlob(data);
            const compressedImageData = await compress(croppedBlob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
            });

            handleUpload(compressedImageData);
        }
    };
    var modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }, { align: [] }]
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent", "size",
    ];

    const handleCreateOption = async (inputValue: string, type: number, index: number) => {
        const newOption: OptionType = { value: inputValue, label: inputValue };

        const position: PositionDetails = { ...selectedPositions[index] };
        if (type == 2) position.optionalSkills = [...position.skills || [], inputValue];
        else position.skills = [...position.skills || [], inputValue];
        selectedPositions[index] = position;
        setSelectedPositions([...selectedPositions]);

        setSkillsDB([newOption, ...skillsDB]);

        try {
            await helper.postReq(helper.POST.ADD_SKILL_DATA_DB, { skill: inputValue });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <NotificationComponent />
                        {activeTab == 3 ? <>
                            <h2>Benefits and Local Procurement</h2>
                        </>
                            : <h2>Hire Software Engineers Globally</h2>}
                        <p>From HR and payroll to legal compliance and local procurement, we've got you covered.</p>
                    </div>

                    {activeTab === 0 && (<>
                        <div className='d-flex mt-4 mb-4 justify-content-end align-items-center flex-wrap'>

                            <div className='d-flex' style={{ gap: "12px" }}>
                                <button
                                    className='btn create-account-btn p-3 '
                                    style={{ minWidth: "159px", maxHeight: "70px" }}
                                    onClick={handlePosReqFinalSubmit}
                                    disabled={loading}
                                > {
                                        loading ?
                                            `Creating ${currentReq} Position...` :
                                            `Submit (${selectedPositions.length})`}
                                </button>
                                <button
                                    className='btn btn-outline create-account-btn-outline p-3'
                                    style={{ minWidth: "159px", maxHeight: "70px" }}
                                    disabled={loading}
                                    onClick={() => setActiveTab(3)}
                                >Cancel
                                </button>
                            </div>
                        </div>
                        {/* <div className='mb-4'>
                            <h4 onClick={() => setPosReqOtherData({ ...posReqOtherData, isPostConsent: !posReqOtherData.isPostConsent })} style={{ cursor: 'pointer' }} >
                            <input type='checkbox' className='form-check-input cst-terms-con-accept' id='flexCheckDefault' checked={posReqOtherData.isPostConsent} />&nbsp;We may post this job on our platform to accept terms and conditions</h4>
                        </div> */}
                    </>
                    )}

                    {(activeTab === 1 || activeTab === 0) && (
                        <>
                            {activeTab === 1 && (
                                <>
                                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                        <h3 className='mb-4 fw-bold'>Let's start with the job role you want to hire.</h3>
                                        <ProgressLineComponentForHireTalent />
                                    </div>

                                    <div className='mt-4'>
                                        <h4>Select as many roles as you need.</h4>
                                    </div>

                                    <div className='mt-5 col-12 form-check cst-job-listing margin-fix-bottam' >
                                        <div className='row'>
                                            {activeTab === 0 && <h4>Profile</h4>}
                                            {
                                                positions.length > 0 && positions.map((pos) => (
                                                    <div className='col-md-3 mb-4' key={pos.id}>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            checked={selectedArr.includes(pos.id)}
                                                            // id={pos.id.toString()}
                                                            id={`posInp${pos.id}`}
                                                            name={pos.position}
                                                            value={pos.id}
                                                            onChange={(ev) => handleCheckboxChange(ev)}
                                                        />
                                                        <h4 className='profile-heading-cst'>{pos.position}</h4>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                    </div>

                                    <div className='row d-flex align-items-end' style={{ gap: "20px" }}>
                                        <div className='col-md-3 col-12'>
                                            <label htmlFor="Others">Others</label>
                                            <input
                                                type='text'
                                                id='customPosition'
                                                value={customPosition}
                                                onChange={(ev) => setCustomPosition(ev.target.value)}
                                                disabled={loading}
                                                className='form-control custom-input col-md-6 col-12'
                                                placeholder="Enter a Job role not listed"
                                            />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <button
                                                className='btn create-account-btn p-2 col-md-3  col-12'
                                                style={{ minWidth: "159px", minHeight: "58px" }}
                                                type='button'
                                                disabled={loading}
                                                onClick={() => addCustomPosition()}
                                            >{loading ? 'Adding...' : 'Add'}</button>
                                        </div>
                                    </div>
                                </>
                            )}
                            {
                                selectedPositions.length > 0 && (
                                    <form name='posForm' id='posForm' onSubmit={handelPosSubmit} >
                                        <div className='mt-4 mb-4'>
                                            {
                                                activeTab === 1 ? <h4>Please provide more information about your selected profiles</h4> : <h4>Profiles</h4>
                                            }
                                        </div>
                                        <div className='card cst-padding-admin-hire-talets' style={{ borderRadius: "27px", background: "#FFF" }}>
                                            {selectedPositions.map((pos, index) => (
                                                <React.Fragment key={pos.id + index}>
                                                    {
                                                        !pos.hideTitle && (
                                                            <>
                                                                {index !== 0 && <hr />}
                                                                <div className='d-flex align-items-center cst-position-for-admin-hire'>
                                                                    <h3>{pos.position}</h3>
                                                                    {activeTab === 1 && (<>
                                                                        <div>
                                                                            <label htmlFor="position">No. of position&nbsp;</label>&nbsp;
                                                                            <button
                                                                                type='button'
                                                                                className='btn btn-outline'
                                                                                style={{
                                                                                    borderRadius: '12px',
                                                                                    border: '1px solid #000',
                                                                                    background: '#FFF',
                                                                                    minWidth: '182px',
                                                                                    minHeight: '60px',
                                                                                    textAlign: 'center',
                                                                                }}>
                                                                                <img
                                                                                    src={SubstractionIcon}
                                                                                    className='img-fluid'
                                                                                    onClick={() => substrationHandlebutton(index)}
                                                                                />
                                                                                &nbsp;&nbsp;{pos.numberOfPosition ? pos.numberOfPosition : 1}&nbsp;&nbsp;&nbsp;
                                                                                <img
                                                                                    src={AdditionIcon}
                                                                                    className='img-fluid'
                                                                                    onClick={() => additionHandlebutton(index)}
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                    )}

                                                                </div>
                                                            </>
                                                        )
                                                    }
                                                    <div className='row'>
                                                        <p className='mt-5 mb-3' style={{
                                                            fontSize: ' 19px',
                                                            fontStyle: 'normal',
                                                            fontWeight: ' 500',
                                                            lineHeight: 'normal',
                                                            letterSpacing: '-0.76px',
                                                        }}>Position {pos.positionCount ? pos.positionCount : 1}</p>
                                                        <div className='col-md-4 col-12 mb-2'>
                                                            <label>Seniority*</label>
                                                            <Select
                                                                options={EXPERIENCE_LEVEL}
                                                                value={EXPERIENCE_LEVEL.filter((p) => Number(p.value) == pos.seniority)[0]}
                                                                onChange={(ev) => handleSelectChange(1, index, ev?.value || 1)}
                                                                id={`se${pos.id}${index}`} styles={customStyles}
                                                                className="mb-4"
                                                                required
                                                                placeholder="Select Seniority"
                                                                isDisabled={activeTab === 0}

                                                            />
                                                        </div>
                                                        <div className='col-md-3 col-12 mb-2'>
                                                            <label>Type of role*</label>
                                                            <Select
                                                                options={POSITION_TYPES}
                                                                onChange={(ev) => handleSelectChange(3, index, ev?.value)}
                                                                value={POSITION_TYPES.filter((p) => Number(p.value) == pos.positionType)[0]}
                                                                id={`ro${pos.id}${index}`}
                                                                styles={customStyles}
                                                                className="mb-4"
                                                                required
                                                                placeholder="Select"
                                                                isDisabled={activeTab === 0}
                                                            />
                                                        </div>
                                                        <div className='col-md-3 col-12 mb-2'>
                                                            <label>Salary</label>
                                                            {/* <Select
                                                                options={SALARY_CONST}                                                                                                                                 
                                                                styles={customStyles}
                                                                className="mb-4"
                                                                required
                                                                placeholder="Select"
                                                             /> */}
                                                            <div className='FieldPlaceholderWrap'>
                                                                <CurrencyInput
                                                                    // type='text'
                                                                    id={`ro${pos.id}${index}`}
                                                                    name="input-name"
                                                                    placeholder={`$    Salary`}
                                                                    required
                                                                    prefix={`$    `}
                                                                    className="form-control custom-input"
                                                                    value={pos.salary || undefined}
                                                                    decimalsLimit={2}
                                                                    onValueChange={(ev) => handleSelectChange(6, index, Number(ev))}
                                                                />
                                                                {/* <span>/&nbsp;annum</span> */}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2 col-12 mb-2'>
                                                            <label></label>
                                                            <Select
                                                                options={PER_MONTH_YEAR_CONST}
                                                                styles={customStyles}
                                                                className="mb-4"
                                                                placeholder="Salary Type"
                                                                onChange={(ev) => handleSelectChange(7, index, ev?.value)}
                                                                value={PER_MONTH_YEAR_CONST.filter((p) => Number(p.value) == pos.salaryType)[0]}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-2'>
                                                            <label>Must have Skills*</label>
                                                            <CreatableSelect
                                                                options={skillsDB.filter((op) => !pos.optionalSkills?.includes((op.value).toString()))}
                                                                value={getMultiSelectedDef(skillsDB, pos.skills || [])}
                                                                onChange={(ev) => handleSelectChange(2, index, ev)}
                                                                onCreateOption={(ev: string) => handleCreateOption(ev, 1, index)}
                                                                id={`sk${pos.id}${index}`}
                                                                styles={customStyles}
                                                                isMulti
                                                                className="mb-4"
                                                                required
                                                                placeholder="Select Skills"
                                                                isDisabled={activeTab === 0}
                                                            />
                                                        </div>
                                                        <div className='col-md-6 col-12 mb-2'>
                                                            <label>Good to have Skills*</label>
                                                            <CreatableSelect
                                                                options={skillsDB.filter((op) => !pos.skills?.includes((op.value).toString()))}
                                                                value={getMultiSelectedDef(skillsDB, pos.optionalSkills || [])}
                                                                onChange={(ev) => handleSelectChange(5, index, ev)}
                                                                onCreateOption={(ev: string) => handleCreateOption(ev, 2, index)}
                                                                id={`opsk${pos.id}${index}`}
                                                                styles={customStyles}
                                                                isMulti
                                                                className="mb-4"
                                                                placeholder="Select Skills"
                                                                isDisabled={activeTab === 0}
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-12 mb-4'>
                                                            <label>Job Description*({pos.descLength || 0}/10000)</label>
                                                            <div className='cst-react-quill'>
                                                                <ReactQuill
                                                                    theme="snow"
                                                                    id={`des${pos.id}${index}`}
                                                                    modules={modules}
                                                                    formats={formats}
                                                                    placeholder="Tell us about your ideal candidate! We'll find a match based on your description."
                                                                    value={pos.description}
                                                                    onChange={(content: any) => handleSelectChange(4, index, content)}
                                                                    style={{ height: "200px" }}
                                                                    readOnly={activeTab === 0}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {textLimitExceeded && <span className='text-danger cst-margin-top-for-red-limit-exceed'>Description Limit Exceeded (Maximum {maxTextLength} characters)</span>}
                                                </React.Fragment>
                                            ))}
                                        </div>

                                        {activeTab === 1 && (
                                            <div className='mt-4 d-flex justify-content-end mobile-two-buttons'>
                                                <button
                                                    type='submit'
                                                    className='btn create-account-btn p-3 '
                                                    style={{ minWidth: "159px" }}
                                                > Next</button>
                                                <button
                                                    type='button'
                                                    className='btn btn-outline create-account-btn-outline p-3 '
                                                    style={{ minWidth: "159px" }}
                                                    onClick={() => navigate(`/employer-hire-talents`, { replace: true })}
                                                >Cancel</button>
                                            </div>
                                        )}

                                    </form>
                                )
                            }
                        </>
                    )}

                    {(activeTab === 2 || activeTab === 0) && (
                        <>
                            {activeTab === 2 && (
                                <>
                                    <div className='d-flex justify-content-between align-items-center flex-wrap'>
                                        <h3 className='mb-4 fw-bold'>Great! Let’s brush up your requirement</h3>
                                        {/* <ProgressLineComponentForHireTalent /> */}
                                        <div className='d-flex progress-lines'>
                                            <div className='hor-line-primary '></div>
                                            <div className='hor-line-primary'></div>
                                            <div className='hor-line-secondary'></div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <h4>Please provide more information about these job roles.</h4>
                                    </div>
                                </>
                            )}
                            <form name='posOtherForm' id='posForm' onSubmit={handelPosOtherSubmit} >
                                <div className='card p-4 mt-4' style={{ borderRadius: "27px", background: "#FFF" }}>
                                    <div className='row '>
                                        <div className='col-md-4 col-12'>
                                            <label>Languages*</label>
                                            <Select
                                                styles={customStyles}
                                                options={languagesOptions}
                                                onChange={(ev) => handleLanguageSelect(ev)}
                                                value={getMultiSelectedDef2()}
                                                isMulti
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                                isDisabled={activeTab === 0}
                                            />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <label>Time Zone*</label>
                                            <Select
                                                styles={customStyles}
                                                options={TIMEZONES}
                                                value={TIMEZONES.filter((p) => p.value == posReqOtherData.timezone)[0]}
                                                onChange={(ev) => setPosReqOtherData({ ...posReqOtherData, timezone: ev?.value || '' })}
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                                isDisabled={activeTab === 0}
                                            />
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <label>Office Policy*</label>
                                            <Select
                                                styles={customStyles}
                                                options={OFFICE_POLICY}
                                                value={OFFICE_POLICY.filter((p) => p.value == posReqOtherData.officePolicy)[0]}
                                                onChange={(ev) => setPosReqOtherData({ ...posReqOtherData, officePolicy: ev?.value || 1 })}
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                                isDisabled={activeTab === 0}
                                            />
                                        </div>
                                        <div className='col-md-3 col-12'>
                                            <label>Tentative Start Date*</label>
                                            <div className='date-picker-input'>
                                                <DatePicker
                                                    className='form-control custom-datepicker'
                                                    disabledDate={d => !d || d.isBefore(new Date())}
                                                    suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                    allowClear={false}
                                                    defaultValue={posReqOtherData.startDate ? dayjs(posReqOtherData.startDate) : undefined}
                                                    onChange={(val) => setPosReqOtherData({ ...posReqOtherData, startDate: val?.toISOString() || '' })}
                                                    required
                                                    disabled={activeTab === 0}
                                                    placeholder='YYYY-MM-DD'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            posReqOtherData.languagesData.length !== 0 && (
                                                <>

                                                    {
                                                        posReqOtherData.languagesData.map((lan, index) => (
                                                            <div className='row' key={`${lan.language}${index}`} >
                                                                <div className='col-md-3 col-12'>
                                                                    {index == 0 && <label>Language selected</label>}
                                                                    <h5 className='mt-3'>{lan.language}</h5>
                                                                </div>

                                                                <div className='col-md-7 col-12'>
                                                                    {index == 0 && <label>Select Proficiency</label>}
                                                                    <div className='d-flex flex-wrap mt-3' style={{ gap: "12px" }}>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Fluent' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Fluent")}
                                                                            disabled={activeTab === 0}
                                                                        >Fluent</button>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Intermediate' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Intermediate")}
                                                                            disabled={activeTab === 0}
                                                                        >Intermediate</button>
                                                                        <button
                                                                            type='button'
                                                                            className={`btn button-lag-proficiancy ${lan.fluency === 'Native' ? 'button-lag-proficiancy-active' : ''}`}
                                                                            onClick={() => setFluency(index, "Native")}
                                                                            disabled={activeTab === 0}
                                                                        >Native</button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                                {activeTab === 2 && (
                                    <div className='mt-4 d-flex justify-content-end mobile-two-buttons'>
                                        <button
                                            type='submit'
                                            className='btn create-account-btn p-3 '
                                            style={{ minWidth: "159px" }}
                                        > Next</button>
                                        <button
                                            type='button'
                                            className='btn btn-outline create-account-btn-outline p-3 '
                                            style={{ minWidth: "159px" }}
                                            onClick={() => setActiveTab(1)}
                                        >Cancel</button>
                                    </div>
                                )}
                            </form>
                        </>
                    )}

                    {(activeTab === 3 || activeTab === 0) && (
                        <>
                            {activeTab === 3 && (
                                <>
                                    <div className='d-flex justify-content-between flex-wrap align-items-center'>
                                        <h3 className='mb-4 fw-bold'>Great! Let’s complete the last step</h3>
                                        {/* <ProgressLineComponentForHireTalent /> */}
                                        <div className='d-flex progress-lines'>
                                            <div className='hor-line-primary '></div>
                                            <div className='hor-line-primary '></div>
                                            <div className='hor-line-primary '></div>
                                        </div>
                                    </div>

                                    {activeTab === 3 && (
                                        <div className='d-flex justify-content-between align-items-center mt-5 mb-5'>
                                            <div>
                                                <h4>You can select the equipment and benefits you want to <br />provide your new teammates! We'll handle all the logistics.</h4>
                                            </div>
                                            <div>
                                                <div className='d-flex justify-content-end mobile-two-buttons'>
                                                    <button
                                                        className='btn create-account-btn p-3 '
                                                        style={{ minWidth: "159px" }}
                                                        onClick={() => setActiveTab(0)}
                                                    > Next</button>
                                                    <button
                                                        className='btn btn-outline create-account-btn-outline p-3 '
                                                        style={{ minWidth: "159px" }}
                                                        onClick={() => setActiveTab(2)}
                                                    >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {(activeTab === 3 || (activeTab === 0 && posReqOtherData.perks.includes(PERKS.WELCOME_KIT))) && (
                                <div className='card p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF", marginTop: '20px' }}>
                                    <div className='row'>
                                        <div className='col-md-6 col-12 mb-2'>
                                            <h4><img src={WelcomeIcon} className='img-fluid' /> Welcome Kit</h4>
                                        </div>

                                        <div className='col-md-2 col-12 mb-2'></div>
                                        <div className='col-md-4 mb-2 text-end'>
                                            {
                                                posReqOtherData.perks.includes(PERKS.WELCOME_KIT) ? (
                                                    <button
                                                        className='delete-button p-2 col-md-3 col-12'
                                                        style={{
                                                            minHeight: "58px",
                                                            fontSize: '22px'
                                                        }}
                                                        onClick={() => handleRemovePerk(1)}
                                                        disabled={activeTab === 0}
                                                    ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                ) : (
                                                    <button
                                                        className='btn add-kit-button p-2 col-md-3 col-12'
                                                        style={{ minHeight: "58px" }}
                                                        onClick={() => handleAddPerk(1)}
                                                        disabled={activeTab === 0}
                                                    >
                                                        <img src={PlusIconForAddKitButton} className='img-fluid' /> Add Kit</button>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between mt-2 flex-wrap'>
                                        <div className='mb-2'>
                                            <h5>A welcome kit includes the following items:</h5>
                                            <ul className="attach-kit-cst">
                                                <li>T-shirt</li>
                                                <li>Notebook</li>
                                                <li>Pen</li>
                                                <li>Mug</li>
                                                <li>Hoodie</li>
                                                <li>Backpack</li>
                                            </ul>
                                        </div>
                                        {
                                            posReqOtherData.perks.includes(PERKS.WELCOME_KIT) &&
                                            <div className='mb-2'>
                                                <div className='text-end'>
                                                    <h5 className="cst-primary-color">Upload your Company Logo</h5>
                                                    <button
                                                        className='btn uplod-copmany-logo p-2 col-md-4 col-12'
                                                        style={{ minWidth: "184px", minHeight: "58px" }}
                                                        onClick={() => document.getElementById('imageInput')?.click()}
                                                        disabled={activeTab === 0}
                                                    >
                                                        <BsUpload /> Upload Logo
                                                    </button>

                                                    <input
                                                        type="file"
                                                        name="myImage"
                                                        id='imageInput'
                                                        hidden
                                                        accept={imageAllowedFileTypes}
                                                        onChange={handleImageChange}
                                                        disabled={activeTab === 0}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>


                                </div>
                            )}

                            {
                                (activeTab === 3 ||
                                    (activeTab === 0 &&
                                        (
                                            posReqOtherData.perks.includes(PERKS.HEALTHCARE_BASIC) ||
                                            posReqOtherData.perks.includes(PERKS.HEALTHCARE_STANDARD) ||
                                            posReqOtherData.perks.includes(PERKS.HEALTHCARE_PREMIUM)
                                        ))
                                ) && (
                                    <div className='card mt-4 p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF" }}>
                                        <div className='row'>
                                            <div className='col-md-8 mb-2'>
                                                <h4><img src={HealthCareIcon} className='img-fluid' /> Healthcare benefits</h4>
                                            </div>

                                            <div className='col-md-4 mb-2 text-end'>
                                                {
                                                    (
                                                        posReqOtherData.perks.includes(PERKS.HEALTHCARE_BASIC) ||
                                                        posReqOtherData.perks.includes(PERKS.HEALTHCARE_STANDARD) ||
                                                        posReqOtherData.perks.includes(PERKS.HEALTHCARE_PREMIUM)
                                                    ) ? (
                                                        <button
                                                            className='delete-button p-2 col-md-3 col-12'
                                                            style={{
                                                                minHeight: "58px",
                                                                fontSize: '22px'
                                                            }}
                                                            onClick={() => handleRemovePerk(2)}
                                                            disabled={activeTab === 0}
                                                        ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                    ) : (
                                                        <button
                                                            className='btn add-kit-button p-2 col-md-3  col-12'
                                                            style={{ minHeight: "58px" }}
                                                            data-bs-target="#healthCareModalToggle"
                                                            data-bs-toggle="modal"
                                                            disabled={activeTab === 0}
                                                        // onClick={() => handleAddPerk(2)}
                                                        ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add</button>
                                                    )
                                                }
                                            </div>
                                            <div className='col-md-8 mb-2'>
                                                <ul>
                                                    {posReqOtherData.perks.includes(PERKS.HEALTHCARE_BASIC) &&
                                                        <li>
                                                            <h5 className='owow-savings'>Cover $6K</h5>
                                                            <span>$10/Month Per Person</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.HEALTHCARE_STANDARD) &&
                                                        <li>
                                                            <h5 className='owow-savings'>Cover $12K</h5>
                                                            <span>$15/Month Per Person</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.HEALTHCARE_PREMIUM) &&
                                                        <li>
                                                            <h5 className='owow-savings'>Cover $20K</h5>
                                                            <span>$24/Month Per Person</span>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className='owow-savings'>Offer health benefits to your teammates. It boosts employee retention, motivates staff, and leads to enhanced productivity.</h5>
                                    </div>
                                )}

                            {
                                (activeTab === 3 ||
                                    (activeTab === 0 &&
                                        (
                                            posReqOtherData.perks.includes(PERKS.EQUIPMENT_LAPTOP) ||
                                            posReqOtherData.perks.includes(PERKS.EQUIPMENT_MONITOR) ||
                                            posReqOtherData.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) ||
                                            posReqOtherData.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION)
                                        ))
                                ) && (
                                    <div className='card mt-4 p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF" }}>
                                        <div className='row'>
                                            <div className='col-md-8 mb-2'>
                                                <h4><img src={EqvipmentIconIcon} /> Equipments Rental</h4>
                                            </div>
                                            <div className='col-md-4 mb-2 text-end'>
                                                {
                                                    (
                                                        posReqOtherData.perks.includes(PERKS.EQUIPMENT_LAPTOP) ||
                                                        posReqOtherData.perks.includes(PERKS.EQUIPMENT_MONITOR) ||
                                                        posReqOtherData.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) ||
                                                        posReqOtherData.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION)
                                                    ) ? (
                                                        <button
                                                            className='delete-button p-2 col-md-3 col-12'
                                                            style={{
                                                                minHeight: "58px",
                                                                fontSize: '22px'
                                                            }}
                                                            onClick={() => handleRemovePerk(3)}
                                                            disabled={activeTab === 0}
                                                        ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                    ) : (
                                                        <button
                                                            className='btn add-kit-button p-2 col-md-3  col-12'
                                                            style={{ minHeight: "58px" }}
                                                            data-bs-target="#EquipmentModelToggle"
                                                            data-bs-toggle="modal"
                                                            disabled={activeTab === 0}
                                                        // onClick={() => handleAddPerk(3)}
                                                        >
                                                            <img src={PlusIconForAddKitButton} className='img-fluid' /> Add Equipment</button>
                                                    )
                                                }
                                            </div>
                                            <div className='col-md-8 mb-2'>
                                                <ul>
                                                    {posReqOtherData.perks.includes(PERKS.EQUIPMENT_LAPTOP) &&
                                                        <li className="mb-2">
                                                            <h5 className='owow-savings'>Laptop</h5>
                                                            <span>16' MacBook Pro M1 MAX</span>
                                                            <span> $100/Month Per unit</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.EQUIPMENT_MONITOR) &&
                                                        <li className="mb-2">
                                                            <h5 className='owow-savings'>Monitor</h5>
                                                            <span>4K Monitor 27</span>
                                                            <span> $20/Month Per unit</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.EQUIPMENT_KEYBOARD_AND_MOUSE) &&
                                                        <li className="mb-2">
                                                            <h5 className='owow-savings'>Keyboard And Mouse</h5>
                                                            <span>Additional unit of keyboard and mouse</span>
                                                            <span> $10/Month Per unit</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.EQUIPMENT_WIFI_CONNECTION) &&
                                                        <li className="mb-2">
                                                            <h5 className='owow-savings'>Hi-Speed Internet</h5>
                                                            <span>Wifi Connection for your teammates</span>
                                                            <span> $10/Month Per unit</span>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className='owow-savings'>Equip your teammates with everything they need, from laptops and monitors to dongles and headsets, and more.</h5>

                                    </div>
                                )}

                            {
                                (activeTab === 3 ||
                                    (activeTab === 0 &&
                                        (
                                            posReqOtherData.perks.includes(PERKS.CO_WORKING_SEAT) ||
                                            posReqOtherData.perks.includes(PERKS.CO_WORKING_CABIN)
                                        ))
                                ) && (
                                    <div className='card mt-4 p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF" }}>
                                        <div className='row'>
                                            <div className='col-md-8 mb-2'>
                                                <h4><img src={CoworkingSpace} /> Co-working space</h4>
                                            </div>

                                            <div className='col-md-4 mb-2 text-end'>
                                                {
                                                    (
                                                        posReqOtherData.perks.includes(PERKS.CO_WORKING_SEAT) ||
                                                        posReqOtherData.perks.includes(PERKS.CO_WORKING_CABIN)
                                                    ) ? (
                                                        <button
                                                            className='delete-button p-2 col-md-3 col-12'
                                                            style={{
                                                                minHeight: "58px",
                                                                fontSize: '22px'
                                                            }}
                                                            onClick={() => handleRemovePerk(4)}
                                                            disabled={activeTab === 0}
                                                        ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                    ) : (
                                                        <button
                                                            className='btn add-kit-button p-2 col-md-3  col-12'
                                                            style={{ minHeight: "58px" }}
                                                            data-bs-target="#CoWorkingSpaceModelToggle"
                                                            data-bs-toggle="modal"
                                                            disabled={activeTab === 0}
                                                        // onClick={() => handleAddPerk(4)}
                                                        ><img src={PlusIconForAddKitButton} className='img-fluid' /> Add Space</button>
                                                    )
                                                }
                                            </div>
                                            <div className='col-md-8 mb-2'>
                                                <ul>
                                                    {posReqOtherData.perks.includes(PERKS.CO_WORKING_SEAT) &&
                                                        <li>
                                                            <h5 className='owow-savings'>Dedicated Work Station</h5>
                                                            <span>$99/Month Per desk</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.CO_WORKING_CABIN) &&
                                                        <li>
                                                            <h5 className='owow-savings'>Private Cabins</h5>
                                                            <span>$149/Month Per desk</span>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className='owow-savings'>Offer your teammates access to nearby coworking spaces in their city to keep them engaged.</h5>
                                    </div>
                                )}

                            {
                                (activeTab === 3 ||
                                    (activeTab === 0 &&
                                        (
                                            posReqOtherData.perks.includes(PERKS.GYM_ACCESS_BASIC) ||
                                            posReqOtherData.perks.includes(PERKS.GYM_ACCESS_STANDARD) ||
                                            posReqOtherData.perks.includes(PERKS.GYM_ACCESS_PREMIUM)
                                        ))
                                ) && (
                                    <div className='card mt-4 p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF" }}>
                                        <div className='row'>
                                            <div className='col-md-8 mb-2'>
                                                <h4><img src={GymIcon} className='img-fluid' /> Gym Access</h4>
                                            </div>
                                            <div className='col-md-4 mb-2 text-end'>
                                                {
                                                    (
                                                        posReqOtherData.perks.includes(PERKS.GYM_ACCESS_BASIC) ||
                                                        posReqOtherData.perks.includes(PERKS.GYM_ACCESS_STANDARD) ||
                                                        posReqOtherData.perks.includes(PERKS.GYM_ACCESS_PREMIUM)
                                                    ) ? (
                                                        <button
                                                            className='delete-button p-2 col-md-3 col-12'
                                                            style={{
                                                                minHeight: "58px",
                                                                fontSize: '22px'
                                                            }}
                                                            onClick={() => handleRemovePerk(5)}
                                                            disabled={activeTab === 0}
                                                        ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                    ) : (
                                                        <button
                                                            className='btn add-kit-button p-2 col-md-3  col-12'
                                                            style={{ minHeight: "58px" }}
                                                            data-bs-target="#GymAccessModelToggle"
                                                            data-bs-toggle="modal"
                                                            // onClick={() => handleAddPerk(5)}
                                                            disabled={activeTab === 0}
                                                        ><img src={PlusIconForAddKitButton} className='img-fluid' />  Add Access</button>
                                                    )
                                                }
                                            </div>
                                            <div className='col-md-8 mb-2'>
                                                <ul>
                                                    {posReqOtherData.perks.includes(PERKS.GYM_ACCESS_BASIC) &&
                                                        <li>
                                                            <h5 className='owow-savings'>3 Months</h5>
                                                            <span>Your teammate will get access to cult.fit pass for 3 months</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.GYM_ACCESS_STANDARD) &&
                                                        <li>
                                                            <h5 className='owow-savings'>6 Months</h5>
                                                            <span>Your teammate will get access to cult.fit pass for 6 months</span>
                                                        </li>
                                                    }
                                                    {posReqOtherData.perks.includes(PERKS.GYM_ACCESS_PREMIUM) &&
                                                        <li>
                                                            <h5 className='owow-savings'>12 Months</h5>
                                                            <span>Your teammate will get access to cult.fit pass for 12 months</span>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className='owow-savings'>Show you care by providing your teammates with access to the nearest gym subscription</h5>
                                    </div>
                                )}

                            {/* {(activeTab === 4 || (activeTab === 0 && posReqOtherData.perks.includes(6))) && (
                                <div className='card mt-4 p-4 perks-employee' style={{ borderRadius: "27px", background: "#FFF" }}>
                                    <div className='row'>
                                        <div className='col-md-6 mb-2'>
                                            <h4><img src={BackGroundCheckIcon} className='img-fluid' /> Background check</h4>
                                        </div>

                                        <div className='col-md-6 mb-2 text-end'>
                                            {
                                                posReqOtherData.perks.includes(PERKS.BACKGROUND_CHECK) ? (
                                                    <button
                                                        className='delete-button p-2 col-md-3 col-12'
                                                        style={{
                                                             minHeight: "58px",
                                                            fontSize: '22px'
                                                        }}
                                                        onClick={() => handleRemovePerk(6)}
                                                    ><img src={DeleteIcon} className='img-fluid' /> Remove</button>
                                                ) : (
                                                    <button
                                                        className='btn add-kit-button p-2 col-md-3  col-12'
                                                        style={{  minHeight: "58px" }}
                                                        onClick={() => handleAddPerk(6)}
                                                    >Add Kit</button>
                                                )
                                            }

                                        </div>
                                    </div>
                                    <h5 className='owow-savings'>Lorem ipsum dolor sit amet consectetur. In porttitor egestas eros egestas pellentesque fermentum. Eu nec ut scelerisque pretium condimentum risus sagittis.</h5>
                                </div>
                            )} */}

                            {activeTab === 3 && (
                                <div className='mt-4 d-flex justify-content-end mobile-two-buttons'>
                                    <button
                                        className='btn create-account-btn p-3 '
                                        style={{ minWidth: "159px" }}
                                        onClick={() => setActiveTab(0)}
                                    > Next</button>
                                    <button
                                        className='btn btn-outline create-account-btn-outline p-3 '
                                        style={{ minWidth: "159px" }}
                                        onClick={() => setActiveTab(2)}
                                    >Cancel</button>
                                </div>
                            )}
                        </>
                    )}
                    {activeTab === 0 && (<>
                        <div className='d-flex mt-4 mb-4 justify-content-end align-items-center flex-wrap'>
                            <div className='d-flex' style={{ gap: "12px" }}>
                                <button className='btn create-account-btn p-3' style={{ minWidth: "159px", maxHeight: "70px" }} onClick={handlePosReqFinalSubmit} disabled={loading} > {loading ? `Creating ${currentReq} Position...` : `Submit (${selectedPositions.length})`} </button>
                                <button className='btn btn-outline create-account-btn-outline p-3' style={{ minWidth: "159px", maxHeight: "70px" }} disabled={loading} onClick={() => setActiveTab(3)} >Cancel </button>
                            </div>
                        </div>
                        {/* <div className='mb-4'>
                            <h4 onClick={() => setPosReqOtherData({ ...posReqOtherData, isPostConsent: !posReqOtherData.isPostConsent })} style={{ cursor: 'pointer' }} >
                            <input type='checkbox' className='form-check-input cst-terms-con-accept' id='flexCheckDefault' checked={posReqOtherData.isPostConsent} />&nbsp;We may post this job on our platform to accept terms and conditions</h4>
                        </div> */}
                    </>
                    )}
                </div >
            </div >

            <button type='button' id="crop-button" className="btn d-none" data-bs-target="#cropperModal" data-bs-toggle="modal"></button>
            <div className="modal fade" id="cropperModal" aria-hidden="true" aria-labelledby="cropperModalLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="cropperModalLabel">Crop Image</h3>
                            <button id='closeCropperModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* {imageSrc && ( */}
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-12">
                                    <Cropper
                                        aspectRatio={1}
                                        ref={cropperRef}
                                        src={imageSrc || ''}
                                        style={{ height: 400, width: '100%' }}
                                    />
                                </div>
                            </div>

                        </div>
                        {/* )} */}
                        {/* <div className='d-flex my-2 justify-content-center' style={{ gap: "16px" }}> */}
                        <div className='d-flex my-2 justify-content-center flex-md-row flex-column' style={{ gap: "16px" }}>

                            <button
                                className='btn social-media-btn'
                                type='button'
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ minWidth: "326px" }}>
                                Cancel
                            </button>
                            <button
                                className='btn create-account-btn'
                                type='submit'
                                style={{ minWidth: "326px" }}
                                onClick={handleCrop}
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Models */}
            <HealthCareModel setPosReqOtherData={setPosReqOtherData} />
            <EquipmentModel setPosReqOtherData={setPosReqOtherData} />
            <CoWorkingSpaceModel setPosReqOtherData={setPosReqOtherData} />
            <GymAccessModel setPosReqOtherData={setPosReqOtherData} />

        </>
    )
}

export default HireTalents;
