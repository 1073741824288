import { useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent'
import FinancialMenImage from '../../Assets/Images/finaancialMen.png'
import { BsPlusCircle } from "react-icons/bs"
import UplodImage from '../../Assets/Images/uplodImage.png'
import DefaultImage from '../../Assets/Icons/default.png';
import IditIcon from '../../Assets/Icons/EditIcon.svg'
import SettingImg from '../../Assets/Icons/setting.svg'
import RightImg from '../../Assets/Icons/rightArrow.svg'
import CreditCardIcon from '../../Assets/Icons/creditCardIcon.svg'
import HandThumbIcon from '../../Assets/Icons/handThumbIcon.svg'
import RegtangleImg from '../../Assets/Images/CcRegtangle.svg'
import BankAccountIcon from '../../Assets/Icons/bankAccountIcon.svg'

const PaymentsAndRefundsPage = () => {
    const [flag, setflag] = useState(false)
    const [isEditing, setEditing] = useState<boolean>(false);

    const [view, setView] = useState<number>(1)
    const [activeTab, setActiveTab] = useState("FinancialPOC")
    const buttonStyle = {
        minWidth: "285px",
        border: "none",
        fontSize: "18px"
    }

    const CircleIcon = {
        color: "white",
        fontSize: "18px"
    }
    const handleFinancialPOC = () => {
        setView(1);
        setActiveTab("FinancialPOC")
    };

    const handlePayments = () => {
        setView(2);
        setActiveTab("Payments")

    };

    const handleRefunds = () => {
        setView(3);
        setActiveTab("Refunds")

    };
    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                         <NotificationComponent/>
                        <h2>Payments and refunds</h2>
                        <p>This dashboard will help you in analysing your activities in various aspects.</p>
                    </div>
                    <div className='mt-5 mb-5'>
                        <ul className="my-account-tabs-list">
                            <li><a href="#" className={`${activeTab == "FinancialPOC" && 'active'}`} onClick={handleFinancialPOC}>Financial POC</a></li>
                            <li><a href="#" className={`${activeTab == "Payments" && 'active'}`} onClick={handlePayments}>Payments</a></li>
                            <li><a href="#" className={`${activeTab == "Refunds" && 'active'}`} onClick={handleRefunds}>Refunds</a></li>
                        </ul>
                        {activeTab === "FinancialPOC" && <>
                            {!flag ? <>
                                <div className='text-center finantial-information'>
                                    <img src={FinancialMenImage} className='img-fluid' />
                                    <h2 className='mt-5'>No Financial POC added</h2>
                                    <div className='d-flex justify-content-center'>
                                        <h4 className='col-md-6 col-12 cst-secondary-color'>Please add a financial POC to get into this method. This person will be managing all finances.</h4>
                                    </div>
                                    <button id="add-collaborator" className="create-account-btn p-3" style={buttonStyle} onClick={() => setflag(true)}>
                                        <BsPlusCircle style={CircleIcon} /> Add Financial POC</button>
                                </div>
                            </> :
                                <>

                                    {!isEditing &&
                                        <div className='container-fluid'>
                                            <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                                <div className="d-flex align-items-center flex-wrap" style={{ gap: "16px" }}>
                                                    <div>
                                                        <img src={DefaultImage} alt='image' className='img-fluid' style={{height:'80px'}} />
                                                    </div>
                                                    <div>
                                                        <h1>Satyam Tiwari</h1>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="d-flex my-4" style={{ gap: "16px" }}>
                                                        <div className="text-end cst-ptr" onClick={() => setEditing(true)}>
                                                            <img src={IditIcon} className='img-fluid' alt='idit-icon' />&nbsp;<span style={{ color: "#492A99", fontSize: "22px", fontWeight: "500" }}>Edit</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='card font-size-small-div p-3' style={{
                                                borderRadius: "36px",
                                                background: "rgb(255, 255, 255)",
                                                border: "none",
                                            }}>
                                                <h2>Overview</h2>
                                                <div className="d-flex align-items-center flex-wrap m-3" style={{ gap: "24px" }}>
                                                    <div>
                                                        <label className='overview-sec-label'>Mobile number</label>
                                                        <h4>+91 9354210744</h4>
                                                    </div>
                                                    <div>
                                                        <label className='overview-sec-label'>Email ID</label>
                                                        <h4>easy@gmail.cm</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div >
                                    }
                                    {isEditing &&
                                        <div className='container-fluid'>
                                            <form  >
                                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                                    <div className="d-flex align-items-center flex-wrap" style={{ gap: "16px" }}>
                                                        <div>
                                                            <img src={DefaultImage} alt='image' className='img-fluid'  />
                                                        </div>
                                                        <div>
                                                            <h1>Satyam Tiwari</h1>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex my-4" style={{ gap: "16px" }}>
                                                            <div className='d-flex' style={{ gap: "16px" }}>
                                                                <button className='btn create-account-btn cst-save-das' type='submit'>Add</button>
                                                                <button
                                                                    className='btn social-media-btn cst-save-das'
                                                                    type='button'
                                                                    onClick={() => setEditing(false)}
                                                                >Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card cst-account-card' style={{ borderRadius: "36px", background: "#FFF", border: "none" }}>
                                                    <div className='row'>
                                                        <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                                            <label>Full name</label>
                                                            <input type="text" name='name' className="form-control custom-input" placeholder="Enter here" value="Satyam Tiwari" />
                                                        </div>
                                                        <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                                            <label>Email ID</label>
                                                            <input type='email' id='email' className="form-control custom-input" value="satyamtiwari.27@gmail.com" />
                                                        </div>
                                                        <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                                            <label>Mobile number</label>
                                                            <input type="text" id='phone' className="form-control custom-input" placeholder="Enter here" value="9354210744" />
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    }
                                </>
                            }
                        </>}

                        {activeTab === "Payments" && <>
                            <div className='container-fluid'>

                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <button className="button-container cst-ptr mt-4">
                                            <img src={CreditCardIcon} alt='setting' className='img-fluid' />
                                            <div className="label-container ">
                                                <label className="change-password cst-ptr" >Credit cards</label>
                                                <label className="change-your-login cst-ptr" >Add credit card and relax. We will do the rest.</label>
                                            </div>
                                            <img src={RightImg} alt='arrow' />
                                        </button>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <button className="button-container cst-ptr mt-4">
                                            <img src={HandThumbIcon} alt='setting' className='img-fluid' />
                                            <div className="label-container ">
                                                <label className="change-password cst-ptr" >Manual transfer</label>
                                                <label className="change-your-login cst-ptr" >If you want to do transaction by your choice.</label>
                                            </div>
                                            <img src={RightImg} alt='arrow' />
                                        </button>
                                    </div>
                                </div>
                                <div className='mt-5 text-center'>
                                    <img src={RegtangleImg} alt='Regtangle' />
                                    <div className='d-flex justify-content-center mt-5'>
                                        <h4 className='owow-savings cst-secondary-color'>You don’t have any saved credit card. Add and save credit card to move further.</h4>
                                    </div>
                                </div>
                                <div className='card cst-account-card mt-4' style={{ borderRadius: "36px", background: "#FFF", border: "none" }}>
                                    <h2>Adding credit card</h2>
                                    <div className='row mt-2'>
                                        <div className='col-md-4 mb-4'>
                                            <label>Name on credit card</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter Here' />
                                        </div>
                                        <div className='col-md-4 mb-4'>
                                            <label>Card number</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter Here' />
                                        </div>
                                        <div className='col-md-2 mb-4'>
                                            <label>Expiry month</label>
                                            <input type='text' className='form-control custom-input' placeholder='MM/YY' />
                                        </div>
                                        <div className='col-md-2 mb-4'>
                                            <label>CVV</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter here' />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mobile-flex-col-cst mt-4' style={{ gap: "16px" }}>
                                    <button className='btn create-account-btn cst-save-das' type='submit'>Add</button>
                                    <button className='btn social-media-btn cst-save-das' type='button'>Cancel </button>
                                </div>
                            </div>
                        </>}


                        {activeTab === "Refunds" && <>
                            <div className='container-fluid'>

                                <div className='row'>
                                    <div className='col-md-6 col-12'>
                                        <button className="button-container cst-ptr mt-4">
                                            <img src={CreditCardIcon} alt='setting' className='img-fluid' />
                                            <div className="label-container ">
                                                <label className="change-password cst-ptr" >Credit cards / Debit cards</label>
                                                <label className="change-your-login cst-ptr" >Add credit card and relax. We will do the rest.</label>
                                            </div>
                                            <img src={RightImg} alt='arrow' />
                                        </button>
                                    </div>
                                    <div className='col-md-6 col-12'>
                                        <button className="button-container cst-ptr mt-4">
                                            <img src={BankAccountIcon} alt='setting' className='img-fluid' />
                                            <div className="label-container ">
                                                <label className="change-password cst-ptr" >Add bank account</label>
                                                <label className="change-your-login cst-ptr" >If you want to do transaction by your choice.</label>
                                            </div>
                                            <img src={RightImg} alt='arrow' />
                                        </button>
                                    </div>

                                </div>

                                <div className='card cst-account-card mt-4' style={{ borderRadius: "36px", background: "#FFF", border: "none" }}>
                                    <h2>Adding bank account</h2>
                                    <div className='row mt-2'>
                                        <div className='col-md-4 mb-4'>
                                            <label>Account holder name</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter Here' />
                                        </div>
                                        <div className='col-md-4 mb-4'>
                                            <label>Account number</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter Here' />
                                        </div>
                                        <div className='col-md-4 mb-4'>
                                            <label>Re-enter account number</label>
                                            <input type='text' className='form-control custom-input' placeholder='MM/YY' />
                                        </div>
                                        <div className='col-md-4 mb-4'>
                                            <label>IFSC code</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter here' />
                                        </div>
                                        <div className='col-md-8  mb-4'>
                                            <label>Branch address</label>
                                            <input type='text' className='form-control custom-input' placeholder='Enter here' />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end mobile-flex-col-cst mt-4' style={{ gap: "16px" }}>
                                    <button className='btn create-account-btn cst-save-das' type='submit'>Add</button>
                                    <button className='btn social-media-btn cst-save-das' type='button'>Cancel </button>
                                </div>
                            </div>
                        </>}
                    </div>
                </div >
            </div >
        </>
    )
}

export default PaymentsAndRefundsPage