import { useEffect, useRef, useState } from 'react'
import Select from 'react-select';

import { CompanyAddressDetails, CompanyDetails, imageAllowedFileTypes } from '../../types'
import NotificationComponent from '../../Components/NotificationComponent'
import DefaultImage from '../../Assets/Images/uploadImage.png';
import IditIcon from '../../Assets/Icons/EditIcon.svg'
import Helper from '../../utils/helper';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import Spinner from '../../Assets/Images/spinner.png';
import Cropper, { ReactCropperElement } from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { compress } from 'image-conversion';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CompanySize, IndustryTypes, addressCountries, canadianProvinces, companyTypes, countries, currencies, currencies2, customStyles, indianStates, usStates } from '../../utils/constant';

const CompanyGerenralInformationPage = () => {

    const { user } = useAuth();
    const [view, setView] = useState<number>(1)
    const [isEditing, setEditing] = useState<boolean>(false);

    const initValue: CompanyDetails = {
        customerId: 1,
        country: "",
        currency: "",
        name: "",
        type: "",
        registrationNumber: "",
        VAT: "",
        EIN: "",
        taxId: "",
        imageUrl: ''
    };
    const [company, setCompany] = useState<CompanyDetails>(initValue);

    const initialValue2: CompanyAddressDetails = {
        companyId: 1,
        country: '',
        state: '',
        city: '',
        address1: '',
        address2: '',
        zipCode: '',
        billingCountry: '',
        billingState: '',
        billingCity: '',
        billingAddress1: '',
        billingAddress2: '',
        billingZipCode: '',
    };

    const [address, setAddress] = useState<CompanyAddressDetails>(initialValue2);

    const [loading, setLoading] = useState<boolean>(true);
    const [isSameAddr, setIsSameAddr] = useState<boolean>(true);
    const helper = Helper.getInstance();
    const navigate = useNavigate();

    const cropperRef = useRef<ReactCropperElement>(null);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [isZip, setIsZip] = useState<boolean>(true);
    const [isZip2, setIsZip2] = useState<boolean>(true);


    useEffect(() => {
        if (user) getCompanyGI();
    }, [user]);

    const getCompanyGI = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.COMPANY_GI);
            const { data } = res_data.data;

            if (data) {
                setCompany(data)
                setAddress(data.address);
                if (data.address.billingCountry && data.address.billingCountry !== data.address.country) setIsSameAddr(false);
                else {
                    setIsSameAddr(true);
                    setAddress({
                        ...data.address,
                        billingCountry: data.address.country,
                        billingState: data.address.state,
                        billingCity: data.address.city,
                        billingZipCode: data.address.zipCode,
                        billingAddress1: data.address.address1,
                        billingAddress2: data.address.address2
                    });

                }
                setLoading(false);
            }
            else {
                setLoading(false);
                navigate('/employer-dashboard', { replace: true })
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const handleUpload = async (blobData: Blob) => {
        if (!blobData) return;

        try {
            setLoading2(true);

            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);
            const fileTypeArr = blobData.type.split("/");
            const name = `${fileID}.${fileTypeArr[1]}`;

            reqData.append('file', blobData, name);

            reqData.append('id', JSON.stringify(company.id));

            console.log(reqData);
            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.COMPANY_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            // const res_data = await axios.post(`http://localhost:3030${helper.POST.CUSTOMER_IMAGE_UPLOAD}`, reqData);
            const data = res_data.data;
            const imageUrl = data.data;

            if (imageUrl) {
                setCompany({ ...company, imageUrl });
                setTimeout(() => {
                    setLoading2(false);
                }, 100);
            } else {
                setLoading2(false);
            }
        } catch (error) {
            setLoading2(false);
            console.log(error);
            toast.error('Upload Image Failed');
        }
    };

    // Function to handle image selection
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result as string);
                setTimeout(() => {
                    document.getElementById('crop-button')?.click();
                }, 100);
            };

            reader.readAsDataURL(file);
        }
    };

    // Function to handle cropping
    const handleCrop = async () => {
        if (cropperRef.current) {
            // if (typeof cropperRef.current?.cropper !== "undefined") {
            // setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            handleCompress(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
            setTimeout(() => {
                document.getElementById('crop-button')?.click();
            }, 100);
            // }
        }
    };

    const dataURLtoBlob = (dataURL: string): Blob => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)![1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    };

    const handleCompress = async (data: any) => {
        if (data) {
            // console.log('Cropped Image URL:', croppedImage);

            const croppedBlob = dataURLtoBlob(data);
            const compressedImageData = await compress(croppedBlob, {
                quality: 0.8,
                maxWidth: 800,
                maxHeight: 800,
            });

            handleUpload(compressedImageData);
        }
    };

    const handleCompanyDetails = () => {
        setEditing(false);
        setView(1);

    };

    const handleCompanyAddress = () => {
        setEditing(false);
        setView(2);
    };

    const handleCompany = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        await updateCompany();
    };

    const updateCompany = async () => {
        try {
            const comData = {
                name: company.name,
                type: company.type,
                taxId: company.taxId,
                currency: company.currency,
                industry: company.industry,
                employeeSize: company.employeeSize,
                website: company.website,
                linkedInUrl: company.linkedInUrl,
                companyId: company.id
            };

            const res_data = await helper.putReq(helper.PUT.UPDATE_COMPANY, comData);
            const { data } = res_data.data;
            setLoading(false);

            if (data) {
                toast.success('Updated Successfully');
                setEditing(false);
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            toast.error('Update Details Failed');
            console.log(error);
        }
    };

    const handleAddress = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        if (!isZip) return toast.error('Invalid Zip Code');
        if (!isZip2) return toast.error('Invalid Business Address Zip Code');

        await updateAddress();
    };

    const updateAddress = async () => {
        try {
            const comData = { ...address, companyAddressId: address.id };
            delete comData.id;
            const res_data = await helper.putReq(helper.PUT.UPDATE_COMPANY_ADDRESS, comData);
            const { data } = res_data.data;
            setLoading(false);

            if (data) {
                toast.success('Updated Successfully');
                setEditing(false);
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            toast.error('Update Details Failed');
            console.log(error);
        }
    };

    const getStateArray = () => {
        if (!address || !address.country) return usStates;
        else if (address.country == 'CA') return canadianProvinces;
        else if (address.country == 'IN') return indianStates;
        else return usStates;
    }

    const getBillStateArray = () => {
        if (!address || !address.billingCountry) return usStates;
        else if (address.billingCountry == 'CA') return canadianProvinces;
        else if (address.billingCountry == 'IN') return indianStates;
        else return usStates;
    };

    const handleZip = async (val: string) => {
        const postalCodeRegex: RegExp = /^[0-9a-zA-Z\s]+$/;
        const isZip222 = postalCodeRegex.test(val);
        setIsZip(isZip222);

        setAddress(({
            ...address,
            zipCode: val
        }))
    };

    const handleBillZip = async (val: string) => {
        const postalCodeRegex: RegExp = /^[0-9a-zA-Z\s]+$/;
        const isZip22 = postalCodeRegex.test(val);
        setIsZip2(isZip22);

        setAddress(({
            ...address,
            billingZipCode: val
        }))
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const handleSave = async () => {
        if (view === 1) {
            if (company.website && webErr) return toast.error(webErr);
            if (company.linkedInUrl && linkErr) return toast.error(linkErr);
            setLoading(true);
            await updateCompany();
        } else if (view === 2) {
            setLoading(true);
            if (!isZip) return toast.error('Invalid Zip Code');
            if (!isZip2) return toast.error('Invalid Business Address Zip Code');
            await updateAddress();
        }
    };

    const handleCheck = () => {
        setIsSameAddr(!isSameAddr);
        if (isSameAddr) {
            setAddress({
                ...address,
                billingCountry: '',
                billingState: '',
                billingCity: '',
                billingZipCode: '',
                billingAddress1: '',
                billingAddress2: ''
            })
        } else {
            setAddress({
                ...address,
                billingCountry: address.country,
                billingState: address.state,
                billingCity: address.city,
                billingZipCode: address.zipCode,
                billingAddress1: address.address1,
                billingAddress2: address.address2
            })
        }
    };

    const [linkErr, setLinkErr] = useState<string | null>(null);
    const [webErr, setWebErr] = useState<string | null>(null);


    const handleWebsite = (val: string) => {
        const urlRegex = /^(?:(?:https?|ftp):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
        const isValidUrl = urlRegex.test(val);

        if (!isValidUrl) setWebErr('Invalid Website URL');
        else setWebErr(null);

        setCompany({ ...company, website: val });
    };

    const handleLinkedIn = (val: string) => {
        const linkedinCompanyPageRegex = /^(https:\/\/www\.linkedin\.com\/company\/[a-zA-Z0-9-]+)\/?$/;
        const isValidLinkedInUrl = linkedinCompanyPageRegex.test(val);

        if (!isValidLinkedInUrl) setLinkErr('Invalid LinkedIn URL');
        else setLinkErr(null);

        setCompany({ ...company, linkedInUrl: val });

    }

    const handleCancel = () => {
        setEditing(false);
        getCompanyGI();
    };

    const handleCompleteItNow = () => {
        setView(1);
        setEditing(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    if (!company.id) return <>Loading...</>;

    return (
        <>
            <div id="content" className='pt-5'>
                <div className='top-navigation mb-5'>
                    <NotificationComponent />
                    <h2>General Information</h2>
                    <p>Ensure smooth verification and onboarding by updating your company details and address.</p>
                </div>
                <div className='mt-5'>
                    <ul className="my-account-tabs-list">
                        <li><a href="#" className={`${view === 1 && 'active'}`} onClick={handleCompanyDetails}>Company Details</a></li>
                        <li><a href="#" className={`${view === 2 && 'active'}`} onClick={handleCompanyAddress}>Company Address</a></li>
                    </ul>
                </div>

                <div className='container-fluid'>
                    <div className='d-flex align-items-center justify-content-between flex-wrap'>
                        <div className='d-flex align-items-center flex-wrap' style={{ gap: "16px" }}>
                            <div>
                                {loading2 ? (
                                    <img
                                        alt="not found"
                                        className='img-fluid'
                                        style={{ width: '80px', borderRadius: "50%" }}
                                        src={Spinner}
                                    />
                                ) : (
                                    <img
                                        alt="not found"
                                        className='img-fluid'
                                        style={{ width: '80px', cursor: 'pointer', borderRadius: "50%" }}
                                        src={company.imageUrl || DefaultImage}
                                        onClick={() => document.getElementById("imageInput")?.click()}
                                    />
                                )}

                                <input
                                    type="file"
                                    name="myImage"
                                    id='imageInput'
                                    hidden
                                    accept={imageAllowedFileTypes}
                                    onChange={handleImageChange}
                                />
                            </div>
                            <div>
                                <h1>{company.name}</h1>
                                <p className='text-black'>{getOptionLabel(company.country, countries)}</p>
                            </div>
                        </div>
                        <div>
                            <div className='d-flex my-4' style={{ gap: "16px" }}>
                                {isEditing ? (
                                    <>
                                        <button
                                            className='btn create-account-btn cst-save-das'
                                            type='button'
                                            onClick={handleSave}
                                            disabled={loading}
                                        >{loading ? 'Saving...' : 'Save'}
                                        </button>
                                        <button
                                            className='btn social-media-btn cst-save-das'
                                            type='button'
                                            onClick={() => handleCancel()}
                                            disabled={loading}
                                        >Cancel
                                        </button>

                                    </>
                                ) : (
                                    <div className='text-end cst-ptr' onClick={() => setEditing(true)}>
                                        <img
                                            src={IditIcon}
                                            className='img-fluid'
                                            alt='edit-icon' />
                                        &nbsp;
                                        <span
                                            style={{ color: "#492A99", fontSize: "22px", fontWeight: "500" }}
                                        >Edit
                                        </span>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    {view === 1 && <>
                        {!isEditing ? (
                            <>
                                <div className='card general-company-details-cst p-3 mt-4 ' style={{
                                    borderRadius: "36px",
                                    background: "rgb(255, 255, 255)",
                                    border: "none",
                                }}>
                                    <div className='row m-3'>
                                        <h2>Company Overview</h2>
                                        <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Company type</label>
                                            <h4>{getOptionLabel(company.type, companyTypes) || company.type}</h4>
                                        </div>
                                        {company.taxId && <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Company tax ID</label>
                                            <h4>{company.taxId}</h4>
                                        </div>}
                                        {company.EIN && <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Company EIN</label>
                                            <h4>{company.EIN}</h4>
                                        </div>}
                                        {company.registrationNumber && <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Company Registration Number</label>
                                            <h4>{company.registrationNumber}</h4>
                                        </div>}
                                        {company.VAT && <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Company VAT</label>
                                            <h4>{company.VAT}</h4>
                                        </div>}
                                        <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Desired currency</label>
                                            <h4>{getOptionLabel(company.currency, currencies2)}</h4>
                                        </div>
                                        <div className='col-12 col-md-3 mt-4'>
                                            <label className='overview-sec-label'>Industry</label>
                                            <h4>{getOptionLabel(company.industry || '', IndustryTypes) || "-"}</h4>
                                        </div>
                                        <hr className='mt-4 mb-4' />
                                        <h2>Additional information</h2>
                                        <div className='col-sm-4 mt-4'>
                                            <label className='overview-sec-label'>Employee size</label>
                                            <h4>{getOptionLabel(company.employeeSize || '', CompanySize) || "-"}</h4>
                                        </div>
                                        <div className='col-sm-4 mt-4'>
                                            <label className='overview-sec-label'>Company website</label>
                                            <h4>{company.website ? <a href={company.website} target='_blank'>{company.website}</a> : '-'}</h4>
                                        </div>
                                        <div className='col-sm-4 mt-4'>
                                            <label className='overview-sec-label'>Company linkedin URL</label>
                                            <h4>{company.linkedInUrl ? <a href={company.linkedInUrl} target='_blank'>{company.linkedInUrl}</a> : '-'}</h4>
                                        </div>

                                    </div>
                                </div>
                                {(user?.regCompPerc || 0) + 20 !== 100 && <div className="card card-cst-bg-profile pt-4 pb-4 mt-4">
                                    <div className="d-flex flex-wrap align-items-center" style={{ justifyContent: "space-around" }}>
                                        <div>
                                            <div className="ui-widgets">
                                                <h4 className="ui-values">{(user?.regCompPerc || 0) + 20}</h4>
                                            </div>
                                        </div>
                                        <div className="text-white">
                                            <h4>Profile {(user?.regCompPerc || 0) + 20}% completed</h4>
                                            <h5>Add company logo, website, LinkedIn and other information to<br /> complete your profile.</h5>
                                        </div>
                                        <div>
                                            <button className="btn btn-complete-it-now px-4"
                                                onClick={() => handleCompleteItNow()}
                                            >Complete it now
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </>
                        ) : (
                            <form name='companyForm' onSubmit={handleCompany}>
                                <div className='card cst-account-card mt-4'>
                                    <div className='row'>
                                        <h2 className='mb-4'>Overview</h2>
                                        <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                            <label>Company legal name</label>
                                            <input
                                                type="text"
                                                name='name'
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={company.name}
                                                onChange={(ev) => setCompany({ ...company, name: ev.target.value || '' })}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label>Company type</label>
                                            <Select
                                                options={companyTypes}
                                                value={companyTypes.filter((ct) => ct.value == company.type)[0]}
                                                onChange={(ev) => setCompany({ ...company, type: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                            />
                                        </div>
                                        {company.taxId && <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                            <label>Company tax ID</label>
                                            <input
                                                type="text"
                                                id='phone'
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={company.taxId || ''}
                                                onChange={(ev) => setCompany({ ...company, taxId: ev.target.value })}
                                            />
                                        </div>}
                                        {company.EIN && <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                            <label>Company EIN</label>
                                            <input
                                                type="text"
                                                id='phone'
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={company.EIN || ''}
                                                onChange={(ev) => setCompany({ ...company, EIN: ev.target.value })}
                                            />
                                        </div>}
                                        {company.registrationNumber && <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                            <label>Company Registration Number</label>
                                            <input
                                                type="text"
                                                id='phone'
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={company.registrationNumber || ''}
                                                onChange={(ev) => setCompany({ ...company, registrationNumber: ev.target.value })}
                                            />
                                        </div>}
                                        {company.VAT && <div className="col-12 col-md-4 input-group-lg margin-fix-bottam">
                                            <label>Company VAT</label>
                                            <input
                                                type="text"
                                                id='phone'
                                                className="form-control custom-input"
                                                placeholder="Enter here"
                                                value={company.VAT || ''}
                                                onChange={(ev) => setCompany({ ...company, VAT: ev.target.value })}
                                            />
                                        </div>}
                                        <div className="col-12 col-md-4">
                                            <label>Desired currency</label>
                                            <Select
                                                options={currencies}
                                                value={currencies.filter((ct) => ct.value == company.currency)[0]}
                                                onChange={(ev) => setCompany({ ...company, currency: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label>Industry</label>
                                            <Select
                                                options={IndustryTypes}
                                                value={IndustryTypes.filter((ct) => ct.value == company.industry)[0]}
                                                onChange={(ev) => setCompany({ ...company, industry: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                            />
                                        </div>

                                        <hr />
                                        <h2 className='mb-4'>Additional information</h2>
                                        <div className="col-12 col-md-4  margin-fix-bottam">
                                            <label>Employee size</label>
                                            <Select
                                                options={CompanySize}
                                                value={CompanySize.filter((ct) => ct.value == company.employeeSize)[0]}
                                                onChange={(ev) => setCompany({ ...company, employeeSize: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                            />

                                        </div>
                                        <div className="col-12 col-md-4  margin-fix-bottam">
                                            <label>Company Website</label>
                                            <input
                                                type="text"
                                                className="form-control custom-input"
                                                placeholder="www.example.com"
                                                value={company.website || ''}
                                                onChange={(ev) => handleWebsite(ev.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4  margin-fix-bottam">
                                            <label>Company linkedin URL</label>
                                            <input
                                                type="text"
                                                className="form-control custom-input"
                                                placeholder="https://www.linkedin.com/company/username"
                                                value={company.linkedInUrl || ''}
                                                onChange={(ev) => handleLinkedIn(ev.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    </>}

                    {view === 2 && <>
                        {!isEditing ? (
                            <div className='card general-company-details-cst  p-3 mt-4' style={{
                                borderRadius: "36px",
                                background: "rgb(255, 255, 255)",
                                border: "none",
                            }}>
                                <div className='row m-3'>
                                    <div className='d-flex justify-content-between flex-wrap mb-4'>
                                        <h2>Corporate Address</h2>
                                        <h4 className='cst-primary-color' style={{ fontWeight: "600" }}>
                                            {isSameAddr ? 'Using same address as billing address' : 'Different billing address'}
                                        </h4>
                                    </div>
                                    <div className='col-sm-3 mt-4'>
                                        <label className='overview-sec-label'>Country</label>
                                        <h4>{getOptionLabel(address.country, countries)}</h4>
                                    </div>
                                    <div className='col-sm-3 mt-4'>
                                        <label className='overview-sec-label'>State</label>
                                        <h4>{getOptionLabel(address.state, getStateArray())}</h4>
                                    </div>
                                    <div className='col-sm-3 mt-4'>
                                        <label className='overview-sec-label'>City</label>
                                        <h4>{address.city}</h4>
                                    </div>
                                    <div className='col-sm-3 mt-4'>
                                        <label className='overview-sec-label'>Postal code</label>
                                        <h4>{address.zipCode}</h4>
                                    </div>
                                    <div className='col-sm-6 mt-4'>
                                        <label className='overview-sec-label'>Address line 1</label>
                                        <h4>{address.address1}</h4>
                                    </div>
                                    <div className='col-sm-6 mt-4'>
                                        <label className='overview-sec-label'>Address line 2</label>
                                        <h4>{address.address2}</h4>
                                    </div>

                                </div>
                                {!isSameAddr && (
                                    <div className='row m-3'>
                                        <div className='d-flex justify-content-between flex-wrap mb-4'>
                                            <h2>Billing Address</h2>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <label className='overview-sec-label'>Billing Country</label>
                                            <h4>{getOptionLabel(address.billingCountry || '', countries)}</h4>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <label className='overview-sec-label'>Billing State</label>
                                            <h4>{getOptionLabel(address.billingState || '', getStateArray())}</h4>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <label className='overview-sec-label'>Billing City</label>
                                            <h4>{address.billingCity}</h4>
                                        </div>
                                        <div className='col-sm-3 mt-4'>
                                            <label className='overview-sec-label'>Billing Postal code</label>
                                            <h4>{address.billingZipCode}</h4>
                                        </div>
                                        <div className='col-sm-6 mt-4'>
                                            <label className='overview-sec-label'>Billing Address line 1</label>
                                            <h4>{address.billingAddress1}</h4>
                                        </div>
                                        <div className='col-sm-6 mt-4'>
                                            <label className='overview-sec-label'>Billing Address line 2</label>
                                            <h4>{address.billingAddress2}</h4>
                                        </div>

                                    </div>
                                )}
                            </div>
                        ) : (
                            <form name='companyAddressForm' onSubmit={handleAddress}>
                                <div className='card cst-account-card mt-4'>
                                    <div className='row'>
                                        <div className='d-flex justify-content-between flex-wrap mb-4 align-items-center'>
                                            <h2>Address</h2>
                                            <div className="d-flex justify-content-between align-items-center form-check" >
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="flexCheckDefault"
                                                    checked={isSameAddr}
                                                    onChange={() => handleCheck()}
                                                />
                                                <p className="text-black checkbox-text">&nbsp;Use same address as billing address</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label>Country</label>
                                            <Select
                                                styles={customStyles}
                                                className="mb-4"
                                                options={addressCountries}
                                                value={addressCountries.filter((ct) => ct.value == address?.country)[0]}
                                                onChange={(ev) => setAddress({ ...address, country: ev?.value || '' })}
                                                required
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label>State</label>
                                            <Select
                                                options={getStateArray()}
                                                value={getStateArray().filter((ct) => ct.value == address?.state)[0]}
                                                onChange={(ev) => setAddress({ ...address, state: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-12 col-md-3 mb-4">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                value={address.city}
                                                onChange={(ev) => setAddress({ ...address, city: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                required
                                            />
                                        </div>

                                        <div className="col-12 col-md-3 mb-4">
                                            <label>Postal Code</label>
                                            <input
                                                type="number"
                                                value={address.zipCode}
                                                onChange={(ev) => handleZip(ev.target.value)}
                                                className="form-control custom-input"
                                                placeholder="e.g., 12345"
                                                required
                                            />
                                            {!isZip && <p style={{ color: 'red', fontSize: 'medium' }}>Invalid Postal Code/Zip Code </p>}
                                        </div>

                                        <div className="col-12 col-md-6 mb-4">
                                            <label>Address line 1</label>
                                            <input
                                                type="text"
                                                value={address.address1}
                                                onChange={(ev) => setAddress({ ...address, address1: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Street address (e.g., 123 Main St)"
                                                required
                                            />
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <label>Address line 2</label>
                                            <input
                                                type="text"
                                                value={address.address2}
                                                onChange={(ev) => setAddress({ ...address, address2: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                required
                                            />
                                        </div>


                                        {/* {
                                            !isSameAddr && (
                                                <> */}
                                        <hr />
                                        <h2 className='mb-4'>Billing address</h2>
                                        <div className="col-12 col-md-3">
                                            <label>Country</label>
                                            <Select
                                                styles={customStyles}
                                                className="mb-4"
                                                options={addressCountries}
                                                value={addressCountries.filter((ct) => ct.value == address?.billingCountry)[0]}
                                                onChange={(ev) => setAddress({ ...address, billingCountry: ev?.value || '' })}
                                                placeholder="Select"
                                            />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label>State</label>
                                            <Select
                                                options={getBillStateArray()}
                                                value={getBillStateArray().filter((ct) => ct.value == address?.billingState)[0]}
                                                onChange={(ev) => setAddress({ ...address, billingState: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                            />

                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label>City</label>
                                            <input
                                                type="text"
                                                value={address.billingCity}
                                                onChange={(ev) => setAddress({ ...address, billingCity: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                required
                                            />

                                        </div>

                                        <div className="col-12 col-md-3">
                                            <label>Postal Code</label>
                                            <input
                                                type="number"
                                                value={address.billingZipCode}
                                                onChange={(ev) => handleBillZip(ev.target.value)}
                                                className="form-control custom-input"
                                                placeholder="e.g., 12345"
                                            />
                                            {!isZip2 && <p style={{ color: 'red', fontSize: 'medium' }}>Invalid Postal Code/Zip Code </p>}
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <label>Address line 1</label>
                                            <input
                                                type="text"
                                                value={address.billingAddress1}
                                                onChange={(ev) => setAddress({ ...address, billingAddress1: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Street address (e.g., 123 Main St)"
                                            />
                                        </div>

                                        <div className="col-12 col-md-6  margin-fix-bottam">
                                            <label>Address line 2</label>
                                            <input
                                                type="text"
                                                value={address.billingAddress2}
                                                onChange={(ev) => setAddress({ ...address, billingAddress2: ev.target.value })}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                            />
                                        </div>
                                        {/* </>
                                            )
                                        } */}
                                    </div>
                                </div>
                            </form>
                        )}
                    </>
                    }
                </div>
            </div>


            <button type='button' id="crop-button" className="btn d-none" data-bs-target="#cropperModal" data-bs-toggle="modal"></button>
            <div className="modal fade" id="cropperModal" aria-hidden="true" aria-labelledby="cropperModalLabel" tabIndex={-1}>
                <div className="modal-dialog modal-lg modal-dialog-centered" >
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="cropperModalLabel">Crop Image</h3>
                            <button id='closeCropperModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {/* {imageSrc && ( */}
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-12">
                                    <Cropper
                                        aspectRatio={1}
                                        ref={cropperRef}
                                        src={imageSrc || ''}
                                        style={{ height: 400, width: '100%' }}
                                    />
                                </div>
                            </div>

                        </div>
                        {/* )} */}
                        {/* <div className='d-flex my-2 justify-content-center' style={{ gap: "16px" }}> */}
                        <div className='d-flex my-2 justify-content-center flex-md-row flex-column' style={{ gap: "16px" }}>

                            <button
                                className='btn social-media-btn'
                                type='button'
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                style={{ minWidth: "326px" }}>
                                Cancel
                            </button>
                            <button
                                className='btn create-account-btn'
                                type='submit'
                                style={{ minWidth: "326px" }}
                                onClick={handleCrop}
                            >
                                Crop
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CompanyGerenralInformationPage