import { ObjectMap, OptionType } from "../types";

export const CLIENT_DEVICE_TYPE = {
    ANDROID: 1,
    IOS: 2,
    WEB: 3
};

export const IMAGE_URL_TYPE = {
    APPEND_BASE: 1,
    DIRECT_URL: 2
};

export const MEMBER_TYPE = {
    USER: 1,
    ADMIN: 2
};

export const LOGIN_TYPES = {
    GOOGLE: 1,
    LINKEDIN: 2
};

export const addressCountries = [
    { value: 'US', label: 'United States of America' },
    { value: 'IN', label: 'India' },
];

export const countries = [
    { value: 'US', label: 'United States of America' },
    { value: 'IN', label: 'India' },
];

export const companyTypes = [
    { value: 'Sole Proprietorship', label: 'Sole Proprietorship' },
    { value: 'Partnership', label: 'Partnership' },
    { value: 'Limited Liability Partnership (LLP)', label: 'Limited Liability Partnership (LLP)' },
    { value: 'C-Corporation', label: 'C-Corporation' },
    { value: 'S-Corporation', label: 'S-Corporation' },
    { value: 'Nonprofit Corporation', label: 'One Person Company (OPC)' },
    { value: 'Professional Corporation (PC)', label: 'Professional Corporation (PC)' },
    { value: 'Limited Partnership (LP)', label: 'Limited Partnership (LP)' },
    { value: 'Limited Liability Partnership (LLP)', label: 'Limited Liability Partnership (LLP)' },
    { value: 'Private Limited Company', label: 'Private Limited Company' },
    { value: 'Public Limited Company', label: 'Public Limited Company' },
    { value: 'One Person Company (OPC)', label: 'One Person Company (OPC)' },
    { value: 'Section 8 Company', label: 'Section 8 Company' },
    { value: 'Proprietary Firm', label: 'Proprietary Firm' },
    { value: 'Unregistered Individual', label: 'Unregistered Individual' },
    { value: 'Others', label: 'Others' },
];

export const currencies = [
    { value: 'USD', label: 'US$' },
    { value: 'INR', label: 'INR (₹)' },
];

export const currencies2 = [
    { value: 'USD', label: 'United States Dolar (USD)' },
    { value: 'INR', label: 'Indian Rupees (INR)' },
];

export const companyProofOptions = [
    { value: 'EIN', label: 'EIN' },
    { value: 'VAT', label: 'VAT' },
    { value: 'taxId', label: 'Tax ID' },
];

export const usStates = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
];

export const canadianProvinces = [
    { label: 'Alberta', value: 'AB' },
    { label: 'British Columbia', value: 'BC' },
    { label: 'Manitoba', value: 'MB' },
    { label: 'New Brunswick', value: 'NB' },
    { label: 'Newfoundland and Labrador', value: 'NL' },
    { label: 'Nova Scotia', value: 'NS' },
    { label: 'Ontario', value: 'ON' },
    { label: 'Prince Edward Island', value: 'PE' },
    { label: 'Quebec', value: 'QC' },
    { label: 'Saskatchewan', value: 'SK' },
    { label: 'Northwest Territories', value: 'NT' },
    { label: 'Nunavut', value: 'NU' },
    { label: 'Yukon', value: 'YT' }
];

export const indianStates = [
    { label: 'Andhra Pradesh', value: 'AP' },
    { label: 'Arunachal Pradesh', value: 'AR' },
    { label: 'Assam', value: 'AS' },
    { label: 'Bihar', value: 'BR' },
    { label: 'Chhattisgarh', value: 'CG' },
    { label: 'Goa', value: 'GA' },
    { label: 'Gujarat', value: 'GJ' },
    { label: 'Haryana', value: 'HR' },
    { label: 'Himachal Pradesh', value: 'HP' },
    { label: 'Jharkhand', value: 'JH' },
    { label: 'Karnataka', value: 'KA' },
    { label: 'Kerala', value: 'KL' },
    { label: 'Madhya Pradesh', value: 'MP' },
    { label: 'Maharashtra', value: 'MH' },
    { label: 'Manipur', value: 'MN' },
    { label: 'Meghalaya', value: 'ML' },
    { label: 'Mizoram', value: 'MZ' },
    { label: 'Nagaland', value: 'NL' },
    { label: 'Odisha', value: 'OD' },
    { label: 'Punjab', value: 'PB' },
    { label: 'Rajasthan', value: 'RJ' },
    { label: 'Sikkim', value: 'SK' },
    { label: 'Tamil Nadu', value: 'TN' },
    { label: 'Telangana', value: 'TG' },
    { label: 'Tripura', value: 'TR' },
    { label: 'Uttar Pradesh', value: 'UP' },
    { label: 'Uttarakhand', value: 'UT' },
    { label: 'West Bengal', value: 'WB' },
    { label: 'Andaman and Nicobar Islands', value: 'AN' },
    { label: 'Chandigarh', value: 'CH' },
    { label: 'Dadra and Nagar Haveli and Daman and Diu', value: 'DN' },
    { label: 'Lakshadweep', value: 'LD' },
    { label: 'Delhi', value: 'DL' },
    { label: 'Puducherry', value: 'PY' }];

export const IndustryTypes = [
    { label: "Technology", value: "tech" },
    { label: "Healthcare", value: "healthcare" },
    { label: "Finance", value: "finance" },
    { label: "Manufacturing", value: "manufacturing" },
    { label: "Retail", value: "retail" },
    { label: "Energy", value: "energy" },
    { label: "Telecommunications", value: "telecom" },
    { label: "Transportation", value: "transportation" },
    { label: "Hospitality", value: "hospitality" },
    { label: "Education", value: "education" },
    { label: "Others", value: "Others" }
];

export const roleTypes = [
    { value: 'Owner', label: 'Owner' },
    { value: 'Administratorr', label: 'Administrator' },
    { value: 'Hiring Manager', label: 'Hiring Manager' },
    { value: 'Billing and Payments', label: 'Billing and Payments' },
];

export const genders = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
    { value: 'Prefer not to say', label: 'Prefer not to say' },
];

export const LANGUAGE_FLUENCY_DATA = {
    1: "Fluent",
    2: "Intermediate",
    3: "Natve"
};

export const LANGUAGES = [
    "English",
    "Hindi",
];

export const OFFICE_POLICY_DATA = {
    1: "Onsite",
    2: "Work from Home",
    3: "Hybrid"
};

export const TICKET_STATUS_DATA: ObjectMap = {
    1: "Open",
    2: "In Review",
    3: "Resolved",
    4: "Rejected"
};

// export const skillsOptions = [
//     { value: 'React', label: 'React' },
//     { value: 'Node.js', label: 'Node.js' },
//     { value: 'Angular', label: 'Angular' },
//     { value: 'Swift (for IOS)', label: 'Swift (for IOS)' },
//     { value: 'React Native', label: 'React Native' },
//     { value: 'Android', label: 'Android' },
//     { value: 'Java', label: 'Java' },
//     { value: 'Ruby on Rails', label: 'Ruby on Rails' },
//     { value: 'Go', label: 'Go' },
//     { value: 'Vue.js', label: 'Vue.js' },
//     { value: 'PHP', label: 'PHP' },
//     { value: 'DevOps', label: 'DevOps' },
//     { value: 'Machine Learning', label: 'Machine Learning' },
//     { value: 'Data Engineering', label: 'Data Engineering' },
//     { value: 'Scala', label: 'Scala' },
//     { value: 'TypeScript', label: 'TypeScript' },
//     { value: 'Kotlin (Non-Android)', label: 'Kotlin (Non-Android)' },
//     { value: 'Adobe Analytics', label: 'Adobe Analytics' },
//     { value: 'Amazon S3', label: 'Amazon S3' },
//     { value: 'Databricks', label: 'Databricks' },
//     { value: 'FastAPI', label: 'FastAPI' },
//     { value: 'Kamailio', label: 'Kamailio' },
//     { value: 'AWS TimeStream', label: 'AWS TimeStream' },
//     { value: 'Azure Purview', label: 'Azure Purview' },
//     { value: 'AsyncIO', label: 'AsyncIO' },
//     { value: 'R programming language', label: 'R programming language' },
//     { value: 'SAS', label: 'SAS ML Data Science Language' },
//     { value: 'Technical Writing', label: 'Technical Writing' },
//     { value: 'DBT', label: 'Data build tool' },
//     { value: 'GeoPandas', label: 'GeoPandas' },
//     { value: 'Shapely', label: 'Shapely' },
//     { value: 'Snowflake', label: 'Snowflake' },
//     { value: 'Data Governance', label: 'Data Governance' },
//     { value: 'Data Management', label: 'Data Management' },
//     { value: 'HTML', label: 'HTML' },
//     { value: 'CSS', label: 'CSS' },
//     { value: 'JavaScript', label: 'JavaScript' },
//     { value: 'Java', label: 'Java' },
//     { value: 'Python', label: 'Python' },
//     { value: 'Ruby', label: 'Ruby' },
//     { value: 'C', label: 'C' },
//     { value: 'C++', label: 'C++' },
//     { value: 'C#', label: 'C#' },
//     { value: 'Swift', label: 'Swift' },
//     { value: 'Kotlin', label: 'Kotlin' },
//     { value: 'TypeScript', label: 'TypeScript' },
//     { value: 'PHP', label: 'PHP' },
//     { value: 'Go', label: 'Go' },
//     { value: 'Rust', label: 'Rust' },
//     { value: 'Scala', label: 'Scala' },
//     { value: 'Objective-C', label: 'Objective-C' },
//     { value: 'SQL', label: 'SQL' },
//     { value: 'Shell Scripting', label: 'Shell Scripting' },
//     { value: 'Bash', label: 'Bash' },
//     { value: 'MATLAB', label: 'MATLAB' },
//     { value: 'R', label: 'R' },
//     { value: 'Groovy', label: 'Groovy' },
//     { value: 'Dart', label: 'Dart' },
//     { value: 'Assembly Language', label: 'Assembly Language' },
//     { value: 'Haskell', label: 'Haskell' },
//     { value: 'Lua', label: 'Lua' },
//     { value: 'Perl', label: 'Perl' },
//     { value: 'F#', label: 'F#' },
//     { value: 'Clojure', label: 'Clojure' },
//     { value: 'Elm', label: 'Elm' },
//     { value: 'Fortran', label: 'Fortran' },
//     { value: 'Julia', label: 'Julia' },
//     { value: 'COBOL', label: 'COBOL' },
//     { value: 'Flutter', label: 'Flutter' },
//     { value: 'Express.js', label: 'Express.js' },
//     { value: 'Django', label: 'Django' },
//     { value: 'Flask', label: 'Flask' },
//     { value: 'Laravel', label: 'Laravel' },
//     { value: 'Spring Boot', label: 'Spring Boot' },
//     { value: 'GraphQL', label: 'GraphQL' },
//     { value: 'RESTful API', label: 'RESTful API' },
//     { value: 'JSON', label: 'JSON' },
//     { value: 'XML', label: 'XML' },
//     { value: 'AWS TimeStream', label: 'AWS TimeStream' },
//     { value: 'Azure Purview', label: 'Azure Purview' },
//     { value: 'AI', label: 'AI' },
//     { value: 'ML', label: 'ML' },
//     { value: 'LLM', label: 'LLM' },
//     { value: 'Computer Vision', label: 'Computer Vision' },
//     { value: 'NLP', label: 'NLP' },
//     { value: 'Deep Learning', label: 'Deep Learning' },
//     { value: 'AWS', label: 'AWS' },
//     { value: 'GCP', label: 'GCP' },
//     { value: 'Azure', label: 'Azure' },
//     { value: 'Pytorch', label: 'Pytorch' },
//     { value: 'Tensorflow', label: 'Tensorflow' },
//     { value: 'Kafka', label: 'Kafka' }
// ];


export const languagesOptions = [
    { value: 'English', label: 'English' },
    { value: 'Hindi', label: 'Hindi' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'Kannada', label: 'Kannada' },
    { value: 'Telugu', label: 'Telugu' },
    { value: 'Malayalam', label: 'Malayalam' },
];

export const EXPERIENCE_LEVEL = [
    // { value: 1, label: 'Junior (0-2 years)' },
    // { value: 2, label: 'Mid (3-5 years)' },
    // { value: 3, label: 'Senior (6-8 years)' },
    // { value: 4, label: 'Staff (9-10 years)' },

    { value: 1, label: 'Internship (0 yrs)' },
    { value: 2, label: 'Entry Level (0-1 yrs)' },
    { value: 3, label: 'Intermediate (1-3 yrs)' },
    { value: 4, label: 'Mid-senior (3-5 yrs)' },
    // { value: 5, label: 'Mid-senior (3-5 yrs)' },
    { value: 5, label: 'Senior (5-8 yrs)' },
    { value: 6, label: 'Senior+ (8-10 yrs)' },
    { value: 7, label: 'Staff (10+ yrs)' },

];


export const POSITION_TYPES = [
    { value: 1, label: 'Full-Time Jobs' },
    { value: 2, label: 'Contract Jobs'},
];

export const SALARY_CONST = [
    { value: 125000, label: '1,25,000' },
    { value: 1000, label: '1,000' },
    { value: 100000, label: '1,00,000' },
    { value: 50000, label: '50000' },

];

export const PER_MONTH_YEAR_CONST = [
    { value: 1, label: '/Mth' },
    { value: 2, label: '/Yr' },


];

export const TIMEZONES = [
    {
        label: "Any Time Zone",
        value: "Any Time Zone"
    },
    {
        label: "IST-UTC+5:30",
        value: "IST-UTC+5:30"
    },
    {
        label: "EST-UTC-5",
        value: "EST-UTC-5"
    },
    {
        label: "CST-UTC-6",
        value: "CST-UTC-6"
    },
    {
        label: "MST-UTC-7",
        value: "MST-UTC-7"
    },
    {
        label: "PST-UTC-8",
        value: "PST-UTC-8"
    }
];
export const OFFICE_POLICY = [
    { label: "Onsite", value: 1 },
    { label: "Remote", value: 2 },
    { label: "Hybrid", value: 3 },
];

export const COST_COUNTRIES = [
    { value: 'US', label: 'USA' },
];

export const PERKS = {
    WELCOME_KIT: 1,
    HEALTHCARE_BASIC: 2,
    HEALTHCARE_STANDARD: 3,
    HEALTHCARE_PREMIUM: 4,
    EQUIPMENT_LAPTOP: 5,
    EQUIPMENT_MONITOR: 6,
    EQUIPMENT_KEYBOARD_AND_MOUSE: 7,
    EQUIPMENT_WIFI_CONNECTION: 8,
    CO_WORKING_SEAT: 9,
    CO_WORKING_CABIN: 10,
    GYM_ACCESS_BASIC: 11,
    GYM_ACCESS_STANDARD: 12,
    GYM_ACCESS_PREMIUM: 13,
    BACKGROUND_CHECK: 14
};

export const PERKS_DATA = {
    1: 'Welcome Kit',
    2: 'Basic',
    3: 'Standard',
    4: 'Premium',
    5: 'Equipment Laptop',
    6: 'Equipment Monitor',
    7: 'Equipment Keyboard+Mouse',
    8: 'Equipment Wifi Connection',
    9: 'Co Working Seat',
    10: 'Co Working Cabin',
    11: 'Gym Access Basic',
    12: 'Gym Access Standard',
    13: 'Gym Access Premium',
    14: 'Background Check'
};

export const EMP_STATUS = [
    { label: "Employed full-time", value: 1 },
    { label: "Employed part-time", value: 2 },
    { label: "Self-employed", value: 3 },
    { label: "Unemployed", value: 4 },
    { label: "Student", value: 5 },
    { label: "Other", value: 6 },
];

export const YEARS = [
    {
        value: 2024,
        label: "2024"
    },
    {
        value: 2023,
        label: "2023"
    },
    {
        value: 2022,
        label: "2022"
    },
    {
        value: 2021,
        label: "2021"
    },
    {
        value: 2020,
        label: "2020"
    },
    {
        value: 2019,
        label: "2019"
    },
    {
        value: 2018,
        label: "2018"
    },
    {
        value: 2017,
        label: "2017"
    },
    {
        value: 2016,
        label: "2016"
    },
    {
        value: 2015,
        label: "2015"
    },
    {
        value: 2014,
        label: "2014"
    },
    {
        value: 2013,
        label: "2013"
    },
    {
        value: 2012,
        label: "2012"
    },
    {
        value: 2011,
        label: "2011"
    },
    {
        value: 2010,
        label: "2010"
    },
    {
        value: 2009,
        label: "2009"
    },
    {
        value: 2008,
        label: "2008"
    },
    {
        value: 2007,
        label: "2007"
    },
    {
        value: 2006,
        label: "2006"
    },
    {
        value: 2005,
        label: "2005"
    },
    {
        value: 2004,
        label: "2004"
    },
    {
        value: 2003,
        label: "2003"
    },
    {
        value: 2002,
        label: "2002"
    },
    {
        value: 2001,
        label: "2001"
    },
    {
        value: 2000,
        label: "2000"
    },
    {
        value: 1999,
        label: "1999"
    },
    {
        value: 1998,
        label: "1998"
    },
    {
        value: 1997,
        label: "1997"
    },
    {
        value: 1996,
        label: "1996"
    },
    {
        value: 1995,
        label: "1995"
    },
    {
        value: 1994,
        label: "1994"
    },
    {
        value: 1993,
        label: "1993"
    },
    {
        value: 1992,
        label: "1992"
    },
    {
        value: 1991,
        label: "1991"
    },
    {
        value: 1990,
        label: "1990"
    },
    {
        value: 1989,
        label: "1989"
    },
    {
        value: 1988,
        label: "1988"
    },
    {
        value: 1987,
        label: "1987"
    },
    {
        value: 1986,
        label: "1986"
    },
    {
        value: 1985,
        label: "1985"
    },
    {
        value: 1984,
        label: "1984"
    },
    {
        value: 1983,
        label: "1983"
    },
    {
        value: 1982,
        label: "1982"
    },
    {
        value: 1981,
        label: "1981"
    },
    {
        value: 1980,
        label: "1980"
    },
    {
        value: 1979,
        label: "1979"
    },
    {
        value: 1978,
        label: "1978"
    },
    {
        value: 1977,
        label: "1977"
    },
    {
        value: 1976,
        label: "1976"
    },
    {
        value: 1975,
        label: "1975"
    },
    {
        value: 1974,
        label: "1974"
    },
    {
        value: 1973,
        label: "1973"
    },
    {
        value: 1972,
        label: "1972"
    },
    {
        value: 1971,
        label: "1971"
    },
    {
        value: 1970,
        label: "1970"
    }
]

export const YEARSPLUS10 = [
    {
        value: 2032,
        label: "2032"
    },
    {
        value: 2031,
        label: "2031"
    },
    {
        value: 2030,
        label: "2030"
    },
    {
        value: 2029,
        label: "2029"
    },
    {
        value: 2028,
        label: "2028"
    },
    {
        value: 2027,
        label: "2027"
    },
    {
        value: 2026,
        label: "2026"
    },
    {
        value: 2025,
        label: "2025"
    },
    {
        value: 2024,
        label: "2024"
    },
    {
        value: 2023,
        label: "2023"
    },
    {
        value: 2023,
        label: "2023"
    },
    {
        value: 2022,
        label: "2022"
    },
    {
        value: 2021,
        label: "2021"
    },
    {
        value: 2020,
        label: "2020"
    },
    {
        value: 2019,
        label: "2019"
    },
    {
        value: 2018,
        label: "2018"
    },
    {
        value: 2017,
        label: "2017"
    },
    {
        value: 2016,
        label: "2016"
    },
    {
        value: 2015,
        label: "2015"
    },
    {
        value: 2014,
        label: "2014"
    },
    {
        value: 2013,
        label: "2013"
    },
    {
        value: 2012,
        label: "2012"
    },
    {
        value: 2011,
        label: "2011"
    },
    {
        value: 2010,
        label: "2010"
    },
    {
        value: 2009,
        label: "2009"
    },
    {
        value: 2008,
        label: "2008"
    },
    {
        value: 2007,
        label: "2007"
    },
    {
        value: 2006,
        label: "2006"
    },
    {
        value: 2005,
        label: "2005"
    },
    {
        value: 2004,
        label: "2004"
    },
    {
        value: 2003,
        label: "2003"
    },
    {
        value: 2002,
        label: "2002"
    },
    {
        value: 2001,
        label: "2001"
    },
    {
        value: 2000,
        label: "2000"
    },
    {
        value: 1999,
        label: "1999"
    },
    {
        value: 1998,
        label: "1998"
    },
    {
        value: 1997,
        label: "1997"
    },
    {
        value: 1996,
        label: "1996"
    },
    {
        value: 1995,
        label: "1995"
    },
    {
        value: 1994,
        label: "1994"
    },
    {
        value: 1993,
        label: "1993"
    },
    {
        value: 1992,
        label: "1992"
    },
    {
        value: 1991,
        label: "1991"
    },
    {
        value: 1990,
        label: "1990"
    },
    {
        value: 1989,
        label: "1989"
    },
    {
        value: 1988,
        label: "1988"
    },
    {
        value: 1987,
        label: "1987"
    },
    {
        value: 1986,
        label: "1986"
    },
    {
        value: 1985,
        label: "1985"
    },
    {
        value: 1984,
        label: "1984"
    },
    {
        value: 1983,
        label: "1983"
    },
    {
        value: 1982,
        label: "1982"
    },
    {
        value: 1981,
        label: "1981"
    },
    {
        value: 1980,
        label: "1980"
    },
    {
        value: 1979,
        label: "1979"
    },
    {
        value: 1978,
        label: "1978"
    },
    {
        value: 1977,
        label: "1977"
    },
    {
        value: 1976,
        label: "1976"
    },
    {
        value: 1975,
        label: "1975"
    },
    {
        value: 1974,
        label: "1974"
    },
    {
        value: 1973,
        label: "1973"
    },
    {
        value: 1972,
        label: "1972"
    },
    {
        value: 1971,
        label: "1971"
    },
    {
        value: 1970,
        label: "1970"
    }
]

export const MONTHS = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
];

export const SORT_BY = [
    { label: "Relevance", value: 1 },
    { label: "Newest First", value: 2 },
    { label: "Most Applicants", value: 3 },
];

export const APPLICATION_STATUS_DATA: any = {
    1: 'New Applicant',
    2: 'Rejected',
    3: 'Interview Requested',
    4: 'Interview Outsourced',
    5: 'Interview Scheduled',
    6: 'Under Review',
    10: 'Hired'
};

export const APPLICATION_STATUS = {
    NEW_APPLICATION: 1,
    REJECTED: 2,
    INTERVIEW_REQUESTED: 3,
    INTERVIEW_OUTSOURCED: 4,
    INTERVIEW_SCHEDULED: 5,
    UNDER_REVIEW: 6,
    SELECTED: 10
};

export const CompensationFrequency = [
    { label: 'Single Payment', value: 'Single Payment' },
    { label: 'Multiple Payments', value: 'Multiple Payments' },
    { label: 'End of Employment', value: 'End of Employment' },
];

export const RetentionBonusType = [
    { label: 'Lump Sum Bonus', value: 'Lump Sum Bonus' },
    { label: 'Incremental Bonuses', value: 'Incremental Bonuses' },
    { label: 'Performance-Based Retention Bonus', value: 'Performance-Based Retention Bonus' },
    { label: 'Skill-Based Bonus', value: 'Skill-Based Bonus' },
    { label: 'Long-Term Incentive Plans (LTIP)', value: 'Long-Term Incentive Plans (LTIP)' },
    { label: 'Stay Bonus', value: 'Stay Bonus' },
    { label: 'Retention Bonus Pool', value: 'Retention Bonus Pool' },
];

export const GratuityAllowancePaymentType = [
    { label: 'Lump Sum Bonus', value: 'Lump Sum Bonus' },
    { label: 'Incremental Bonuses', value: 'Incremental Bonuses' },
];

export const CompanySize = [
    { label: "Small (1-50 employees)", value: "small" },
    { label: "Medium (51-500 employees)", value: "medium" },
    { label: "Large (501-5000 employees)", value: "large" },
    { label: "Enterprise (5001+ employees)", value: "enterprise" }
]

export const TypeOfWorker = [
    { label: "Employee on Record", value: "Employee on Record" },
    { label: "Contractor", value: "Contractor" },
];

export const PayemntFrequeny = [
    { label: "Monthly", value: "Monthly" },
    { label: "Weekly", value: "Weekly" },
    { label: "Bi Weekly", value: "Bi Weekly" },
];
// export const customStyles = {
//     control: (provided: any, state: any) => ({
//         ...provided,
//         borderColor: '#9e9e9e',
//         minHeight: '60px',
//         // height: '60px',
//         boxShadow: state.isFocused ? null : null,
//         border: '1px solid #492A99',
//         borderRadius: '12px',
//         background: '#FFF',
//         fontWeight: 600,
//         color: 'black',
//         fontSize: '18px',
//     }),
//     valueContainer: (provided: any, state: any) => ({
//         ...provided,
//         // height: '60px',
//         // padding: '0 6px',
//         // minHeight: '60px', // Remove fixed height
//     }),

//     input: (provided: any, state: any) => ({
//         ...provided,
//         // margin: '0px',
//     }),
//     indicatorSeparator: (state: any) => ({
//         display: 'none',
//     }),
//     // indicatorsContainer: (provided: any, state: any) => ({
//     //     ...provided,
//     //     height: '60px',
//     // }),
//     indicatorsContainer: (provided: any, state: any) => ({
//         ...provided,
//         minHeight: 'unset', // Remove fixed height
//         height: 'unset',   // Remove fixed height
//     }),
//     multiValueLabel: (provided: any, state: any) => ({
//         ...provided,
//         backgroundColor: '#AB8BFC',
//         color: '#000',
//         borderRadius: "12px",
//     }),

//     option: (provided: any, state: any) => ({
//         ...provided,
//         fontSize: '18px',
//         fontWeight: 500,
//         borderBottom: '1px solid black',
//         ':hover': {
//             background: '#492A99',
//             color: 'white',
//         },
//         background: state.isSelected ? '#492A99' : '#FFF',
//     }),
//     // multiValue: (provided: any) => ({
//     //     ...provided,
//     //     background: '#AB8BFC',

//     // }),
//     multiValue: (provided: any) => ({
//         ...provided,
//         // minHeight: '30px',  
//         // height: '30px',  
//         background: '#AB8BFC',
//     }),
// };

export const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        borderColor: '#9e9e9e',
        minHeight: '60px',
        boxShadow: state.isFocused ? null : null,
        border: '1px solid #492A99',
        borderRadius: '12px',
        background: '#FFF',
        fontWeight: 600,
        color: 'black',
        fontSize: '18px',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        fontSize: '18px',
        fontWeight: 500,
        borderBottom: '1px solid black',
        ':hover': {
            background: '#492A99',
            color: 'white',
        },
        background: state.isSelected ? '#492A99' : '#FFF',
    }),
    multiValue: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: '#AB8BFC',
        borderRadius: "12px",
        fontSize: "18px"
    }),
    multiValueLabel: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
    }),
    multiValueRemove: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        borderRadius: "12px",
        ':hover': {
            backgroundColor: '#AB8BFC',
            color: '#000',
        },
    }),
    indicatorSeparator: (state: any) => ({
        display: 'none',
    }),
    placeholder: (provided: any, state: any) => ({
        ...provided,
        fontWeight: 500,
        color: '#bcbcbc',
    }),
};

export const ALL_COUNTRY_CODES = [
    {
        "label": "+91",
        "value": "IN"
    },
    {
        "label": "+1",
        "value": "US"
    },
    {
        "label": "+93",
        "value": "AF"
    },
    {
        "label": "+358",
        "value": "AX"
    },
    {
        "label": "+355",
        "value": "AL"
    },
    {
        "label": "+213",
        "value": "DZ"
    },
    {
        "label": "+1684",
        "value": "AS"
    },
    {
        "label": "+376",
        "value": "AD"
    },
    {
        "label": "+244",
        "value": "AO"
    },
    {
        "label": "+1264",
        "value": "AI"
    },
    {
        "label": "+672",
        "value": "AQ"
    },
    {
        "label": "+1268",
        "value": "AG"
    },
    {
        "label": "+54",
        "value": "AR"
    },
    {
        "label": "+374",
        "value": "AM"
    },
    {
        "label": "+297",
        "value": "AW"
    },
    {
        "label": "+61",
        "value": "AU"
    },
    {
        "label": "+43",
        "value": "AT"
    },
    {
        "label": "+994",
        "value": "AZ"
    },
    {
        "label": "+1242",
        "value": "BS"
    },
    {
        "label": "+973",
        "value": "BH"
    },
    {
        "label": "+880",
        "value": "BD"
    },
    {
        "label": "+1246",
        "value": "BB"
    },
    {
        "label": "+375",
        "value": "BY"
    },
    {
        "label": "+32",
        "value": "BE"
    },
    {
        "label": "+501",
        "value": "BZ"
    },
    {
        "label": "+229",
        "value": "BJ"
    },
    {
        "label": "+1441",
        "value": "BM"
    },
    {
        "label": "+975",
        "value": "BT"
    },
    {
        "label": "+591",
        "value": "BO"
    },
    {
        "label": "+387",
        "value": "BA"
    },
    {
        "label": "+267",
        "value": "BW"
    },
    {
        "label": "+55",
        "value": "BR"
    },
    {
        "label": "+246",
        "value": "IO"
    },
    {
        "label": "+673",
        "value": "BN"
    },
    {
        "label": "+359",
        "value": "BG"
    },
    {
        "label": "+226",
        "value": "BF"
    },
    {
        "label": "+257",
        "value": "BI"
    },
    {
        "label": "+855",
        "value": "KH"
    },
    {
        "label": "+237",
        "value": "CM"
    },
    {
        "label": "+1",
        "value": "CA"
    },
    {
        "label": "+238",
        "value": "CV"
    },
    {
        "label": "+ 345",
        "value": "KY"
    },
    {
        "label": "+236",
        "value": "CF"
    },
    {
        "label": "+235",
        "value": "TD"
    },
    {
        "label": "+56",
        "value": "CL"
    },
    {
        "label": "+86",
        "value": "CN"
    },
    {
        "label": "+61",
        "value": "CX"
    },
    {
        "label": "+61",
        "value": "CC"
    },
    {
        "label": "+57",
        "value": "CO"
    },
    {
        "label": "+269",
        "value": "KM"
    },
    {
        "label": "+242",
        "value": "CG"
    },
    {
        "label": "+243",
        "value": "CD"
    },
    {
        "label": "+682",
        "value": "CK"
    },
    {
        "label": "+506",
        "value": "CR"
    },
    {
        "label": "+225",
        "value": "CI"
    },
    {
        "label": "+385",
        "value": "HR"
    },
    {
        "label": "+53",
        "value": "CU"
    },
    {
        "label": "+357",
        "value": "CY"
    },
    {
        "label": "+420",
        "value": "CZ"
    },
    {
        "label": "+45",
        "value": "DK"
    },
    {
        "label": "+253",
        "value": "DJ"
    },
    {
        "label": "+1767",
        "value": "DM"
    },
    {
        "label": "+1849",
        "value": "DO"
    },
    {
        "label": "+593",
        "value": "EC"
    },
    {
        "label": "+20",
        "value": "EG"
    },
    {
        "label": "+503",
        "value": "SV"
    },
    {
        "label": "+240",
        "value": "GQ"
    },
    {
        "label": "+291",
        "value": "ER"
    },
    {
        "label": "+372",
        "value": "EE"
    },
    {
        "label": "+251",
        "value": "ET"
    },
    {
        "label": "+500",
        "value": "FK"
    },
    {
        "label": "+298",
        "value": "FO"
    },
    {
        "label": "+679",
        "value": "FJ"
    },
    {
        "label": "+358",
        "value": "FI"
    },
    {
        "label": "+33",
        "value": "FR"
    },
    {
        "label": "+594",
        "value": "GF"
    },
    {
        "label": "+689",
        "value": "PF"
    },
    {
        "label": "+241",
        "value": "GA"
    },
    {
        "label": "+220",
        "value": "GM"
    },
    {
        "label": "+995",
        "value": "GE"
    },
    {
        "label": "+49",
        "value": "DE"
    },
    {
        "label": "+233",
        "value": "GH"
    },
    {
        "label": "+350",
        "value": "GI"
    },
    {
        "label": "+30",
        "value": "GR"
    },
    {
        "label": "+299",
        "value": "GL"
    },
    {
        "label": "+1473",
        "value": "GD"
    },
    {
        "label": "+590",
        "value": "GP"
    },
    {
        "label": "+1671",
        "value": "GU"
    },
    {
        "label": "+502",
        "value": "GT"
    },
    {
        "label": "+44",
        "value": "GG"
    },
    {
        "label": "+224",
        "value": "GN"
    },
    {
        "label": "+245",
        "value": "GW"
    },
    {
        "label": "+595",
        "value": "GY"
    },
    {
        "label": "+509",
        "value": "HT"
    },
    {
        "label": "+379",
        "value": "VA"
    },
    {
        "label": "+504",
        "value": "HN"
    },
    {
        "label": "+852",
        "value": "HK"
    },
    {
        "label": "+36",
        "value": "HU"
    },
    {
        "label": "+354",
        "value": "IS"
    },
    {
        "label": "+62",
        "value": "ID"
    },
    {
        "label": "+98",
        "value": "IR"
    },
    {
        "label": "+964",
        "value": "IQ"
    },
    {
        "label": "+353",
        "value": "IE"
    },
    {
        "label": "+44",
        "value": "IM"
    },
    {
        "label": "+972",
        "value": "IL"
    },
    {
        "label": "+39",
        "value": "IT"
    },
    {
        "label": "+1876",
        "value": "JM"
    },
    {
        "label": "+81",
        "value": "JP"
    },
    {
        "label": "+44",
        "value": "JE"
    },
    {
        "label": "+962",
        "value": "JO"
    },
    {
        "label": "+77",
        "value": "KZ"
    },
    {
        "label": "+254",
        "value": "KE"
    },
    {
        "label": "+686",
        "value": "KI"
    },
    {
        "label": "+850",
        "value": "KP"
    },
    {
        "label": "+82",
        "value": "KR"
    },
    {
        "label": "+965",
        "value": "KW"
    },
    {
        "label": "+996",
        "value": "KG"
    },
    {
        "label": "+856",
        "value": "LA"
    },
    {
        "label": "+371",
        "value": "LV"
    },
    {
        "label": "+961",
        "value": "LB"
    },
    {
        "label": "+266",
        "value": "LS"
    },
    {
        "label": "+231",
        "value": "LR"
    },
    {
        "label": "+218",
        "value": "LY"
    },
    {
        "label": "+423",
        "value": "LI"
    },
    {
        "label": "+370",
        "value": "LT"
    },
    {
        "label": "+352",
        "value": "LU"
    },
    {
        "label": "+853",
        "value": "MO"
    },
    {
        "label": "+389",
        "value": "MK"
    },
    {
        "label": "+261",
        "value": "MG"
    },
    {
        "label": "+265",
        "value": "MW"
    },
    {
        "label": "+60",
        "value": "MY"
    },
    {
        "label": "+960",
        "value": "MV"
    },
    {
        "label": "+223",
        "value": "ML"
    },
    {
        "label": "+356",
        "value": "MT"
    },
    {
        "label": "+692",
        "value": "MH"
    },
    {
        "label": "+596",
        "value": "MQ"
    },
    {
        "label": "+222",
        "value": "MR"
    },
    {
        "label": "+230",
        "value": "MU"
    },
    {
        "label": "+262",
        "value": "YT"
    },
    {
        "label": "+52",
        "value": "MX"
    },
    {
        "label": "+691",
        "value": "FM"
    },
    {
        "label": "+373",
        "value": "MD"
    },
    {
        "label": "+377",
        "value": "MC"
    },
    {
        "label": "+976",
        "value": "MN"
    },
    {
        "label": "+382",
        "value": "ME"
    },
    {
        "label": "+1664",
        "value": "MS"
    },
    {
        "label": "+212",
        "value": "MA"
    },
    {
        "label": "+258",
        "value": "MZ"
    },
    {
        "label": "+95",
        "value": "MM"
    },
    {
        "label": "+264",
        "value": "NA"
    },
    {
        "label": "+674",
        "value": "NR"
    },
    {
        "label": "+977",
        "value": "NP"
    },
    {
        "label": "+31",
        "value": "NL"
    },
    {
        "label": "+599",
        "value": "AN"
    },
    {
        "label": "+687",
        "value": "NC"
    },
    {
        "label": "+64",
        "value": "NZ"
    },
    {
        "label": "+505",
        "value": "NI"
    },
    {
        "label": "+227",
        "value": "NE"
    },
    {
        "label": "+234",
        "value": "NG"
    },
    {
        "label": "+683",
        "value": "NU"
    },
    {
        "label": "+672",
        "value": "NF"
    },
    {
        "label": "+1670",
        "value": "MP"
    },
    {
        "label": "+47",
        "value": "NO"
    },
    {
        "label": "+968",
        "value": "OM"
    },
    {
        "label": "+92",
        "value": "PK"
    },
    {
        "label": "+680",
        "value": "PW"
    },
    {
        "label": "+970",
        "value": "PS"
    },
    {
        "label": "+507",
        "value": "PA"
    },
    {
        "label": "+675",
        "value": "PG"
    },
    {
        "label": "+595",
        "value": "PY"
    },
    {
        "label": "+51",
        "value": "PE"
    },
    {
        "label": "+63",
        "value": "PH"
    },
    {
        "label": "+872",
        "value": "PN"
    },
    {
        "label": "+48",
        "value": "PL"
    },
    {
        "label": "+351",
        "value": "PT"
    },
    {
        "label": "+1939",
        "value": "PR"
    },
    {
        "label": "+974",
        "value": "QA"
    },
    {
        "label": "+40",
        "value": "RO"
    },
    {
        "label": "+7",
        "value": "RU"
    },
    {
        "label": "+250",
        "value": "RW"
    },
    {
        "label": "+262",
        "value": "RE"
    },
    {
        "label": "+590",
        "value": "BL"
    },
    {
        "label": "+290",
        "value": "SH"
    },
    {
        "label": "+1869",
        "value": "KN"
    },
    {
        "label": "+1758",
        "value": "LC"
    },
    {
        "label": "+590",
        "value": "MF"
    },
    {
        "label": "+508",
        "value": "PM"
    },
    {
        "label": "+1784",
        "value": "VC"
    },
    {
        "label": "+685",
        "value": "WS"
    },
    {
        "label": "+378",
        "value": "SM"
    },
    {
        "label": "+239",
        "value": "ST"
    },
    {
        "label": "+966",
        "value": "SA"
    },
    {
        "label": "+221",
        "value": "SN"
    },
    {
        "label": "+381",
        "value": "RS"
    },
    {
        "label": "+248",
        "value": "SC"
    },
    {
        "label": "+232",
        "value": "SL"
    },
    {
        "label": "+65",
        "value": "SG"
    },
    {
        "label": "+421",
        "value": "SK"
    },
    {
        "label": "+386",
        "value": "SI"
    },
    {
        "label": "+677",
        "value": "SB"
    },
    {
        "label": "+252",
        "value": "SO"
    },
    {
        "label": "+27",
        "value": "ZA"
    },
    {
        "label": "+211",
        "value": "SS"
    },
    {
        "label": "+500",
        "value": "GS"
    },
    {
        "label": "+34",
        "value": "ES"
    },
    {
        "label": "+94",
        "value": "LK"
    },
    {
        "label": "+249",
        "value": "SD"
    },
    {
        "label": "+597",
        "value": "SR"
    },
    {
        "label": "+47",
        "value": "SJ"
    },
    {
        "label": "+268",
        "value": "SZ"
    },
    {
        "label": "+46",
        "value": "SE"
    },
    {
        "label": "+41",
        "value": "CH"
    },
    {
        "label": "+963",
        "value": "SY"
    },
    {
        "label": "+886",
        "value": "TW"
    },
    {
        "label": "+992",
        "value": "TJ"
    },
    {
        "label": "+255",
        "value": "TZ"
    },
    {
        "label": "+66",
        "value": "TH"
    },
    {
        "label": "+670",
        "value": "TL"
    },
    {
        "label": "+228",
        "value": "TG"
    },
    {
        "label": "+690",
        "value": "TK"
    },
    {
        "label": "+676",
        "value": "TO"
    },
    {
        "label": "+1868",
        "value": "TT"
    },
    {
        "label": "+216",
        "value": "TN"
    },
    {
        "label": "+90",
        "value": "TR"
    },
    {
        "label": "+993",
        "value": "TM"
    },
    {
        "label": "+1649",
        "value": "TC"
    },
    {
        "label": "+688",
        "value": "TV"
    },
    {
        "label": "+256",
        "value": "UG"
    },
    {
        "label": "+380",
        "value": "UA"
    },
    {
        "label": "+971",
        "value": "AE"
    },
    {
        "label": "+44",
        "value": "GB"
    },
    {
        "label": "+598",
        "value": "UY"
    },
    {
        "label": "+998",
        "value": "UZ"
    },
    {
        "label": "+678",
        "value": "VU"
    },
    {
        "label": "+58",
        "value": "VE"
    },
    {
        "label": "+84",
        "value": "VN"
    },
    {
        "label": "+1284",
        "value": "VG"
    },
    {
        "label": "+1340",
        "value": "VI"
    },
    {
        "label": "+681",
        "value": "WF"
    },
    {
        "label": "+967",
        "value": "YE"
    },
    {
        "label": "+260",
        "value": "ZM"
    },
    {
        "label": "+263",
        "value": "ZW"
    }
]

export const ALL_COUNTY = [
    {
        "label": "India",
        "value": "IN"
    },
    {
        "label": "United States",
        "value": "US"
    },
    {
        "label": "Afghanistan",
        "value": "AF"
    },
    {
        "label": "Aland Islands",
        "value": "AX"
    },
    {
        "label": "Albania",
        "value": "AL"
    },
    {
        "label": "Algeria",
        "value": "DZ"
    },
    {
        "label": "AmericanSamoa",
        "value": "AS"
    },
    {
        "label": "Andorra",
        "value": "AD"
    },
    {
        "label": "Angola",
        "value": "AO"
    },
    {
        "label": "Anguilla",
        "value": "AI"
    },
    {
        "label": "Antarctica",
        "value": "AQ"
    },
    {
        "label": "Antigua and Barbuda",
        "value": "AG"
    },
    {
        "label": "Argentina",
        "value": "AR"
    },
    {
        "label": "Armenia",
        "value": "AM"
    },
    {
        "label": "Aruba",
        "value": "AW"
    },
    {
        "label": "Australia",
        "value": "AU"
    },
    {
        "label": "Austria",
        "value": "AT"
    },
    {
        "label": "Azerbaijan",
        "value": "AZ"
    },
    {
        "label": "Bahamas",
        "value": "BS"
    },
    {
        "label": "Bahrain",
        "value": "BH"
    },
    {
        "label": "Bangladesh",
        "value": "BD"
    },
    {
        "label": "Barbados",
        "value": "BB"
    },
    {
        "label": "Belarus",
        "value": "BY"
    },
    {
        "label": "Belgium",
        "value": "BE"
    },
    {
        "label": "Belize",
        "value": "BZ"
    },
    {
        "label": "Benin",
        "value": "BJ"
    },
    {
        "label": "Bermuda",
        "value": "BM"
    },
    {
        "label": "Bhutan",
        "value": "BT"
    },
    {
        "label": "Bolivia, Plurinational State of",
        "value": "BO"
    },
    {
        "label": "Bosnia and Herzegovina",
        "value": "BA"
    },
    {
        "label": "Botswana",
        "value": "BW"
    },
    {
        "label": "Brazil",
        "value": "BR"
    },
    {
        "label": "British Indian Ocean Territory",
        "value": "IO"
    },
    {
        "label": "Brunei Darussalam",
        "value": "BN"
    },
    {
        "label": "Bulgaria",
        "value": "BG"
    },
    {
        "label": "Burkina Faso",
        "value": "BF"
    },
    {
        "label": "Burundi",
        "value": "BI"
    },
    {
        "label": "Cambodia",
        "value": "KH"
    },
    {
        "label": "Cameroon",
        "value": "CM"
    },
    {
        "label": "Canada",
        "value": "CA"
    },
    {
        "label": "Cape Verde",
        "value": "CV"
    },
    {
        "label": "Cayman Islands",
        "value": "KY"
    },
    {
        "label": "Central African Republic",
        "value": "CF"
    },
    {
        "label": "Chad",
        "value": "TD"
    },
    {
        "label": "Chile",
        "value": "CL"
    },
    {
        "label": "China",
        "value": "CN"
    },
    {
        "label": "Christmas Island",
        "value": "CX"
    },
    {
        "label": "Cocos (Keeling) Islands",
        "value": "CC"
    },
    {
        "label": "Colombia",
        "value": "CO"
    },
    {
        "label": "Comoros",
        "value": "KM"
    },
    {
        "label": "Congo",
        "value": "CG"
    },
    {
        "label": "Congo, The Democratic Republic of the Congo",
        "value": "CD"
    },
    {
        "label": "Cook Islands",
        "value": "CK"
    },
    {
        "label": "Costa Rica",
        "value": "CR"
    },
    {
        "label": "Cote d'Ivoire",
        "value": "CI"
    },
    {
        "label": "Croatia",
        "value": "HR"
    },
    {
        "label": "Cuba",
        "value": "CU"
    },
    {
        "label": "Cyprus",
        "value": "CY"
    },
    {
        "label": "Czech Republic",
        "value": "CZ"
    },
    {
        "label": "Denmark",
        "value": "DK"
    },
    {
        "label": "Djibouti",
        "value": "DJ"
    },
    {
        "label": "Dominica",
        "value": "DM"
    },
    {
        "label": "Dominican Republic",
        "value": "DO"
    },
    {
        "label": "Ecuador",
        "value": "EC"
    },
    {
        "label": "Egypt",
        "value": "EG"
    },
    {
        "label": "El Salvador",
        "value": "SV"
    },
    {
        "label": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "label": "Eritrea",
        "value": "ER"
    },
    {
        "label": "Estonia",
        "value": "EE"
    },
    {
        "label": "Ethiopia",
        "value": "ET"
    },
    {
        "label": "Falkland Islands (Malvinas)",
        "value": "FK"
    },
    {
        "label": "Faroe Islands",
        "value": "FO"
    },
    {
        "label": "Fiji",
        "value": "FJ"
    },
    {
        "label": "Finland",
        "value": "FI"
    },
    {
        "label": "France",
        "value": "FR"
    },
    {
        "label": "French Guiana",
        "value": "GF"
    },
    {
        "label": "French Polynesia",
        "value": "PF"
    },
    {
        "label": "Gabon",
        "value": "GA"
    },
    {
        "label": "Gambia",
        "value": "GM"
    },
    {
        "label": "Georgia",
        "value": "GE"
    },
    {
        "label": "Germany",
        "value": "DE"
    },
    {
        "label": "Ghana",
        "value": "GH"
    },
    {
        "label": "Gibraltar",
        "value": "GI"
    },
    {
        "label": "Greece",
        "value": "GR"
    },
    {
        "label": "Greenland",
        "value": "GL"
    },
    {
        "label": "Grenada",
        "value": "GD"
    },
    {
        "label": "Guadeloupe",
        "value": "GP"
    },
    {
        "label": "Guam",
        "value": "GU"
    },
    {
        "label": "Guatemala",
        "value": "GT"
    },
    {
        "label": "Guernsey",
        "value": "GG"
    },
    {
        "label": "Guinea",
        "value": "GN"
    },
    {
        "label": "Guinea-Bissau",
        "value": "GW"
    },
    {
        "label": "Guyana",
        "value": "GY"
    },
    {
        "label": "Haiti",
        "value": "HT"
    },
    {
        "label": "Holy See (Vatican City State)",
        "value": "VA"
    },
    {
        "label": "Honduras",
        "value": "HN"
    },
    {
        "label": "Hong Kong",
        "value": "HK"
    },
    {
        "label": "Hungary",
        "value": "HU"
    },
    {
        "label": "Iceland",
        "value": "IS"
    },
    {
        "label": "Indonesia",
        "value": "ID"
    },
    {
        "label": "Iran, Islamic Republic of Persian Gulf",
        "value": "IR"
    },
    {
        "label": "Iraq",
        "value": "IQ"
    },
    {
        "label": "Ireland",
        "value": "IE"
    },
    {
        "label": "Isle of Man",
        "value": "IM"
    },
    {
        "label": "Israel",
        "value": "IL"
    },
    {
        "label": "Italy",
        "value": "IT"
    },
    {
        "label": "Jamaica",
        "value": "JM"
    },
    {
        "label": "Japan",
        "value": "JP"
    },
    {
        "label": "Jersey",
        "value": "JE"
    },
    {
        "label": "Jordan",
        "value": "JO"
    },
    {
        "label": "Kazakhstan",
        "value": "KZ"
    },
    {
        "label": "Kenya",
        "value": "KE"
    },
    {
        "label": "Kiribati",
        "value": "KI"
    },
    {
        "label": "Korea, Democratic People's Republic of Korea",
        "value": "KP"
    },
    {
        "label": "Korea, Republic of South Korea",
        "value": "KR"
    },
    {
        "label": "Kuwait",
        "value": "KW"
    },
    {
        "label": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "label": "Laos",
        "value": "LA"
    },
    {
        "label": "Latvia",
        "value": "LV"
    },
    {
        "label": "Lebanon",
        "value": "LB"
    },
    {
        "label": "Lesotho",
        "value": "LS"
    },
    {
        "label": "Liberia",
        "value": "LR"
    },
    {
        "label": "Libyan Arab Jamahiriya",
        "value": "LY"
    },
    {
        "label": "Liechtenstein",
        "value": "LI"
    },
    {
        "label": "Lithuania",
        "value": "LT"
    },
    {
        "label": "Luxembourg",
        "value": "LU"
    },
    {
        "label": "Macao",
        "value": "MO"
    },
    {
        "label": "Macedonia",
        "value": "MK"
    },
    {
        "label": "Madagascar",
        "value": "MG"
    },
    {
        "label": "Malawi",
        "value": "MW"
    },
    {
        "label": "Malaysia",
        "value": "MY"
    },
    {
        "label": "Maldives",
        "value": "MV"
    },
    {
        "label": "Mali",
        "value": "ML"
    },
    {
        "label": "Malta",
        "value": "MT"
    },
    {
        "label": "Marshall Islands",
        "value": "MH"
    },
    {
        "label": "Martinique",
        "value": "MQ"
    },
    {
        "label": "Mauritania",
        "value": "MR"
    },
    {
        "label": "Mauritius",
        "value": "MU"
    },
    {
        "label": "Mayotte",
        "value": "YT"
    },
    {
        "label": "Mexico",
        "value": "MX"
    },
    {
        "label": "Micronesia, Federated States of Micronesia",
        "value": "FM"
    },
    {
        "label": "Moldova",
        "value": "MD"
    },
    {
        "label": "Monaco",
        "value": "MC"
    },
    {
        "label": "Mongolia",
        "value": "MN"
    },
    {
        "label": "Montenegro",
        "value": "ME"
    },
    {
        "label": "Montserrat",
        "value": "MS"
    },
    {
        "label": "Morocco",
        "value": "MA"
    },
    {
        "label": "Mozambique",
        "value": "MZ"
    },
    {
        "label": "Myanmar",
        "value": "MM"
    },
    {
        "label": "Namibia",
        "value": "NA"
    },
    {
        "label": "Nauru",
        "value": "NR"
    },
    {
        "label": "Nepal",
        "value": "NP"
    },
    {
        "label": "Netherlands",
        "value": "NL"
    },
    {
        "label": "Netherlands Antilles",
        "value": "AN"
    },
    {
        "label": "New Caledonia",
        "value": "NC"
    },
    {
        "label": "New Zealand",
        "value": "NZ"
    },
    {
        "label": "Nicaragua",
        "value": "NI"
    },
    {
        "label": "Niger",
        "value": "NE"
    },
    {
        "label": "Nigeria",
        "value": "NG"
    },
    {
        "label": "Niue",
        "value": "NU"
    },
    {
        "label": "Norfolk Island",
        "value": "NF"
    },
    {
        "label": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "label": "Norway",
        "value": "NO"
    },
    {
        "label": "Oman",
        "value": "OM"
    },
    {
        "label": "Pakistan",
        "value": "PK"
    },
    {
        "label": "Palau",
        "value": "PW"
    },
    {
        "label": "Palestinian Territory, Occupied",
        "value": "PS"
    },
    {
        "label": "Panama",
        "value": "PA"
    },
    {
        "label": "Papua New Guinea",
        "value": "PG"
    },
    {
        "label": "Paraguay",
        "value": "PY"
    },
    {
        "label": "Peru",
        "value": "PE"
    },
    {
        "label": "Philippines",
        "value": "PH"
    },
    {
        "label": "Pitcairn",
        "value": "PN"
    },
    {
        "label": "Poland",
        "value": "PL"
    },
    {
        "label": "Portugal",
        "value": "PT"
    },
    {
        "label": "Puerto Rico",
        "value": "PR"
    },
    {
        "label": "Qatar",
        "value": "QA"
    },
    {
        "label": "Romania",
        "value": "RO"
    },
    {
        "label": "Russia",
        "value": "RU"
    },
    {
        "label": "Rwanda",
        "value": "RW"
    },
    {
        "label": "Reunion",
        "value": "RE"
    },
    {
        "label": "Saint Barthelemy",
        "value": "BL"
    },
    {
        "label": "Saint Helena, Ascension and Tristan Da Cunha",
        "value": "SH"
    },
    {
        "label": "Saint Kitts and Nevis",
        "value": "KN"
    },
    {
        "label": "Saint Lucia",
        "value": "LC"
    },
    {
        "label": "Saint Martin",
        "value": "MF"
    },
    {
        "label": "Saint Pierre and Miquelon",
        "value": "PM"
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "value": "VC"
    },
    {
        "label": "Samoa",
        "value": "WS"
    },
    {
        "label": "San Marino",
        "value": "SM"
    },
    {
        "label": "Sao Tome and Principe",
        "value": "ST"
    },
    {
        "label": "Saudi Arabia",
        "value": "SA"
    },
    {
        "label": "Senegal",
        "value": "SN"
    },
    {
        "label": "Serbia",
        "value": "RS"
    },
    {
        "label": "Seychelles",
        "value": "SC"
    },
    {
        "label": "Sierra Leone",
        "value": "SL"
    },
    {
        "label": "Singapore",
        "value": "SG"
    },
    {
        "label": "Slovakia",
        "value": "SK"
    },
    {
        "label": "Slovenia",
        "value": "SI"
    },
    {
        "label": "Solomon Islands",
        "value": "SB"
    },
    {
        "label": "Somalia",
        "value": "SO"
    },
    {
        "label": "South Africa",
        "value": "ZA"
    },
    {
        "label": "South Sudan",
        "value": "SS"
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "value": "GS"
    },
    {
        "label": "Spain",
        "value": "ES"
    },
    {
        "label": "Sri Lanka",
        "value": "LK"
    },
    {
        "label": "Sudan",
        "value": "SD"
    },
    {
        "label": "Suriname",
        "value": "SR"
    },
    {
        "label": "Svalbard and Jan Mayen",
        "value": "SJ"
    },
    {
        "label": "Swaziland",
        "value": "SZ"
    },
    {
        "label": "Sweden",
        "value": "SE"
    },
    {
        "label": "Switzerland",
        "value": "CH"
    },
    {
        "label": "Syrian Arab Republic",
        "value": "SY"
    },
    {
        "label": "Taiwan",
        "value": "TW"
    },
    {
        "label": "Tajikistan",
        "value": "TJ"
    },
    {
        "label": "Tanzania, United Republic of Tanzania",
        "value": "TZ"
    },
    {
        "label": "Thailand",
        "value": "TH"
    },
    {
        "label": "Timor-Leste",
        "value": "TL"
    },
    {
        "label": "Togo",
        "value": "TG"
    },
    {
        "label": "Tokelau",
        "value": "TK"
    },
    {
        "label": "Tonga",
        "value": "TO"
    },
    {
        "label": "Trinidad and Tobago",
        "value": "TT"
    },
    {
        "label": "Tunisia",
        "value": "TN"
    },
    {
        "label": "Turkey",
        "value": "TR"
    },
    {
        "label": "Turkmenistan",
        "value": "TM"
    },
    {
        "label": "Turks and Caicos Islands",
        "value": "TC"
    },
    {
        "label": "Tuvalu",
        "value": "TV"
    },
    {
        "label": "Uganda",
        "value": "UG"
    },
    {
        "label": "Ukraine",
        "value": "UA"
    },
    {
        "label": "United Arab Emirates",
        "value": "AE"
    },
    {
        "label": "United Kingdom",
        "value": "GB"
    },

    {
        "label": "Uruguay",
        "value": "UY"
    },
    {
        "label": "Uzbekistan",
        "value": "UZ"
    },
    {
        "label": "Vanuatu",
        "value": "VU"
    },
    {
        "label": "Venezuela, Bolivarian Republic of Venezuela",
        "value": "VE"
    },
    {
        "label": "Vietnam",
        "value": "VN"
    },
    {
        "label": "Virgin Islands, British",
        "value": "VG"
    },
    {
        "label": "Virgin Islands, U.S.",
        "value": "VI"
    },
    {
        "label": "Wallis and Futuna",
        "value": "WF"
    },
    {
        "label": "Yemen",
        "value": "YE"
    },
    {
        "label": "Zambia",
        "value": "ZM"
    },
    {
        "label": "Zimbabwe",
        "value": "ZW"
    }
]

export const TerminationReasons: OptionType[] = [
    { label: "Resignation", value: "resignation" },
    { label: "Retirement", value: "retirement" },
    { label: "Mutual Agreement", value: "mutual_agreement" },
    { label: "End of Contract", value: "end_of_contract" },
    { label: "Probation Period", value: "probation_period" },
    { label: "Redundancy", value: "redundancy" },
    { label: "Performance Issues", value: "performance_issues" },
    { label: "Misconduct", value: "misconduct" },
    { label: "Violation of Contract Terms", value: "violation_of_contract_terms" },
    { label: "Health Reasons", value: "health_reasons" },
    { label: "Personal Reasons", value: "personal_reasons" },
    { label: "Relocation", value: "relocation" },
    { label: "Change in Career", value: "change_in_career" },
    { label: "Completion of Project", value: "completion_of_project" },
    { label: "Legal or Regulatory Reasons", value: "legal_or_regulatory_reasons" },
    { label: "Others", value: "others" }
];

export const SKILL_TYPES = {
    MAIN: 1,
    ADDITIONAL: 2
  };
  