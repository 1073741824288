import NotificationComponent from '../../Components/NotificationComponent'
import InterviewerImage from '../../Assets/Images/Rectangle17405.png'
// import AddFlexibleAllowanceModel from '../../Components/Employer/Modals/AddFlexibleAllowanceModel'
// import AddSigningBonusModel from '../../Components/Employer/Modals/AddSigningBonusModel'
// import AddVariableComponentModel from '../../Components/Employer/Modals/AddVariableComponentModel'
import ProgressLineComponentForHireTalent from '../../Components/Employer/ProgressLineComponentForHireTalent'
import PlusIconForAddKitButton from '../../Assets/Icons/addKitPlusIcon.svg'
import RetentionBonusModel from '../../Components/Employer/Modals/RetentionBonusModel'
import GratuityAllowanceModel from '../../Components/Employer/Modals/GratuityAllowanceModel'
import SigningBonusModel from '../../Components/Employer/Modals/SigningBonusModel'
import EditIcon from '../../Assets/Icons/EditIcon.svg'
import InfoIcon from '../../Assets/Icons/infoIcon.svg'
import Select from 'react-select';
import { FaCalendarAlt } from 'react-icons/fa';
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { PositionDetails, Talent, TalentDetails, TalentProfile, imageAllowedFileTypes } from '../../types';
import { BsCalendarFill, BsPlusCircle, BsUpload } from 'react-icons/bs';
import MenuIcon from '../../Assets/Icons/menuIcon.svg'
import DefaultImage from '../../Assets/Icons/default.png';
import Helper from '../../utils/helper'
import useAuth from '../../hooks/useAuth'
import { PERKS, POSITION_TYPES, customStyles, TypeOfWorker, PayemntFrequeny } from '../../utils/constant'
import { toast } from 'react-toastify'
import DeleteIcon from '../../Assets/Icons/DeleteIcon.svg'
import { Tooltip as ReactTooltip } from "react-tooltip";
import CurrencyInput from 'react-currency-input-field'
const prefix = '₹    ';

const TestPage = () => {
    return (
        <>
            <div id="content" className='pt-5'>

                <div className='top-navigation mb-5'>
                    <NotificationComponent/>
                    <h2>Hire Talents</h2>
                    <p>This dashboard will help you in analysing your activities in various aspects.</p>
                </div>

                
            </div>

        </>
    )
}

export default TestPage