import { useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent'
import ManageCollaboratorsTable from './ManageCollaboratorsTable'
import AccountForm from './AccountForm'
import SecurityButton from './SecurityButton'


const MyAccountComponent = (props: any) => {

    const [view, setView] = useState<number>(1)
    const [activeTab, setActiveTab] = useState("Account")

    const handleAccccount = () => {
        setView(1);
        setActiveTab("Account")
    };

    const handleCollaborator = () => {
        setView(2);
        setActiveTab("ManageCollaborators")

    };

    const handleSecurityButton = () => {
        setView(3);
        setActiveTab("Security")

    };

    return (
        <>
            <div id="content" className='pt-5'>
                <div className='top-navigation mb-5'>
                     <NotificationComponent/>
                    <h2>My Account</h2>
                    <p>This dashboard will help you to update your personal information.</p>
                </div>
                <div className='mt-5'>
                    <ul className="my-account-tabs-list">
                        <li><a href="#" className={`${activeTab == "Account" && 'active'}`} onClick={handleAccccount}>Account</a></li>
                        <li><a href="#" className={`${activeTab == "ManageCollaborators" && 'active'}`} onClick={handleCollaborator}>Manage Collaborators</a></li>
                        <li><a href="#" className={`${activeTab == "Security" && 'active'}`} onClick={handleSecurityButton}>Security</a></li>
                    </ul>
                </div>

                {view === 1 && <AccountForm/>}
                {view === 2 && <ManageCollaboratorsTable />}
                {view === 3 && <SecurityButton />}
            </div>
        </>
    )
}

export default MyAccountComponent