import React from 'react'
import CongratulationsComponent from '../../../Components/Employer/CongratulationsComponent'

const CongratulationsPage = () => {
    return (
        <>
            <CongratulationsComponent />
        </>
    )
}

export default CongratulationsPage