import { useEffect, useState } from 'react'
import { BsPlusCircle } from 'react-icons/bs'
import NotificationComponent from '../../Components/NotificationComponent'
import RegtangleImg from '../../Assets/Images/CcRegtangle.svg'
import { PosReqData, PositionDetails } from '../../types';
import Helper from '../../utils/helper';
import useAuth from '../../hooks/useAuth';
 import { toast } from 'react-toastify';
import { driver } from "driver.js";
import { useLocation, useNavigate } from 'react-router-dom'

const CircleIcon = {
    color: "white",
    fontSize: "22px"
};
const buttonStyle = {
    minWidth: "285px",
    border: "none",
    fontSize: "22px"
};

const PositionReqList = () => {
    const { user } = useAuth();
    let location = useLocation();

    const [loading, setLoading] = useState<boolean>(true);
    const [positionData, setPositions] = useState<PositionDetails[]>([]);
    const [positionReqs, setPositionReqs] = useState<PosReqData[]>([]);

    const navigate = useNavigate();


    const helper = Helper.getInstance();

    useEffect(() => {
        if (user) {
            getPositionDetails();
            getReqs();
        }
    }, [user]);

    const getPositionDetails = async () => {
        try {
            // setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            if (data) setPositions(data);
            // setLoading(false)
        } catch (error) {
            console.log(error);
            // setLoading(false)
        }
    };

    const getReqs = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.ALL_REQS);
            const { data } = res_data.data;

            if (data) setPositionReqs(data);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        // console.log(opArr)
        const op = opArr.find((x) => x.id == val);

        if (op) return op.position;

        return '';
    };

    const getSuffix = (value: number = 0) => {
        if (value && Number(value) > 2) return 's';
        return '';
    };

    const handleCardClick = (id: number, applicants: number = 0) => {
        // if (applicants > 0) {
        // setCurrentPosReq(id);
        navigate(`/employer-view-job/${id}`, { replace: true });
        // setActiveTab(5);
        // } else toast.error('No Candidates applied for this Job');
    }

    const handleAddClick = () => {
        navigate(`/employer-hire-talents-main`, { replace: true });
    };

    if (loading) return <p>Loading...</p>

    // if (location.pathname == "/employer-hire-talents") {
    //     const driverObj = driver({
    //         showProgress: true,
    //         steps: [
    //             { element: '.create-account-btn', popover: { title: 'Click Here', description: 'To Create a Job Requirement in few clicks.' } },
    //             // { element: '.company-info-cst', popover: { title: 'Your Company', description: "View and edit your company's profile and billing information" } },
    //             // { element: '.manage-talents-cst', popover: { title: 'Hire Software Engineers', description: "Recruit, hire, and manage payroll and benefits for talent worldwide in just a few clicks." } },
    //             // { element: '.cost-calcultor-cst', popover: { title: 'Cost Calculator', description: 'Estimate hiring costs.' } },
    //         ]
    //     });

    //     driverObj.drive();
    // }   

    return (
        <div id="content" className='pt-5'>
            <div className="container-fluid">
                <div className='top-navigation mb-5'>
                     <NotificationComponent/>
                    <h2>Hire Talents</h2>
                    <p>Create new requirements or review candidates for posted jobs.</p>
                </div>
                {
                    positionReqs.length > 0 ? (
                        <>
                            <div className='d-flex justify-content-between align-items-center flex-wrap mt-5 mb-5'>
                                <div>
                                    <h3>All Requirements</h3>
                                </div>
                                <div>
                                    <button onClick={() => handleAddClick()} className="create-account-btn p-3" style={buttonStyle}>
                                        <BsPlusCircle style={CircleIcon} />
                                        &nbsp;Hire Talents
                                    </button>
                                </div>
                            </div>

                                <div className='d-flex flex-wrap display-cst-requirements'>
                                    {
                                        positionReqs.map((posReq) => (
                                            <div className='mb-4' style={{ cursor: 'pointer' }} key={posReq.id} onClick={() => handleCardClick(posReq.id, posReq.noOfApply)}>
                                                <div className='card hire-talents-card p-3'>
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <h4>{getOptionLabel(posReq.positionId || 1, positionData)}</h4>
                                                        {posReq.noOfApply !== undefined && posReq.noOfApply > 0 && <button className='button-active'>{posReq.noOfUnseen} New Applicant{getSuffix(posReq.noOfUnseen)}</button>}
                                                    </div>
                                                    <div className='d-flex justify-content-between mt-2 align-items-center'>
                                                        <ul className='d-flex m-0 p-0 px-3' style={{ gap: "30px" }}>
                                                            <li><p>{posReq.numberOfPosition} position{getSuffix(posReq.numberOfPosition)}</p></li>
                                                            <li><p>{posReq.noOfApply} Applicant{getSuffix(posReq.numberOfPosition)}</p></li>
                                                        </ul>
                                                        <p className='hired-cst'>{posReq.noOfHired}/{posReq.numberOfPosition} Hired</p>
                                                    </div>
                                                    <div className='d-flex justify-content-between mt-4'>
                                                        <div>
                                                            <h5 className='cst-secondary-color'>Reject</h5>
                                                            <h6>{posReq.noOfRejected} applicant{getSuffix(posReq.noOfRejected)}</h6>
                                                        </div>
                                                        <div>
                                                            <h5 className='cst-secondary-color'>Interview Scheduled</h5>
                                                            <h6>{posReq.noOfShortlisted} applicant{getSuffix(posReq.noOfShortlisted)}</h6>
                                                        </div>
                                                        <div>
                                                            <h5 className='cst-secondary-color'>Not Seen</h5>
                                                            <h6>{posReq.noOfUnseen} applicant{getSuffix(posReq.noOfUnseen)}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        ) : (
                            <div className='mt-5 text-center'>
                                <img src={RegtangleImg} alt='Regtangle' />
                                <div className='d-flex justify-content-center mt-5'>
                                    <div className='col-md-6 col-sm-6 col-lg-6 col-12'>
                                        <h4 style={{ color: "rgb(188, 188, 188)" }}>Please add requirement to take a glimpse of your savings you can do with owow.</h4>
                                    </div>
                                </div>
                                <div className='mt-5'>
                                    <button onClick={() => handleAddClick()} className="create-account-btn p-3" style={buttonStyle}> <BsPlusCircle style={CircleIcon} />
                                        &nbsp;Hire Talents
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
    )
}

export default PositionReqList