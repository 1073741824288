import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAuth from '../../../hooks/useAuth';

import MarshMello from '../../../Assets/Icons/undraw_mello.svg'
import { User } from '../../../types';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeLetsGetStartedPage = () => {
    const navigate = useNavigate();
    const { tokenLogin } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        const init = async () => {
            const token = searchParams.get("token");
            if (!token) navigate('/candidate-signup', { replace: true });
            else {
                const userData = await tokenLogin(token, true);
                if (!userData) navigate('/candidate-login', { replace: true });
                else setUser(userData)
            }
        }
        init();
    }, [searchParams])

    return (
        <>
            <section className='let-started'>
                <div className='row'>
                    <div className='col-sm-6 lets-get-started-container d-none d-sm-block d-print-block'>
                        <div className='container'>
                            <h1 className='owow d-sm-none mb-4'>owow</h1>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    {/* <h1 className="owow-lets-started">owow</h1> */}
                                    <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />

                                    <h2 className="welcome-lete-started" >Create your profile👋</h2>
                                    <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                                    <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                        <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6 new-right-side-col'>
                        {/* <h2 className='owow d-sm-none mb-4'>owow</h2> */}
                        <div className="text-center pt-4 pb-4">
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none ' />
                        </div>
                        <div className='container'>
                            <div className='right-side-container'>
                                <p className='d-sm-none text-white text-center'>Welcome
                                    {/* { user?.email || ''} */}
                                    !👋</p>
                                <img src={MarshMello} className='px-2 marshmello-image-box img-fluid' />
                                <h1 className='lets-started-heading mt-4' style={{ fontSize: "40px", letterSpacing: "-0.64px" }}>Let’s get started!</h1>
                                <p className='heado-para margin-fix-bottam'>
                                    Email verified! 🎉<br />
                                    Just a few clicks left to complete your account.
                                </p>
                                <div className="col-12 text-center margin-fix-bottam">
                                    <button className='btn create-account-btn p-3 col-12' style={{ fontSize: "20px" }}
                                        onClick={() => navigate("/candidate-personal-details", { state: { customer: user } })}
                                    >Get Started</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default EmployeeLetsGetStartedPage