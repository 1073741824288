
import { useEffect, useState } from 'react';
import CheckIconImage from '../../../Assets/Icons/Group 47363 (3).png'

const ContractEndedMessageModel = ({ name }: { name: string }) => {
    const [name2, setName2] = useState<string>('');

    useEffect(() => {
        if (name) setName2(name);
    }, [name]);

    const clearCache = () => {
        setName2('');
        document.getElementById('updateView')?.click()
    };

    return (
        <>
            <div className="modal fade" id="endcontractMessage" aria-hidden="true" aria-labelledby="endcontractMessageLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="endcontractMessageLabel">End Contract</h3>
                            <button
                                id='endcontractMessageModel'
                                type="button"
                                className="btn-close"
                                data-bs-target="#endcontractMessage"
                                data-bs-toggle="modal"
                                aria-label="Close"
                                onClick={() => clearCache()}
                            ></button>
                        </div>
                        <div className='container' style={{ backgroundColor: '#492A99' }}>
                            <div className="modal-body">
                                <div className="text-center contract-ended-cst-background">
                                    <div className='mb-5'>
                                        <img src={CheckIconImage} className="img-fluid" />
                                    </div>
                                    <h2>Contract Ended</h2>
                                    <br />
                                    <p>Your contract with <b>{name2}</b> will end after the notice period, effective from today. You will be billed for the services provided during this specific time period.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContractEndedMessageModel