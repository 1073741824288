import React, { useState } from 'react'
import { PerkModelProps, PosReqOtherDetails } from '../../../types'

const HealthCareModel = ({ setPosReqOtherData }: PerkModelProps) => {
    const [selected, setSelected] = useState<number>();

    const handelChange = (evt: any) => {
        const val = evt.target.value;
        setSelected(Number(val));
    };

    const handleSubmit = (evt: any) => {

        evt.preventDefault();

        if (!selected) return;

        setPosReqOtherData((posReq: PosReqOtherDetails) => {
            const perksData = posReq.perks;
            perksData.push(selected);

            console.log(perksData);

            return { ...posReq, perks: perksData };
        });

        document.getElementById("closeHealthCareModal")?.click();
    };

    return (
        <>
            <div className="modal fade" id="healthCareModalToggle" aria-hidden="true" aria-labelledby="healthCareModalToggleLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="healthCareModalToggleLabel" className='cst-primary-color'>Healthcare Plans</h3>
                            <button id='closeHealthCareModal' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='container'>
                            <h5 className='mt-3'>Personalized health plans for your teammates with a dedicated claim manager.</h5>
                            <form name='HealthCareModel' id='HealthCareModelForm' onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12'>
                                            <input
                                                type="radio"
                                                id="healcareRadio"
                                                name="drone"
                                                value="2"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12'>
                                            <h5 className='cst-primary-color'>Cover $6K</h5>
                                            <h6>Select a healthcare plan that best meets your team’s needs and budget</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$10/Month Per Person</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12'>
                                            <input
                                                type="radio"
                                                id="healcareRadio"
                                                name="drone"
                                                value="3"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12'>
                                            <h5 className='cst-primary-color'>Cover $12K </h5>
                                            <h6>Select a healthcare plan that best meets your team’s needs and budget</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6> $15/Month Per Person</h6>
                                        </div>
                                    </div><div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12'>
                                            <input
                                                type="radio"
                                                id="healcareRadio"
                                                name="drone"
                                                value="4"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12'>
                                            <h5 className='cst-primary-color'>Cover $20K  </h5>
                                            <h6>Select a healthcare plan that best meets your team’s needs and budget</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$24/Month Per Person</h6>
                                        </div>
                                    </div>
                                    <button
                                        className='btn create-account-btn p-2 col-12'
                                        style={{ minWidth: "158px", minHeight: "58px" }}
                                        type='submit'>
                                        {/* <img src={AddIcon}/> */}
                                        Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HealthCareModel