import MarshMello from '../Assets/Admin/Icons/undraw_mello.svg'
import React, { useEffect, useState } from 'react'
import { PasswordValidation, User, UserPersonalDetails } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { BsCalendarFill, BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import useAuth from '../../../hooks/useAuth';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import ProgressLineComponentForHireTalent from '../../../Components/Employer/ProgressLineComponentForHireTalent';
import { ALL_COUNTRY_CODES, ALL_COUNTY, addressCountries, customStyles, genders } from '../../../utils/constant';
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

import CountryIconOne from '../../../Assets/CountryIcons/in.png'
import CountryIconTwo from '../../../Assets/CountryIcons/ad.png'
import CountryIconThree from '../../../Assets/CountryIcons/ae.png'
import CountryIconFoure from '../../../Assets/CountryIcons/us.png'
import dayjs from 'dayjs';
import { DatePicker } from 'antd';


const EmployeePersonalDeatilsPage = () => {

    const helper = Helper.getInstance();
    const navigate = useNavigate()
    const location = useLocation();
    const [verifying, setVerifying] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPassValid, setIsPassValid] = useState<boolean>(true);
    const { login } = useAuth();

    const initialValue: User = {
        id: 1,
        email: '',
        name: "",
        phone: "",
        dob: "",
        jobTitle: "",
        country: "",
        workingCountry: "",
        gender: "",
        password: ""
    };

    const [user, setUser] = useState<User>(initialValue);
    // const [OTPToken, setOTPToken] = useState('');
    // const [OTP, setOTP] = useState<any[]>([null, null, null, null]);

    // const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    // const [selectedCountryFlag, setSelectedCountryFlag] = useState(CountryIconOne);
    // // const [selectedDay, setSelectedDay] = useState(null);
    // // const [startDate, setStartDate] = useState(new Date());
    // const [tandcRead, setTandcRead] = useState<boolean>(false);

    const initPassVal: PasswordValidation = {
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
        hasLowercase: false,
        isMinLength: false,
    };

    const [passValidation, setPassValidation] = useState<PasswordValidation>(initPassVal);

    useEffect(() => {
        if (!location.state || !location.state.customer) navigate('/candidate-login', { replace: true });
        else {
            // login('', '', location.state.customer);
            if (location.state.customer.dob) {
                try {
                    location.state.customer.dob = (new Date(location.state.customer.dob)).toISOString().split('T')[0];
                } catch (error) {
                    console.log(error);
                }
            };
            setUser(location.state.customer);
            // setSelectedCountryCode(location.state?.customer?.countryCode || '+1');
            // setSelectedCountryFlag(location.state?.customer?.countryCode == '+1' ? CountryIconFoure : CountryIconOne);
        }
    }, []);

    // const getOTP = async () => {
    //     setVerifying(true);
    //     try {
    //         if (!user.phone) return;

    //         const res_data = await helper.postReq(helper.POST.EMP_GEN_OTP, { phone: `${selectedCountryCode}${user.phone}`, type: 1 });
    //         const { data } = res_data.data;

    //         if (data) {
    //             setOTPToken(data);
    //             toast.success('OTP Sent Successfully');
    //         }
    //         else throw new Error("Failed");

    //     } catch (error) {
    //         console.log(error);
    //         toast.error('Invalid Phone Number');
    //     }
    //     setVerifying(false);
    // };

    const addUserDetails = async () => {

        try {
            const userDat = {
                name: user.name?.replaceAll("null", ''),
                phone: user.phone,
                dob: user.dob,
                gender: user.gender,
                country: user.country,
                countryCode: user.countryCode,
                workingCountry: user.workingCountry,
                password: user.password
            };

            const res_data = await helper.postReq(helper.POST.EMP_ADD_PERSONAL_DETAILS, userDat);
            const { data } = res_data.data;

            if (data.customer) {
                if (user.isCollaborator) {
                    navigate("/candidate-congratulations", { replace: true, state: { customer: data.customer } })
                } else {
                    const state = {
                        customer: data.customer,
                        skillAndEmpStatus: data.skillAndEmpStatus
                    }
                    navigate('/candidate-your-expirence', { replace: true, state });
                    setLoading(false);
                }
            }

            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Add Personal Details Failed');
        }
    }

    // const verifyOTP = async (auto: boolean = false) => {
    //     try {
    //         if (!OTPToken) return;

    //         const enteredOTP = ((OTP.join()).replaceAll(',', '')).trim();
    //         console.log(enteredOTP);

    //         // if (enteredOTP === '0000') await addUserDetails();
    //         // else {
    //             const res_data = await helper.postReq(helper.POST.EMP_VERIFY_OTP, { token: OTPToken, OTP: enteredOTP });
    //             const { data } = res_data.data;

    //             if (data) await addUserDetails();
    //             else if (!auto) throw new Error("Failed");
    //         // }
    //     } catch (error) {
    //         setLoading(false);
    //         if (!auto) {
    //             console.log(error);
    //             toast.error('Entered OTP is Incorrect');
    //         }
    //     }
    // };

    // const handleOTPChange = (index: number, val: number) => {
    //     const newOTP = [...OTP];
    //     newOTP[index] = val;
    //     setOTP(newOTP);
    //     // if (index === 4) verifyOTP(true);
    // }

    // useEffect(() => {
    //     const enteredOTP = ((OTP.join()).replaceAll(',', '')).trim();
    //     console.log(enteredOTP);

    //     if (enteredOTP === '0000') {
    //         setLoading(true);
    //         addUserDetails();
    //     }
    // }, [OTP])

    const maxDate = () => {
        const today = new Date('01/01/2012');
        return today;
    };

    const minDate = () => {
        const today = new Date('01/01/1950');
        return today;
    };

    const handleSubmit = async (ev: any) => {
        setLoading(true);
        ev.preventDefault();

        if (nameErr) return toast.error(nameErr);

        // if (OTPToken) verifyOTP();
        // else if (user.isPhoneVerified) {
        if (user.isCollaborator && !isPassValid) return toast.error('Invalid Password');
        await addUserDetails();
        // }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const current = e.currentTarget;
        if (e.key === "ArrowLeft" || e.key === "Backspace") {
            const prev = current.previousElementSibling as HTMLInputElement | null;
            prev?.focus();
            prev?.setSelectionRange(0, 1);
            return;
        }

        else {
            const prev = current.nextSibling as HTMLInputElement | null;
            prev?.focus();
            prev?.setSelectionRange(0, 1);
            return;
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        // const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(
        const isPasswordValid = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z]).{10,}$/.test(
            newPassword
        );
        setIsPassValid(isPasswordValid);
        setPassValidation({
            hasNumber: /\d/.test(newPassword),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
            hasUppercase: /[A-Z]/.test(newPassword),
            hasLowercase: /[a-z]/.test(newPassword),
            isMinLength: newPassword.length >= 10,
        });
        setUser({ ...user, password: newPassword });
    };

    // const handleCountryCodeChange = (code: any, flag: any) => {
    //     setSelectedCountryCode(code);
    //     setSelectedCountryFlag(flag);
    //     setUser({ ...user, countryCode: code });
    // };

    const [nameErr, setNameErr] = useState<string>('');

    const handleName = (name: string) => {
        const nameRegex = /^[^\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/;
        const valid = nameRegex.test(name);

        if (!valid) setNameErr('Invalid Name');
        else setNameErr('');

        setUser({ ...user, name });
    };

    return (
        <section className='let-started'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                        <div className='container'>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    {/* <h1 className="owow-lets-started">owow</h1> */}
                                    <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started pt-5' />

                                    <h2 className="welcome-lete-started" >Create your profile👋</h2>
                                    <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                                    <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                        <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='d-sm-block d-print-block cst-container-bg'>
                        {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                        <div className='text-center'>
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none mt-4' />
                        </div>

                        <div className='cst-mobile-bg '>
                            <div className='right-side-container-personal'>
                                {/* <div className='text-center'>
                                    <img src={Logo_Horizontal_purpleImage} className='img-fluid d-none d-sm-block d-print-block mt-5' />
                                </div> */}
                                <div className='owow d-none d-sm-block d-print-block'>
                                    <ProgressLineComponentForHireTalent />
                                </div>
                                <h1 className='welcome-heading personal-details-heading-right-side pt-4 mt-0'>Personal details</h1>
                                <h4 className='heado-para margin-fix-bottam'>We need your personal details to shape your profile like a pro.</h4>
                                <form id='personalForm' onSubmit={handleSubmit}>
                                    <div className="col-md-12 margin-fix-bottam">
                                        <label>Name*</label>
                                        <input
                                            type="text"
                                            name='firstname'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={user.name}
                                            onChange={(val) => handleName(val.target.value)}
                                            required
                                        />
                                        {nameErr !== "" && <p style={{ color: 'red', fontSize: 'medium' }}>{nameErr}</p>}
                                    </div>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Nationality*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Nationality'
                                            options={ALL_COUNTY}
                                            value={ALL_COUNTY.filter((ct) => ct.value == user?.country)[0]}
                                            onChange={(ev) => setUser({ ...user, country: ev?.value || '' })}
                                        />
                                    </div>

                                    <div className="col-12  margin-fix-bottam">
                                        <label>Working From*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Nationality'
                                            options={ALL_COUNTY}
                                            value={ALL_COUNTY.filter((ct) => ct.value == user?.workingCountry)[0]}
                                            onChange={(ev) => setUser({ ...user, workingCountry: ev?.value || '' })}
                                        />
                                    </div>

                                    {(user.isCollaborator || user.addedById !== 0) && (
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Password*</label>
                                            <input
                                                value={user.password}
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={handlePasswordChange}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                id="password"
                                                required
                                            />
                                            {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}

                                            {!isPassValid && (
                                                <div className='password-validation-card p-3 mb-4'>
                                                    <h6 className="text-black checkbox-text">Your password must contain:</h6>
                                                    <div className='form-check'>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.isMinLength} />
                                                                <p className="text-black checkbox-text"> a minimum of 10 character</p>
                                                            </li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasUppercase} />
                                                                <p className="text-black checkbox-text"> an uppercase character</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasLowercase} />
                                                                <p className="text-black checkbox-text"> a lowercase character</p>
                                                            </li>
                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasNumber} />
                                                                <p className="text-black checkbox-text"> a number</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasSpecialChar} />
                                                                <p className="text-black checkbox-text"> a special character</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}


                                    <div className="col-12 margin-fix-bottam">
                                        <label>Date of birth*</label>
                                        <div className='date-picker-input'>
                                            <DatePicker
                                                className='form-control custom-datepicker'
                                                disabledDate={d => !d || d.isAfter(maxDate()) || d.isBefore(minDate())}
                                                suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                allowClear={false}
                                                defaultValue={user.dob ? dayjs(user.dob) : undefined}
                                                defaultPickerValue={dayjs('12/31/2011')}
                                                onChange={(val) => setUser({ ...user, dob: val?.toISOString() })}
                                                placeholder='YYYY-MM-DD'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Gender*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Gender'
                                            options={genders}
                                            value={genders.filter((ct) => ct.value == user.gender)[0]}
                                            onChange={(ev) => setUser({ ...user, gender: ev?.value || '' })}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label>Mobile number*</label>
                                        <div className='d-flex' style={{ gap: "12px" }}>
                                            <div className='col-2'>
                                                <Select
                                                    styles={customStyles}
                                                    className="mb-4"
                                                    placeholder="Select"
                                                    name='Country Code'
                                                    options={ALL_COUNTRY_CODES}
                                                    value={ALL_COUNTRY_CODES.filter((ct) => ct.label == user.countryCode)[0]}
                                                    onChange={(ev) => setUser({ ...user, countryCode: ev?.label || '' })}
                                                />
                                                {/* <button
                                                    type="button"
                                                    className="phno"
                                                    id="countryCodeDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span id="selectedCountryCode">
                                                        {selectedCountryCode} <img src={selectedCountryFlag} className="img-fluid" />{' '}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                                            <path d="M7 8L0 0H14L7 8Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="countryCodeDropdown">
                                                    <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+91', CountryIconOne)}>
                                                        <img src={CountryIconOne} className="img-fluid" alt="India" /> +91 (India) <hr />
                                                    </a>
                                                    <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+1', CountryIconFoure)}>
                                                        <img src={CountryIconFoure} className="img-fluid" alt="US" />  +1 (US)
                                                    </a>

                                                </div> */}
                                            </div>
                                            <div style={{ width: "-webkit-fill-available" }}>
                                                <input
                                                    type="number"
                                                    className="form-control custom-input"
                                                    name="phone"
                                                    value={user.phone}
                                                    pattern="[0-9]*"
                                                    onChange={(val) => setUser({ ...user, phone: val.target.value.replace(/[^0-9]/g, '') })}
                                                    id="phone"
                                                    placeholder="Enter here"
                                                    minLength={9}
                                                    maxLength={10}
                                                    required
                                                />
                                                {/* {!user.isPhoneVerified && <span onClick={() => getOTP()} className='verify'>Verify</span>} */}
                                            </div>
                                        </div>
                                        <label htmlFor=""></label>

                                    </div>
                                    {/* <div className="col-12 form-check  margin-fix-bottam">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={tandcRead}
                                            onChange={() => setTandcRead((val) => !val)}
                                            id="flexCheckDefault"
                                        />
                                        <p className="text-black checkbox-text">I agree to receive text messages from OWOW.</p>
                                    </div>

                                    {OTPToken && (
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Enter OTP sent on your mobile number.</label>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <div className="position-relative">
                                                    <div id="otp" className="message-inputs d-flex flex-row justify-content-center" style={{ gap: "26px" }}>
                                                        <input
                                                            className="mt-2 text-center form-control rounded"
                                                            onChange={(ev) => handleOTPChange(0, Number(ev.target.value))}
                                                            onKeyUp={handleKeyUp}
                                                            type="text"
                                                            id="first"
                                                            maxLength={1}
                                                        />
                                                        <input
                                                            className="mt-2 text-center form-control rounded"
                                                            onChange={(ev) => handleOTPChange(1, Number(ev.target.value))}
                                                            onKeyUp={handleKeyUp}
                                                            type="text"
                                                            id="second"
                                                            maxLength={1}
                                                        />
                                                        <input
                                                            className="mt-2 text-center form-control rounded"
                                                            onChange={(ev) => handleOTPChange(2, Number(ev.target.value))}
                                                            onKeyUp={handleKeyUp}
                                                            type="text"
                                                            id="third"
                                                            maxLength={1}
                                                        />
                                                        <input
                                                            className="mt-2 text-center form-control rounded"
                                                            onChange={(ev) => handleOTPChange(3, Number(ev.target.value))}
                                                            onKeyUp={handleKeyUp}
                                                            type="text"
                                                            id="fourth"
                                                            maxLength={1}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2">
                                                <label>Didn’t receive the code?<b style={{ textDecorationLine: "underline" }} onClick={() => getOTP()}> Re-Send</b></label>
                                            </div>
                                        </div>
                                    )} */}

                                    <div className="col-12 text-center margin-fix-bottam pb-5">
                                        <button
                                            type='submit'
                                            className='btn create-account-btn p-3 col-12'
                                            style={{ fontSize: "20px" }}
                                            disabled={nameErr !== '' || !user.name || !user.phone || !user.dob || !user.gender || loading}
                                        >{loading ? 'Saving Details...' : 'Next'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmployeePersonalDeatilsPage