import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { YEARSPLUS10, customStyles, roleTypes } from '../../../utils/constant';
import ProfileEditIcon from '../../../Assets/Images/Group47624.png'
import CloseIcon from '../../../Assets/Icons/close-bold-icon.png'
import { Certification, CertificationProps, OptionType2 } from '../../../types';
import Helper from '../../../utils/helper';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';



export const AddCertificationsModal = ({ certification }: CertificationProps) => {

    const initVal: Certification = {
        employeeId: 1,
        courseName: '',
        orgName: '',
        issueDate: '',
        expirationDate: '',
        credentialId: '',
        credentialUrl: '',
    };
    const [cert, setCert] = useState<Certification>(certification || initVal);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setEditing] = useState<boolean>(false);
    const [ENDYEARS, setENDYEARS] = useState<OptionType2[]>([]);
    const helper = Helper.getInstance();
    const { user } = useAuth();

    useEffect(() => {
        setCert(certification || initVal);
        if (certification) setEditing(true);
        else setEditing(false);


        return () => {
            setCert(initVal);
            setEditing(false);
        }
    }, [certification]);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        if (isEditing) updateCertification();
        else addCertification();

        ev.target.reset();
    };

    const addCertification = async () => {
        try {
            const addData = { ...cert, employeeId: user?.id };
            const res_data = await helper.postReq(helper.POST.ADD_CERTIFICATION, addData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                setCert(initVal);
                setEditing(false);
                toast.success('Aded Successfully');

                document.getElementById('updatePr')?.click();
                document.getElementById("AddCertModalClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Add Details Failed');
        }
        setLoading(false);
    }

    const updateCertification = async () => {
        try {
            const uData = { ...cert, employeeId: user?.id };
            const res_data = await helper.putReq(helper.PUT.UPDATE_CERTIFICATION, uData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                setCert(initVal);
                setEditing(false);
                toast.success('Updated Successfully');
                document.getElementById('updatePr')?.click();
                document.getElementById("AddCertModalClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Update Details Failed');
        }
        setLoading(false);
    }

    const handleStartYear = (year: string) => {
        setCert({ ...cert, issueDate: year });
        const data = YEARSPLUS10.filter((op) => op.value >= Number(year));
        setENDYEARS(data);
    };

    return (
        <>
            <form name='addExp' onSubmit={handleSubmit}>
                <div className="modal fade" id="AddCertificationsModalToggle" aria-hidden="true" aria-labelledby="AddCertificationsModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered add-certifications-modal-cst" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3>Certifications</h3>
                                <button type="button" id='AddCertModalClose' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-md-12 margin-fix-bottam">
                                        <label>Course Name</label>
                                        <input
                                            type="text"
                                            name='Course'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={cert?.courseName}
                                            onChange={(val) => setCert({ ...cert, courseName: val.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-12 margin-fix-bottam">
                                        <label>Issuing Organization</label>
                                        <input
                                            type="text"
                                            name='Issuing'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={cert?.orgName}
                                            onChange={(val) => setCert({ ...cert, orgName: val.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-4">
                                        <label>Issue Year</label>
                                        <Select
                                            styles={customStyles}
                                            placeholder="Select"
                                            name='StartYear'
                                            options={YEARSPLUS10}
                                            value={YEARSPLUS10.filter((ctd) => ctd.value.toString() == cert.issueDate)[0]}
                                            onChange={(ev) => handleStartYear((ev?.value || '').toString())}
                                            required
                                        />

                                    </div>
                                    <div className="col-md-6 col-12 mb-4">
                                        <label>Expiration Year <span className='cst-secondary-color'>(optional)</span></label>
                                        <Select
                                            styles={customStyles}
                                            placeholder="Select"
                                            name='StartYear'
                                            options={ENDYEARS}
                                            value={ENDYEARS.filter((ctd) => ctd.value.toString() == cert.expirationDate)[0]}
                                            onChange={(ev) => setCert({ ...cert, expirationDate: (ev?.value || '').toString() })}
                                            required
                                        />

                                    </div>
                                    <div className="col-md-12 margin-fix-bottam">
                                        <label>Credential Id <span className='cst-secondary-color'>(optional)</span></label>
                                        <input
                                            type="text"
                                            name='Credential'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={cert?.credentialId}
                                            onChange={(val) => setCert({ ...cert, credentialId: val.target.value })}
                                        />
                                    </div>
                                    <div className="col-md-12 margin-fix-bottam">
                                        <label>Credential URL <span className='cst-secondary-color'>(optional)</span></label>
                                        <input
                                            type="text"
                                            name='URL'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={cert?.credentialUrl}
                                            onChange={(val) => setCert({ ...cert, credentialUrl: val.target.value })}
                                        />
                                    </div>
                                    <div className='d-flex chnagepasss-cst-col my-2' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                        {
                                            isEditing ? (
                                                <button
                                                    type='submit'
                                                    className='btn create-account-btn'
                                                    style={{ minWidth: "159px" }}
                                                    disabled={loading}
                                                >{loading ? 'Updating...' : 'Update'}
                                                </button>
                                            ) : (
                                                <button
                                                    type='submit'
                                                    className='btn create-account-btn'
                                                    style={{ minWidth: "159px" }}
                                                    disabled={loading}
                                                >{loading ? 'Saving...' : 'Save'}
                                                </button>
                                            )
                                        }

                                        <button
                                            type='button'
                                            id='AddCertModalClose'
                                            className='btn social-media-btn'
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            style={{ minWidth: "159px" }}
                                            disabled={loading}
                                        >Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
