import MarshMello from '../Assets/Admin/Icons/undraw_mello.svg'
import React, { useEffect, useState } from 'react'
import { EmpStatusDetails, SkillsAndExpDetails, User, UserPersonalDetails } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import Select from 'react-select';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import useAuth from '../../../hooks/useAuth';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import ProgressLineComponentForHireTalent from '../../../Components/Employer/ProgressLineComponentForHireTalent';
import { EMP_STATUS, POSITION_TYPES, customStyles } from '../../../utils/constant';
import axios from 'axios';
import UplodIcon from '../../../Assets/Icons/material-symbols_upload.svg'
import { imageAllowedFileTypes } from '../../../types';
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'
import CurrencyInput from 'react-currency-input-field';
// const prefix = '$    ';
const postfix = ' /annum';
const EmployeeEmploymentStatus = () => {

    const [customer, setCustomer] = useState<User | null>(null);
    const [selectedFileName, setSelectedFileName] = useState('Upload Resume (PDF | Max: 5 MB)');
    const helper = Helper.getInstance();
    const [prefix, setPrefix] = useState<string>('$    ');

    const { login } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    const initialValue: EmpStatusDetails = {
        employeeId: null,
        status: null,
        noticePeriod: null,
        positionType: null,
        currentPASalary: null,
        expectedPASalary: null,
        resumeLink: ''
    };

    const [empStatus, setEmpStatus] = useState<EmpStatusDetails>(initialValue);
    const [loading, setLoading] = useState<boolean>(false);
    const [skAndExp, setSkAndExp] = useState<SkillsAndExpDetails>();

    useEffect(() => {
        if (!location.state || !location.state.customer) navigate('/candidate-login', { replace: true });
        else setCustomer(location.state.customer);

        // if (props && props.company) setCompany(location.state.company);
        if (location.state && location.state.skAndExp) setSkAndExp(location.state.skAndExp);

    }, []);

    useEffect(() => {
        if (customer) setPrefix(customer.workingCountry == 'IN' ? '₹    ' : '$    ');
    }, [customer]);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);

        if (!empStatus.resumeLink) return toast.error('Please Upload Resume First');

        addEmpStatus();
    };

    const addEmpStatus = async () => {
        try {
            const newData = { ...empStatus, employeeId: customer?.id };

            // if (!newData.noticePeriod) newData.noticePeriod = 0;
            const bData: EmpStatusDetails = {
                ...newData,
                noticePeriod: newData.noticePeriod || 0,
                currentPASalary: newData.currentPASalary || 0,
                employeeId: customer?.id || 1
            };
            // console.log(newData);
            const res_data = await helper.postReq(helper.POST.ADD_EMP_STATUS_DETAILS, bData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                navigate('/candidate-congratulations', { replace: true, state: { customer } });
                console.log(data);
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Add Details Failed');
        }
    }

    const handleUpload = async (ev: any) => {
        const file = ev.target.files[0]
        if (!file) return;

        try {
            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);

            reqData.append('id', (customer?.id || 1).toString());
            reqData.append('file', file, `${fileID}_${file.name}`);

            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.RESUME_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            const { data } = res_data.data;
            setSelectedFileName(file.name);


            console.log(data);
            if (data) setEmpStatus({ ...empStatus, resumeLink: data });
        } catch (error) {
            console.log(error);
            toast.error('Failed to Upload Resume');
        }
    };

    return (
        <>
            <section>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                            <div className='container'>
                                <div className='cst-mobile-bg '>
                                    <div className='right-side-container'>
                                        <img src={Logo_Horizontal_WhiteImage} className="img-fluid owow-lets-started" />
                                        <h2 className="welcome-lete-started" >Create your profile👋</h2>
                                        <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                                        <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                            <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                            <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                            <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-6'>
                        <div className='d-sm-block d-print-block cst-container-bg'>
                            <div className='text-center'>
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none mt-4' />
                            </div>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container-personal'>
                                    <br />
                                    <div className='owow d-none d-sm-block d-print-block'>
                                        {/* <ProgressLineComponentForHireTalent /> */}
                                        <div className='d-flex progress-lines'>
                                            <div className='hor-line-primary '></div>
                                            <div className='hor-line-primary '></div>
                                            <div className='hor-line-primary '></div>
                                        </div>
                                    </div>
                                    <h1 className='welcome-heading personal-details-heading-right-side mt-5'>Your employment details</h1>
                                    <h4 className='heado-para margin-fix-bottam'>We need your employment details to shape your profile like a pro.</h4>
                                    <form name='EMPSTATUS' onSubmit={handleSubmit} >
                                        <div className="col-12  margin-fix-bottam">
                                            <label>Current employment status*</label>
                                            <Select
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                                name='JobRole'
                                                options={EMP_STATUS}
                                                value={EMP_STATUS.filter((ct) => ct.value == empStatus.status)[0]}
                                                onChange={(ev) => setEmpStatus({ ...empStatus, status: ev?.value || 1 })}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Notice period</label>
                                            <div className='FieldPlaceholderWrap'>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Ex: 15, 25, 30....."
                                                    value={empStatus.noticePeriod || undefined}
                                                    onChange={(val) => setEmpStatus({ ...empStatus, noticePeriod: Number(val.target.value) })}
                                                />
                                                <span>In&nbsp;Days</span>
                                            </div>
                                        </div>

                                        <div className="col-12  margin-fix-bottam">
                                            <label>Job type*</label>
                                            <Select
                                                name='worktype'
                                                styles={customStyles}
                                                className="mb-4"
                                                placeholder="Select"
                                                options={POSITION_TYPES}
                                                value={POSITION_TYPES.filter((ct) => ct.value == empStatus.positionType)[0]}
                                                onChange={(ev) => setEmpStatus({ ...empStatus, positionType: ev?.value || 1 })}
                                                required
                                            />
                                        </div>
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Current annual salary</label>
                                            <div className='FieldPlaceholderWrap'>
                                                <CurrencyInput
                                                    // type='text'
                                                    id="input-example"
                                                    name="input-name"
                                                    placeholder={`${prefix} Current annual salary`}
                                                    required
                                                    prefix={prefix}
                                                    className="form-control custom-input"
                                                    value={empStatus.currentPASalary || undefined}
                                                    decimalsLimit={2}
                                                    onValueChange={(val) => setEmpStatus({ ...empStatus, currentPASalary: Number(val) })}
                                                 />
                                                <span>/&nbsp;annum</span>
                                            </div>
                                        </div>
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Expected annual salary*</label>
                                            <div className='FieldPlaceholderWrap'>
                                                <CurrencyInput
                                                    required
                                                    prefix={prefix}
                                                    name='epas'
                                                    className='form-control custom-input'
                                                    placeholder={`${prefix} Expected annual salary`}
                                                    value={empStatus.expectedPASalary || undefined}
                                                    // defaultValue={1000}
                                                    decimalsLimit={2}
                                                    onValueChange={(value) => setEmpStatus({ ...empStatus, expectedPASalary: Number(value) })}
                                                />
                                                <span>/&nbsp;annum</span>
                                            </div>
                                        </div>
                                        <div className="col-12  margin-fix-bottam">
                                            <label>Upload resume*</label>
                                            <div className='cst-input-field-made d-flex justify-content-between align-items-center'>
                                                <span>{selectedFileName}</span>
                                                <label htmlFor="file" className="custom-file-upload">
                                                    <input
                                                        required
                                                        type="file"
                                                        id='file'
                                                        className="form-control custom-input"
                                                        onChange={handleUpload}
                                                        accept="application/pdf"
                                                        style={{ display: 'none' }}
                                                    />
                                                    <img src={UplodIcon} className='img-fluid cst-ptr' alt="Upload Icon" />
                                                </label>
                                            </div>
                                        </div>

                                        <div className='d-flex mb-4' style={{
                                            justifyContent: 'center',
                                            gap: "16px"
                                        }}>
                                        </div>
                                        <div className="container col-12 text-center margin-fix-bottam pb-5" style={{ display: "flex", gap: "20px", justifyContent: 'space-evenly' }}>
                                            {/* <button
                                                type="button"
                                                className='btn btn-outline create-account-btn-outline p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                                onClick={() => navigate('/candidate-your-expirence', { replace: true, state: { customer, skillAndEmpStatus: skAndExp } })}
                                            >Back
                                            </button> */}
                                            <button
                                                type="submit"
                                                className='btn create-account-btn p-3 col-6'
                                                style={{ fontSize: "20px" }}
                                            disabled={loading || !empStatus.resumeLink}
                                            >{loading ? 'Finishing Up...' : 'Finish'}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

        </>
    )
}

export default EmployeeEmploymentStatus