import React from 'react'
import SettingImg from '../../Assets/Icons/setting.svg'
import RightImg from '../../Assets/Icons/rightArrow.svg'

const SecurityButton = () => {
    return (
        <>
            <button className="button-container cst-ptr mt-4" data-bs-target="#chnagePassordModalToggle" data-bs-toggle="modal" >
                <img src={SettingImg} alt='setting' />
                <div className="label-container ">
                    <label className="change-password cst-ptr" >Change password</label>
                    <label className="change-your-login cst-ptr" >Change your login password.</label>
                </div>
                <img src={RightImg} alt='arrow' />
            </button>

        </>
    )
}

export default SecurityButton