import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ProfileEditIcon from '../../Assets/Images/Group47624.png'
import { MONTHS, YEARS, customStyles, roleTypes } from '../../../utils/constant';
import { Experience, ExperienceProps, OptionType, OptionType2 } from '../../../types';
import useAuth from '../../../hooks/useAuth';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';



export const AddExperienceModal = ({ experience }: ExperienceProps) => {

    const initVal = {
        employeeId: 1,
        company: "",
        role: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        currentlyWorking: false,
        totalExp: "",
    };
    const [exp, setExp] = useState<Experience>(experience || initVal);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setEditing] = useState<boolean>(false);
    const [ENDYEARS, setENDYEARS] = useState<OptionType2[]>([]);
    const helper = Helper.getInstance();
    const { user } = useAuth();

    const handleSubmit = async (ev: any) => {

        ev.preventDefault();
        
        if ((exp.startYear == exp.endYear) && (Number(exp.startMonth) >= Number(exp.endMonth))) return toast.error('Invalid End Month');
        setLoading(true);
        if (isEditing) updateExperience();
        else addExperience();

        ev.target.reset();

    };

    useEffect(() => {
        setExp(experience || initVal);
        if (experience) setEditing(true);
        else setEditing(false);


        return () => {
            setExp(initVal);
            setEditing(false);
        }
    }, [experience]);


    const addExperience = async () => {
        try {
            const addData = { ...exp, employeeId: user?.id };
            const res_data = await helper.postReq(helper.POST.ADD_EXPERIENCE, addData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                setExp(initVal);
                setEditing(false);
                toast.success('Added Successfully');
                document.getElementById('updatePr')?.click();
                document.getElementById("AddExpirenceModalClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Add Details Failed');
        }
        setLoading(false);
    }

    const updateExperience = async () => {
        try {
            const uData = { ...exp, employeeId: user?.id };
            const res_data = await helper.putReq(helper.PUT.UPDATE_EXPERIENCE, uData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                setExp(initVal);
                setEditing(false);
                toast.success('Updated Successfully');
                document.getElementById('updatePr')?.click();
                document.getElementById("AddExpirenceModalClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Update Details Failed');
        }
        setLoading(false);
    }

    const handleStartYear = (year: string) => {
        setExp({ ...exp, startYear: year });
        const data = YEARS.filter((op) => op.value >= Number(year));
        setENDYEARS(data);
    };

    return (
        <>
            <form name='addExp' onSubmit={handleSubmit}>
                <div className="modal fade" id="AddExpirenceModalToggle" aria-hidden="true" aria-labelledby="AddExpirenceModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered add-expirence-modal-cst" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3>Add Experience</h3>
                                <button id='AddExpirenceModalClose' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-sm-12 col-12 margin-fix-bottam">
                                        <label>Company</label>
                                        <input
                                            type="text"
                                            name='Company'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={exp?.company}
                                            onChange={(val) => setExp({ ...exp, company: val.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-12 col-12 margin-fix-bottam">
                                        <label>Role</label>
                                        <input
                                            type="text"
                                            name='Role'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            value={exp?.role}
                                            onChange={(val) => setExp({ ...exp, role: val.target.value })}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-12 col-12">
                                        <label>Start Month </label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='StartMonth'
                                            options={MONTHS}
                                            value={MONTHS.filter((ct) => ct.value.toString() == exp.startMonth)[0]}
                                            onChange={(ev) => setExp({ ...exp, startMonth: (ev?.value || '').toString() })}
                                            required
                                        />
                                    </div>
                                    <div className="col-sm-6 col-md-12 col-12">
                                        <label>Start Year</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='StartYear'
                                            options={YEARS}
                                            value={YEARS.filter((ct) => ct.value.toString() == exp.startYear)[0]}
                                            onChange={(ev) => handleStartYear((ev?.value)?.toString() || '')}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 form-check d-flex" style={{ paddingLeft: "46px", paddingBottom: "20px" }}>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="flexCheckDefault"
                                            checked={exp.currentlyWorking}
                                            onChange={() => setExp({ ...exp, currentlyWorking: !exp.currentlyWorking })}
                                        />
                                        <p className="text-black privacy-policy pt-0">&nbsp;I am currently working in this role</p>
                                    </div>
                                    {
                                        !exp.currentlyWorking && (
                                            <>

                                                <div className="col-sm-6 col-md-12 col-12">
                                                    <label>End Month </label>
                                                    <Select
                                                        styles={customStyles}
                                                        className="mb-4"
                                                        placeholder="Select"
                                                        name='EndtMonth'
                                                        options={MONTHS}
                                                        value={MONTHS.filter((ct) => ct.value.toString() == exp.endMonth)[0]}
                                                        onChange={(ev) => setExp({ ...exp, endMonth: (ev?.value || '').toString() })}
                                                        required
                                                    />
                                                </div>
                                                <div className="col-sm-6 col-md-12 col-12">
                                                    <label>End Year</label>
                                                    <Select
                                                        styles={customStyles}
                                                        className="mb-4"
                                                        placeholder="Select"
                                                        name='EndYear'
                                                        options={ENDYEARS}
                                                        value={YEARS.filter((ct) => ct.value.toString() == exp.endYear)[0]}
                                                        onChange={(ev) => setExp({ ...exp, endYear: (ev?.value || '').toString() })}
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )
                                    }

                                </div>
                                <div className='d-flex chnagepasss-cst-col my-2' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                    {
                                        isEditing ? (
                                            <button
                                                type='submit'
                                                className='btn create-account-btn'
                                                style={{ minWidth: "159px" }}
                                                disabled={loading}
                                            >{loading ? 'Updating...' : 'Update'}
                                            </button>
                                        ) : (
                                            <button
                                                type='submit'
                                                className='btn create-account-btn'
                                                style={{ minWidth: "159px" }}
                                                disabled={loading}
                                            >{loading ? 'Saving...' : 'Save'}
                                            </button>
                                        )
                                    }

                                    <button
                                        type='button'
                                        id='AddExpirenceModalClose'
                                        className='btn social-media-btn'
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        style={{ minWidth: "159px" }}
                                        disabled={loading}
                                    >Cancel
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
