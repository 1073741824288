import React, { useEffect, useRef, useState } from 'react'
import Logo_Horizontal_WhiteImage from '../../Assets/Icons/Logo_Horizontal_White.png'
import Result1 from '../../Assets/Icons/result1.png';
import Result2 from '../../Assets/Icons/result2.png';
import Result3 from '../../Assets/Icons/result3.png';
import Result4 from '../../Assets/Icons/result4.png';
import Result5 from '../../Assets/Icons/result5.png';
import Result6 from '../../Assets/Icons/result6.png';
import Result7 from '../../Assets/Icons/result7.png';
import Result8 from '../../Assets/Icons/result8.png';
import Result9 from '../../Assets/Icons/result9.png';
import Result10 from '../../Assets/Icons/result10.png';

import moment from 'moment'

import TimerIcon from '../../Assets/Icons/Group 47637.png';
import CalenderIcon from '../../Assets/Icons/Group 47636.png';
import { Employee3, skData2 } from '../../types'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import Helper from '../../utils/helper'

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SKILL_TYPES } from '../../utils/constant';

export const AssesmentResultPDF = () => {

    const [skillsData, setSkillsData] = useState<skData2[]>();
    const [employee, setEmployee] = useState<Employee3>();
    const [loading, setLoading] = useState<boolean>(true);
    const helper = Helper.getInstance();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();

    const contentRef = useRef<HTMLDivElement>(null);
    const [pdfURL, setPdfURL] = useState<string | null>(null);

    useEffect(() => {
        if (id) getSkData();
    }, [id]);

    useEffect(() => {
        if (pdfURL) downloadPDF();
    }, [pdfURL]);

    const getSkData = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.EMPLOYEE_SK_DATA + id);
            const { data } = res_data.data;

            if (data) {
                setSkillsData(data.skills);
                setEmployee(data.employee);
                setTimeout(() => {
                    convertToPDF();
                }, 1000);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getImagefromResult = (result: number) => {
        switch (result) {
            case 1:
                return Result1;
            case 2:
                return Result2;
            case 3:
                return Result3;
            case 4:
                return Result4;
            case 5:
                return Result5;
            case 6:
                return Result6;
            case 7:
                return Result7;
            case 8:
                return Result8;
            case 9:
                return Result9;
            case 10:
                return Result10;
            default:
                return Result1;
        }
    };

    const convertToPDF = () => {
        if (contentRef.current) {
            html2canvas(contentRef.current).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgWidth = 210; // A4 size
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
                const pdfURL = pdf.output('bloburl');
                setPdfURL(pdfURL.toString());
            });
        }
    };

    const downloadPDF = () => {
        if (pdfURL) {
            const a = document.createElement('a');
            a.href = pdfURL;
            a.download = `Assesment-Result-OWOW000${employee?.id}.pdf`;
            a.click();
            // window.close();
        }
    };


    return (
        <>
            <section style={{ background: "#F1EBFF", height: '100%' }}>
                <div className='container' >
                    <div className='row'>
                        <div className='col-12 mx-auto' ref={contentRef}>
                            <div className='black-header-part-logoand-text p-4'>
                                <div className='mt-4'>
                                    <img src={Logo_Horizontal_WhiteImage} className='img-fluid' />
                                </div>
                                <div className='d-flex justify-content-between flex-wrap mt-5 mb-5 align-item-center'>
                                    <div >
                                        <label>Candidate Name</label>
                                        {searchParams.get('fromAdmin') && Number(searchParams.get('fromAdmin')) == 1 ? <h5>{employee?.name}</h5>: <h5
                                            className="blurred2"
                                            contentEditable={false}
                                            onMouseDown={(e) => e.preventDefault()}
                                            onContextMenu={(e) => e.preventDefault()}
                                            onSelect={(e) => e.preventDefault()}
                                        >{employee?.name}
                                        </h5>}
                                    </div>
                                    <div >
                                        <label>Position</label>
                                        <h5>{employee?.jobTitle}</h5>
                                    </div>
                                    <div >
                                        <label>Candidate Id</label>
                                        <h5>{`OWOW000${employee?.id}`}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className='cst-background-white-div p-4'>
                                <h3>Core Skills</h3>
                                <div className='mt-4 row p-3'>
                                    {skillsData?.map((sk) => {
                                        if (sk.assesmentResult && sk.skillType == SKILL_TYPES.MAIN) return (
                                            <div className='mb-2 col-4' key={sk.skill}>
                                                <img src={getImagefromResult(sk.assesmentResult?.result) || Result1} className='img-fluid' style={{ height: '150px', width: '150px' }} />
                                                <h4 className='mt-2'>{sk.skill}</h4>
                                                <label><img src={TimerIcon} className='img-fluid' /> 10 minutes</label><br />
                                                <label><img src={CalenderIcon} className='img-fluid' /> {moment(new Date(sk.assesmentResult?.createdAt || Date.now())).format("Do MMM YYYY")},</label><br />
                                                <label><img src={TimerIcon} /> {moment(new Date(sk.assesmentResult?.createdAt || Date.now())).format("h:mm a")}</label>
                                            </div>
                                        )
                                    })}
                                    {/* <div className='mb-2'>
                                        <img src={NineNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div>
                                    <div className='mb-2'>
                                        <img src={SixNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div>
                                    <div className='mb-2'>
                                        <img src={FoureNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div> */}

                                </div>
                                {/* <div className='d-flex justify-content-between flex-wrap mt-4'>
                                    <div className='mb-2'>
                                        <img src={TwoNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div>
                                    <div className='mb-2'>
                                        <img src={NineNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div>
                                    <div className='mb-2'>
                                        <img src={NineNumberImage} className='img-fluid' />
                                        <h5 className='mt-2'>React js</h5>
                                        <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                        <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                        <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                    </div>

                                </div> */}

                                <div className='mt-4'>
                                    {skillsData?.length !== 0 && <h3>Additional Skills</h3>}
                                    <div className='mt-4 row p-3'>
                                        {skillsData?.map((sk) => {
                                            if (sk.assesmentResult && sk.skillType == SKILL_TYPES.ADDITIONAL) return (
                                                <div className='mb-2 col-4' key={sk.skill}>
                                                    <img src={getImagefromResult(sk.assesmentResult?.result) || Result1} className='img-fluid' style={{ height: '150px', width: '150px' }} />
                                                    <h4 className='mt-2'>{sk.skill}</h4>
                                                    <label><img src={TimerIcon} className='img-fluid' /> 10 minutes</label><br />
                                                    <label><img src={CalenderIcon} className='img-fluid' /> {moment(new Date(sk.assesmentResult?.createdAt || Date.now())).format("Do MMM YYYY")},</label><br />
                                                    <label><img src={TimerIcon} /> {moment(new Date(sk.assesmentResult?.createdAt || Date.now())).format("h:mm a")}</label>
                                                </div>
                                            )
                                        })}
                                        {/* <div className='mb-2'>
                                            <img src={NineNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div>
                                        <div className='mb-2'>
                                            <img src={SixNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div>
                                        <div className='mb-2'>
                                            <img src={FoureNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div> */}

                                    </div>
                                    {/* <div className='d-flex justify-content-between flex-wrap mt-4'>
                                        <div className='mb-2'>
                                            <img src={TwoNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div>
                                        <div className='mb-2'>
                                            <img src={NineNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div>
                                        <div className='mb-2'>
                                            <img src={NineNumberImage} className='img-fluid' />
                                            <h5 className='mt-2'>React js</h5>
                                            <label><img src={TimerIcon} className='img-fluid' /> 1 hour 12 minutes</label><br />
                                            <label><img src={CalenderIcon} className='img-fluid' /> 29th September 2022,</label><br />
                                            <label><img src={TimerIcon} /> 8:00 PM IST</label>
                                        </div>

                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
