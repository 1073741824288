import React, { useEffect, useState } from 'react'
import Helper from '../../../utils/helper';
import { TicketDetails, imageAllowedFileTypes } from '../../../types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { BsCloudUploadFill, BsUpload } from 'react-icons/bs';
import UplodIcon from '../../../Assets/Icons/material-symbols_upload.svg'
const RaiseTicket = () => {

    const helper = Helper.getInstance();
    const [selectedFileName, setSelectedFileName] = useState('Upload here');

    const [loading, setLoading] = useState<boolean>(false);
    const initVal = {
        title: "",
        description: "",
        imageURL: "",
    };

    const [ticket, setTicket] = useState<TicketDetails>(initVal);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        try {
            const res_data = await helper.postReq(helper.POST.ADD_TICKET, ticket);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Added Successfully');
                document.getElementById('updateViewHelp')?.click();
                document.getElementById("cancel3")?.click();
                setSelectedFileName('');
                setTicket(initVal);
            }
            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Create Ticket Failed');
        }
    }

    const handleUpload = async (ev: any) => {
        const file = ev.target.files[0]
        if (!file) return;

        try {
            const reqData = new FormData();
            const fileID = (Math.random() + 1).toString(36).substring(7);

            reqData.append('file', file, `${fileID}_${file.name}`);

            const res_data = await axios.post(`${process.env.REACT_APP_IMAGE_BASE_URL}${helper.POST.TICKET_IMAGE_UPLOAD}`, reqData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                }
            });

            const { data } = res_data.data;

            console.log(data);
            if (data) {
                setTicket({ ...ticket, imageUrl: data });
                // Update the selected file name
                setSelectedFileName(file.name);
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to Upload Image');
        }
    };

    const handleCancel = () => {
        setSelectedFileName('');
                setTicket(initVal);
    };

    return (
        <>
            <form name='helpForm' id='helpForm' onSubmit={handleSubmit}>
                <div className="modal fade" id="exampleHelpModalToggle" aria-hidden="true" aria-labelledby="exampleHelpModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered rise-tickit-modal-cst" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h2 id="exampleHelpModalToggleLabel">Contact Us</h2>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Subject*</label>
                                        <input
                                            type="text"
                                            value={ticket.title}
                                            onChange={(ev) => setTicket({ ...ticket, title: ev.target.value })}
                                            name='name'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            required
                                        />
                                    </div>
                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Suggestions*</label>
                                        <textarea
                                            value={ticket.description}
                                            onChange={(ev) => setTicket({ ...ticket, description: ev.target.value })}
                                            id='Description'
                                            className="form-control"
                                            rows={4}
                                            placeholder="Enter here"
                                            style={{ resize: "none" }}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 margin-fix-bottam">
                                        <label>Upload Screenshot</label>
                                        {/* <input type="file" onChange={handleUpload} accept={imageAllowedFileTypes} id='file' className="form-control custom-input" /> */}
                                        <div className='cst-input-field-made d-flex justify-content-between align-items-center'>
                                            <span>{selectedFileName}</span>
                                            <label htmlFor="file" className="custom-file-upload">
                                                <input
                                                    type="file"
                                                    onChange={handleUpload}
                                                    accept={imageAllowedFileTypes}
                                                    id="file"
                                                    className="form-control custom-input"
                                                    style={{ display: 'none' }}
                                                />
                                                <img src={UplodIcon} className='img-fluid cst-ptr' alt="Upload Icon" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex my-2' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                <button
                                    type='button'
                                    id='cancel3'
                                    className='btn social-media-btn'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    style={{ minWidth: "159px" }}
                                    onClick={() => handleCancel()}
                                >Cancel</button>
                                <button
                                    type='submit'
                                    className='btn create-account-btn'
                                    disabled={loading}
                                    style={{ minWidth: "159px" }}
                                >{loading ? 'Creating Ticket...' : 'Submit'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

        </>
    )
}

export default RaiseTicket