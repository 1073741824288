import React, { useState } from 'react'
import { PerkModelProps, PosReqOtherDetails } from '../../../types';

const GymAccessModel = ({ setPosReqOtherData }: PerkModelProps) => {

    const [selected, setSelected] = useState<number>();

    const handelChange = (evt: any) => {
        const val = evt.target.value;
        setSelected(Number(val));
    };

    const handleSubmit = (evt: any) => {

        evt.preventDefault();

        if (!selected) return;

        setPosReqOtherData((posReq: PosReqOtherDetails) => {
            const perksData = posReq.perks;
            perksData.push(selected);

            console.log(perksData);

            return { ...posReq, perks: perksData };
        });

        document.getElementById("closeGymAccessModel")?.click();
    };
    return (
        <>
            {/* <button className="button-container cst-ptr mt-4" data-bs-target="#GymAccessModelToggle" data-bs-toggle="modal" >Gym Access</button> */}
            <div className="modal fade" id="GymAccessModelToggle" aria-hidden="true" aria-labelledby="GymAccessModelToggleLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="GymAccessModelToggleLabel" className='cst-primary-color'>Cult.Fit Subscription</h3>
                            <button id='closeGymAccessModel' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='container'>
                            <h5 className='mt-3'>Unlimited access to all PRO gyms, At-home live workouts, 2 Sessions per month at ELITE gyms & group classes.</h5>
                            <form name='GymAccessModel' id='GymAccessModelForm' onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                type="radio"
                                                id="no"
                                                name="drone"
                                                value="11"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>3 Months</h5>
                                            <h6>Your teammate will get access to cult.fit pass for 3 months</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$32/Month</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                type="radio"
                                                id="no"
                                                name="drone"
                                                value="12"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>6 Months</h5>
                                            <h6>Your teammate will get access to cult.fit pass for 6 months</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$20/Month</h6>
                                        </div>
                                    </div>
                                    <div className='card-model-healthcare row mb-3'>
                                        <div className='col-md-1 col-12 mb-2'>
                                            <input
                                                type="radio"
                                                id="no"
                                                name="drone"
                                                value="13"
                                                onClick={handelChange}
                                                required
                                                className='form-check-input'
                                            />
                                        </div>
                                        <div className='col-md-9 col-12 mb-2'>
                                            <h5 className='cst-primary-color'>12 Months <button className='btn btn-recommended'>Recommended</button></h5>
                                            <h6>Your teammate will get access to cult.fit pass for 12 months</h6>
                                        </div>
                                        <div className='col-md-2 col-12'>
                                            <h6>$12.5/Month</h6>
                                        </div>
                                    </div>


                                    <button
                                        className='btn create-account-btn p-2 col-12'
                                        style={{ minWidth: "158px", minHeight: "58px" }}
                                        type='submit'
                                    > + Add</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GymAccessModel