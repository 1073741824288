import React, { useEffect, useState } from 'react'
import EndContractModel from './EndContractModel';
import BlurredCV from '../../../Assets/Images/blurredCV.png';
import Helper from '../../../utils/helper';
import useAuth from '../../../hooks/useAuth';

const ContractsAgreementsModel = ({ posReqId, employeeId }: { posReqId: number, employeeId: number }) => {

    const [posRqId, setPosRqId] = useState<number>();
    const [empId, setEmpId] = useState<number>();
    const [isEnded, setIsEnded] = useState<boolean>(false);
    const [contractUrl, setContractUrl] = useState<string>();
    const [appliId, setAppliId] = useState<number>();
    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const { user } = useAuth();

    useEffect(() => {
        if (posReqId) {
            setPosRqId(posReqId);
            setEmpId(employeeId)
        };
    }, [posReqId, employeeId]);

    useEffect(() => {
        if (user && posRqId) getContract();
    }, [posRqId]);

    const getContract = async () => {

        if (!posRqId || !empId) return;

        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.CONTRACT + posRqId + '/' + empId);
            const { data } = res_data.data;

            if (data) {
                setAppliId(data.id);
                setContractUrl(data.contractUrl);
                setIsEnded(data.endContractDate ? true : false);
            }

            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const clearCache = () => {
        setPosRqId(undefined);
        setEmpId(undefined);
        setContractUrl('');
        setIsEnded(false);
        setAppliId(undefined);
    };

    return (
        <>
            {/* <button className="button-container cst-ptr mt-4" data-bs-target="#contractsAgreement" data-bs-toggle="modal" >Gym Access</button> */}
            <div className="modal fade" id="contractsAgreement" aria-hidden="true" aria-labelledby="contractsAgreementLabel" tabIndex={-1} >
                <div className="modal-dialog modal-lg modal-dialog-centered" style={{ maxWidth: "666px" }}>
                    <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                        <div className="modal-header">
                            <h3 id="contractsAgreementLabel">Contracts / Agreements</h3>
                            <button 
                                type="button" 
                                className="btn-close" 
                                data-bs-dismiss="modal" 
                                aria-label="Close"
                                onClick={() => clearCache()}
                            ></button>
                        </div>
                        <div className='container'>
                            {/* onSubmit={handleSubmit} */}
                            <div className="modal-body">
                                <div className='card contract-card-cst  mb-3'>
                                    {contractUrl ? <img src={BlurredCV} alt="BlurredCV" className='img-fluid' /> : 'No Contract Found'}

                                    {/* <p>Masood Ali <br />Banglore, India</p>
                                        <div className='d-flex justify-content-end'>
                                            <p>May 1st, 2022</p>
                                        </div>
                                        <p>Dear Masood;</p>
                                        <p>
                                            We are pleased to inform you that we have prepared the contract for our upcoming project.
                                            Please find the contract document attached to this email.
                                        </p>
                                        <p>
                                            To proceed, kindly review the contract thoroughly. If you agree with the terms and conditions,
                                            please sign the document and return it to us at your earliest convenience.
                                        </p>
                                        <p>
                                            You can also click the button below to access the contract document online and sign it digitally.
                                        </p>

                                        <p>
                                            Should you have any questions or require further clarification, do not hesitate to contact us.
                                            We are here to assist you and ensure a smooth process.
                                        </p> */}

                                </div>
                                {contractUrl && <div className="d-flex justify-content-end flex-wrap" style={{ gap: '12px' }}>
                                    <button
                                        disabled={loading || isEnded || !contractUrl}
                                        className='btn end-contract-btn p-2 col-md-4 col-12'
                                        type='button'
                                        data-bs-target="#endcontract"
                                        data-bs-toggle="modal"
                                        onClick={() => clearCache()}
                                    >{isEnded ? 'Contract Ended' : 'End Contract'}
                                    </button>
                                    <button
                                        disabled={loading || !contractUrl}
                                        className='btn create-account-btn p-2 col-md-4 col-12'
                                        type='button'
                                        onClick={() => window.open(contractUrl, '_blank', 'rel=noopener noreferrer')}
                                    >{contractUrl ? 'Download Pdf' : 'No Contract'}
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <EndContractModel appliId={appliId || 0} />
        </>
    )
}

export default ContractsAgreementsModel