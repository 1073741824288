import CheckIcon from '../../../Assets/Images/checkIcon.png'

const RequestRecivedModel = ({ name }: { name: string; }) => {

    const done = () => {
        document.getElementById("offcanvasRightFouretoggler")?.click();
        document.getElementById('updateView')?.click();
    }
    return (
        <>
            <div className=" offcanvas offcanvas-end offcanvas-cst-width " tabIndex={-1} id="offcanvasRightFoure" aria-labelledby="offcanvasRightFoureLabel"  >

                <div className="offcanvas-body">
                    <div className="container-fluid">

                        {/* <div className='float-end'>
                            <img src={CloseIcon} id='cancel' data-bs-dismiss="offcanvas" aria-label="Close" />
                        </div> */}
                        <div className='d-flex justify-content-center'>
                            <div className='request-recived-card-cst card'>
                                <div className='text-center'>
                                    <br />
                                    <br />
                                    <img src={CheckIcon} className='img-fluid' />
                                    <h2 className='mt-4 mb-4'>Request Received</h2>
                                    <h4 className='mt-4 mb-4'>Your interview with <b>{name}</b> has been successfully requested</h4>
                                    <h4>Please wait for at least 2 working days to get the interview report</h4>
                                    <br />
                                    <br />
                                </div>
                            </div>
                        </div>
                        <div className='text-center mt-5'>
                            <button className='btn request-outsource-interview-cst p-3' onClick={() => done()}>Done</button>
                            <button
                                id='offcanvasRightFouretoggler'
                                className='btn d-none'
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>

                    </div>
                </div>

            </div >
        </>
    )
}

export default RequestRecivedModel
