import React from 'react'

const ProgressLineComponentForHireTalent = () => {
    return (
        <>
            <div className='d-flex progress-lines'>
                <div className='hor-line-primary '></div>
                <div className='hor-line-secondary'></div>
                <div className='hor-line-secondary'></div>
            </div>
        </>
    )
}

export default ProgressLineComponentForHireTalent