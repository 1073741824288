import { KeyedObject } from '../types';

export const POST: KeyedObject = {
    USER_LOGIN: '/employer/customer/login',
    USER_REGISTER: '/employer/customer/',
    SOCIAL_LOGIN: '/employer/customer/social_login/',
    TOKEN_LOGIN: '/employer/customer/token_login/',
    GEN_OTP: '/employer/customer/send_resend_otp/',
    VERIFY_OTP: '/employer/customer/verify_otp/',
    ADD_PERSONAL_DETAILS: '/employer/customer/personal_details/',
    ADD_COMPANY_DETAILS: '/employer/company/',
    ADD_COMPANY_ADDRESS_DETAILS: '/employer/company_address/',
    ADD_COLLABORATOR: '/employer/collaborator',
    FORGOT_PASSWORD: '/employer/customer/forgot_password',
    CUSTOMER_IMAGE_UPLOAD: '/employer/customer/upload',
    ADD_TICKET: '/employer/ticket/',
    TICKET_IMAGE_UPLOAD: '/employer/ticket/upload',
    ADD_POSITION: '/employer/position',
    ADD_POSITION_REQUEST: '/employer/position_request',

    EMP_USER_LOGIN: '/employee/customer/login',
    EMP_USER_REGISTER: '/employee/customer/',
    EMP_SOCIAL_LOGIN: '/employee/customer/social_login/',
    EMP_TOKEN_LOGIN: '/employee/customer/token_login/',
    EMP_GEN_OTP: '/employee/customer/send_resend_otp/',
    EMP_VERIFY_OTP: '/employee/customer/verify_otp/',
    EMP_FORGOT_PASSWORD: '/employee/customer/forgot_password',
    EMP_ADD_PERSONAL_DETAILS: '/employee/customer/personal_details/',
    EMP_CUSTOMER_IMAGE_UPLOAD: '/employee/customer/upload',
    ADD_SK_AND_EXP_DETAILS: '/employee/skills_and_exp',
    ADD_EMP_STATUS_DETAILS: '/employee/emp_status',

    RESUME_UPLOAD: '/employee/emp_status/resume_upload',
    EMPLOYEE_IMAGE_UPLOAD: '/employee/customer/upload',

    ADD_EXPERIENCE: '/employee/experience/',
    ADD_EDUCATION: '/employee/education/',
    ADD_CERTIFICATION: '/employee/certification/',
    APPLY_FOR_JOB: '/employee/application',
    ALL_JOBS: '/employee/position_request/filtered',
    EMP_ADD_TICKET: '/employee/ticket/',
    APPLICANTS: 'employer/application/applicants',
    SCHEDULE_INTERVIEW: 'employer/application/schedule_interview',
    ADD_TALENT: 'employer/talent',
    TALENT_PROFILE: 'employer/talent/profile',

    COMPANY_IMAGE_UPLOAD: '/employer/company/upload',
    COMPANY_LOGO_IMAGE_UPLOAD: '/employer/position_request/upload',
    GEN_MCQS: '/employee/assesment/generate',
    CREATE_RESULT: '/employee/assesment/result',
    ADD_SKILL_DATA_DB: '/employer/skill',
    END_CONTRACT: '/employer/talent/end_contract/'
};

export const GET: KeyedObject = {
    DETAILS: '/employer/customer/',
    COLLABORATOR_DETAILS: '/employer/collaborator',
    COLLABORATOR: '/employer/collaborator',
    RESET_PASSWORD: '/employer/customer/reset_password',
    TICKETS: '/employer/ticket/',
    POSITIONS: '/employer/position',
    SALARYS: '/employer/position/salary',

    EMP_DETAILS: '/employee/customer/',
    EMP_POSITIONS: '/employee/skills_and_exp/position',
    EMP_PROFILES: '/employee/customer/profile',
    EMP_RESET_PASSWORD: '/employee/customer/reset_password',
    POS_DETAILS: '/employee/position_request/',
    GET_SK_AND_EXP: '/employee/skills_and_exp',
    EMP_TICKETS: '/employee/ticket/',

    ALL_REQS: '/employer/position_request/all',
    EMP_PROFILE_DETAILS: 'employer/application/emp_profile/',
    APPLIED_JOBS: '/employee/position_request/applied',
    ALL_TALENT: 'employer/talent/all',

    COMPANY_GI: '/employer/company/gi',
    EMPLOYER_DASH: '/employer/customer/dashboard',
    EMPLOYEE_DASH: '/employee/customer/dashboard',
    SAVED_JOBS: '/employee/position_request/bookmarked',
    OUT_INT: '/employer/position_request/outsource_interview',
    SK_DATA: '/employee/assesment/skills',
    EMPLOYEE_SK_DATA: '/employee/assesment/skills_data/',
    EMPLOYEE_NOTIFICATION: '/employee/customer/notifications',
    EMPLOYER_NOTIFICATION: '/employer/customer/notifications',
    SKILLS_DATA: '/employer/skill',
    CONTRACT: '/employer/talent/contract/',
};

export const PUT: KeyedObject = {
    RESEND_EMAIL: '/employer/customer/resend_verification_email',
    UPDATE_COMPANY: '/employer/company/',
    UPDATE_COLLABORATOR: '/employer/collaborator/',
    UPDATE_COLLABORATOR_STATUS: '/employer/collaborator/status',
    UPDATE_PASSWORD: '/employer/customer/change_password',
    RESET_PASSWORD: '/employer/customer/reset_password',
    UPDATE_TICKET: '/employer/ticket/',

    UPDATE_SK_AND_EXP_DETAILS: '/employee/skills_and_exp',
    UPDATE_EMP_STATUS_DETAILS: '/employee/emp_status',
    UPDATE_PROFILE: '/employee/customer/profile',
    UPDATE_SKILLS: '/employee/skills_and_exp/skills',
    UPDATE_OPS_SKILLS: '/employee/skills_and_exp/additonalSkills',

    UPDATE_EXPERIENCE: '/employee/experience/',
    UPDATE_EDUCATION: '/employee/education/',
    UPDATE_CERTIFICATION: '/employee/certification/',
    UPDATE_JOB_PREFRENCES: '/employee/customer/job_pref',

    BOOKMARK: '/employee/position_request/bookmark',

    EMP_UPDATE_PASSWORD: '/employee/customer/change_password',
    EMP_RESET_PASSWORD: '/employee/customer/reset_password',
    EMP_UPDATE_TICKET: '/employee/ticket/',
    EMP_RESEND_EMAIL: '/employee/customer/resend_verification_email',
    UPDATE_APPLICATION: 'employer/application',
    SCHEDULE_INTERVIEW: 'employer/application/schedule_interview',
    UPDATE_TALENT: 'employer/talent',
    UPDATE_COMPANY_ADDRESS: '/employer/company_address/',
    UPDATE_CUS_GUIDE_PREF: '/employer/customer/guide_pref',
    UPDATE_EMP_GUIDE_PREF: '/employee/customer/guide_pref',
};

export const DELETE: KeyedObject = {
    DELETE_EXPERIENCE: '/employee/experience/',
    DELETE_EDUCATION: '/employee/education/',
    DELETE_CERTIFICATION: '/employee/certification/',
    DELETE_POSITION_REQ: '/employer/position_request/',
};
