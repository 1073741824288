import { useEffect, useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent';
import WorkSuitcaseIcon from '../../Assets/Icons/material-symbols_work-outline.svg';
import CalenderIcon from '../../Assets/Icons/calendar-linear.svg';
import ClockIcon from '../../Assets/Icons/clockIcon.svg'
import { ApplPosReqData, OptionType, PosReqData, PositionDetails } from '../../types';
import Helper from '../../utils/helper';
import useAuth from '../../hooks/useAuth';
import DefaultImage from '../../Assets/Images/Image20240129183323.png';
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import { OFFICE_POLICY, EXPERIENCE_LEVEL, APPLICATION_STATUS, APPLICATION_STATUS_DATA } from '../../utils/constant';
import LottieAnimation from '../../Components/LottieAnimation';
import moment from 'moment'
import InitialsAvatar from 'react-initials-avatar';

const EmployeeMyApllicationsPage = () => {

    const [positionReqs, setPositionReqs] = useState<ApplPosReqData[]>([]);
    const [positions, setPositions] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const { user } = useAuth();
    const [activeTab, setActiveTab] = useState("MyApplications");

    useEffect(() => {
        if (user) {
            getPositionReqs();
            getPositionDetails();
        }
    }, [user]);

    const getPositionReqs = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.APPLIED_JOBS);
            const { data } = res_data.data;

            if (data) setPositionReqs(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getPositionDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
            if (data) setPositions(mapped);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };



    const handleMyApplications = () => {
        setActiveTab("MyApplications")
    };

    const handleMyInterview = () => {
        setActiveTab("MyInterview")
    };

    const getAplStatus = (status: number) => {
        console.log(status);
        switch (status) {

            case 1:
                return 'Applied';
            case 2:
                return 'Rejected';
            case 3:
                return 'Interview Requested';
            case 4:
                return 'Interview Outsourced';
            case 5:
                return 'Interview Scheduled';
            case 6:
                return 'Under Review';
            case 10:
                return 'Selected';
            default:
                return 'Under Review';
        }
    }

    if (!user) return <p>Loading...</p>

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <NotificationComponent />
                        <h2>My Applications</h2>
                        <p >You can review all your applications and interview status</p>
                    </div>
                    <div className='mt-5'>
                        <ul className="my-account-tabs-list">
                            <li><a href="#" className={`${activeTab == "MyApplications" && 'active'}`} onClick={handleMyApplications}>My Applications</a></li>
                            <li><a href="#" className={`${activeTab == "MyInterview" && 'active'}`} onClick={handleMyInterview}>My Interview</a></li>
                        </ul>
                    </div>
                    {activeTab === 'MyApplications' && <>
                        {positionReqs.map((posReq, index) => {
                            if (posReq.JobApplication[0].status !== APPLICATION_STATUS.INTERVIEW_REQUESTED) {
                                return (
                                    <div className='row employee-white-card align-items-center p-4 mb-4' key={posReq.id}>
                                        <div className='col-lg-1 col-md-2 col-sm-3'>
                                            {posReq.company.imageUrl ?
                                                <img src={posReq.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%" }} /> :
                                                <InitialsAvatar name={posReq.company.name} />
                                            }
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-3'>
                                            <label className='cst-secondary-color'>Job Role</label>
                                            <h3>{getOptionLabel(posReq.positionId || 1, positions)}</h3>

                                            <label className='cst-secondary-color'>Company</label>
                                            <h3
                                                className="blurred"
                                                contentEditable={false}
                                                onMouseDown={(e) => e.preventDefault()}
                                                onContextMenu={(e) => e.preventDefault()}
                                                onSelect={(e) => e.preventDefault()}
                                            >{posReq.company.name}</h3>
                                        </div>
                                        <div className='col-lg-3 col-md-3 col-sm-3'>
                                            <label className='cst-secondary-color'>Location</label>
                                            <h4><img src={LocationIcon} /> {getOptionLabel(posReq.officePolicy || 1, OFFICE_POLICY)} • {posReq.company.country}</h4>

                                            <label className='cst-secondary-color'>Experience Required</label>
                                            <h4><img src={WorkSuitcaseIcon} /> {getOptionLabel(posReq.seniority || 1, EXPERIENCE_LEVEL)}</h4>
                                        </div>
                                        <div className='col-lg-2 col-md-3 col-sm-3'>
                                            <label className='cst-secondary-color'>Shift Time</label>
                                            <h4><img src={ClockIcon} />&nbsp;{posReq.timezone}</h4>

                                            <label className='cst-secondary-color'>Notice Period</label>
                                            <h4><img src={CalenderIcon} /> 2 Weeks</h4>
                                        </div>
                                        <div className='col-lg-3 col-md-4 col-sm-3'>
                                            <button className='btn under-review-btn px-4'>{getAplStatus(posReq.JobApplication[0].status)}</button>
                                        </div>

                                    </div>
                                )
                            }
                        })}
                        {/* {positionReqs.length == 0 && <LottieAnimation />} */}
                    </>}

                    {activeTab === 'MyInterview' && <>
                        {positionReqs.map((posReq, index) => {
                            if (posReq.JobApplication[0].status === APPLICATION_STATUS.INTERVIEW_REQUESTED) {
                                return (
                                    <>
                                        <div className='employee-white-card p-4 mb-4' key={posReq.id}>
                                            <div className="d-flex justify-content-between formobile-all-jobs">
                                                <div>
                                                    {posReq?.company.imageUrl ?
                                                        <img src={posReq?.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%" }} /> :
                                                        <InitialsAvatar name={posReq?.company?.name || ''} />
                                                    }
                                                </div>
                                                <div>
                                                    <label className='cst-secondary-color'>Job Role</label>
                                                    <h2>{getOptionLabel(posReq.positionId || 1, positions)}</h2>

                                                    <label className='cst-secondary-color'>Company</label>
                                                    <h2
                                                        className="blurred"
                                                        contentEditable={false}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                        onSelect={(e) => e.preventDefault()}
                                                    >{posReq.company.name}</h2>
                                                </div>
                                                <div>
                                                    <label className='cst-secondary-color'>Location</label>
                                                    <h4><img src={LocationIcon} /> {getOptionLabel(posReq.officePolicy || 1, OFFICE_POLICY)} • {posReq.company.country}</h4>

                                                    <label className='cst-secondary-color'>Experience Required</label>
                                                    <h4><img src={WorkSuitcaseIcon} /> {getOptionLabel(posReq.seniority || 1, EXPERIENCE_LEVEL)}</h4>
                                                </div>
                                                <div>
                                                    <label className='cst-secondary-color'>Shift Time</label>
                                                    <h4><img src={ClockIcon} />&nbsp;{posReq.timezone}</h4>

                                                    <label className='cst-secondary-color'>Notice Period</label>
                                                    <h4><img src={CalenderIcon} /> 2 Weeks</h4>
                                                </div>
                                                <div>
                                                    <button className='btn under-review-btn'>Interview Scheduled</button>
                                                    <p className='text-black' style={{ fontSize: "16px" }}>on
                                                        {posReq.JobApplication[0]?.interviewScheduledAt1 ?
                                                            moment(new Date(posReq.JobApplication[0].interviewScheduledAt1)).format("Do MMM YYYY h:mm a") :
                                                            posReq.JobApplication[0].calendarLink || '-'}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })}

                        {/* {positionReqs.length == 0 && <LottieAnimation />} */}
                    </>}
                </div>
            </div>
        </>
    )
}

export default EmployeeMyApllicationsPage