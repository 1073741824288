import { useEffect, useState } from 'react';
import VerifiedIcon from '../../../Assets/Icons/material-symbols_verified.svg'
import { BsFillEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { PasswordValidation, UserEmailPass } from '../../../types';
import useAuth from '../../../hooks/useAuth';
import { toast } from 'react-toastify';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { LOGIN_TYPES } from '../../../utils/constant';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo_Horizontal_purpleImage from '../../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'
import jwtDecode from 'jwt-decode';

const EmployerLogin = () => {
    const initialValue: UserEmailPass = {
        email: "",
        password: ""
    };

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPassValid, setIsPassValid] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const location = useLocation();
    const navigate = useNavigate();

    const [user, setUser] = useState<UserEmailPass>(initialValue);
    const { socialLogin, login } = useAuth();
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);
        await login(user.email.toLocaleLowerCase().trim(), user.password);
        setLoading(false);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        const isPasswordValid = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z]).{10,}$/.test(
            newPassword
        );
        setIsPassValid(isPasswordValid);
        setUser({ ...user, password: newPassword });
    };

    const handleEmailChange = async (email: string) => {
        setUser({ ...user, email });
    };

    const isCompanyEmail = (email: string): boolean => {
        // List of company domains to compare against
        const commonEmailProviderDomains: string[] = [
            'gmail.com',
            'yahoo.com',
            'outlook.com',
            'aol.com',
            'icloud.com',
            'protonmail.com',
            'zoho.com',
            'mail.com',
            'yandex.com',
            'gmx.com',
            'tutanota.com',
            'fastmail.com',
            'hushmail.com',
            'rackspace.com',
            'cox.net',
            'earthlink.net',
            'att.net',
            'comcast.net',
            'verizon.net',
            'spectrum.net',
            '',
            '.com',
            '.org',
            '.net'
            // Add more domains as needed
        ];

        // Extract the domain from the email address
        const emailParts = email.split('@');
        if (emailParts.length !== 2) return false;

        const domain = emailParts[1];

        return !commonEmailProviderDomains.includes(domain);
    };

    const handleGoogleSuccess = async (data: any) => {
        if (data && data.credential) {
            console.log(data);
            const decoded: any = jwtDecode(data.credential);

            if (decoded && decoded.email && isCompanyEmail(decoded.email)) {
                setLoading(true);
                socialLogin(data.credential, LOGIN_TYPES.GOOGLE);
                setLoading(false);
            } else toast.error('Please Use a Company Email to Login');
        } else {
            setLoading(false);
            toast.error('Google Login Failed');
        }
    };

    return (
        <>
            <section>
                <div className='row'>
                    <div className='col-sm-6 d-none d-sm-block d-print-block'>
                        <div className='hero-left-col px-5'>
                            <h1 className='left-clumn-first-heading text-white'>“</h1>
                            <h1 className='left-clumn-heading text-white'>From talent acquisition to HR, legal compliance, and  logistics, we've got you covered.</h1>
                            <button className='btn btn-outline verified-button mt-2'><img src={VerifiedIcon} className='px-2' /> 100% Verfied Talents</button>
                        </div>
                    </div>
                    <div className='col-sm-6 '>
                        <div className='cst-container-bg'>
                            {/* <h1 className='owow'>owow</h1> */}
                            <div className='text-center'>
                                <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none mt-4' />
                            </div>

                            <div className='cst-mobile-bg'>
                                <form onSubmit={handleSubmit}>
                                    <div className='right-side-container'>
                                        {/* <h1 className='owow d-none d-sm-block d-print-block'>owow</h1> */}
                                        <img src={Logo_Horizontal_purpleImage} className='img-fluid d-none d-sm-block d-print-block mt-5' />

                                        <h1 className='welcome-heading d-none d-sm-block d-print-block mt-5'>Welcome 👋</h1>
                                        <h1 className='welcome-heading d-sm-none'>Welcome</h1> {/* Display on Mobile Only */}
                                        <h4 className='heado-para margin-fix-bottam'>Please Log in to continue further with the platform.</h4>



                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Email*</label>
                                            <input
                                                type="text"
                                                id='email'
                                                value={user.email}
                                                onChange={(val) => handleEmailChange(val.target.value)}
                                                className="form-control custom-input"
                                                placeholder="Enter Your Email ID"
                                                required
                                            />
                                        </div>

                                        <div className="col-12 input-group-lg">
                                            <label>Password*</label>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={handlePasswordChange}
                                                className="form-control custom-input"
                                                placeholder="Enter your password"
                                                id="password"
                                                required
                                            />
                                            {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}
                                        </div>
                                        <div className='col-12 text-end mb-4' style={{ cursor: 'pointer' }}>
                                            <a
                                                onClick={() => navigate('/employer-forgot-password', { replace: true })}
                                                className='log-in'
                                            >Forgot Password?</a>
                                        </div>

                                        <div className="col-12 text-center margin-fix-bottam">
                                            <button
                                                type='submit'
                                                disabled={loading}
                                                className='btn create-account-btn col-12 '>
                                                {!loading ? 'Log In' : 'Logging In...'}
                                            </button>

                                        </div>
                                        <div className='col-12 d-flex justify-content-center fix-margin-for-or '>
                                            <span className='divide-span '> </span>
                                            <span className='px-1 py-0' style={{ marginTop: "-10px" }}> OR</span>
                                            <span className='divide-span'> </span>
                                        </div>
                                        <div className='cst-button-alignment'>


                                            <button
                                                type='button'
                                                className='btn btn-outline social-media-btn w-100'>
                                                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
                                                    <GoogleLogin
                                                        onSuccess={handleGoogleSuccess}
                                                        text={'continue_with'}
                                                        onError={() => toast.error('Google Login Failed')}
                                                    />
                                                </GoogleOAuthProvider>
                                            </button>
                                        </div>
                                        <div className='col-12 text-center mt-4' style={{ gap: "10px" }}>
                                            <label>
                                                <p className='already-account'>Didn't have an account?</p>
                                            </label> <a href='#' onClick={() => navigate('/employer-signup', { replace: true })} className='log-in'>Sign Up</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default EmployerLogin