import { useEffect, useState } from 'react'
import { BsPlusCircle } from "react-icons/bs"
import Helper from '../../utils/helper'
import { ObjectMap, TicketDetails } from '../../types'
import { TICKET_STATUS_DATA } from '../../utils/constant'
import useAuth from '../../hooks/useAuth'
import moment from 'moment'
import MenuIcon from '../../Assets/Icons/menuIcon.svg'
import RegtangleImg from '../../Assets/Images/CcRegtangle.svg'
import EmpRaiseTicket from '../../Components/Candidate/Modals/EmpRaiseTicket'

const EmployeeHelpPage = () => {
    const [activeTab, setActiveTab] = useState("Alltickets");
    const [tickets, setTickets] = useState<TicketDetails[]>([]);
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(true);
    const { user } = useAuth();

    const getDetails = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.EMP_TICKETS);
            const { data } = res_data.data;

            if (data) setTickets(data);

        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // if (location.state && location.state.customer) login('', '', location.state.customer);

        setLoading(true)
        if (user) getDetails();
        setLoading(false)
    }, [user]);

    const buttonStyle = {
        minWidth: "285px",
        border: "none",
        fontSize: "22px"
    }

    const CircleIcon = {
        color: "white",
        fontSize: "22px"
    }

    const handleAllTickits = () => {
        setActiveTab("Alltickets")
    };

    const handleColsedTickits = () => {
        setActiveTab("Closedtickets")
    };

    return (
        <>
            <button id="updateViewHelp" className='d-none' onClick={() => getDetails()}></button>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>

                        <div className='d-flex justify-content-between align-items-center flex-wrap mb-5'>
                            <div>
                                <h2>Contact Us</h2>
                                <p >We're here to assist you with any questions or concerns you may have.</p>
                            </div>
                            {/* {tickets.length > 0 && <div>
                                <button id="add-collaborator" className="create-account-btn p-3" data-bs-target="#exampleEmpHelpModalToggle" data-bs-toggle="modal" style={buttonStyle}>
                                    <BsPlusCircle style={CircleIcon} /> Contact Us
                            0-6=poooo    </button>
                            </div>} */}
                        </div>
                    </div>

                    {/* {tickets.length > 0 ? (
                        <>
                            <div className='mt-5 mb-5'>
                                <ul className="my-account-tabs-list">
                                    <li><a href="#" className={`${activeTab == "Alltickets" && 'active'}`} onClick={handleAllTickits}>All tickets</a></li>
                                    <li><a href="#" className={`${activeTab == "Closedtickets" && 'active'}`} onClick={handleColsedTickits}>Closed tickets</a></li>
                                </ul>
                            </div>  <div className='card p-3' style={{ borderRadius: "17px", background: "#FFF", border: "none" }}>
                                <div className="table-responsive">
                                    <table className="table help-table-admin-cst">
                                        <thead>
                                            <tr>
                                                <th scope="col">TICKET NUMBER</th>
                                                <th scope="col">TITLE</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Date Created</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tickets.map((ticket) => {
                                                return <>
                                                    <tr key={ticket.id}>
                                                        <td className='pt-3'><b> OWOW{ticket.id}</b></td>
                                                        <td className='pt-3 text-capitalize' style={{ textDecoration: "underline", color: "#492A99" }}><b> {ticket.title}</b></td>
                                                        <td className='pt-3'>{ticket.description}</td>
                                                        <td className='pt-3'>{moment(ticket.createdAt).format("MM-DD-YYYY")}</td>
                                                        <td className='pt-3'>
                                                            <button className='button-active'>{TICKET_STATUS_DATA[ticket.status as keyof ObjectMap]}</button>
                                                        </td>
                                                        <td className='pt-3 details-card-for-input cst-ptr'>
                                                            <div className="dropdown">
                                                                <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" href="#">Close ticket<hr /></a></li>
                                                                    <li><a className="dropdown-item" href="#">Open</a></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                    ) : ( */}
                        <div className="textcenter">
                            <div className='mt-5 text-center'>
                                <img src={RegtangleImg} alt='Regtangle' />
                                <div className='d-flex justify-content-center mt-5'>
                                    <div className='col-md-6 col-sm-6 col-lg-6 col-12'>
                                        <h4>Request help or share your suggestion in just a few clicks.</h4>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <button id="add-collaborator" className="create-account-btn p-3" data-bs-target="#exampleEmpHelpModalToggle" data-bs-toggle="modal" style={buttonStyle}>
                                        <BsPlusCircle style={CircleIcon} /> Contact Us
                                    </button>
                                </div>
                            </div>
                        </div>
                    {/* )} */}
                </div>
            </div >
            <EmpRaiseTicket />
        </>
    )
}

export default EmployeeHelpPage