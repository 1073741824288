import { useEffect, useState } from 'react'
import NotificationComponent from '../../Components/NotificationComponent'
import MenuIcon from '../../Assets/Icons/menuIcon.svg'
import CloseCardIcon from '../../Assets/Icons/CloseCardIcon.svg'
import InterviewerImage from '../../Assets/Images/Ellipse505.png'

const BillingAndinvoicesPage = () => {

    const [view, setView] = useState<number>(1)
    const [activeTab, setActiveTab] = useState("AllInvoices");
    const [flag, setflag] = useState(false)

    const handleAllInvoices = () => {
        setView(1);
        setActiveTab("AllInvoices")
    };

    const handleRefunds = () => {
        setView(3);
        setActiveTab("Refunds")
    };
    const arr = [1, 2, 3, 4, 5, 6];
    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                         <NotificationComponent/>
                        <h2>Payments and refunds</h2>
                        <p>This dashboard will help you in analysing your activities in various aspects.</p>
                    </div>
                    <div className='mt-5 mb-5'>
                        <ul className="my-account-tabs-list">
                            <li><a href="#" className={`${activeTab == "AllInvoices" && 'active'}`} onClick={handleAllInvoices}>All invoices</a></li>
                            <li><a href="#" className={`${activeTab == "Refunds" && 'active'}`} onClick={handleRefunds}>Refunds</a></li>
                        </ul>
                    </div>
                    {activeTab === 'AllInvoices' && <>
                        {!flag ? <>
                            <div className='card cst-account-card p-4'>
                            <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">INVOICE NO.</th>
                                                <th scope="col">MONTH</th>
                                                <th scope="col">AMOUNT</th>
                                                <th scope="col">DATE CREATED</th>
                                                <th scope="col">DUE DATE</th>
                                                <th scope="col">STATUS</th>
                                                <th scope="col">ACTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {arr.map((iteam, index) => {
                                                return <>
                                                    <tr>
                                                        <td className='pt-3'>OWOW1245</td>
                                                        <td className='pt-3'>February, 2023</td>
                                                        <td className='pt-3'>$ 4,896</td>
                                                        <td className='pt-3'>23-09-2023</td>
                                                        <td className='pt-3'>31-09-2023</td>
                                                        <td className='pt-3'> <button className='button-active payment-pending-btn'>PAYMENT PENDING</button> </td>
                                                        <td className='pt-3 details-card-for-input cst-ptr'>
                                                            <div className="dropdown">
                                                                <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><a className="dropdown-item" href="#" onClick={() => setflag(true)}>View Details<hr /></a></li>
                                                                    <li><a className="dropdown-item" href="#">Download as PDF</a></li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </> : <>
                            <div className='card p-4 invoice-cst-owow' style={{ borderRadius: "36px", background: "#FFF", border: "none"}}>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <h2 className="cst-font-weight">INVOICE NO. #OWOW1245</h2>
                                        <h6 className='cst-secondary-color cst-font-weight-600'>From: OWOW talents solutions Pvt. Ltd.</h6>
                                        <h6 className='cst-secondary-color cst-font-weight-600'>To: Lazy Designers INC.</h6>

                                        <div className="billing-invoice-details table-responsive mt-5">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">ITEMS</th>
                                                        <th scope="col" className='text-end text-capitalize'>Payroll Pre-Funding</th>
                                                        <th scope="col" className='text-end text-capitalize'>Final Actual</th>
                                                        <th scope="col" className='text-end text-capitalize'>Net Due (USD)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Base Salary</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Employer Contribution</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Payslip Benefits</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Expenses</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Incentives</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 7,337.89</td>
                                                        <td className='pt-3 text-end '>$ 0.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <hr />
                                        </div>
                                        <div className="billing-invoice-details table-responsive mt-5">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td scope="col" className=''>Payroll Services Total</td>
                                                        <td scope="col" className='text-capitalize '>$ 9,387.85</td>
                                                        <td scope="col" className='text-capitalize '>$ 9,387.85</td>
                                                        <td scope="col" className='text-capitalize '>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Off payslips cost (employment)</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Off payslips cost (company)</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Other Benefits</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Deposits</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='pt-3 cst-secondary-color'>Management fees</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                        <td className=' pt-3'>$ 0.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <hr />
                                        <div className="billing-invoice-details table-responsive mt-5">
                                            <table className="table">
                                                <tbody>
                                                    <tr>
                                                        <td scope="col" className='w-50 invoice-cst-owow '>Total</td>
                                                        <td scope="col" className='text-capitalize '>$ 9,387.85</td>
                                                        <td scope="col" className='text-capitalize '>$ 9,387.85</td>
                                                        <td scope="col" className='text-capitalize '>$ 0.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className='d-flex justify-content-end px-5' style={{ gap: "46px" }}>
                                            <h2>Total amount (USD)</h2>
                                            <h2>$ 590.00</h2>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='d-flex justify-content-end mb-5'>
                                            <img src={CloseCardIcon} className='img-fluid cst-ptr' onClick={() => setflag(false)} />
                                        </div>
                                        <div className='card-payment-blue p-3'>
                                            <h4 className='cst-font-weight-600'>Document information</h4>
                                            <h6 className='cst-secondary-color'>Payment method</h6>
                                            <div className='d-flex justify-content-between mt-4 mb-2'>
                                                <h6>Due Mar 28, 2023</h6>
                                                <h6 className='cst-primary-color'>Change card</h6>
                                            </div>
                                            <div className='d-flex mb-5 flex-wrap' style={{ gap: "16px" }}>
                                                <button className='btn pay-now-btn p-2'>Pay now</button>
                                                <button className='btn manully-pay-btn p-2'>I want to pay manually</button>
                                            </div>
                                            <hr />
                                            <h6 className='cst-secondary-color cst-font-weight-600'>Resource breakup</h6>
                                            <div className='d-flex justify-content-between mobile-two-buttons mb-2'>
                                                <div className='d-flex mobile-two-buttons mb-4' style={{ gap: "20px" }}>
                                                    <div>
                                                        <img src={InterviewerImage} className='img-fluid' />
                                                    </div>
                                                    <div>
                                                        <h6>Satyam Tiwari</h6>
                                                        <h6>Product Designer</h6>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>$ 3,076</h6>
                                                    <h6 className='cst-primary-color'>view breakup</h6>
                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-between mobile-two-buttons mb-2'>
                                                <div className='d-flex mobile-two-buttons mb-4' style={{ gap: "20px" }}>
                                                    <div>
                                                        <img src={InterviewerImage} className='img-fluid' />
                                                    </div>
                                                    <div>
                                                        <h6>Satyam Tiwari</h6>
                                                        <h6>Sr. Product Designer</h6>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>$ 3,076</h6>
                                                    <h6 className='cst-primary-color'>view breakup</h6>
                                                </div>
                                            </div>
                                            <hr />
                                            <h6 className='cst-secondary-color'>View & print PDF</h6>
                                            <h6 className='cst-primary-color'>Download PDF</h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>}
                    </>}

                    {activeTab === 'Refunds' && <>
                        <div className='card p-4' style={{ borderRadius: "36px", background: "#FFF", border: "none"}}>
                        <div className="table-responsive" style={{ height: "100vh" }}>

                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">INVOICE NO.</th>
                                            <th scope="col">MONTH</th>
                                            <th scope="col">AMOUNT</th>
                                            <th scope="col">DATE CREATED</th>
                                            <th scope="col">DUE DATE</th>
                                            <th scope="col">STATUS</th>
                                            <th scope="col">ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr>
                                            <td className='pt-3'>OWOW1245</td>
                                            <td className='pt-3'>February, 2023</td>
                                            <td className='pt-3'>$ 4,896</td>
                                            <td className='pt-3'>23-09-2023</td>
                                            <td className='pt-3'>31-09-2023</td>
                                            <td className='pt-3'> <button className='button-active'>SUCCESSFUL</button> </td>
                                            <td className='pt-3 details-card-for-input cst-ptr'>
                                                <div className="dropdown">
                                                    <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="#" onClick={() => setflag(true)}>View Details<hr /></a></li>
                                                        <li><a className="dropdown-item" href="#">Download as PDF</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-3'>OWOW1245</td>
                                            <td className='pt-3'>February, 2023</td>
                                            <td className='pt-3'>$ 4,896</td>
                                            <td className='pt-3'>23-09-2023</td>
                                            <td className='pt-3'>31-09-2023</td>
                                            <td className='pt-3'> <button className='button-pending-payment payment-pending-btn'>PAYMENT PENDING</button> </td>
                                            <td className='pt-3 details-card-for-input cst-ptr'>
                                                <div className="dropdown">
                                                    <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="#" onClick={() => setflag(true)}>View Details<hr /></a></li>
                                                        <li><a className="dropdown-item" href="#">Download as PDF</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-3'>OWOW1245</td>
                                            <td className='pt-3'>February, 2023</td>
                                            <td className='pt-3'>$ 4,896</td>
                                            <td className='pt-3'>23-09-2023</td>
                                            <td className='pt-3'>31-09-2023</td>
                                            <td className='pt-3'> <button className='button-pending-payment payment-pending-btn'>PAYMENT PENDING</button> </td>
                                            <td className='pt-3 details-card-for-input cst-ptr'>
                                                <div className="dropdown">
                                                    <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="#" onClick={() => setflag(true)}>View Details<hr /></a></li>
                                                        <li><a className="dropdown-item" href="#">Download as PDF</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='pt-3'>OWOW1245</td>
                                            <td className='pt-3'>February, 2023</td>
                                            <td className='pt-3'>$ 4,896</td>
                                            <td className='pt-3'>23-09-2023</td>
                                            <td className='pt-3'>31-09-2023</td>
                                            <td className='pt-3'> <button className='button-active'>SUCCESSFUL</button> </td>
                                            <td className='pt-3 details-card-for-input cst-ptr'>
                                                <div className="dropdown">
                                                    <img src={MenuIcon} className='img-fluid btn-add-dropdwon-cst dropdown-toggle' id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" />
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" href="#" onClick={() => setflag(true)}>View Details<hr /></a></li>
                                                        <li><a className="dropdown-item" href="#">Download as PDF</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>}
                </div>
            </div >

        </>
    )
}

export default BillingAndinvoicesPage