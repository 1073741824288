import React, { useEffect, useState } from 'react'
import CompanyDetailsComponent from '../../../Components/Employer/CompanyDetailsComponent'
import CompanyDetailsSecondComponent from '../../../Components/Employer/CompanyDetailsSecondComponent'
import { useLocation } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

const CompanyDetailsPage = () => {
    const [flag, setflag] = useState<boolean | null>(null);
    const [data, setData] = useState(null);
    const [company, setCompany] = useState(null);
    const location = useLocation();
    // const { login } = useAuth();

    useEffect(() => {
        // console.log(location.state.company);
        // if (location.state.customer) login('', '', location.state.customer);
        if (location.state && location.state.company) handleNextButton(location.state.company);

    }, []);

    const handleNextButton = (data: any) => {
        setflag(false);
        setData(data);
    }
    const handlebackButton = () => {
        console.log("sdsdds")
        setflag(true)
        setData(null);
    }       
    return (
        <>
            {flag && <CompanyDetailsComponent company={company} handleNextButton={handleNextButton} />}
            {!flag && <CompanyDetailsSecondComponent setCompany={setCompany} data={data} handlebackButton={handlebackButton} />}
        </>
    )
}

export default CompanyDetailsPage