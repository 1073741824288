import NotificationComponent from '../../Components/NotificationComponent';
import BookMarkIcon from '../../Assets/Icons/bookmark-close-icon.svg';
import LocationIcon from '../../Assets/Icons/LocationIcon.svg';
import WorkSuitcaseIcon from '../../Assets/Icons/material-symbols_work-outline.svg';
import CalenderIcon from '../../Assets/Icons/calendar-linear.svg';
import ClockIcon from '../../Assets/Icons/clockIcon.svg'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useAuth from '../../hooks/useAuth';
import { OptionType, PosReqData, PositionDetails } from '../../types';
import Helper from '../../utils/helper';
import DefaultImage from '../../Assets/Images/Image20240129183323.png';
import { EXPERIENCE_LEVEL, OFFICE_POLICY } from '../../utils/constant';
import LottieAnimation from '../../Components/LottieAnimation';
import InitialsAvatar from 'react-initials-avatar';

const EmployeeSavedJobsPage = () => {

    const [positions, setPositions] = useState<OptionType[]>([]);
    const [positionReqs, setPositionReqs] = useState<PosReqData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const helper = Helper.getInstance();
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            getPositionReqs();
            getPositionDetails();
        }
    }, [user]);

    const getPositionReqs = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.SAVED_JOBS);
            const { data } = res_data.data;

            if (data) setPositionReqs(data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const getPositionDetails = async () => {
        try {
            setLoading(true);
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
            if (data) setPositions(mapped);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getOptionLabel = (val: string | number, opArr: any[]) => {
        const op = opArr.find((x) => x.value == val);

        if (op) return op.label;

        return '';
    };

    const handleViewDetailsButton = (id: number) => {
        navigate(`/candidate-view-jobs/${id}`, { replace: true });
    };

    const removeBookMark = async (id: number) => {
        try {
            setLoading(true);
            const uData = { positionReqId: id, isBookMarked: false };
            const res_data = await helper.putReq(helper.PUT.BOOKMARK, uData);
            const { data } = res_data.data;

            if (data) getPositionReqs();
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            console.log(error);
            toast.error('Update Details Failed');
        }
        setLoading(false);
    };

    if (!user) return <p>Loading...</p>

    return (
        <>
            <div id="content" className='pt-5'>
                <div className="container-fluid">
                    <div className='top-navigation mb-5'>
                        <NotificationComponent/>
                        <h2>Saved Applications</h2>
                        <p >This section helps you to  find jobs saved by you</p>
                    </div>
                    {positionReqs.map((posReq) => (
                        <div className='row employee-white-card align-items-center p-4 mb-4' key={posReq.id}>
                            <div className='col-lg-1 col-md-2 col-sm-3'>
                                {posReq?.company.imageUrl ?
                                    <img src={posReq?.company.imageUrl || DefaultImage} className='img-fluid' style={{ borderRadius: "50%" }} /> :
                                    <InitialsAvatar name={posReq?.company?.name || ''} />
                                }
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-3'>
                                <label className='cst-secondary-color'>Job Role</label>
                                <h3>{getOptionLabel(posReq.positionId || 1, positions)}</h3>

                                <label className='cst-secondary-color'>Company</label>
                                <h3
                                    className="blurred" 
                                    contentEditable={false} 
                                    onMouseDown={(e) => e.preventDefault()} 
                                    onContextMenu={(e) => e.preventDefault()} 
                                    onSelect={(e) => e.preventDefault()}
                                >{posReq.company.name}</h3>
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-3'>
                                <label className='cst-secondary-color'>Location</label>
                                <h4><img src={LocationIcon} /> {getOptionLabel(posReq.officePolicy || 1, OFFICE_POLICY)} • {posReq.companyAddres.country}</h4>

                                <label className='cst-secondary-color'>Experience Required</label>
                                <h4><img src={WorkSuitcaseIcon} /> {getOptionLabel(posReq.seniority || 1, EXPERIENCE_LEVEL)}</h4>
                            </div>
                            <div className='col-lg-2 col-md-2 col-sm-3'>
                                <label className='cst-secondary-color'>Shift Time</label>
                                <h4><img src={ClockIcon} />&nbsp;{posReq.timezone}</h4>

                                <label className='cst-secondary-color'>Notice Period</label>
                                <h4><img src={CalenderIcon} /> 2 Weeks</h4>
                            </div>
                            <div className='col-lg-2 col-md-2 col-sm-3 mt-3'>
                                <button className='btn view-details-btn px-4' onClick={() => handleViewDetailsButton(posReq.id)} disabled={loading}>View Details</button>
                            </div>
                            <div className='col-lg-1 col-md-2 col-sm-3 mt-3'>
                                <img src={BookMarkIcon} className='img-fluid cst-ptr' onClick={() => removeBookMark(posReq.id)} />
                            </div>
                        </div>
                    ))}
                    {positionReqs.length == 0 && <>
                        <br />
                        <br />
                        <br />
                        <br />
                        <h4 className='text-center pt-5'>You haven't applied any job yet</h4>
                    </>}
                    {/* {positionReqs.length == 0 && <LottieAnimation />} */}
                </div>
            </div>
        </>
    )
}

export default EmployeeSavedJobsPage