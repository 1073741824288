import MarshMello from '../Assets/Admin/Icons/undraw_mello.svg'
import React, { useEffect, useState } from 'react'
import { LanguageSelected, OptionType, PositionDetails, SkillData, SkillsAndExpDetails, SkillsAndExpDetails2, User } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import CheckWhiteIcon from '../../../Assets/Icons/VectorCheckWhite.svg'
import useAuth from '../../../hooks/useAuth';
import { EXPERIENCE_LEVEL, OFFICE_POLICY, TIMEZONES, customStyles, languagesOptions } from '../../../utils/constant';
import Logo_Horizontal_WhiteImage from '../../../Assets/Icons/Logo_Horizontal_White.png'

const EmployeeYourSkillsExperience = () => {

    const [customer, setCustomer] = useState<User | null>(null);
    const helper = Helper.getInstance();

    const { login } = useAuth();

    const location = useLocation();
    const navigate = useNavigate();
    const initialValue: SkillsAndExpDetails2 = {
        employeeId: 1,
        positionIds: [],
        skills: [],
        additionalSkills: [],
        seniority: 1,
        officePolicys: [],
        timezone: '',
        languages: [],
    };

    const [skAndExp, setSkAndExp] = useState<SkillsAndExpDetails2>(initialValue);
    const [positions, setPositions] = useState<OptionType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setEditing] = useState<boolean>(false);
    const [skillsDB, setSkillsDB] = useState<OptionType[]>([]);

    useEffect(() => {
        if (!location.state || !location.state.customer) navigate('/candidate-login', { replace: true });
        else {
            // login('', '', location.state.customer);
            setCustomer(location.state.customer);
            getPositionDetails();
            getSkillsDataDB();
        }

        // if (props && props.company) setCompany(location.state.company);
        if (location.state && location.state.skillAndEmpStatus) {
            setSkAndExp(location.state.skillAndEmpStatus)
            setEditing(true);
        }
    }, []);

    const getPositionDetails = async () => {
        try {
            setLoading(true)
            const res_data = await helper.getReq(helper.GET.POSITIONS);
            const { data } = res_data.data;

            const mapped: OptionType[] = data.map((dt: PositionDetails) => ({ label: dt.position, value: dt.id }));
            if (data) setPositions(mapped);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    };

    const getSkillsDataDB = async () => {
        try {
            const res_data = await helper.getReq(helper.GET.SKILLS_DATA);
            const { data } = res_data.data;
            if (data) {
                const mapped: OptionType[] = data.map((sk: SkillData) => ({ label: sk.skill, value: sk.skill }));
                setSkillsDB(mapped);
            }
        } catch (error) {
            console.log(error);
        }
    };
    
    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        if (isEditing) updateSkAndExp();
        else addSkAndExp();

    };

    const addSkAndExp = async () => {
        try {
            const newData = { ...skAndExp, employeeId: customer?.id };
            console.log(newData);
            const res_data = await helper.postReq(helper.POST.ADD_SK_AND_EXP_DETAILS, newData);
            const { data } = res_data.data;

            if (data) {
                navigate('/candidate-your-employement', { replace: true, state: { customer, skAndExp: data } });
                console.log(data);
            }
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Add Details Failed');
        }
        setLoading(false);
    }

    const updateSkAndExp = async () => {
        try {
            const upData = { ...skAndExp, id: skAndExp.id };
            // delete upData.id;

            const res_data = await helper.putReq(helper.PUT.UPDATE_SK_AND_EXP_DETAILS, upData);
            const { data } = res_data.data;

            if (data) navigate('/candidate-your-employement', { replace: true, state: { customer, skAndExp: data } });
            else throw new Error("Failed");
        } catch (error) {
            console.log(error);
            toast.error('Add Details Failed');
        }
        setLoading(false);
    };

    const getMultiSelectedDef = (dataArr: OptionType[], dataVal: number[] | string[]) => {
        if (!dataVal.length) return;

        const jdataArr: OptionType[] = JSON.parse(JSON.stringify(dataArr));
        const newArr = [];

        for (let d of dataVal) {
            const val = jdataArr.filter((dt) => dt.value == d);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handleLanguageSelect = (val: any) => {
        let arr = val.map((d: any) => ({ language: d.value, fluency: "Intermediate" }));
        setSkAndExp({ ...skAndExp, languages: arr });
    };

    const getMultiSelectedDef2 = () => {
        if (!skAndExp.languages.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(languagesOptions));
        const newArr = [];

        for (let ln of skAndExp.languages) {
            const val = ldataArr.filter((dt) => dt.value == ln.language);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const getMultiSelectedDef3 = () => {
        if (!skAndExp.skills.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(skillsDB));
        const newArr = [];

        for (let sk of skAndExp.skills) {
            const val = ldataArr.filter((dt) => dt.value == sk.skill);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const getMultiSelectedDef4 = () => {
        if (!skAndExp.additionalSkills.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(skillsDB));
        const newArr = [];

        for (let sk of skAndExp.additionalSkills) {
            const val = ldataArr.filter((dt) => dt.value == sk.skill);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handleLanguageSelect3 = (val: any) => {
        // let arr = val.map((d: any) => ({ skill: d.value, exp: 0 }));
        let arr = val.map((d: any) => {
            const found = skAndExp.skills.find((x) => x.skill == d.value);
            return { skill: d.value, experience: found?.experience || null }
        });
        setSkAndExp({ ...skAndExp, skills: arr });
    };

    const handleLanguageSelect4 = (val: any) => {
        // let arr = val.map((d: any) => ({ skill: d.value, exp: 0 }));
        let arr = val.map((d: any) => {
            const found = skAndExp.additionalSkills.find((x) => x.skill == d.value);
            return { skill: d.value, experience: found?.experience || null }
        });
        setSkAndExp({ ...skAndExp, additionalSkills: arr });
    };

    const handleSelectChange = (keyType: number, value: any) => {
        // console.log(value);
        let arr = value.map((d: any) => d.value);

        switch (keyType) {
            case 1:
                skAndExp.positionIds = arr;
                break;
            case 2:
                skAndExp.skills = arr;
                break;
            case 3:
                skAndExp.officePolicys = arr;
                break;
            case 4:
                skAndExp.timezone = arr;
                break;
            default:
                break;
        }

        setSkAndExp(skAndExp);
    };

    const setFluency = (index: number, val: string) => {
        const lang: LanguageSelected = { ...skAndExp.languages[index] };
        lang.fluency = val;
        skAndExp.languages[index] = lang;
        setSkAndExp({ ...skAndExp, languages: skAndExp.languages });
        // console.log(posReqOtherData.languagesData)
    };

    const setExp = (index: number, val: string) => {
        const sk = { ...skAndExp.skills[index] };
        sk.experience = val;
        skAndExp.skills[index] = sk;
        setSkAndExp({ ...skAndExp, skills: skAndExp.skills });
        // console.log(posReqOtherData.languagesData)
    };

    const setExp2 = (index: number, val: string) => {
        const sk = { ...skAndExp.additionalSkills[index] };
        sk.experience = val;
        skAndExp.additionalSkills[index] = sk;
        setSkAndExp({ ...skAndExp, additionalSkills: skAndExp.additionalSkills });
        // console.log(posReqOtherData.languagesData)
    };


    return (
        <section className='let-started'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                        <div className='container'>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    <img src={Logo_Horizontal_WhiteImage} className="owow-lets-started" />
                                    <h2 className="welcome-lete-started" >Create your profile👋</h2>
                                    <h4 className='text-white'>Unlock high-paying tech jobs at the best tech companies in the USA and India.</h4>
                                    <ul className='text-white regsiter-page-lists p-0 mt-4'>
                                        <li><h4><img src={CheckWhiteIcon} /> Global opportunities, excellent pay.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Full-time jobs, long-term engagements.</h4></li>
                                        <li><h4><img src={CheckWhiteIcon} /> Work directly with top tech companies.</h4></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='d-sm-block d-print-block cst-container-bg'>
                        {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                        <div className='text-center'>
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none mt-4' />
                        </div>
                        <div className='cst-mobile-bg '>
                            <div className='right-side-container-personal'>
                                <br />
                                <div className='owow d-none d-sm-block d-print-block'>
                                    {/* <ProgressLineComponentForHireTalent /> */}
                                    <div className='d-flex progress-lines'>
                                        <div className='hor-line-primary '></div>
                                        <div className='hor-line-primary '></div>
                                        <div className='hor-line-secondary'></div>
                                    </div>
                                </div>
                                <h1 className='welcome-heading personal-details-heading-right-side pt-4 mt-0'>Your Skills And Preferences</h1>
                                <h4 className='heado-para margin-fix-bottam'>We need your skills and job preferences to shape your profile</h4>
                                <form name="skForm" onSubmit={handleSubmit}>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Preferred Job Roles*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='JobRole'
                                            options={positions}
                                            value={getMultiSelectedDef(positions, skAndExp.positionIds || [])}
                                            onChange={(ev) => handleSelectChange(1, ev)}
                                            isMulti
                                            required
                                            isOptionDisabled={() => skAndExp.positionIds.length >= 3}
                                        />
                                    </div>

                                    <div className="col-12  margin-fix-bottam">
                                        <label>Core Technical Skills*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Skills'
                                            // options={skillsDB}
                                            options={skillsDB.filter((op) => skAndExp.additionalSkills?.find((sk) => sk.skill == op.value) == undefined)}
                                            onChange={(ev) => handleLanguageSelect3(ev)}
                                            value={getMultiSelectedDef3()}
                                            isMulti
                                            required
                                            isOptionDisabled={() => skAndExp.skills.length >= 5}
                                        />

                                        {skAndExp.skills.length !== 0 && (
                                            <>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <h6 className='cst-secondary-color'>Core Technical Skills Selected</h6>
                                                    </div>
                                                    <div className='col-6 text-start'>
                                                        <h6 className='cst-secondary-color'>Relevant years of experience</h6>
                                                    </div>

                                                </div>
                                                {
                                                    skAndExp.skills.map((sk, index) => (
                                                        <div className='row mt-2' key={`${sk.skill}${index}`} >
                                                            {/* <div className='col-md-6 col-12'>
                                                                <h5 className='mt-3'>{sk.skill}</h5>
                                                            </div>
                                                            <div className='col-md-6 col-12'>
                                                                <input
                                                                    name={`${sk.skill}${index}`}
                                                                    type="text"
                                                                    className="form-control custom-input"
                                                                    id={`${sk.skill}${index}`}
                                                                    onChange={(ev) => setExp(index, ev.target.value)}
                                                                    value={sk.exp}
                                                                />
                                                            </div> */}
                                                            <div className='col-6'>
                                                                <h5>{sk.skill}</h5>
                                                            </div>
                                                            <div className='col-6 cst-min-height-input-employee'>
                                                                <input
                                                                    name={`${sk.skill}${index}`}
                                                                    type="number"
                                                                    className="form-control custom-input"
                                                                    id={`${sk.skill}${index}`}
                                                                    onChange={(ev) => setExp(index, ev.target.value)}
                                                                    value={sk.experience}
                                                                    min={1}
                                                                    max={50}
                                                                    required
                                                                />
                                                            </div>
                                                            {/* <div className='col-2 text-end'>
                                                                <img src={CloseIcon} className='img-fluid' />
                                                            </div> */}

                                                        </div>
                                                    ))}

                                            </>
                                        )}
                                    </div>

                                    <div className="col-12  margin-fix-bottam">
                                        <label>Additional Skills</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Add Skills'
                                            // options={skillsDB}
                                            options={skillsDB.filter((op) => skAndExp.skills?.find((sk) => sk.skill == op.value) == undefined)}
                                            onChange={(ev) => handleLanguageSelect4(ev)}
                                            value={getMultiSelectedDef4()}
                                            isMulti
                                            isOptionDisabled={() => skAndExp.additionalSkills.length >= 5}
                                        />

                                        {skAndExp.additionalSkills.length !== 0 && (
                                            <>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <h6 className='cst-secondary-color'>Additional Skills Selected</h6>
                                                    </div>
                                                    <div className='col-6 text-start'>
                                                        <h6 className='cst-secondary-color'>Relevant years of experience</h6>
                                                    </div>

                                                </div>
                                                {
                                                    skAndExp.additionalSkills.map((sk, index) => (
                                                        <div className='row mt-2' key={`ads${sk.skill}${index}`} >
                                                            {/* <div className='col-md-6 col-12'>
                                                                <h5 className='mt-3'>{sk.skill}</h5>
                                                            </div>
                                                            <div className='col-md-6 col-12'>
                                                                <input
                                                                    name={`${sk.skill}${index}`}
                                                                    type="text"
                                                                    className="form-control custom-input"
                                                                    id={`${sk.skill}${index}`}
                                                                    onChange={(ev) => setExp(index, ev.target.value)}
                                                                    value={sk.exp}
                                                                />
                                                            </div> */}
                                                            <div className='col-6'>
                                                                <h5>{sk.skill}</h5>
                                                            </div>
                                                            <div className='col-6 cst-min-height-input-employee'>
                                                                <input
                                                                    name={`${sk.skill}${index}`}
                                                                    type="number"
                                                                    className="form-control custom-input"
                                                                    id={`${sk.skill}${index}`}
                                                                    onChange={(ev) => setExp2(index, ev.target.value)}
                                                                    value={sk.experience}
                                                                    min={1}
                                                                    max={50}
                                                                    required
                                                                />
                                                            </div>
                                                            {/* <div className='col-2 text-end'>
                                                                <img src={CloseIcon} className='img-fluid' />
                                                            </div> */}

                                                        </div>
                                                    ))}

                                            </>
                                        )}
                                    </div>

                                    <div className="col-12  margin-fix-bottam">
                                        <label>Seniority level*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='seniority'
                                            options={EXPERIENCE_LEVEL}
                                            value={EXPERIENCE_LEVEL.filter((ct) => ct.value == skAndExp.seniority)[0]}
                                            onChange={(ev) => setSkAndExp({ ...skAndExp, seniority: ev?.value || 1 })}
                                            required
                                        />
                                    </div>
                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Relevant years of experience*</label>
                                        <input
                                            type="number"
                                            name="relExp"
                                            value={skAndExp.relativeExp}
                                            onChange={(val) => setSkAndExp({ ...skAndExp, relativeExp: Number(val.target.value) })}
                                            className="form-control custom-input"
                                            placeholder="Ex: 2, 3.5, 6.11...."
                                            required
                                        />
                                    </div>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Preferred method of working*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='preferredWork'
                                            options={OFFICE_POLICY}
                                            value={getMultiSelectedDef(OFFICE_POLICY, skAndExp.officePolicys || [])}
                                            onChange={(ev) => handleSelectChange(3, ev)}
                                            isMulti
                                            required
                                        />
                                    </div>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Preferred slot of working hours*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='timezone'
                                            options={TIMEZONES}
                                            value={TIMEZONES.filter((ct) => ct.value == skAndExp.timezone)[0]}
                                            // value={getMultiSelectedDef(TIMEZONES, skAndExp.timezone || '')}
                                            // onChange={(ev) => handleSelectChange(4, ev)}
                                            onChange={(val) => setSkAndExp({ ...skAndExp, timezone: val?.value || "" })}
                                            required
                                        />
                                    </div>
                                    <div className="col-12  margin-fix-bottam">
                                        <label>Language proficiency*</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='languages'
                                            options={languagesOptions}
                                            onChange={(ev) => handleLanguageSelect(ev)}
                                            value={getMultiSelectedDef2()}
                                            isMulti
                                            required
                                        />

                                        {
                                            skAndExp.languages.length !== 0 && (
                                                <>
                                                    {skAndExp.languages.map((lan, index) => (
                                                        <div className='row' key={`${lan.language}${index}`} >
                                                            <div className='col-md-3 col-12'>
                                                                {index == 0 && <label>Language selected</label>}
                                                                <h5 className='mt-3'>{lan.language}</h5>
                                                            </div>
                                                            <div className='col-md-8 col-12'>
                                                                {index == 0 && <label>Select Proficiency</label>}
                                                                <div className='d-flex flex-wrap mt-3' style={{ gap: "12px" }}>
                                                                    <button
                                                                        type='button'
                                                                        className={`btn button-lag-proficiancy ${lan.fluency === 'Fluent' ? 'button-lag-proficiancy-active' : ''}`}
                                                                        onClick={() => setFluency(index, "Fluent")}
                                                                    >Fluent</button>
                                                                    <button
                                                                        type='button'
                                                                        className={`btn button-lag-proficiancy ${lan.fluency === 'Intermediate' ? 'button-lag-proficiancy-active' : ''}`}
                                                                        onClick={() => setFluency(index, "Intermediate")}
                                                                    >Intermediate</button>
                                                                    <button
                                                                        type='button'
                                                                        className={`btn button-lag-proficiancy ${lan.fluency === 'Native' ? 'button-lag-proficiancy-active' : ''}`}
                                                                        onClick={() => setFluency(index, "Native")}
                                                                    >Native</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </>
                                            )
                                        }
                                    </div>

                                    <div className='d-flex mb-4' style={{ justifyContent: 'center', gap: "16px" }}> </div>
                                    <div className="container col-12 text-center margin-fix-bottam pb-5" style={{ display: "flex", gap: "20px", justifyContent: 'space-evenly' }}>
                                        <button type="button" className='btn btn-outline create-account-btn-outline p-3 col-6' style={{ fontSize: "20px" }} onClick={() => navigate("/candidate-personal-details", { state: { customer } })}>Back
                                        </button>
                                        <button type="submit" className='btn create-account-btn p-3 col-6' style={{ fontSize: "20px" }} >{loading ? 'Adding Details...' : 'Next'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EmployeeYourSkillsExperience
