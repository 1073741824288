import React, { useState } from 'react'
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';

import VerifiedIcon from '../../../Assets/Icons/material-symbols_verified.svg'
import UndrawMailbox from '../../../Assets/Icons/undraw_mailbox.svg'
import { toast } from 'react-toastify';
import Helper from '../../../utils/helper';
import OwowLogo from '../../../Assets/Icons/Logo_Horizontal_White.png'
import OwowLogoBlue from '../../../Assets/Icons/Logo_Horizontal_purple.png'

const VerificationEmailPage = () => {
    const navigate = useNavigate();
    const helper = Helper.getInstance();
    const { logout } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);

    const location = useLocation();
    const [email, setEmail] = useState<string>('');

    useEffect(() => {
        if (location.state && location.state.email) setEmail(location.state.email);

        else navigate('/employer-signup', { replace: true });
    }, []);

    const resendEmail = async () => {
        setLoading(true);
        try {
            const res_data = await helper.putReq(helper.PUT.RESEND_EMAIL, { email });
            const { data } = res_data.data;
            // console.log(data);
            toast.success('Email Resent Successfully');
        } catch (error) {
            console.log(error);
            toast.error("Resent Email Failed");
        }
        setLoading(false);
    };

    return (
        <>
            <section className='d-none d-sm-block d-print-block'>
                <div className='row'>
                    <div className='col-sm-6 d-none d-sm-block d-print-block'>
                        <div className='hero-left-col px-5'>
                            <h1 className='left-clumn-first-heading text-white'>“</h1>
                            <h1 className='left-clumn-heading text-white'>From talent acquisition to HR, legal compliance, and  logistics, we've got you covered.</h1>
                            <button className='btn btn-outline verified-button mt-2'><img src={VerifiedIcon} className='px-2' /> 100% Verfied Talents</button>
                        </div>
                    </div>
                    <div className='col-sm-6 '>
                        <div className='container cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                            <img src={OwowLogo} className='owow d-sm-none mb-4' />
                            <div className='cst-mobile-bg container'>
                                <div className='right-side-container '>
                                    {/* <h1 className='owow owow-verification-email d-none d-sm-block d-print-block'>owow</h1> */}
                                    <img src={OwowLogoBlue} className='owow owow-verification-email d-none d-sm-block d-print-block' />

                                    <img src={UndrawMailbox} className='px-2 mail-box' />
                                    <h1>Verification email on its way!</h1>
                                    <h4 className='heado-para margin-fix-bottam'>We've sent a link to <span style={{ color: "#492A99" }}>{email}</span> If it's not in your inbox, please check your spam folder.  </h4>

                                    <div className="d-flex  justify-content-center mobile-two-buttons">
                                        <button
                                            className="btn btn-outline resend-email-foremployer p-3 col-md-6 col-12 btn"
                                            onClick={() => resendEmail()}
                                        >{loading ? 'Resending...' : 'Resend Link'}
                                        </button>
                                        <button onClick={() => navigate('/employer-signup', { replace: true })} className="btn create-account-btn p-3 col-md-6 col-12" >
                                            Change Email
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='d-sm-none'>
                <div className='row'>
                    <div className='col-sm-6 '>
                        <div className='container cst-container-bg'>
                            {/* <h1 className='owow d-sm-none mb-5'>owow</h1> */}
                            <div className='text-center pt-4'>
                                <img src={OwowLogo} className='owow d-sm-none mb-5' />
                            </div>
                            <div className='container text-center'>
                                <img src={UndrawMailbox} className='px-2 mail-box mt-5' />
                                <h1 className='text-white email-sent-text'>Email Sent Successfully!</h1>
                                <h4 className='mobile-para-msg margin-fix-bottam text-white mb-4'>We've sent a link to <span className='email-color-span'>{email}</span> If it's not in your inbox, please check your spam folder.  </h4>

                                <div className="text-center">
                                    {/* <button onClick={() => navigate('/employer-login', { replace: true })}
                                        className="btn btn-outline create-account-btn-outline p-3 col-md-6 col-12"
                                        style={{ background: "white" }}>
                                        Go to inbox
                                    </button> */}
                                    <button
                                        className="btn btn-outline resend-email-foremployer p-3 col-md-6 col-12"
                                        onClick={() => resendEmail()}
                                    >{loading ? 'Resending...' : 'Resend Link'}
                                    </button>
                                    <button onClick={() => navigate('/employer-login', { replace: true })} className="btn resend-email-foremployer p-3 col-md-6 col-12 mt-2 mb-2" >
                                        Change Email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default VerificationEmailPage