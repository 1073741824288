import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { customStyles, roleTypes } from '../../../utils/constant';
import { AddSkillsModalProps, OptionType } from '../../../types';
import Helper from '../../../utils/helper';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';

export const AddOpsSkillsModal = ({ skills, skillsDB }: AddSkillsModalProps) => {

    const [sk, setSk] = useState<any[]>(skills);
    const helper = Helper.getInstance();
    const [loading, setLoading] = useState<boolean>(false);
    // const [skillsDB2, setSkillsDB] = useState<OptionType[]>(skillsDB);

    const getMultiSelectedDef3 = () => {
        if (!sk.length) return;

        const ldataArr: OptionType[] = JSON.parse(JSON.stringify(skillsDB));
        const newArr = [];

        for (let sks of sk) {
            const val = ldataArr.filter((dt) => dt.value == sks.skill);

            if (val.length) newArr.push(val[0]);
        };

        return newArr;
    };

    const handleLanguageSelect3 = (val: any) => {
        console.log(val);
        let arr = val.map((d: any) => {
            const found = sk.find((x) => x.skill == d.value);
            return { skill: d.value, experience: found?.experience || null }
        });

        setSk(arr);
    };

    const setExp = (index: number, val: number) => {
        sk[index].experience = val;
        setSk([...sk]);
        // console.log(posReqOtherData.languagesData)
    };

    const updateProfile = async (ev: any) => {
        ev.preventDefault();
        setLoading(true);
        try {
            const uData = { skills: sk };

            const res_data = await helper.putReq(helper.PUT.UPDATE_OPS_SKILLS, uData);
            const { data } = res_data.data;

            if (data) {
                setLoading(false);
                toast.success('Updated Successfully');
                document.getElementById('updatePr')?.click();
                document.getElementById("AddOpsSkillsModalToggleClose")?.click();
            }
            else {
                setLoading(false);
                throw new Error("Failed");
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Update Failed');
        }
    };

    // const handleCreateOption = async (inputValue: string) => {
    //     setSk([...sk, { skill: inputValue, exp: null }]);
    //     setSkillsDB([{ label: inputValue, value: inputValue }, ...skillsDB2]);

    //     try {
    //         await helper.postReq(helper.POST.ADD_SKILL_DATA_DB, { skill: inputValue });
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    return (
        <>
            <form name='upSkills' onSubmit={updateProfile}>
                <div className="modal fade" id="AddOpsSkillsModalToggle" aria-hidden="true" aria-labelledby="AddOpsSkillsModalToggleLabel" tabIndex={-1}>
                    <div className="modal-dialog modal-lg modal-dialog-centered add-skills-modal-cst" >
                        <div className="modal-content p-3" style={{ borderRadius: '36px' }}>
                            <div className="modal-header">
                                <h3>Update Additional Skills</h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-12 margin-fix-bottam">
                                        <label>Skill</label>
                                        <Select
                                            styles={customStyles}
                                            className="mb-4"
                                            placeholder="Select"
                                            name='Skills'
                                            options={skillsDB}
                                            onChange={(ev) => handleLanguageSelect3(ev)}
                                            // onCreateOption={(ev: string) => handleCreateOption(ev)}
                                            value={getMultiSelectedDef3()}
                                            isMulti
                                            required
                                            isOptionDisabled={() => sk.length >= 5}
                                        />

                                        {sk.length !== 0 && (
                                            <>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <h6 className='cst-secondary-color'>Skills selected</h6>
                                                    </div>
                                                    <div className='col-6 text-start'>
                                                        <h6 className='cst-secondary-color'>Relevant years of experience</h6>
                                                    </div>

                                                </div>
                                                {
                                                    sk.map((sks, index) => (
                                                        <div className='row cst-relevant-years-of-experience mt-2' key={`${sks.skill}${index}`} >
                                                            <div className='col-6'>
                                                                <h5>{sks.skill}</h5>
                                                            </div>
                                                            <div className='col-6'>
                                                                <input
                                                                    name={`${sks.skill}${index}`}
                                                                    type="number"
                                                                    className="form-control custom-input"
                                                                    id={`${sks.skill}${index}`}
                                                                    onChange={(ev) => setExp(index, Number(ev.target.value || 0))}
                                                                    value={sks.experience}
                                                                    min={1}
                                                                    max={60}
                                                                />
                                                            </div>
                                                            {/* <div className='col-2 text-end'>
                                                                <img src={CloseIcon} className='img-fluid cst-cursor' />
                                                            </div> */}

                                                        </div>
                                                    ))}

                                            </>
                                        )}
                                    </div>
                                    <div className='d-flex my-2 chnagepasss-cst-col' style={{ gap: "16px", justifyContent: "flex-end" }}>
                                        <button
                                            type='submit'
                                            className='btn create-account-btn'
                                            style={{ minWidth: "159px" }}
                                            disabled={loading}
                                        >{loading ? 'Updating...' : 'Update'}
                                        </button>
                                        <button
                                            type='button'
                                            id='AddOpsSkillsModalToggleClose'
                                            className='btn social-media-btn'
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            style={{ minWidth: "159px" }}
                                        >Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}
